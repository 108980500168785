import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ServiceTermsService } from "projects/app/src/app/services/http/clients/reporting-app/service-terms.service";
import { salesMainFilters } from "./shared/main-filters";
import {
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    projectDueDateEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    projectStatusEditorConfig
} from "./shared/additional-filters";
import { WidgetType } from "../../types/report-widget-type.enum";
import { percentageFormatter } from "projects/app/src/app/utils/format-functions";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";

export const ServiceTerms: IReportConfiguration<ServiceTermsService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ServiceTermsService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "term1", label: "Term 1" },
                        { name: "term2", label: "Term 2" },
                        { name: "term3", label: "Term 3" },
                        { name: "term4", label: "Term 4" },
                        { name: "term5", label: "Term 5" },
                        { name: "term6", label: "Term 6" },
                        { name: "term7", label: "Term 7" },
                        { name: "term8", label: "Term 8" },
                        { name: "term9", label: "Term 9" },
                        { name: "term10", label: "Term 10" },
                        { name: "term11", label: "Term 11" },
                        { name: "term12", label: "Term 12" }
                    ],
                    rows: [
                        { name: "quantity", label: "Quantity" },
                        { name: "directCost", label: "Cost", format: "currency" },
                        { name: "contingency", label: "Contingency (%) ", format: "percent" },
                        { name: "escalation", label: "Escalation (%) ", format: "percent" },
                        { name: "grossMargin", label: "Gross Margin (%) ", format: "customPercent" },
                        { name: "sellPrice", label: "Sell Price", format: "currency" },
                        { name: "totalDirectCost", label: "TDC ($)", format: "currency" },
                        { name: "totalDirectCostPercent", label: "TDC (%) ", format: "percent" },
                        { name: "avgSellPrice", label: "Avg Sell Price ", format: "currency" },
                        { name: "avgSellPricePercentage", label: "Avg Sell Price (%) ", format: "percent" }
                    ]
                }
            },
            {
                methodName: "getLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Services Terms by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "term",
                            yKey: "sellPrice",
                            yName: "Total Sell Price "
                        },
                        {
                            type: "line",
                            xKey: "term",
                            yKey: "grossMarginPercent",
                            yName: "Total Gross Margin (%) "
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice"],
                            title: {
                                text: "Amount"
                            }
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["grossMarginPercent"],
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "sellPrice",
                            yName: "Total Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "tdc",
                            yName: "Total Direct Cost ($)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice", "tdc"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.FourRowStack
    },
    gridTab: {
        widgets: [
            {
                methodName: "getValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "serviceName", headerName: "Service Name", enableRowGroup: true, lockVisible: true },
                    { field: "usedInAwarded", headerName: "Total in Awarded Final Estimates (qty) ", enableRowGroup: true },
                    { field: "totalSellPrice", headerName: "Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "gmPercentage", headerName: "Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "avgSellPrice", headerName: "Average Sell Price", type: "currency", aggFunc: "sum" },
                    { field: "totalTerms", headerName: "Total Number of Terms " },
                    {
                        field: "avgNumberOfTerms",
                        headerName: "Average Number of Terms ",
                        type: "decimal2",
                        aggFunc: "avg"
                    },

                    { field: "qty1", headerName: "Total Term 1 (qty) " },
                    { field: "gmPercentage1", headerName: "Term 1 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice1", headerName: "Term 1 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice1", headerName: "Term 1 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage1", headerName: "Term 1 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty2", headerName: "Total Term 2 (qty) " },
                    { field: "gmPercentage2", headerName: "Term 2 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice2", headerName: "Term 2 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice2", headerName: "Term 2 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage2", headerName: "Term 2 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty3", headerName: "Total Term 3 (qty) " },
                    { field: "gmPercentage3", headerName: "Term 3 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice3", headerName: "Term 3 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice3", headerName: "Term 3 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage3", headerName: "Term 3 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty4", headerName: "Total Term 4 (qty) " },
                    { field: "gmPercentage4", headerName: "Term 4 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice4", headerName: "Term 4 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice4", headerName: "Term 4 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage4", headerName: "Term 4 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty5", headerName: "Total Term 5 (qty) " },
                    { field: "gmPercentage5", headerName: "Term 5 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice5", headerName: "Term 5 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice5", headerName: "Term 5 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage5", headerName: "Term 5 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty6", headerName: "Total Term 6 (qty) " },
                    { field: "gmPercentage6", headerName: "Term 6 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice6", headerName: "Term 6 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice6", headerName: "Term 6 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage6", headerName: "Term 6 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty7", headerName: "Total Term 7 (qty) " },
                    { field: "gmPercentage7", headerName: "Term 7 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice7", headerName: "Term 7 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice7", headerName: "Term 7 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage7", headerName: "Term 7 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty8", headerName: "Total Term 8 (qty) " },
                    { field: "gmPercentage8", headerName: "Term 8 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice8", headerName: "Term 8 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice8", headerName: "Term 8 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage8", headerName: "Term 8 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty9", headerName: "Total Term 9 (qty) " },
                    { field: "gmPercentage9", headerName: "Term 9 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice9", headerName: "Term 9 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice9", headerName: "Term 9 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage9", headerName: "Term 9 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty10", headerName: "Total Term 10 (qty) " },
                    { field: "gmPercentage10", headerName: "Term 10 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice10", headerName: "Term 10 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice10", headerName: "Term 10 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage10", headerName: "Term 10 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty11", headerName: "Total Term 11 (qty) " },
                    { field: "gmPercentage11", headerName: "Term 11 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice11", headerName: "Term 11 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice11", headerName: "Term 11 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage11", headerName: "Term 11 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" },

                    { field: "qty12", headerName: "Total Term 12 (qty) " },
                    { field: "gmPercentage12", headerName: "Term 12 Total Gross Margin (%) ", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice12", headerName: "Term 12 Total Sell Price ", type: "currency", aggFunc: "sum" },
                    { field: "avgSellPrice12", headerName: "Term 12 Average Sell Price ", type: "currency", aggFunc: "avg" },
                    { field: "sellPricePercentage12", headerName: "Term 12 Average Sell Price (%) ", type: "customPercentage", aggFunc: "avg" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
