import { Component, OnInit } from "@angular/core";
import { ArchiveEntityOptionView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";

@Component({
    selector: "app-active-status-editor",
    templateUrl: "./active-status-editor.component.html",
    styleUrls: ["./active-status-editor.component.scss"]
})
export class ActiveStatusEditorComponent extends EditorComponentBase<boolean | null> implements OnInit {
    statuses: ArchiveEntityOptionView[];
    selectedStatus?: ArchiveEntityOptionView;

    constructor(private archiveEntityService: ArchiveEntityService) {
        super();
        this.statuses = [];
    }

    async ngOnInit(): Promise<void> {
        this.statuses = await this.archiveEntityService.getArchiveOptions();
        this.selectedStatus = this.statuses?.find(x => x.value === this.bindingProperty);
    }

    onSelectStatus() {
        this.bindingProperty = this.selectedStatus?.value ?? null;
        this.valueChange.emit();
    }
}
