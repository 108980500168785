import { SalesLeadService } from "projects/app/src/app/services/http/clients/reporting-app/sales-lead.service";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { WidgetType } from "../../types/report-widget-type.enum";
import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    expectedResponseDateEditorConfig,
    marketsEditorConfig,
    opportunityCreationDateEditorConfig,
    opportunityDueDateEditorConfig,
    opportunitySourceEditorConfig,
    potentialValueEditorConfig,
    probabilityEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig
} from "./shared/additional-filters";

export const SalesLeads: IReportConfiguration<SalesLeadService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: SalesLeadService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getSalesLeadBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getSalesLeadMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [
                        { name: "PotentialValue", label: "Potential Value ($)", format: "currency" },
                        { name: "PotentialGrossMarginPercent", label: "Potential GM (%)", format: "customPercent" }
                    ]
                }
            },
            {
                methodName: "getSalesLeadLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Sales Lead by Potential Value"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "leadName",
                            yKey: "potentialValue",
                            yName: "Potential Value ($)"
                        },
                        {
                            type: "line",
                            xKey: "leadName",
                            yKey: "potentialGrossMarginPercent",
                            yName: "Potential GM (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["potentialValue"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["potentialGrossMarginPercent"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getSalesLeadColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "userName",
                            yKey: "totalPotentialValue",
                            yName: "Total Potential Value ($)"
                        },
                        {
                            type: "line",
                            xKey: "userName",
                            yKey: "totalGMPercent",
                            yName: "Total Potential GM (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["totalPotentialValue"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["totalGMPercent"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview3
    },
    gridTab: {
        widgets: [
            {
                methodName: "getSalesLeadValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "opportunityCode", headerName: "Opportunity ID", lockVisible: true },
                    { field: "opportunityName", headerName: "Opportunity Name", lockVisible: true },
                    { field: "creationDate", headerName: "Creation Date", type: "date" },
                    { field: "dueDate", headerName: "Due Date", type: "date" },
                    { field: "customer", headerName: "Customer" },
                    { field: "account", headerName: "Account" },
                    { field: "potentialValue", headerName: "Potential Value ($)", type: "currency" },
                    { field: "potentialGrossMarginPercent", headerName: "Potential GM (%)", type: "customPercentage" },
                    { field: "division", headerName: "Division" },
                    { field: "salesPerson", headerName: "Salesperson" },
                    { field: "status", headerName: "Status (Unqualified)" },
                    { field: "probability", headerName: "Probability" },
                    { field: "speculativeValue", headerName: "Speculative Value", type: "currency" },
                    { field: "market", headerName: "Market" },
                    { field: "lead", headerName: "Opportunity Source" },
                    { field: "organization", headerName: "Organization" },
                    { field: "condition", headerName: "Condition" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            opportunityCreationDateEditorConfig,
            opportunityDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            accountEditorConfig,
            potentialValueEditorConfig,
            probabilityEditorConfig,
            expectedResponseDateEditorConfig,
            conditionEditorConfig
        ]
    }
};
