<lib-section-header [title]="'Opportunities'" [iconName]="'opportunities'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" (click)="onCreateNewOpportunityClicked()">+ New Opportunity</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="filters">
    <app-opportunities-filters
        [(filters)]="filterValue"
        [mainFiltersToClear]="currentTab === 'Calendar' ? ['dateFrom', 'dateTo'] : undefined"
    ></app-opportunities-filters>
</div>

<div class="tabGroup">
    <lib-tab-view [(currentTab)]="currentTab" [hasSteps]="false" [alignment]="'end'">
        <lib-tab-item tabName="List" [bodyTemplate]="list" [icon]="listIcon">
            <ng-template #list>
                <div class="tab">
                    <app-opportunities-list [filters]="filterValue"></app-opportunities-list>
                </div>
            </ng-template>
        </lib-tab-item>
        <lib-tab-item tabName="Calendar" [bodyTemplate]="calendar" [icon]="calendarIcon">
            <ng-template #calendar>
                <div class="tab">
                    <app-opportunities-calendar [filters]="filterValue"></app-opportunities-calendar>
                </div>
            </ng-template>
        </lib-tab-item>

        <lib-tab-item tabName="Map" [bodyTemplate]="map" [icon]="mapIcon">
            <ng-template #map>
                <div class="tab">
                    <app-opportunities-map [filters]="filterValue"></app-opportunities-map>
                </div>
            </ng-template>
        </lib-tab-item>
    </lib-tab-view>
</div>
