
<button
    (click)="openCloseGroups($event)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="tertiary-btn group-by-btn"
    [ngClass]="{ opened: isOverlayGroupsOpen }"
>
    <span class="icon groupBy" [innerHTML]="groupByIcon"></span>
    Group by
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOverlayGroupsOpen"
    (overlayOutsideClick)="restoreLastGroupsApplied(); isOverlayGroupsOpen = false"
    (detach)="isOverlayGroupsOpen = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="isOverlayGroupsOpen = false"
>
    <div class="content" [ngClass]="{ show: isOverlayGroupsOpen }">
        <ng-container *ngIf="!groupOpened">

            <div class="content-body">
                <button
                    *ngFor="let item of groupingLevels"
                    class="resetStyles group-btn"
                    (click)="openLevel(item, $event)"
                    [disabled]="!groupIsEnable(item)"
                >
                    <span class="group-level">{{ item.name }}</span>
                    <small class="group-title" *ngIf="appliedGroups[item.level - 1]">{{appliedGroups[item.level - 1].headerName}}</small>
                    <span class="icon nextArrow" [innerHTML]="arrowIcon"></span>
                </button>
            </div>
            <div class="buttons-section">
                <button
                    class="resetStyles clear-btn"
                    [disabled]="!appliedGroups.length"
                    (click)="clearSelection()"
                >
                    Clear All
                </button>
                <button
                    class="resetStyles apply-btn"
                    [disabled]="!appliedGroups.length"
                    (click)="applyGroups()"
                >
                    Apply
                </button>
            </div>
        </ng-container>

        <app-column-selector
            *ngIf="groupOpened"
            cdkTrapFocus
            cdkTrapFocusAutoCapture="true"
            [cols]="getAvailableCols(groupOpened)"
            [group]="groupOpened"
            [appliedGroups]="appliedGroups"
            (selectedColumnChange)="applyGroup(groupOpened, $event)"
            (closeOverlayEvent)="closeOverlay()"
        ></app-column-selector>
    </div>

</ng-template>