import { Injectable } from "@angular/core";
import { AccountClient, SearchAccountsBasicDataParameters } from "./reporting-api-proxies";
import { BaseDtoSearchService, IBaseDto } from "./base-dto.service";

@Injectable({
    providedIn: "root"
})
export class ReportingAccountSearchService extends BaseDtoSearchService {
    private client: AccountClient;
    constructor() {
        super();
        this.client = new AccountClient();
    }

    async search(filterText: string): Promise<IBaseDto[]> {
        return await this.client.accountSearchBasicData({ filterText } as SearchAccountsBasicDataParameters);
    }

    override getById(id: number): Promise<IBaseDto> {
        throw new Error("Method not implemented.");
    }

    async getByIds(accountIds: number[]): Promise<IBaseDto[]> {
        return await this.client.accountSearchBasicData({
            accountIds
        } as SearchAccountsBasicDataParameters);
    }
}
