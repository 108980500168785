/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MessageBusService, RegistrationToken } from "projects/ngx-lib/src/public-api";
import { UserInformationChangedMessage } from "../../../messages/user-information-changed.message";
import { Messages } from "../../../messages/messages";

interface User {
    firstName: string;
    lastName: string;
    roleName?: string;
}
@Component({
    selector: "app-user-avatar",
    templateUrl: "./user-avatar.component.html",
    styleUrls: ["./user-avatar.component.scss"]
})
export class UserAvatarComponent implements OnInit, OnDestroy {
    private userInformationChangedToken?: RegistrationToken;

    @Input()
    users: User[] | undefined;

    @Input()
    showHeading?: boolean;

    @Input()
    showSubheading?: boolean;

    @Input()
    size: "small" | "normal";

    avatar: string | null;

    heading: string;

    subheading: string;

    tooltip: string;

    constructor(private readonly messageBusService: MessageBusService) {
        this.users = [];
        this.showHeading = true;
        this.showSubheading = true;
        this.avatar = "";
        this.heading = "";
        this.subheading = "";
        this.tooltip = "";
        this.size = "normal";
    }

    ngOnDestroy(): void {
        if (this.userInformationChangedToken) this.messageBusService.unregister(this.userInformationChangedToken);
    }

    ngOnInit() {
        this.userInformationChangedToken = this.messageBusService.register<UserInformationChangedMessage>(Messages.UserInformationChanged, message =>
            this.renderUser(message.userInformation)
        );

        if (this.users?.length) {
            this.renderUser(this.users[0]);
            this.renderUsers();
        }
    }

    private renderUser(user: { firstName: string; lastName: string }) {
        this.heading = this.getHeading(user);
        this.avatar = this.getAvatar(user);
    }

    private renderUsers() {
        if (this.users?.length) {
            this.subheading = this.getSubheading(this.users);
            this.tooltip = this.getTooltip(this.users);
        }
    }

    private getAvatar(user: User): string | null {
        const heading = this.getHeading(user).trim();
        const headingArray = heading.split(" ");
        if (headingArray.length) {
            const acronym = headingArray.map(word => word[0].replace(/[^a-zA-Z]/g, "")).join("");
            return acronym.substring(0, 3);
        }

        return null;
    }

    private getHeading(user: User): string {
        return `${user.firstName.trim()} ${user.lastName.trim()}`;
    }

    private getSubheading(users: User[]): string {
        if (users.length === 1) return users[0].roleName ?? "";

        return `+${users.length - 1} Person${users.length - 1 > 1 ? "s" : ""}`;
    }

    private getTooltip(users: User[]): string {
        const [, ...rest] = users;
        const restUsersData = rest.map(u => `${u.firstName} ${u.lastName}`);
        return restUsersData.join(",<br />");
    }
}
