import { IconSanitizerService } from "./../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SafeHtml } from "@angular/platform-browser";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AddressService } from "../../../services/http/clients/address.service";
import { AddressView } from "../../../services/http/clients/api-proxies";
import { AddressTypes } from "../../../models/enums/AddressTypes";

interface AddressType {
    icon: SafeHtml;
    typeName: string;
}

@Component({
    selector: "app-type-ahead-addresses",
    templateUrl: "./type-ahead-addresses.component.html",
    styleUrls: ["./type-ahead-addresses.component.scss"]
})
export class TypeAheadAddressesComponent {
    globeIcon: SafeHtml;
    markerPinIcon: SafeHtml;

    @Input()
    selectedAddress?: AddressView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    error?: boolean;

    @Output()
    selectedAddressChange = new EventEmitter<AddressView>();

    searchText: string;

    source?: AddressView[];

    addressTypes: { [key: number]: AddressType };

    constructor(
        private readonly addressService: AddressService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.searchText = "";
        this.isReadOnly = false;

        this.globeIcon = this.iconSanitizer.getIcon("globe");
        this.markerPinIcon = this.iconSanitizer.getIcon("markerPin");

        this.addressTypes = {
            [AddressTypes.Address]: {
                icon: this.markerPinIcon,
                typeName: "Address"
            },
            [AddressTypes.Block]: {
                icon: this.globeIcon,
                typeName: "Block"
            },
            [AddressTypes.Country]: {
                icon: this.globeIcon,
                typeName: "Country"
            },
            [AddressTypes.District]: {
                icon: this.globeIcon,
                typeName: "District"
            },
            [AddressTypes.Locality]: {
                icon: this.globeIcon,
                typeName: "Locality"
            },
            [AddressTypes.Neighborhood]: {
                icon: this.globeIcon,
                typeName: "Neighborhood"
            },
            [AddressTypes.Place]: {
                icon: this.globeIcon,
                typeName: "Place"
            },
            [AddressTypes.PostCode]: {
                icon: this.globeIcon,
                typeName: "Post Code"
            },
            [AddressTypes.Region]: {
                icon: this.globeIcon,
                typeName: "Region"
            },
            [AddressTypes.SecondaryAddress]: {
                icon: this.markerPinIcon,
                typeName: "Secondary Address"
            },
            [AddressTypes.Street]: {
                icon: this.globeIcon,
                typeName: "Street"
            }
        };
    }

    async onAddressTextChanged(): Promise<void> {
        if (this.searchText?.length > 2) {
            const limit = 10;
            this.source = await this.addressService.searchPlaces(this.searchText, limit);
        } else {
            this.source = [];
        }
    }

    onAddressChange(itemSelected: any): void {
        this.selectedAddress = itemSelected;
        this.selectedAddressChange.emit(itemSelected);
        this.source = [];
    }
}
