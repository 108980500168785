import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { EntityComponentType } from "../../../../shared/card-editors/entity-editor/entity-enums/entity";

@Component({
    selector: "app-buildings-single-overview",
    templateUrl: "./buildings-single-overview.component.html",
    styleUrls: ["./buildings-single-overview.component.scss"]
})
export class BuildingsSingleOverviewComponent implements OnChanges {
    @Input()
    building?: BuildingView;

    @Input()
    isReadOnly: boolean;

    factory: AppEditorFactory;

    mainInfoCardConfig!: IEditorItemConfig[];
    locationCardConfig!: IEditorItemConfig[];
    additionalInfoCardConfig!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    constructor() {
        this.factory = new AppEditorFactory();
        this.isReadOnly = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.building && changes["building"]) {
            this.mainInfoCardConfig = [
                {
                    label: "Name",
                    componentId: EditorType.textInput,
                    fieldName: "name",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Alternate Name",
                    componentId: EditorType.multiInput,
                    fieldName: "alternateNames",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Class",
                    componentId: EditorType.buildingClass,
                    fieldName: "buildingBuildingClasses",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Website",
                    componentId: EditorType.link,
                    fieldName: "website",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Description",
                    componentId: EditorType.textarea,
                    fieldName: "description",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                }
            ];

            this.additionalInfoCardConfig = [
                {
                    label: "Property Manager",
                    componentId: EditorType.company,
                    fieldName: "propertyManagerCompanyId",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        allowToNavigate: true
                    }
                },
                {
                    label: "Number Of Floors",
                    componentId: EditorType.numberInput,
                    fieldName: "numberOfFloors",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                { label: "Building (sq ft)", componentId: EditorType.numberInput, fieldName: "size", bindContext: this.building, isReadOnly: this.isReadOnly },
                {
                    label: "Typical Floor Size (sq ft)",
                    componentId: EditorType.numberInput,
                    fieldName: "floorSize",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Product Types",
                    componentId: EditorType.entity,
                    fieldName: "buildingProductTypes",
                    entityId: "buildingProductType",
                    bindContext: this.building,
                    additionalInfo: {
                        entityType: EntityTypes.Building
                    },
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Salesperson",
                    componentId: EditorType.entityUser,
                    fieldName: "buildingSalespersons",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly
                }
            ];

            this.locationCardConfig = [
                {
                    componentId: EditorType.building,
                    fieldName: "id",
                    bindContext: this.building,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { isBuildingOverview: true }
                }
            ];

            this.customFieldsConfig =
                this.building?.customFieldValues?.map(item => {
                    return {
                        label: item.name,
                        componentId: item.editor,
                        bindContext: item,
                        fieldName: "value",
                        isReadOnly: this.isReadOnly
                    } as IEditorItemConfig;
                }) ?? [];
        }
    }
}
