<app-generic-overlay
    [overlayStyles]="{
        '--overlay-min-width': '300px',
        '--overlay-max-width': '300px',
        '--overlay-margin-right': '8px'
    }"
    [overlayOrigin]="overlayOrigin"
    [mainActionable]="mainActionable"
    [actionButtonsRef]="actionButtonsRef"
>
    <section class="container">
        <lib-search-input
            class="filter"
            [text]="searchQuery"
            placeholderText="Search columns..."
            (textChange)="onSearchTextChange($event)"
            [clearValueButton]="true"
            [searchIcon]="true"
        >
        </lib-search-input>
        <ul class="list">
            <li class="item-list" *ngIf="filteredUnlockedCols.length > 0">
                <input class="item-input" type="checkbox" id="all" name="all" [(ngModel)]="allSelected" (ngModelChange)="onAllChange($event)" />
                <label class="item-label" for="all">All</label>
            </li>
            <li class="item-list" *ngFor="let option of filteredUnlockedCols; let i = index">
                <input
                    type="checkbox"
                    [id]="option.field"
                    [name]="i.toString()"
                    [value]="option.field"
                    class="item-input"
                    [(ngModel)]="state[option.field!]"
                    (ngModelChange)="onChange($event, option.field!)"
                />
                <label class="item-label" [for]="option.field">{{ option.headerName }}</label>
            </li>

            <ng-container *ngIf="filteredLockedCols.length > 0">
                <li class="item-list locked" *ngFor="let option of filteredLockedCols">
                    <label class="item-label" [for]="option.field">{{ option.headerName }}</label>
                    <span class="icon" [innerHTML]="lockedIcon"></span>
                </li>
            </ng-container>
        </ul>
        <ng-template #actionButtonsRef let-close="close">
            <button class="tertiary-btn apply-btn" (click)="apply(); close()" [disabled]="isApplyDisabled">Apply</button>
        </ng-template>
    </section>
</app-generic-overlay>

<div cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <ng-template #mainActionable let-openClose="openClose">
        <button class="tertiary-btn" (click)="openClose()">
            <span class="icon" [innerHTML]="settingsPanel"></span>
        </button>
    </ng-template>
</div>
