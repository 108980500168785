import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { ContactView, EntityCustomFieldValueView } from "projects/app/src/app/services/http/clients/api-proxies";
import { GenericCardComponent } from "projects/ngx-lib/src/lib/components/generic-card/generic-card.component";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-contacts-single-overview",
    templateUrl: "./contacts-single-overview.component.html",
    styleUrls: ["./contacts-single-overview.component.scss"]
})
export class ContactsSingleOverviewComponent implements OnChanges {
    @Input()
    contact?: ContactView;

    @Input()
    customFields?: EntityCustomFieldValueView[];

    @Input()
    isReadOnly: boolean;

    factory: AppEditorFactory;

    mainInfoCardConfig!: IEditorItemConfig[];
    companyInfoCard!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    @ViewChild("mainInfoCard")
    mainInfoCard?: GenericCardComponent;

    constructor(private readonly entityCustomFieldService: EntityTypeCustomFieldService) {
        this.factory = new AppEditorFactory();
        this.isReadOnly = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.contact && changes["contact"]) {
            this.mainInfoCardConfig = [
                {
                    label: "First Name",
                    componentId: EditorType.textInput,
                    fieldName: "firstName",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Last Name",
                    componentId: EditorType.textInput,
                    fieldName: "lastName",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Linkedin",
                    componentId: EditorType.link,
                    fieldName: "linkedin",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "E-mail",
                    componentId: EditorType.multiInput,
                    fieldName: "email",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.email }, { type: ValidatorType.string, args: { maxLength: 200 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Phone",
                    componentId: EditorType.multiInput,
                    fieldName: "phone",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 20 } }]
                },
                {
                    label: "Address",
                    componentId: EditorType.multiInput,
                    fieldName: "address",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "Description",
                    componentId: EditorType.textarea,
                    fieldName: "description",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 1000 } }]
                }
            ];

            this.companyInfoCard = [
                {
                    label: "Company",
                    componentId: EditorType.company,
                    fieldName: "companyId",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    // this property 'additionalInfo: { allowAction: true }' must be set if we want to allow the user to create a new company by opening quick add modal
                    additionalInfo: {
                        allowAction: true,
                        hasMainContactSet: !!this.contact?.isMain,
                        isActive: true,
                        allowToNavigate: true
                    }
                },
                {
                    label: "Position",
                    componentId: EditorType.textInput,
                    fieldName: "position",
                    bindContext: this.contact,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                }
            ];

            if (!this.contact.customFieldValues?.length)
                this.contact.customFieldValues = await this.entityCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Contact);
            this.customFieldsConfig =
                this.contact.customFieldValues.map((item: EntityCustomFieldValueView) => {
                    return {
                        label: item.name,
                        componentId: item.editor,
                        bindContext: item,
                        fieldName: "value",
                        isReadOnly: this.isReadOnly
                    } as IEditorItemConfig;
                }) ?? [];
        }
    }
}
