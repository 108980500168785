import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ModalService, TabViewComponent } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";
import { getTab, processTabParameter } from "projects/app/src/app/utils/tab-navigation";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-contacts-single",
    templateUrl: "./contacts-single.component.html",
    styleUrls: ["./contacts-single.component.scss"]
})
export class ContactsSingleComponent implements OnInit, AfterViewInit {
    @ViewChild(TabViewComponent)
    tabControl!: TabViewComponent;

    contactId: number;

    contact?: ContactView;

    currentTab: string;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    tabsCounters: TabCounterType = {
        Opportunities: 0,
        Buildings: 0,
        Notes: 0
    };

    constructor(
        private readonly contactService: ContactService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.contactId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = getTab(this.route.snapshot.paramMap.get("tab")) ?? TabNames[TabNames.Overview];
        this.isActive = true;
        this.entityTypeId = EntityTypes.Contact;
    }

    ngAfterViewInit(): void {
        this.currentTab = processTabParameter(this.route, this.tabControl);
    }

    async ngOnInit(): Promise<void> {
        await this.handleContactChange();

        this.route.paramMap.subscribe(async params => {
            const newId = parseInt(params.get("id") ?? "0");
            const newTab = getTab(params.get("tab"));

            if (newId && newId !== this.contactId) {
                this.contactId = newId;
                await this.handleContactChange();
            }

            if (newTab && newTab !== this.currentTab && this.tabControl) {
                this.currentTab = processTabParameter(this.route, this.tabControl);
            }
        });
    }

    onEditContact() {
        this.router.navigate([`/contacts-edit/${this.contactId}`]);
    }

    async archiveContact(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this contact?",
            title: "Archive Contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Contact", async () => {
            if (!this.contact?.id) return;

            await this.contactService.deactivate(this.contact?.id);
            this.isActive = false;
        });
    }

    async restoreContact(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this contact?",
            title: "Restore Contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Contact", async () => {
            if (!this.contact?.id) return;
            await this.contactService.activate(this.contact.id);
            this.isActive = true;
        });
    }

    async onTabCountersChange(): Promise<void> {
        await this.updateTabCounters();
    }

    tabChange(tabName: string) {
        this.router.navigate(["/contacts-single", this.contactId, tabName]);
    }

    private async handleContactChange(): Promise<void> {
        if (!this.contactId) return;

        const res = await this.updateContactInfo();
        if (res) await this.updateTabCounters();
    }

    private async updateContactInfo(): Promise<boolean | undefined> {
        return await this.awaiter.awaitAction("Getting Contact Info", async () => {
            this.contact = await this.contactService.getById(this.contactId);
            this.isActive = this.contact.isActive;
            return true;
        });
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.contactId, this.tabsCounters);
    }
}
