import { Component, HostListener, Input, OnInit } from "@angular/core";
import { UserOrganization } from "../../../models/UserOrganization";
import { UserOrganizationService } from "../../../services/http/clients/user-organization.service";
import { Router } from "@angular/router";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { Messages } from "../../../messages/messages";
import { OrganizationChangedMessage } from "../../../messages/organization-changed.message";
import { MessageBusService, TooltipPosition } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-organization-switcher",
    templateUrl: "./organization-switcher.component.html",
    styleUrls: ["./organization-switcher.component.scss"]
})
export class OrganizationSwitcherComponent implements OnInit {
    @Input()
    selectedOrganization?: UserOrganization;

    beforeOpenSelectedOrganization?: UserOrganization;

    opened: boolean;

    placeholder: string;

    organizations: UserOrganization[];

    selectedOrganizationTooltipPosition: TooltipPosition;

    constructor(
        private readonly userOrganizationService: UserOrganizationService,
        private readonly basicUserOrganizationService: BasicUserOrganizationService,
        private readonly messageBusService: MessageBusService,
        public readonly router: Router
    ) {
        this.opened = false;
        this.placeholder = "Select an organization";
        this.organizations = [];
        this.selectedOrganizationTooltipPosition = TooltipPosition.BELOW;
    }

    async ngOnInit(): Promise<void> {
        const organizations = await this.userOrganizationService.userOrganizationGetForCurrentUser();
        if (organizations.length > 0) this.organizations = organizations.map(organization => UserOrganization.fromInterface(organization));

        const organizationIdFromStorage = this.basicUserOrganizationService.getUserOrganization();
        this.selectedOrganization = this.organizations.find(organization => {
            return organization.organizationId === organizationIdFromStorage?.id;
        });

        if (!this.selectedOrganization && this.organizations.length > 0) {
            this.selectedOrganization = this.organizations[0];
            if (!this.selectedOrganization.organizationId?.toString() || !this.selectedOrganization.userRoleId) return;
            this.basicUserOrganizationService.setUserOrganization({
                id: this.selectedOrganization.organizationId,
                roleId: this.selectedOrganization.userRoleId
            });
        }
    }

    openSwitcher(): void {
        this.beforeOpenSelectedOrganization = this.selectedOrganization;
        if (!this.opened) this.opened = true;
    }

    applyChanges(): void {
        const shouldReload = this.basicUserOrganizationService.getUserOrganization() !== this.selectedOrganization?.organizationId?.toString();
        if (this.selectedOrganization?.organizationId?.toString() && shouldReload && this.selectedOrganization.userRoleId) {
            this.basicUserOrganizationService.setUserOrganization({
                id: this.selectedOrganization?.organizationId,
                roleId: this.selectedOrganization.userRoleId
            });
            this.messageBusService.send(Messages.OrganizationChanged, new OrganizationChangedMessage(true));
        }
        this.router.navigate(["/"]);
        this.opened = false;
    }

    selectItem(item: UserOrganization): void {
        if (this.selectedOrganization != item) this.selectedOrganization = item;

        this.applyChanges();
    }

    reloadComponent(): void {
        const currentUrl = this.router.url;

        if (this.urlMatchesCriteria(currentUrl)) {
            this.router.navigate(["/dashboard"]);
        } else {
            this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
                this.router.navigate([`/${currentUrl}`]);
            });
        }
    }

    urlMatchesCriteria(url: string): boolean {
        const criteria = ["-single", "/notifications", "/reports-", "/forbidden"];
        return criteria.some(criterion => url.includes(criterion));
    }

    keyup(event: KeyboardEvent, item: UserOrganization) {
        if (event.key === "Enter") {
            this.selectItem(item);
            this.applyChanges();
        }
    }

    @HostListener("document:click", ["$event"])
    private onDocumentClicked(event: MouseEvent): void {
        if (this.opened && (event.target as HTMLElement).classList.contains("panelWrapper")) {
            this.opened = false;
            this.selectedOrganization = this.beforeOpenSelectedOrganization;
        }
    }

    @HostListener("document:keydown", ["$event"])
    private onDocumentKeydown(event: KeyboardEvent): void {
        if (event.key === "Escape" && this.opened) {
            this.opened = false;
            this.selectedOrganization = this.beforeOpenSelectedOrganization;
        }
    }
}

export interface IModalOrganizationParameters {
    title: string;
    applyButton: string;
    organizations?: UserOrganization[];
}
