import { BuildingContactView, ContactView, EntityCustomFieldValueView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Contact extends ModelBase implements ContactView {
    organizationId: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string[] | undefined;
    phone: string[] | undefined;
    linkedin: string | undefined;
    address: string[] | undefined;
    createdByUserId: number | undefined;
    companyId: number | undefined;
    companyName: string | undefined;
    companyTypeId: number | undefined;
    companyTypeName: string | undefined;
    organizationName: string | undefined;
    position: string | undefined;
    isMain: boolean | undefined;
    fullName: string | undefined;
    isActive: boolean;
    buildingContacts: BuildingContactView[] | undefined;
    customFieldValues: EntityCustomFieldValueView[] | undefined;
    description: string | undefined;

    constructor() {
        super();
        this.organizationId = undefined;
        this.firstName = "";
        this.lastName = "";
        this.email = [];
        this.phone = [];
        this.linkedin = "";
        this.address = [];
        this.createdByUserId = undefined;
        this.companyId = undefined;
        this.companyTypeId = undefined;
        this.companyTypeName = "";
        this.companyName = "";
        this.organizationName = "";
        this.position = "";
        this.isMain = false;
        this.fullName = "";
        this.isActive = false;
        this.buildingContacts = [];
        this.customFieldValues = [];
        this.description = "";
    }

    static fromInterface(dto: ContactView): Contact {
        const contact = new Contact();
        contact.mapFromInterface(dto);
        return contact;
    }
}
