<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data
        [iconName]="'emptyData'"
        [title]="'There are no reports created yet!'"
        [linkText]="createNewReportLink"
        (linkAction)="onCreateNewReportExecution()"
    ></lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'description', label: 'Description', sortable: false },
                { key: 'reportTypeName', label: 'Report Type', sortable: false },
                { key: 'executionDate', label: 'Last Run', sortable: true, cellTemplate: cellDueDate },
                { key: 'userFullName', label: 'Created By', sortable: false },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [source]="reportsExecutions"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
    >
        >

        <ng-template #cellDueDate let-data>
            {{ data.object.executionDate | date: "MM/dd/y" }}
        </ng-template>

        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="runReportExecutionItem(data.object)">Run</button>
                <button role="menuitem" class="resetStyles" *ngIf="userHasReportFullAccess" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>
    </lib-grid-view>
</ng-template>
