<div class="wrapper">
    <div class="addContact">
        <app-type-ahead-contacts (selectedContactChange)="onSelectedContact($event)"> </app-type-ahead-contacts>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Contacts available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'fullName', label: 'Name', sortable: true, tooltip: true },
                { key: 'phone', label: 'Phone', sortable: false, tooltip: true, cellTemplate: formatPhoneArray },
                { key: 'email', label: 'Email', sortable: false, tooltip: true, cellTemplate: formatEmailArray },
                { key: 'position', label: 'Position', sortable: true },
                { key: 'isMain', sortable: false, cellTemplate: mainContact },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [source]="contacts"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button *ngIf="!data.object.isMain" role="menuitem" class="resetStyles" (click)="setAsMainContact(data.object)">Set as Main Contact</button>
                <button *ngIf="data.object.isMain" role="menuitem" class="resetStyles" (click)="unsetAsMainContact(data.object)">Unset as Main Contact</button>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>

        <ng-template #mainContact let-data>
            <lib-badge text="Main Contact" *ngIf="data.object.isMain"></lib-badge>
        </ng-template>

        <ng-template #formatEmailArray let-data>
            {{ data.object.email | commaSeparated }}
        </ng-template>

        <ng-template #formatPhoneArray let-data>
            {{ data.object.phone | commaSeparated }}
        </ng-template>
    </lib-grid-view>
</ng-template>
