import { Injectable } from "@angular/core";
import { DivisionClient, DivisionView, IDivisionClient, SearchDivisionsParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class DivisionService {
    private client: IDivisionClient;
    constructor() {
        this.client = new DivisionClient();
    }

    async search(body: SearchDivisionsParameters): Promise<DivisionView[]> {
        return await this.client.divisionSearch(body);
    }

    async getById(id: number): Promise<DivisionView> {
        return await this.client.divisionGetById(id);
    }

    async save(body: DivisionView[]): Promise<DivisionView[]> {
        return await this.client.divisionSave(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.divisionActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.divisionDeactivate(id);
    }
}
