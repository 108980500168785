/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    CompanyView,
    DivisionView,
    OpportunityStatusView,
    QuickSearchCompaniesParameters,
    SearchUsersParameters,
    UserView
} from "../../../../services/http/clients/api-proxies";
import { ITypeAheadService } from "../../generic-type-ahead/generic-type-ahead.component";
import { CompanyService } from "../../../../services/http/clients/company.service";
import { UserService } from "../../../../services/http/clients/user.service";
import { UserRoles } from "../../../../models/enums/UserRoles";
import { IAdditionalFiltersOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-opportunities-additional-filters",
    templateUrl: "./opportunities-additional-filters.component.html",
    styleUrls: ["./opportunities-additional-filters.component.scss"]
})
export class OpportunitiesAdditionalFiltersComponent implements OnInit, OnDestroy {
    @Input()
    divisions?: DivisionView[];

    @Input()
    statuses?: OpportunityStatusView[];

    additionalFilters: IAdditionalFiltersOpportunities;
    selectedFilters: IAdditionalFiltersOpportunities;
    opened: boolean;
    placeholder: string;
    companiesServiceFilter: ITypeAheadService<CompanyView, QuickSearchCompaniesParameters>;
    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;
    userRoleIds: UserRoles[];
    disableClearBtn: boolean;
    filtersApplied: boolean;

    private subscription?: Subscription;

    protected scrollStrategy: ScrollStrategy;

    constructor(
        private readonly companyService: CompanyService,
        private readonly userService: UserService,
        private readonly overlay: Overlay,
        private readonly filterStateService: FilterStateService<any, IAdditionalFiltersOpportunities>
    ) {
        this.selectedFilters = {};
        this.additionalFilters = {};
        this.opened = false;
        this.placeholder = "Additional filters";
        this.companiesServiceFilter = companyService;
        this.userServiceFilter = userService;
        this.userRoleIds = [UserRoles.SalesPerson];
        this.statuses = [];
        this.divisions = [];
        this.disableClearBtn = false;
        this.filtersApplied = false;
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    async ngOnInit(): Promise<void> {
        this.subscription = this.filterStateService.stateFilters$.subscribe(async filters => {
            if (!filters) {
                this.selectedFilters = {} as IAdditionalFiltersOpportunities;
            } else {
                this.selectedFilters = filters as IAdditionalFiltersOpportunities;
            }
        });
    }

    toggleDropdown(): void {
        this.opened = !this.opened;
    }

    onKeyPress(e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === "Escape") {
            this.toggleDropdown();
        }
    }

    closeDropdown(): void {
        this.opened = false;
    }

    onFilterChanged(): void {
        this.disableClearBtn = !this.someFilterApplied();
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    async onClear(): Promise<void> {
        for (const key in this.selectedFilters) {
            if (Object.hasOwn(this.selectedFilters, key)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.selectedFilters as any)[key] = undefined;
            }
        }
        this.filtersApplied = this.someFilterApplied();
        this.selectedFilters = {};

        this.filterStateService.updateAdditionalFilters({ ...this.selectedFilters });
        this.closeDropdown();
    }

    async onApply(): Promise<void> {
        if (Object.keys(this.selectedFilters).length) {
            this.filterStateService.updateAdditionalFilters({ ...this.selectedFilters });
            this.filtersApplied = this.someFilterApplied();
        }
        this.closeDropdown();
    }

    private someFilterApplied(): boolean {
        return Object.values(this.selectedFilters).some(f => (Array.isArray(f) ? f.length : f));
    }
}
