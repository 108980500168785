import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView, SearchContactsForCompanyParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-companies-single-contacts",
    templateUrl: "./companies-single-contacts.component.html",
    styleUrls: ["./companies-single-contacts.component.scss"]
})
export class CompaniesSingleContactsComponent implements OnChanges {
    @Input()
    companyId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    @Output()
    companyChange: EventEmitter<void>;

    contacts: ContactView[];

    orderConfig?: IGenericGridOrderConfig<ContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsForCompanyParameters;

    contact?: ContactView;

    noResults: boolean;

    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<ContactView> = {
        base: "/contacts-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly contactService: ContactService,
        private readonly companyService: CompanyService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.contacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            companyId: this.companyId
        } as SearchContactsForCompanyParameters;

        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
        this.companyChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.companyId && changes["companyId"]) {
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContactsForCompany(this.searchConfig);

                const { pageInfo, results } = response;

                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            companyId: this.companyId
        } as SearchContactsForCompanyParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.contacts = [];
        await this.refreshGridView();
    }

    onRowClicked(contact: ContactView) {
        this.router.navigate(["/contacts-single", contact.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(contact: ContactView) {
        if (contact?.id && contact.id > 0) {
            window.open(`/contacts-single/${contact.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async setAsMainContact(contact: ContactView) {
        const previousMainContact = this.contacts.find(contact => contact.isMain);

        if (!this.companyId) return;

        const company = await this.companyService.getById(this.companyId);

        if (company.contactId) {
            const responseOk = await this.modalService.open(ModalConfirmComponent, {
                acceptCaption: "Confirm",
                cancelCaption: "Cancel",
                content:
                    "You're about to set a new main contact for this company. This will replace your current main contact with the selected one. Do you wish to continue?",
                title: "New Main Contact"
            });

            if (!responseOk) return;
        }

        if (!contact) return;

        await this.awaiter.awaitAction(
            "Setting as Main Contact",
            async () => {
                await this.contactService.setAsMain(contact.id);

                if (previousMainContact) {
                    previousMainContact.isMain = false;
                }

                contact.isMain = true;
                this.companyChange.emit();
            },
            loading => (this.isLoading = loading)
        );
    }

    async unsetAsMainContact(contact: ContactView) {
        await this.awaiter.awaitAction(
            "Unsetting as Main Contact",
            async () => {
                await this.contactService.unsetAsMain(contact.id);
                contact.isMain = false;
                this.companyChange.emit();
            },
            loading => (this.isLoading = loading)
        );
    }

    async onSelectedContact(contact: ContactView): Promise<void> {
        if (!contact || !this.companyId) return;

        contact.companyId = this.companyId;

        try {
            const contactSaved = await this.contactService.save(contact);

            if (contactSaved) {
                this.tabCounterChange.emit();
                this.contacts.unshift(contactSaved);
                this.noResults = false;
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the contact");
        }
    }

    async removeItem(contact: ContactView): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove contact",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this contact from the contact list?",
            title: "Remove contact"
        });

        if (!responseOk) return;

        if (!contact) return;
        contact.companyId = undefined;

        await this.awaiter.awaitAction(
            "Removing contact",
            async () => {
                await this.contactService.save(contact);
                const contactIndex = this.contacts.findIndex(c => c.id === contact.id);
                this.contacts.splice(contactIndex, 1);
                this.tabCounterChange.emit();
                if (contact.isMain) this.companyChange.emit();
                this.noResults = this.contacts.length === 0;
            },
            loading => (this.isLoading = loading)
        );
    }
}
