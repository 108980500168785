import { Component, Input } from "@angular/core";
import { TooltipPosition } from "../../directives";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-dashboard-card",
    templateUrl: "./dashboard-card.component.html",
    styleUrls: ["./dashboard-card.component.scss"]
})
export class DashboardCardComponent {
    @Input()
    contentType?: "stat" | "chart" = "stat";

    @Input()
    icon?: SafeHtml;

    @Input()
    tooltip?: string;

    @Input()
    tooltipPosition?: TooltipPosition;

    constructor() {
        this.tooltipPosition = TooltipPosition.ABOVELEFT;
    }
}
