import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { CalendarType, CalendarComponent } from "../calendar/calendar.component";
import { DateTime } from "luxon";
import { DateTimeService } from "../../services";
import { CalendarRanges, defaultRanges, backwardRanges, forwardRanges, CalendarRangesConfig } from "./default-ranges";

@Component({
    selector: "lib-datepicker",
    templateUrl: "./datepicker.component.html",
    styleUrls: ["./datepicker.component.scss"]
})
export class DatepickerComponent implements OnInit, OnChanges {
    readonly _calendarDefaultText: string = "Select a Date";

    @ViewChild("calendar")
    calendar!: CalendarComponent;

    @Input()
    defaultTitle?: string;

    @Input()
    selectedDate?: DateTime;

    @Output()
    selectedDateChange: EventEmitter<DateTime | undefined>;

    @Input()
    dateFrom?: DateTime;

    @Output()
    dateFromChange: EventEmitter<DateTime | undefined>;

    @Input()
    dateTo?: DateTime;

    @Output()
    dateToChange: EventEmitter<DateTime | undefined>;

    @Output()
    timeChange: EventEmitter<string | undefined>;

    @Input()
    dateRange?: string;

    @Input()
    minDate?: DateTime;

    @Input()
    maxDate?: DateTime;

    @Input()
    dateRangeEnabled: boolean;

    @Input()
    timeEnabled: boolean;

    @Input()
    rangeDirection: "backward" | "forward" | "both" = "backward";

    @Input()
    customDefaultRanges: CalendarRangesConfig[] | null = null;

    @Input()
    displaySelected: boolean;

    @Input()
    calendarTypeDisplay: CalendarType;

    @Output()
    confirmDisabledChange: EventEmitter<boolean>;

    @Output()
    titleChange: EventEmitter<string>;

    @Output()
    saveDate: EventEmitter<void>;

    @Output()
    resetDate: EventEmitter<void>;

    inputFromValue?: string;
    inputToValue?: string;
    timeValue?: string;
    inputSelectedDateValue?: string;
    showCalendarsSelection: boolean;
    defaultRange: CalendarRanges;
    defaultRanges = defaultRanges.filter(x => x.key !== CalendarRanges.CUSTOM);
    monthCalendarType = CalendarType.MONTH;

    constructor(private readonly dateTimeService: DateTimeService) {
        this.defaultRange = CalendarRanges.CUSTOM;
        this.showCalendarsSelection = true;
        this.dateRangeEnabled = false;
        this.timeEnabled = false;
        this.displaySelected = false;
        this.calendarTypeDisplay = CalendarType.MONTH;
        this.selectedDateChange = new EventEmitter<DateTime | undefined>();
        this.dateFromChange = new EventEmitter<DateTime | undefined>();
        this.dateToChange = new EventEmitter<DateTime | undefined>();
        this.timeChange = new EventEmitter<string | undefined>();
        this.confirmDisabledChange = new EventEmitter<boolean>();
        this.titleChange = new EventEmitter<string>();
        this.saveDate = new EventEmitter<void>();
        this.resetDate = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.minDate = this.minDate ?? this.dateTimeService.getMinApiDateTime().startOf("day");

        if (this.customDefaultRanges) {
            this.defaultRanges = this.customDefaultRanges;
        } else {
            if (this.rangeDirection === "backward") {
                this.defaultRanges = backwardRanges;
            } else if (this.rangeDirection === "forward") {
                this.defaultRanges = forwardRanges;
            } else if (this.rangeDirection === "both") {
                this.defaultRanges = [...backwardRanges, ...forwardRanges];
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedDate"]?.currentValue) {
            this.setCalendar();
            if (this.timeEnabled) this.timeValue = this.selectedDate?.toFormat("HH:mm");
            this.setTitle();
            this.inputSelectedDateValue = this.selectedDate?.toFormat("MM/dd/yy");
        } else if (changes["selectedDate"] && !changes["selectedDate"].currentValue) {
            this.inputSelectedDateValue = undefined;
            this.selectedDate = undefined;
        }

        if (changes["dateFrom"]?.currentValue) {
            if (this.dateFrom) this.inputFromValue = this.dateFrom.toFormat("MM/dd/yy");
            this.setCalendar();
            this.setTitle();
        }

        if (changes["dateTo"]?.currentValue) {
            if (this.dateTo) this.inputToValue = this.dateTo.toFormat("MM/dd/yy");
            this.setCalendar();
            this.setTitle();
        }
    }

    onSelectRange(key: CalendarRanges): void {
        this.dateRange = key;

        switch (key) {
            case CalendarRanges.LAST_WEEK:
                this.dateFrom = this.dateTimeService.today().plus({ days: -7 });
                this.dateTo = this.dateTimeService.today();
                break;

            case CalendarRanges.LAST_MONTH:
                this.dateFrom = this.dateTimeService.today().plus({ days: -30 });
                this.dateTo = this.dateTimeService.today();
                break;

            case CalendarRanges.LAST_THREE_MONTHS:
                this.dateFrom = this.dateTimeService.today().plus({ days: -90 });
                this.dateTo = this.dateTimeService.today();
                break;

            case CalendarRanges.LAST_YEAR:
                this.dateFrom = this.dateTimeService.today().plus({ days: -365 });
                this.dateTo = this.dateTimeService.today();
                break;

            case CalendarRanges.NEXT_WEEK:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.today().plus({ days: 7 });
                break;

            case CalendarRanges.NEXT_MONTH:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.today().plus({ days: 30 });
                break;

            case CalendarRanges.NEXT_YEAR:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.today().plus({ days: 365 });
                break;

            case CalendarRanges.END_OF_CURRENT_WEEK:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.endOfWeek();
                break;

            case CalendarRanges.END_OF_CURRENT_MONTH:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.endOfMonth();
                break;

            case CalendarRanges.END_OF_CURRENT_QUARTER:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.endOfQuarter();
                break;

            case CalendarRanges.END_OF_JUNE_CURRENT_YEAR:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.endOfMonthJune();
                break;

            case CalendarRanges.END_OF_CURRENT_YEAR:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.endOfYear();
                break;

            default:
                this.dateFrom = this.dateTimeService.today();
                this.dateTo = this.dateTimeService.today();
                break;
        }

        this.inputFromValue = this.dateFrom?.toFormat("MM/dd/yy");
        this.inputToValue = this.dateTo?.toFormat("MM/dd/yy");

        this.setCalendar();

        this.dateFromChange.emit(this.dateFrom);
        this.dateToChange.emit(this.dateTo);
    }

    onSelectedDateChange(selectedDate: DateTime | undefined): void {
        this.selectedDate = selectedDate;
        this.inputSelectedDateValue = this.selectedDate?.toFormat("MM/dd/yy");
        if (this.timeEnabled && this.timeValue) this.onTimeChange(this.timeValue);
        else {
            this.selectedDateChange.emit(this.selectedDate);
        }
    }

    onTimeChange(time: string) {
        if (this.selectedDate) {
            this.selectedDate = this.dateTimeService.updateDateTimeWithTime(this.selectedDate, time);
            this.selectedDateChange.emit(this.selectedDate);
        }
    }

    onDateFromCalendarChange(dateFrom: DateTime | undefined): void {
        this.dateRange = CalendarRanges.CUSTOM;
        this.dateFrom = dateFrom;
        this.inputFromValue = dateFrom?.toFormat("MM/dd/yy");
        this.dateFromChange.emit(this.dateFrom);
    }

    onDateToCalendarChange(dateTo: DateTime | undefined): void {
        this.dateRange = CalendarRanges.CUSTOM;
        this.dateTo = dateTo;
        this.inputToValue = dateTo?.toFormat("MM/dd/yy");
        this.dateToChange.emit(this.dateTo);
        this.confirmDisabledChange.emit(false);
    }

    onApply(): void {
        this.setTitle();
        this.setCalendar();
        this.saveDate.emit();
    }

    onClear(): void {
        this.inputFromValue =
            this.inputToValue =
            this.inputSelectedDateValue =
            this.selectedDate =
            this.dateFrom =
            this.dateTo =
            this.dateRange =
            this.timeValue =
                undefined;

        this.dateFromChange.emit(this.dateFrom);
        this.dateToChange.emit(this.dateTo);
        this.selectedDateChange.emit(this.selectedDate);
        this.setCalendar();
        this.setTitle();
        this.resetDate.emit();
    }

    setCalendar(): void {
        if (!this.calendar) return;

        if (this.calendarTypeDisplay === CalendarType.YEAR) {
            this.saveDate.emit();
        } else {
            this.calendar.navigateToCalendarType(CalendarType.MONTH);
        }

        if (this.dateRangeEnabled) {
            this.calendar.currentDisplayDate = this.dateFrom ? this.dateFrom.startOf("month") : this.dateTimeService.now().startOf("month");
        } else {
            this.calendar.currentDisplayDate = this.selectedDate ?? this.dateTimeService.now().startOf("month");
        }
    }

    private setTitle(): void {
        let title = this.defaultTitle ?? this._calendarDefaultText;

        if (this.dateRangeEnabled) {
            if (this.inputFromValue && this.inputToValue) {
                const dateToText = this.inputToValue ? `- ${this.inputToValue}` : "";
                title = `${this.inputFromValue} ${dateToText}`;
            } else if (this.inputFromValue && !this.inputToValue) {
                title = this.inputFromValue;
            } else if (!this.inputFromValue && this.inputToValue) {
                title = this.inputToValue;
            }
        } else if (!this.dateRangeEnabled && this.selectedDate) {
            const timeValueText = this.timeValue ? `- ${this.timeValue}` : "";
            title = `${this.selectedDate.toFormat("MM/dd/yy")} ${timeValueText}`;
        }

        this.titleChange.emit(title);
    }
}
