import { Injectable } from "@angular/core";
import { 
    ISchedulerFrequencyClient, 
    SchedulerFrequencyClient, 
    SchedulerFrequencyDto
} from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class SchedulerFrequencyService {
    private client: ISchedulerFrequencyClient;
    constructor() {
        this.client = new SchedulerFrequencyClient();
    }

    async getAll(): Promise<SchedulerFrequencyDto[]> {
        return await this.client.schedulerFrequencyGetAllSchedulerFrequency();
    }
}