import { BidCategoryView, QuickSearchBidCategoriesParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../entity-interfaces/editor-entity.interface";
import { BidCategoryService } from "projects/app/src/app/services/http/clients/bid-category.service";

export abstract class BidCategoryEntity<TEntityDestination> implements IComponentEditorEntity<BidCategoryView, TEntityDestination> {
    constructor(readonly bidCategoryService: BidCategoryService) {}

    abstract toDestination(entity: BidCategoryView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): BidCategoryView;

    async getSource(): Promise<BidCategoryView[]> {
        return await this.bidCategoryService.quickSearch({} as QuickSearchBidCategoriesParameters);
    }
}
