import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "ensureHttps"
})
export class EnsureHttpsPipe implements PipeTransform {
    transform(value: string | null | undefined): string {
        if (!value) {
            return "";
        }

        if (!value.startsWith("http://") && !value.startsWith("https://")) {
            return `https://${value}`;
        }

        return value;
    }
}
