import { Component, OnInit } from "@angular/core";
import {
    ArchiveEntityOptionView,
    BuildingView,
    CityView,
    CountryView,
    SearchCityParameters,
    SearchCountryParameters,
    SearchStateParameters,
    StateView
} from "projects/app/src/app/services/http/clients/api-proxies";
import { ITypeAheadService } from "../../../shared/generic-type-ahead/generic-type-ahead.component";
import { CityService } from "projects/app/src/app/services/http/clients/city.service";
import { StateService } from "projects/app/src/app/services/http/clients/state.service";
import { CountryService } from "projects/app/src/app/services/http/clients/country.service";
import { Router } from "@angular/router";
import { FilterService, IGenericGridOrderConfig, IGetById } from "projects/ngx-lib/src/public-api";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";
import { Condition } from "projects/app/src/app/models/enums/Condition";

export interface IFilterValueBuildings {
    searchText?: string;
    city?: CityView;
    state?: StateView;
    country?: CountryView;
    activeStatus?: ArchiveEntityOptionView;
    sortBy?: string;
    sortDirection?: string;
}

@Component({
    selector: "app-buildings-main",
    templateUrl: "./buildings-main.component.html",
    styleUrls: ["./buildings-main.component.scss"]
})
export class BuildingsMainComponent implements OnInit {
    filterValue: IFilterValueBuildings;

    cityServiceFilter: ITypeAheadService<CityView, SearchCityParameters>;

    stateServiceFilter: ITypeAheadService<StateView, SearchStateParameters>;

    countryServiceFilter: ITypeAheadService<CountryView, SearchCountryParameters>;

    activeStatuses?: ArchiveEntityOptionView[];

    constructor(
        private readonly cityService: CityService,
        private readonly stateService: StateService,
        private readonly countryService: CountryService,
        private readonly router: Router,
        private readonly filterService: FilterService,
        private readonly archiveEntityService: ArchiveEntityService
    ) {
        this.filterValue = {};
        this.cityServiceFilter = cityService;
        this.stateServiceFilter = stateService;
        this.countryServiceFilter = countryService;
    }

    async ngOnInit(): Promise<void> {
        this.activeStatuses = await this.archiveEntityService.getArchiveOptions();
        this.filterValue = {
            ...(this.activeStatuses?.length && { activeStatus: this.activeStatuses.find(c => c.id === Condition.Active) })
        };
        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    buildFilters(): void {
        this.filterValue = {
            searchText: this.filterValue.searchText,
            city: this.filterValue.city,
            state: this.filterValue.state,
            country: this.filterValue.country,
            activeStatus: this.filterValue.activeStatus
        };
    }

    async onFilterChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    onSortChangedFromList(newOrderConfig: IGenericGridOrderConfig<BuildingView>) {
        this.filterValue.sortBy = newOrderConfig?.displayName;
        this.filterValue.sortDirection = newOrderConfig?.order;
        this.updateFilterUrlParams();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IFilterValueBuildings>(this.filterValue, "sortBy");
        await this.filterService.serialize<IFilterValueBuildings>(this.filterValue, "sortDirection");
        await this.filterService.serialize<IFilterValueBuildings>(this.filterValue, "searchText");
        await this.filterService.serializeTypeAhead<IFilterValueBuildings, CityView>("city", this.filterValue.city, "id");
        await this.filterService.serializeTypeAhead<IFilterValueBuildings, StateView>("state", this.filterValue.state, "id");
        await this.filterService.serializeTypeAhead<IFilterValueBuildings, CountryView>("country", this.filterValue.country, "id");
        await this.filterService.serializeArray<IFilterValueBuildings, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IFilterValueBuildings>(this.filterValue, "sortBy");
        this.filterService.deserialize<IFilterValueBuildings>(this.filterValue, "sortDirection");
        this.filterService.deserialize<IFilterValueBuildings>(this.filterValue, "searchText");
        await this.filterService.deserializeTypeAhead<IFilterValueBuildings, CityView>(this.filterValue, "city", this.cityService as IGetById);
        await this.filterService.deserializeTypeAhead<IFilterValueBuildings, StateView>(this.filterValue, "state", this.stateService as IGetById);
        await this.filterService.deserializeTypeAhead<IFilterValueBuildings, CountryView>(this.filterValue, "country", this.countryService as IGetById);
        this.filterService.deserializeArray<IFilterValueBuildings, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id", this.activeStatuses ?? []);
    }

    onCreateNewBuildingClicked() {
        this.router.navigate([`/buildings-edit`]);
    }
}
