import { Component, Input } from "@angular/core";

@Component({
    selector: "lib-counter-badge",
    templateUrl: "./counter-badge.component.html",
    styleUrls: ["./counter-badge.component.scss"]
})
export class CounterBadgeComponent {
    @Input()
    label: string;

    @Input()
    count: number;

    constructor() {
        this.label = "";
        this.count = 0;
    }
}
