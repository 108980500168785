<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Accounts available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Name', sortable: true, tooltip: true },
                { key: 'accountTypeName', label: 'Type', sortable: false, tooltip: true },
                { key: 'markets', label: 'Markets', sortable: false, tooltip: true },
                { key: 'isActive', label: 'Condition', sortable: false, cellTemplate: cellIsActive }
            ]
        }"
        [routerLinkConfig]="routerLinkConfig"
        [source]="accounts"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged($event)"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
    </lib-grid-view>

    <ng-template #cellIsActive let-data>
        {{ data.object.isActive ? "Active" : "Archived" }}
    </ng-template>
</ng-template>
