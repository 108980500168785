import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityView, SearchOpportunitiesParameters, SearchOpportunitiesResult } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { TabNames } from "../../../models/enums/TabNames";

@Component({
    selector: "app-opportunities-grid-single",
    templateUrl: "./opportunities-grid-single.component.html",
    styleUrls: ["./opportunities-grid-single.component.scss"]
})
export class OpportunitiesGridSingleComponent implements OnChanges {
    @Input()
    companyId?: number;

    @Input()
    buildingId?: number;

    @Input()
    contactId?: number;

    searchConfig: SearchOpportunitiesParameters;

    paginationConfig: IGenericGridPaginationConfig;

    opportunities?: SearchOpportunitiesResult[];

    orderConfig?: IGenericGridOrderConfig<SearchOpportunitiesResult>;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router
    ) {
        this.opportunities = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchOpportunitiesParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["buildingId"] || changes["companyId"] || changes["contactId"]) {
            this.resetOpportunitiesList();
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Opportunities",
            async () => {
                const response = await this.opportunityService.search(this.searchConfig);
                const { pageInfo, results } = response;

                this.opportunities = this.opportunities?.concat(results ?? []);

                this.noResults = this.opportunities?.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            companyId: this.companyId,
            buildingId: this.buildingId,
            contactId: this.contactId,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key
        } as SearchOpportunitiesParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.opportunities = [];

        await this.refreshGridView();
    }

    onRowClicked(opportunity: OpportunityView) {
        this.router.navigate(["opportunities-single", opportunity.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(opportunity: OpportunityView) {
        if (opportunity?.id && opportunity.id > 0) {
            window.open(`/opportunities-single/${opportunity.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    private resetOpportunitiesList() {
        this.paginationConfig.currentPage = 1;
        this.opportunities = [];
    }
}
