import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[appTrimSpaces]"
})
export class TrimSpacesDirective {
    @Input()
    appTrimSpaces?: boolean;

    constructor(private readonly elementRef: ElementRef) {}

    @HostListener("input", ["$event.target"])
    onInput() {
        if (this.appTrimSpaces) {
            const inputValue = this.elementRef.nativeElement.value;
            this.elementRef.nativeElement.value = inputValue.replace(/^\s+/, "");
        }
    }

    @HostListener("blur")
    onBlur() {
        if (this.appTrimSpaces) {
            const inputValue = this.elementRef.nativeElement.value;
            this.elementRef.nativeElement.value = inputValue.trim();
        }
    }
}
