<div class="container">
    <div class="filtersContainer">
        <lib-slide-toggle class="filter" label="Starred" [(checked)]="mainFilters.onlyFavorites" (checkedChange)="onFiltersChanged()"> </lib-slide-toggle>

        <lib-search-input class="filter" [placeholderText]="'ID or Name'" [(text)]="mainFilters.searchText" (textChange)="onFiltersChanged()">
        </lib-search-input>

        <lib-datepicker-dropdown
            class="filter"
            defaultTitle="Due date"
            [(dateFrom)]="mainFilters.dateFrom"
            [(dateTo)]="mainFilters.dateTo"
            (filterChanged)="onFiltersChanged()"
            [dateRangeEnabled]="true"
            [disabled]="!!mainFiltersToClear"
            [rangeDirection]="'forward'"
        >
        </lib-datepicker-dropdown>

        <lib-generic-dropdown
            [source]="activeStatuses"
            [config]="{
                multiple: false,
                defaultText: 'Select Condition',
                itemDisplayKey: 'name'
            }"
            [(selectedItem)]="mainFilters.activeStatus"
            (selectedItemChange)="onFiltersChanged()"
        >
        </lib-generic-dropdown>

        <app-opportunities-additional-filters
            class="filter"
            [(additionalFilters)]="additionalFilters"
            (additionalFiltersChange)="onFiltersChanged()"
            [removeFilter]="removeAdditionalFilter"
        ></app-opportunities-additional-filters>

        <div class="clearFilters">
            <button class="tertiary-btn" [disabled]="disabledClearFiltersBtn" (click)="onClearAllFilters()">Clear filters</button>
        </div>
    </div>

    <div class="filtersAppliedContainer">
        <app-opportunities-additional-filters-applied
            [(additionalFilters)]="additionalFilters"
            (additionalFiltersChange)="onAdditionalFiltersChange()"
            (removeFilterTag)="onRemoveAdditionalFilter($event)"
        ></app-opportunities-additional-filters-applied>
    </div>
</div>
