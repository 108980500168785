<aside [ngClass]="{ expanded: isExpanded }" [id]="genericSideNavId | idGenerator: 'ngx-genericSideNav-'">
    <div *ngIf="logoCollapsed && !isExpanded" class="logo" [innerHTML]="svgCollapsed"></div>
    <div *ngIf="logoExpanded && isExpanded" class="logo" [innerHTML]="svgExpanded"></div>
    <button class="expandButton" (click)="onToggleButton()" tabindex="0" aria-label="Open and close menu">
        <div class="icon" [innerHTML]="rightArrowIcon"></div>
    </button>
    <nav>
        <lib-generic-menu [config]="{ defaultText: 'defaultText' }" [source]="source" [isExpanded]="isExpanded" [currentUrl]="currentUrl"></lib-generic-menu>
    </nav>
</aside>
