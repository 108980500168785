<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Update Password</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <form [formGroup]="updatePasswordForm">
                <div class="formGroup">
                    <label for="currentPassword" class="label">
                        <span>Current password</span>
                    </label>
                    <div class="formControl">
                        <app-password-input
                            inputId="currentPassword"
                            inputName="currentPassword"
                            [control]="currentPasswordControl"
                            placeholder="Enter current password"
                        ></app-password-input>
                    </div>
                </div>

                <div class="formGroup">
                    <label for="newPassword" class="label">
                        <span>New password</span>
                    </label>
                    <div class="formControl">
                        <app-password-input
                            inputId="newPassword"
                            inputName="newPassword"
                            [control]="newPasswordControl"
                            placeholder="Enter new password"
                            [minLength]="6"
                            [maxLength]="100"
                        ></app-password-input>
                    </div>
                </div>

                <div class="formGroup">
                    <label for="confirmPassword" class="label">
                        <span>Repeat password</span>
                    </label>
                    <div class="formControl">
                        <app-password-input
                            inputId="confirmPassword"
                            inputName="confirmPassword"
                            [control]="confirmPasswordControl"
                            placeholder="Repeat new password"
                        ></app-password-input>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)">Cancel</button>
            <button class="modal-button confirm" (click)="submitUpdateForm()">Update</button>
        </div>
    </div>
</div>
