import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-no-data",
    templateUrl: "./no-data.component.html",
    styleUrls: ["./no-data.component.scss"]
})
export class NoDataComponent implements OnInit {
    @Input()
    iconName!: string;

    @Input()
    title: string;

    @Input()
    text: string;

    @Input()
    linkText?: string;

    @Input()
    noDataId?: string;

    @Output()
    linkAction: EventEmitter<void>;

    svg?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.title = "No data available";
        this.text = "";
        this.linkAction = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.svg = this.iconSanitizer.getIcon(this.iconName);
    }

    onLinkClicked() {
        this.linkAction?.emit();
    }
}
