import { Component, Input } from "@angular/core";

interface IHeaders {
    name: string;
    label: string;
    format?: "currency" | "percent";
}

interface ISettings {
    cols: IHeaders[];
    rows: IHeaders[];
}

@Component({
    selector: "app-table",
    templateUrl: "./table.component.html",
    styleUrls: ["./table.component.scss"]
})
export class TableComponent {
    @Input()
    metricValues?: { [key: string]: number | undefined };

    @Input()
    settings: ISettings;

    constructor() {
        this.settings = { cols: [], rows: [] };
    }
}
