import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "../../../services/awaiter.service";
import { OpportunityDashboardService } from "../../../services/http/clients/opportunity-dashboard.service";
import { DashboardComparisonOptionView, GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto } from "../../../services/http/clients/api-proxies";
import { DashboardPeriodOptions } from "../../../models/enums/DashboardPeriodOptions";
import { ISalesFunnelChartConfig } from "../../shared/charts/sales-funnel-chart/sales-funnel-chart.component";
import { BidVsAwardedChartConfig } from "../../shared/charts/bid-vs-awarded-chart/bid-vs-awarded-chart.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    comparisonOptions?: DashboardComparisonOptionView[];

    selectedOption?: DashboardComparisonOptionView;

    opportunityStat?: GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto;

    salesFunnelChartConfig?: ISalesFunnelChartConfig;

    bidVsAwardedChartConfig?: BidVsAwardedChartConfig;

    constructor(
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly opportunityDashboardService: OpportunityDashboardService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting Dashboard Stats", async () => {
            this.comparisonOptions = await this.opportunityDashboardService.getComparisonOptions();
        });

        if (this.comparisonOptions?.length) {
            this.selectedOption = this.comparisonOptions?.find(x => x.id === DashboardPeriodOptions.Last365Days);
            await this.getOpportunityStat(DashboardPeriodOptions.Last365Days);
        }

        this.buildChartsConfig();
    }

    async getOpportunityStat(comparisonId: number): Promise<GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto> {
        this.opportunityStat = await this.opportunityDashboardService.getOpportunityStatsByPeriod(comparisonId);
        return this.opportunityStat;
    }

    async onSelectedOption(): Promise<void> {
        if (this.selectedOption) {
            await this.getOpportunityStat(this.selectedOption.id);
            this.buildChartsConfig();
        }
    }

    private buildChartsConfig(): void {
        if (!this.opportunityStat) return;

        this.salesFunnelChartConfig = {
            title: "Sales Funnel",
            labels: {
                current: this.opportunityStat?.dashboardComparisonOptionView?.currentPeriodDescription,
                previous: this.opportunityStat?.dashboardComparisonOptionView?.previousPeriodDescription
            },
            data: [
                {
                    category: "Opportunities",
                    current: this.opportunityStat?.currentResult.opportunitiesAmount,
                    previous: this.opportunityStat?.previousResult.opportunitiesAmount
                },
                { category: "Bid", current: this.opportunityStat.currentResult.bidAmount, previous: this.opportunityStat.previousResult.bidAmount },
                { category: "Awarded", current: this.opportunityStat.currentResult.awardedAmount, previous: this.opportunityStat.previousResult.awardedAmount }
            ]
        };

        this.bidVsAwardedChartConfig = {
            title: "Bid vs. Awarded",
            data: [
                {
                    period: "Previous",
                    bidTotal: this.opportunityStat.previousResult.totalBidCount,
                    awardedTotal: this.opportunityStat.previousResult.totalAwardedCount,
                    awardedBid: this.opportunityStat.previousResult.awardedBidCount,
                    awardedRate: this.opportunityStat.previousResult.awardedRate
                },
                {
                    period: "Current",
                    bidTotal: this.opportunityStat.currentResult.totalBidCount,
                    awardedTotal: this.opportunityStat.currentResult.totalAwardedCount,
                    awardedBid: this.opportunityStat.currentResult.awardedBidCount,
                    awardedRate: this.opportunityStat.currentResult.awardedRate
                }
            ]
        };
    }

    onCreateNewOpportunityClicked() {
        this.router.navigate([`/opportunities-create`]);
    }
}
