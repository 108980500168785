import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingContactView, ContactView, SearchContactsForBuildingParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalEditBuildingContactComponent } from "../../../../shared/modals/modal-edit-building-contact/modal-edit-building-contact.component";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { BuildingContactService } from "./../../../../../services/http/clients/building-contact.service";

@Component({
    selector: "app-buildings-single-contacts",
    templateUrl: "./buildings-single-contacts.component.html",
    styleUrls: ["./buildings-single-contacts.component.scss"]
})
export class BuildingsSingleContactsComponent implements OnChanges {
    @Input()
    buildingId?: number;

    @Output()
    buildingChanged: EventEmitter<void>;

    @Output()
    tabCounterChange: EventEmitter<void>;

    contacts: BuildingContactView[];

    orderConfig?: IGenericGridOrderConfig<BuildingContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsForBuildingParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    allowSetCompany?: boolean;

    buildingContact?: BuildingContactView;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly contactService: ContactService,
        private readonly buildingService: BuildingService,
        private readonly buildingContactService: BuildingContactService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.contacts = [];
        this.buildingChanged = new EventEmitter<void>();
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            buildingId: this.buildingId
        } as SearchContactsForBuildingParameters;

        this.allowSetCompany = true;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Building
        };

        this.buildingContact = undefined;
        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.buildingId && changes["buildingId"]) {
            this.searchConfig.buildingId = this.buildingId;
            this.typeAheadSearchByEntityParameters.entity = {
                buildingId: this.buildingId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContactsForBuilding(this.searchConfig);

                const { pageInfo, results } = response;

                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.contacts = [];

        await this.refreshGridView();
    }

    onRowClicked(buildingContact: BuildingContactView) {
        this.router.navigate([`/contacts-single/${buildingContact.contactId}`]);
    }

    onMouseWheelClicked(buildingContact: BuildingContactView) {
        if (buildingContact?.contactId && buildingContact.contactId > 0) {
            window.open(`/contacts-single/${buildingContact.contactId}`, "_blank");
        }
    }

    async onSelectedContact(contact: ContactView): Promise<void> {
        if (!contact || !this.buildingId) return;

        try {
            const contactSaved = await this.buildingService.buildingSaveContact({
                contactId: contact.id,
                buildingId: this.buildingId
            } as any as BuildingContactView);

            if (contactSaved) {
                this.tabCounterChange.emit();
                this.contacts.unshift(contactSaved);
            }
            this.noResults = false;
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the contact");
        }
    }

    async editItem(buildingContact: BuildingContactView): Promise<void> {
        const buildingContactUpdated = await this.modalService.open(ModalEditBuildingContactComponent, {
            contact: buildingContact
        });

        if (buildingContactUpdated) {
            const contactIndex = this.contacts.findIndex(contact => contact.id === buildingContactUpdated.id);
            this.contacts.splice(contactIndex, 1, buildingContactUpdated);
            this.buildingChanged.emit();
        }
    }

    async removeItem(buildingContact: BuildingContactView): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove contact",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this contact from the contact list?",
            title: "Remove contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing contact",
            async () => {
                if (!buildingContact) return;

                await this.buildingService.removeContact({
                    id: buildingContact.id,
                    contactId: buildingContact.contactId,
                    currentCompanyId: undefined,
                    buildingId: buildingContact.buildingId,
                    contactOrganizationId: undefined,
                    contactFullName: undefined,
                    email: undefined,
                    phone: undefined,
                    companyId: undefined,
                    companyName: undefined,
                    buildingName: undefined,
                    buildingContactRoleId: undefined,
                    buildingContactRoleName: undefined,
                    currentCompanyName: undefined
                });

                const buildingContactIndex = this.contacts.findIndex(contact => contact.id === buildingContact.id);
                this.contacts.splice(buildingContactIndex, 1);
                this.tabCounterChange.emit();
                this.noResults = this.contacts.length === 0;

                if (buildingContact.buildingContactRoleId === 1) this.buildingChanged.emit();
            },
            loading => (this.isLoading = loading)
        );
    }

    async updateContactFreezedData(buildingContact: BuildingContactView): Promise<void> {
        const modalContent = `Company&emsp;&emsp;${buildingContact?.companyName ?? "[No data]"} &ensp;>&ensp; ${
            buildingContact?.currentCompanyName ?? "[No data]"
        }`;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Update Data",
            cancelCaption: "Cancel",
            bodyTitle: "The following data will be updated:",
            content: modalContent,
            title: "Data update available"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Updating contact data",
            async () => {
                const buildingContactUpdated = await this.buildingContactService.buildingContactUpdateCompany(buildingContact.id);

                if (buildingContactUpdated) {
                    this.snackbarNotificationService.success(`${buildingContactUpdated.contactFullName}'s company has been successfully updated.`);
                    const buildingContactIndex = this.contacts.findIndex(contact => contact.id === buildingContactUpdated.id);
                    this.contacts.splice(buildingContactIndex, 1, buildingContactUpdated);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            buildingId: this.buildingId
        } as SearchContactsForBuildingParameters;
    }
}
