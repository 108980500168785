import { IconSanitizerService } from "./../../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { OpportunityUserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";

export interface IOpportunityCardDetails {
    id?: number;
    name?: string;
    contactInfo?: string;
    eventDate?: Date;
    code?: string;
    opportunityStatusName?: string;
    companyName?: string;
    opportunityValue?: number;
    eventType?: number;
    opportunityUsers?: OpportunityUserView[];
    latitude?: number;
    longitude?: number;
}

@Component({
    selector: "app-opportunity-card-expanded",
    templateUrl: "./opportunity-card-expanded.component.html",
    styleUrls: ["./opportunity-card-expanded.component.scss"],
    animations: [
        trigger("animation", [
            transition(":enter", [
                style({
                    opacity: 0,
                    transform: "scale(0.8)"
                }),
                animate(
                    "0.15s ease-in-out",
                    style({
                        opacity: 1,
                        transform: "scale(1)"
                    })
                )
            ]),
            transition(":leave", [
                style({
                    opacity: 1,
                    transform: "scale(1)"
                }),
                animate(
                    "0.15s ease-in-out",
                    style({
                        opacity: 0,
                        transform: "scale(0.8)"
                    })
                )
            ])
        ])
    ]
})
export class OpportunityCardExpandedComponent {
    readonly icons = icons;

    @HostBinding("@animation") animationState = true;

    @Input()
    data?: IOpportunityCardDetails;

    @Input()
    hasBorder: boolean;

    @Input()
    showEventInfo?: boolean;

    @Input()
    allowCloseCard?: boolean;

    @Output()
    closeClicked: EventEmitter<void>;

    @Output()
    goToClicked: EventEmitter<void>;

    closeIcon?: SafeHtml;

    dueDatetype?: OpportunityCalendarEvent;
    followUpType?: OpportunityCalendarEvent;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.closeIcon = this.iconSanitizer.getIcon("close");
        this.showEventInfo = true;
        this.hasBorder = true;
        this.allowCloseCard = true;
        this.dueDatetype = OpportunityCalendarEvent.DueDate;
        this.followUpType = OpportunityCalendarEvent.FollowUpContactDate;
        this.closeClicked = new EventEmitter<void>();
        this.goToClicked = new EventEmitter<void>();
    }

    onCloseClicked(): void {
        this.closeClicked.emit();
    }

    onGoToClicked(): void {
        this.goToClicked.emit();
    }
}
