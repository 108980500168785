import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView, OpportunityContactView, SearchContactsForOpportunityParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityContactService } from "projects/app/src/app/services/http/clients/opportunity-contact.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalEditOpportunityContactComponent } from "../../../../shared/modals/modal-edit-opportunity-contact/modal-edit-opportunity-contact.component";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-opportunities-single-contacts",
    templateUrl: "./opportunities-single-contacts.component.html",
    styleUrls: ["./opportunities-single-contacts.component.scss"]
})
export class OpportunitiesSingleContactsComponent implements OnChanges {
    @Input()
    opportunityId?: number;

    @Output()
    opportunityContactsChange: EventEmitter<OpportunityContactView[]>;

    @Output()
    tabCounterChange: EventEmitter<void>;

    opportunityContacts: OpportunityContactView[];

    orderConfig?: IGenericGridOrderConfig<OpportunityContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsForOpportunityParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    allowSetCompany?: boolean;

    opportunityContact?: OpportunityContactView;

    isActionAllowed: boolean;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly opportunityContactService: OpportunityContactService,
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.opportunityContacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            opportunityId: this.opportunityId
        } as SearchContactsForOpportunityParameters;

        this.allowSetCompany = true;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Opportunity
        };

        this.opportunityContact = undefined;
        this.isActionAllowed = false;

        this.opportunityContactsChange = new EventEmitter<OpportunityContactView[]>();
        this.tabCounterChange = new EventEmitter<void>();

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            const availableActions = await this.opportunityService.availableActions(this.opportunityId);
            this.isActionAllowed = availableActions.includes(OpportunityAction.EditContact);
            this.searchConfig.opportunityId = this.opportunityId;
            this.typeAheadSearchByEntityParameters.entity = {
                opportunityId: this.opportunityId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Opportunity Contacts",
            async () => {
                const response = await this.opportunityContactService.search(this.searchConfig);

                const { pageInfo, results } = response;

                this.opportunityContacts = this.opportunityContacts?.concat(results ?? []);

                this.noResults = this.opportunityContacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.opportunityContacts = [];
        await this.refreshGridView();
    }

    onRowClicked(opportunityContact: OpportunityContactView) {
        this.router.navigate(["contacts-single", opportunityContact.contactId, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(opportunityContact: OpportunityContactView) {
        if (opportunityContact?.contactId && opportunityContact.contactId > 0) {
            window.open(`/contacts-single/${opportunityContact.contactId}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onSelectedContact(contact: ContactView): Promise<void> {
        if (!contact || !this.opportunityId) return;

        try {
            const contactSaved = await this.opportunityService.saveContact({
                contactId: contact.id,
                opportunityId: this.opportunityId
            } as any as OpportunityContactView);

            if (contactSaved) {
                this.tabCounterChange.emit();
                this.opportunityContacts.unshift(contactSaved);
                this.noResults = false;
                this.opportunityContactsChange.emit(this.opportunityContacts);
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the contact");
        }
    }

    async editItem(opportunityContact: OpportunityContactView): Promise<void> {
        const opportunityContactUpdated = await this.modalService.open(ModalEditOpportunityContactComponent, {
            contact: opportunityContact
        });

        if (opportunityContactUpdated) {
            const opportunityContactIndex = this.opportunityContacts.findIndex(contact => contact.id === opportunityContactUpdated.id);
            this.opportunityContacts.splice(opportunityContactIndex, 1, opportunityContactUpdated);
            this.opportunityContactsChange.emit(this.opportunityContacts);
        }
    }

    async removeItem(opportunityContact: OpportunityContactView): Promise<void> {
        const { id: opportunityContactId } = opportunityContact;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove contact",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this contact from the contact list?",
            title: "Remove contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing Contact",
            async () => {
                if (!opportunityContactId) return;
                await this.opportunityContactService.delete(opportunityContactId);

                const opportunityContactIndex = this.opportunityContacts.findIndex(contact => contact.id === opportunityContactId);
                this.opportunityContacts.splice(opportunityContactIndex, 1);
                this.tabCounterChange.emit();
                this.noResults = this.opportunityContacts.length === 0;
                this.opportunityContactsChange.emit(this.opportunityContacts);
            },
            loading => (this.isLoading = loading)
        );
    }

    async updateContactFreezedData(opportunityContact: OpportunityContactView): Promise<void> {
        const modalContent = `Company&emsp;&emsp;${opportunityContact?.companyName ?? "[No data]"} &ensp;>&ensp; ${
            opportunityContact?.currentCompanyName ?? "[No data]"
        }`;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Update Data",
            cancelCaption: "Cancel",
            bodyTitle: "The following data will be updated:",
            content: modalContent,
            title: "Data update available"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Updating contact data",
            async () => {
                const opportunityContactUpdated = await this.opportunityContactService.opportunityContactUpdateCompany(opportunityContact.id);

                if (opportunityContactUpdated) {
                    this.snackbarNotificationService.success(`${opportunityContactUpdated.fullName}'s company has been successfully updated.`);
                    const opportunityContactIndex = this.opportunityContacts.findIndex(contact => contact.id === opportunityContactUpdated.id);
                    this.opportunityContacts.splice(opportunityContactIndex, 1, opportunityContactUpdated);
                    this.opportunityContactsChange.emit(this.opportunityContacts);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            opportunityId: this.opportunityId
        } as SearchContactsForOpportunityParameters;
    }
}
