/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FilterValueOpportunities } from "../../../shared/opportunities-filters/opportunities-filters.component";
import { SearchOpportunitiesMapParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { MapService } from "projects/app/src/app/services/map.service";
import { IOpportunityCardDetails } from "../../../shared/opportunity-card/opportunity-card-expanded/opportunity-card-expanded.component";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { OrganizationService } from "projects/app/src/app/services/http/clients/organization.service";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-opportunities-map",
    templateUrl: "./opportunities-map.component.html",
    styleUrls: ["./opportunities-map.component.scss"]
})
export class OpportunitiesMapComponent implements AfterViewInit, OnChanges {
    readonly icons = icons;

    @Input()
    filters?: FilterValueOpportunities;

    opportunities: IOpportunityCardDetails[];

    searchConfig: SearchOpportunitiesMapParameters;

    mapInstanceCreated: boolean;

    mapContainerId: string;

    initialCenterCoordinates: [number, number];

    zoomLevel: number;

    selectedMarkerData: any;

    opportunitiesCounter?: number;

    closeIcon?: SafeHtml;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityService: OpportunityService,
        private readonly mapService: MapService,
        private readonly basicUserOrganizationService: BasicUserOrganizationService,
        private readonly organizationService: OrganizationService,
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer
    ) {
        this.opportunities = [];
        this.searchConfig = {} as SearchOpportunitiesMapParameters;
        this.mapInstanceCreated = false;
        this.mapContainerId = "map";
        this.initialCenterCoordinates = [-103.5917, 40.6699]; // center of US
        this.zoomLevel = 4;
        this.closeIcon = this.sanitizer.bypassSecurityTrustHtml(icons.close);
    }

    async ngAfterViewInit(): Promise<void> {
        await this.setInitialCenterCoordinates();
        this.mapInstanceCreated = await this.mapService.initializeMap(this.mapContainerId, this.initialCenterCoordinates, this.zoomLevel, "opportunities");

        if (this.mapInstanceCreated) {
            this.mapService.markerSelected.subscribe(data => {
                this.openPanel(data);
            });

            // Set the callback for load event
            this.mapService.setOnLoadedCallback(async () => {
                await this.refreshMapView();
            });

            // Set the callback for moveend event
            this.mapService.setOnMoveEndCallback(async () => {
                await this.refreshMapView();
            });
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange && this.mapInstanceCreated) {
            this.closePanel();
            this.opportunities = [];
            await this.refreshMapView();
        }
    }

    openPanel(data: any): void {
        if (data.length) {
            this.selectedMarkerData = [];
            this.opportunitiesCounter = data.length;
            for (const element of data) {
                this.selectedMarkerData.push({
                    id: element.id,
                    name: element.name,
                    code: element.code,
                    eventType: OpportunityCalendarEvent.DueDate,
                    eventDate: element.dueDate,
                    opportunityStatusName: element.opportunityStatusName,
                    companyName: element.companyName,
                    opportunityValue: element.potentialValue,
                    opportunityUsers: element.opportunityUsers
                });
            }
        }
    }

    closePanel(): void {
        this.selectedMarkerData = null;
    }

    onGoToClicked(id?: number): void {
        this.router.navigate([`/opportunities-single/${id}/${TabNames[TabNames.Overview]}`]);
    }

    async refreshMapView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction("Loading Opportunities", async () => {
            this.opportunities = await this.opportunityService.searchMap(this.searchConfig);
            this.mapService.fetchData(this.opportunities, "opportunities-data");
        });
    }

    private buildSearchConfig() {
        const bounds = this.mapService.getMapBounds();

        if (!bounds) return;

        this.searchConfig = {
            latitudeFrom: bounds.getSouth(),
            latitudeTo: bounds.getNorth(),
            longitudeFrom: bounds.getWest(),
            longitudeTo: bounds.getEast(),

            filterText: this.filters?.searchText ?? undefined,
            opportunityStatusIds: this.filters?.statuses?.map(s => s.id) ?? undefined,
            divisionIds: this.filters?.divisions?.map(d => d.id) ?? undefined,
            probabilityValueFrom: this.filters?.probabilityFrom,
            probabilityValueTo: this.filters?.probabilityTo,
            onlyFavorites: this.filters?.onlyFavorites,
            companyIds: this.filters?.companies?.map(c => c.id) ?? undefined,
            salesUserId: this.filters?.salesperson?.externalId,
            dueDateFrom: this.filters?.dateFrom,
            dueDateTo: this.filters?.dateTo,
            potentialValueFrom: this.filters?.potentialValueFrom,
            potentialValueTo: this.filters?.potentialValueTo,
            proposedGrossMarginFrom: this.filters?.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.filters?.proposedGrossMarginTo,
            isActive: this.filters?.activeStatus?.value
        } as SearchOpportunitiesMapParameters;
    }

    private async setInitialCenterCoordinates(): Promise<void> {
        const userOrganization = this.basicUserOrganizationService.getUserOrganization();

        if (userOrganization?.id) {
            const organizationInfo = await this.organizationService.getById(userOrganization.id);

            if (organizationInfo.longitude && organizationInfo.latitude) {
                this.initialCenterCoordinates = [organizationInfo.longitude, organizationInfo.latitude];
            }
        }
    }
}
