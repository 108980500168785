<div class="container" *ngIf="location">
    <ul>
        <li>
            <div class="label">Address Line 1</div>
            <div class="value">{{ location.line1 }}</div>
        </li>
        <li>
            <div class="label">Address Line 2</div>
            <input
                class="value"
                [placeholder]="(isReadOnly && !line2) ? '' : 'Add Address Line 2'"
                type="text"
                [(ngModel)]="line2"
                (ngModelChange)="onInputChange($event)"
                [readOnly]="isReadOnly"
            />
        </li>
        <li>
            <div class="label">Country</div>
            <div class="value">{{ location.countryName }}</div>
        </li>
        <li>
            <div class="label">State</div>
            <div class="value">{{ location.stateName }}</div>
        </li>
        <li>
            <div class="label">City</div>
            <div class="value">{{ location.cityName }}</div>
        </li>
        <li *ngIf="location.zipCode">
            <div class="label">Zip Code</div>
            <div class="value">{{ location.zipCode }}</div>
        </li>
    </ul>
</div>
