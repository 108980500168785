<lib-generic-dropdown
    name="leadSource"
    id="leadSource"
    [(selectedItem)]="selectedLeadSource"
    (selectedItemChange)="onLeadSourceChange()"
    [source]="leadSourceItems"
    [config]="{
        defaultText: 'Select Opportunity Source',
        itemDisplayKey: 'name'
    }"
    [isReadOnly]="isReadOnly"
></lib-generic-dropdown>
