/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, ValidatorFn } from "@angular/forms";

export function passwordsMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl) => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (!control || !matchingControl) {
            return null;
        }

        if (!matchingControl.value || matchingControl.value.trim() === "") {
            matchingControl.setErrors({ required: true });
        } else if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ passwordsMismatch: true });
        } else {
            matchingControl.setErrors(null);
        }

        return null;
    };
}

export function requiredTrimValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value?.trim();
        return value ? null : { required: true };
    };
}
