import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DateTime } from "luxon";
import { BaseFilterComponent } from "../filters/base-filter/base-filter.component";
import { DateTimeService } from "../../services";
import { DatepickerComponent } from "../datepicker/datepicker.component";
import { CalendarRangesConfig } from "../datepicker/default-ranges";
import { CalendarType } from "../calendar/calendar.component";

@Component({
    selector: "lib-datepicker-dropdown",
    templateUrl: "./datepicker-dropdown.component.html",
    styleUrls: ["./datepicker-dropdown.component.scss"]
})
export class DatepickerDropdownComponent extends BaseFilterComponent implements OnInit, OnChanges {
    @ViewChild("datepicker")
    datepicker!: DatepickerComponent;

    @Input()
    defaultTitle?: string;

    @Input()
    error: boolean;

    @Input()
    dateRangeEnabled: boolean;

    @Input()
    rangeDirection: "backward" | "forward" | "both" = "backward";

    @Input()
    customDefaultRanges: CalendarRangesConfig[] | null = null;

    @Input()
    timeEnabled: boolean;

    @Input()
    disabled: boolean;

    @Input()
    selectedDate?: Date;

    @Input()
    datepickerDropdownId?: string;

    @Output()
    selectedDateChange: EventEmitter<Date | undefined>;

    @Input()
    dateFrom?: Date;

    @Output()
    dateFromChange: EventEmitter<Date | undefined>;

    @Input()
    dateTo?: Date;

    @Output()
    dateToChange: EventEmitter<Date | undefined>;

    @Input()
    minDate?: Date;

    @Input()
    isReadOnly?: boolean;

    @Input()
    flexibleSize: boolean;

    @Input()
    displaySelected: boolean;

    @Input()
    calendarTypeDisplay: CalendarType;

    @Input()
    isFullView?: boolean;

    @Output()
    override filterChanged: EventEmitter<void>;

    selectedDateTime?: DateTime;

    dateTimeFrom?: DateTime;

    dateTimeTo?: DateTime;

    minDateTime?: DateTime;

    opened: boolean;

    title?: string;

    originalValues?: { selectedDate?: Date; dateFrom?: Date; dateTo?: Date };

    constructor(private readonly dateTimeService: DateTimeService) {
        super();
        this.error = false;
        this.dateRangeEnabled = false;
        this.timeEnabled = false;
        this.disabled = false;
        this.opened = false;
        this.isReadOnly = false;
        this.flexibleSize = false;
        this.displaySelected = false;
        this.calendarTypeDisplay = CalendarType.MONTH;
        this.selectedDateChange = new EventEmitter<Date | undefined>();
        this.dateFromChange = new EventEmitter<Date | undefined>();
        this.dateToChange = new EventEmitter<Date | undefined>();
        this.filterChanged = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.setTitle();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["defaultTitle"]?.currentValue) {
            this.title = changes["defaultTitle"].currentValue;
        }

        if (changes["disabled"]?.currentValue) {
            this.selectedDate = this.dateFrom = this.dateTo = undefined;
        }

        if (changes["selectedDate"]) {
            this.selectedDateTime = this.selectedDate ? this.dateTimeService.getDateTimeFromString(this.selectedDate.toString()) : undefined;
        }

        if (changes["dateFrom"]) {
            this.dateTimeFrom = this.dateFrom ? this.dateTimeService.getDateTimeFromString(this.dateFrom.toString()) : undefined;
        }

        if (changes["dateTo"]) {
            this.dateTimeTo = this.dateTo ? this.dateTimeService.getDateTimeFromString(this.dateTo.toString()) : undefined;
        }

        if (!changes["dateFrom"]?.currentValue && !changes["dateTo"]?.currentValue) {
            this.title = this.defaultTitle ?? "";
        }

        if (changes["minDate"]) {
            this.minDateTime = this.minDate ? this.dateTimeService.getDateTimeFromString(this.minDate.toString()) : undefined;
        }

        if (changes["dateFrom"]?.currentValue || changes["dateTo"]?.currentValue) {
            this.setTitle();
        }

        if (changes["selectedDate"] && !this.dateRangeEnabled) {
            this.setTitle();
        }
    }

    openClose(): void {
        if (this.disabled || this.isReadOnly) return;

        if (!this.opened) {
            this.originalValues = {
                selectedDate: this.selectedDate,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo
            };
        }
        this.opened = !this.opened;
    }

    onHeaderKeydown(e: KeyboardEvent): void {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            this.openClose();
        }
    }

    onChanged(): void {
        this.selectedDate = this.selectedDateTime ? this.dateTimeService.formatDateTimeToDate(this.selectedDateTime) : undefined;
        this.dateFrom = this.dateTimeFrom ? this.dateTimeService.formatDateTimeToDate(this.dateTimeFrom) : undefined;
        this.dateTo = this.dateTimeTo ? this.dateTimeService.formatDateTimeToDate(this.dateTimeTo) : undefined;

        this.selectedDateChange.emit(this.selectedDate);
        this.dateFromChange.emit(this.dateFrom);
        this.dateToChange.emit(this.dateTo);

        const selectedDateHasChanged =
            typeof this.originalValues?.selectedDate === typeof Date
                ? this.originalValues?.selectedDate?.toISOString() !== this.selectedDate?.toISOString()
                : this.originalValues?.selectedDate !== this.selectedDate;
        const dateFromHasChanged =
            typeof this.originalValues?.dateFrom === typeof Date
                ? this.originalValues?.dateFrom?.toISOString() !== this.dateFrom?.toISOString()
                : this.originalValues?.dateFrom !== this.dateFrom;
        const dateToHasChanged =
            typeof this.originalValues?.dateTo === typeof Date
                ? this.originalValues?.dateTo?.toISOString() !== this.dateTo?.toISOString()
                : this.originalValues?.dateTo !== this.dateTo;

        if (selectedDateHasChanged || dateFromHasChanged || dateToHasChanged) {
            this.filterChanged.emit();
        }

        this.openClose();
    }

    onTitleChanged(value: string) {
        setTimeout(() => {
            this.title = value;
        }, 0);
    }

    private setTitle() {
        if (this.dateRangeEnabled) {
            const dateFrom = this.dateTimeFrom?.toFormat("MM/dd/yy");
            const dateTo = this.dateTimeTo?.toFormat("MM/dd/yy");
            if (this.dateFrom && this.dateTo) {
                const dateToText = dateTo ? `- ${dateTo}` : "";
                this.title = `${dateFrom} ${dateToText}`;
            } else if (dateFrom && !dateTo) {
                this.title = dateFrom;
            } else if (!dateFrom && dateTo) {
                this.title = dateTo;
            }
        } else if (!this.dateRangeEnabled && this.selectedDate) {
            const timeValueText = this.timeEnabled ? `- ${this.selectedDate.toLocaleTimeString(undefined, { timeStyle: "short", hour12: false })}` : "";
            this.title = `${this.selectedDate.toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "2-digit" })} ${timeValueText}`;
        }
    }
}
