import { IconSanitizerService } from "./../../../../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { Subscription } from "rxjs";
import { FilterPropType, IFilterValues } from "../../reports-single.component";

@Component({
    selector: "app-additional-filters",
    templateUrl: "./additional-filters.component.html",
    styleUrls: ["./additional-filters.component.scss"]
})
export class AdditionalFiltersComponent implements OnInit, OnDestroy {
    @Input() filters?: any[];
    @Input() filterValues?: IFilterValues;
    @Output() clearFilters = new EventEmitter<void>();

    factory: AppEditorFactory;
    closeIcon?: SafeHtml;
    additionalFiltersIcon?: SafeHtml;
    appliedAdditionalFiltersIcon?: SafeHtml;
    hasAppliedFilters: boolean;
    opened: boolean;
    subscription?: Subscription;

    constructor(
        private readonly iconSanitizer: IconSanitizerService,
        private readonly reportsService: ReportsService
    ) {
        this.factory = new AppEditorFactory();
        this.closeIcon = this.iconSanitizer.getIcon("close");
        this.additionalFiltersIcon = this.iconSanitizer.getIcon("additionalFilters");
        this.appliedAdditionalFiltersIcon = this.iconSanitizer.getIcon("appliedAdditionalFilters");
        this.hasAppliedFilters = false;
        this.opened = false;
    }

    ngOnInit() {
        this.reportsService;
        this.subscription = this.reportsService.selectedProperty$.subscribe(ready => {
            if (ready) {
                this.hasAppliedFilters = this.checkAppliedFilters();
            }
        });
    }

    onClearFilters() {
        this.clearFilters.emit();
    }

    checkAppliedFilters(): boolean {
        const additionalFilters = this.excludeMainFilters();
        const additionalFiltersValues = this.getAdditionalFiltersValues(additionalFilters);

        return additionalFiltersValues.some(val => val !== null && val !== undefined);
    }

    onOverlayChange(isOpen: boolean) {
        this.hasAppliedFilters = !isOpen ? this.checkAppliedFilters() : false;
        this.opened = isOpen;
    }

    private excludeMainFilters(): IFilterValues {
        const { organizationIds, salesPersonIds, opportunityIds, projectIds, ...additionalFilters } = this.filterValues || {};
        return additionalFilters;
    }

    private getAdditionalFiltersValues(filters: IFilterValues): FilterPropType<number | Date | boolean>[] {
        return Object.values(filters).flatMap((val: any) => (this.isPlainObject(val) ? this.getAdditionalFiltersValues(val) : val));
    }

    private isPlainObject(val: object): boolean {
        return val !== null && typeof val === "object" && !(val instanceof Date);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
