import { Component, OnInit } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityCompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService, ObjectExtensionsService, IconSanitizerService } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

interface IModalEditOpportunityCompanyParameters {
    company: OpportunityCompanyView;
}

@Component({
    selector: "app-modal-edit-opportunity-company",
    templateUrl: "./modal-edit-opportunity-company.component.html",
    styleUrls: ["./modal-edit-opportunity-company.component.scss"]
})
export class ModalEditOpportunityCompanyComponent extends ModalComponentBase<IModalEditOpportunityCompanyParameters, OpportunityCompanyView> implements OnInit {
    protected override parameters!: IModalEditOpportunityCompanyParameters;

    companyName!: string;

    company!: OpportunityCompanyView;

    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    companyInitialState!: OpportunityCompanyView;

    isLoading: boolean;

    closeIcon: SafeHtml;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.companyName = this.parameters?.company.companyName ?? "";
        this.company = this.objectExtensionService.clone(this.parameters?.company) as OpportunityCompanyView;
        this.companyInitialState = this.objectExtensionService.clone(this.parameters?.company) as OpportunityCompanyView;
        this.configItems = [
            {
                label: "Roles",
                componentId: EditorType.entity,
                fieldName: "opportunityCompanyRoles",
                entityId: "opportunityBuildingContactRole",
                bindContext: this.company,
                additionalInfo: {
                    entityType: EntityTypes.Company && EntityTypes.Contact
                }
            }
        ];
    }

    async saveCompany(): Promise<void> {
        await this.awaiter.awaitAction(
            "Saving Company...",
            async () => {
                const opportunityCompanySaved = await this.opportunityService.saveCompany(this.company);

                if (opportunityCompanySaved) {
                    this.snackbarNotificationService.success("Company saved successfully.");
                    this.close(opportunityCompanySaved);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    onClose() {
        this.company = this.companyInitialState;
        this.close(this.companyInitialState);
    }
}
