import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig,
    Order
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { SearchCompaniesParameters, CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { IFilterValueCompanies } from "../companies-main/companies-main.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-companies-list",
    templateUrl: "./companies-list.component.html",
    styleUrls: ["./companies-list.component.scss"]
})
export class CompaniesListComponent implements OnInit, OnChanges {
    companies: CompanyView[];

    @Input() filters?: IFilterValueCompanies;

    @Output() sortChanged = new EventEmitter<IGenericGridOrderConfig<CompanyView>>();

    orderConfig?: IGenericGridOrderConfig<CompanyView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchCompaniesParameters;

    noResults: boolean;
    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<CompanyView> = {
        base: "/companies-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly companyService: CompanyService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute
    ) {
        this.companies = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchCompaniesParameters;
        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnInit() {
        const params = this.route.snapshot.queryParams;

        const sortBy = params["sortBy"] as string | undefined;
        const sortDirection = params["sortDirection"] as Order | undefined;

        if (sortBy && sortDirection) {
            const newOrderConfig: IGenericGridOrderConfig<CompanyView> = {
                key: this.getKeyFromLabel(sortBy),
                displayName: sortBy,
                order: sortDirection
            };

            await this.onOrderChanged(newOrderConfig);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.companies = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();
        await this.awaiter.awaitAction(
            "Loading Companies",
            async () => {
                const response = await this.companyService.searchCompanies(this.searchConfig);
                const { pageInfo, results } = response;
                this.companies = this.companies?.concat(results ?? []);
                this.noResults = this.companies.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage;
        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.companyTypeId = this.filters?.companyType?.id;
        this.searchConfig.marketId = this.filters?.market?.id;
        this.searchConfig.contactId = this.filters?.contact?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    async onOrderChanged(newOrderConfig: IGenericGridOrderConfig<CompanyView> | undefined): Promise<void> {
        this.orderConfig = newOrderConfig;

        this.searchConfig.sortBy = newOrderConfig?.key;
        this.searchConfig.sortDirection = newOrderConfig?.order;

        if (this.filters) {
            this.filters.sortBy = newOrderConfig?.displayName;
            this.filters.sortDirection = newOrderConfig?.order;
        }

        this.paginationConfig.currentPage = 1;
        this.companies = [];

        await this.refreshGridView();

        this.sortChanged.emit(this.orderConfig);
    }

    onRowClicked(company: CompanyView) {
        this.router.navigate(["/companies-single", company.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(company: CompanyView) {
        if (company?.id && company.id > 0) {
            window.open(`/companies-single/${company.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    private getKeyFromLabel(label: string): keyof CompanyView {
        const mapping: Record<string, keyof CompanyView> = {
            Name: "name",
            "Main Office Address": "fullAddress",
            "Main Contact": "contactFirstName",
            Type: "companyTypeName",
            Markets: "markets",
            Condition: "isActive"
        };

        return mapping[label] ?? "name";
    }
}
