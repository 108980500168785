/* eslint-disable @typescript-eslint/no-empty-interface */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";

export interface EmptyAdditionalFilters {}

@Injectable({
    providedIn: "root"
})
export class FilterStateService<M extends object, A extends object = EmptyAdditionalFilters> {
    private readonly filtersSubject = new BehaviorSubject<(M & A) | undefined>(undefined);

    private currentMain: M | undefined;
    private currentAdditional: A | undefined;

    get stateFilters$(): Observable<(M & A) | undefined> {
        return this.filtersSubject.asObservable().pipe(debounceTime(500));
    }

    get current(): (M & A) | undefined {
        return this.filtersSubject.getValue();
    }

    constructor() {
        this.currentMain = undefined;
        this.currentAdditional = undefined;
    }

    updateMainFilters(mainFilters: M): void {
        this.currentMain = mainFilters;
        this.emitMerged();
    }

    updateAdditionalFilters(additionalFilters: A): void {
        this.currentAdditional = additionalFilters;
        this.emitMerged();
    }

    updateFilters(mainFilters: M, additionalFilters?: A): void {
        this.currentMain = mainFilters;

        this.currentAdditional = additionalFilters ?? ({} as A);

        this.emitMerged();
    }

    async clearAllFilters(): Promise<void> {
        this.updateFilters({} as M, {} as A);
    }

    private emitMerged(): void {
        const merged = { ...this.currentMain, ...this.currentAdditional } as M & A;

        this.filtersSubject.next(merged);
    }
}
