export enum Entity {
    opportunityMarket = "opportunityMarket",
    companyMarket = "companyMarket",
    accountMarket = "accountMarket",
    opportunityTag = "opportunityTag",
    opportunityProductType = "opportunityProductType",
    buildingProductType = "buildingProductType",
    opportunityBuildingContactRole = "opportunityBuildingContactRole",
    opportunityBidCategory = "opportunityBidCategory",
    opportunityUserRole = "opportunityUserRole",
    accountProductType = "accountProductType"
}

export enum EntityComponentType {
    dropdownMultiselect = "dropdown-multiselect",
    typeAheadMultiselect = "type-ahead-multiselect"
}
