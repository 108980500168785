import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-welcome-screen",
    templateUrl: "./welcome-screen.component.html",
    styleUrls: ["./welcome-screen.component.scss"]
})
export class WelcomeScreenComponent implements OnInit {
    singInIcon: SafeHtml;
    loginSectionIcon: SafeHtml;

    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.singInIcon = this.iconSanitizer.getIcon("singIn");
        this.loginSectionIcon = this.iconSanitizer.getIcon("loginSection");
    }

    ngOnInit(): void {
        this.authService.redirectIfAuthenticated();
    }

    redirectToLogin() {
        this.router.navigate(["/login"]);
    }
}
