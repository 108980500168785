import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ModalComponentBase, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

interface IModalShareReportParameters {
    reportService: any;
    filterParameters: any;
    reportData: any;
}
@Component({
    selector: "app-modal-report-share",
    templateUrl: "./modal-report-share.component.html",
    styleUrls: ["./modal-report-share.component.scss"]
})
export class ModalReportShareComponent extends ModalComponentBase<IModalShareReportParameters, undefined> implements OnInit {
    shareLInk: string;
    isLoading: boolean;

    closeIcon: SafeHtml;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.shareLInk = "testes";
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = true;
    }

    async ngOnInit(): Promise<void> {
        await this.setSharedReportLink();
    }

    async setSharedReportLink(): Promise<void> {
        await this.awaiter.awaitAction(
            "Running template...",
            async () => {
                const res = await this.parameters?.reportService.shareReport({
                    name: this.parameters?.reportData?.name,
                    reportTemplateId: this.parameters?.reportData.reportTemplateId
                        ? this.parameters.reportData.reportTemplateId
                        : this.parameters?.reportData.id,
                    filterParameters: this.parameters?.filterParameters
                });
                this.shareLInk = res.url;
            },
            loading => (this.isLoading = loading)
        );
    }

    async copyToClipboard(): Promise<void> {
        if (!this.shareLInk) return;
        try {
            await navigator.clipboard.writeText(this.shareLInk);
            this.snackbarNotificationService.success("Link copied to clipboard");
        } catch (err) {
            this.snackbarNotificationService.error("An error occurred while trying to copy link to clipboard");
        }
    }
}
