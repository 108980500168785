import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { Contact } from "projects/app/src/app/models/Contact";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { EditorItemComponent } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

interface IModalAddContactParameters {
    setCompany?: boolean;
}

@Component({
    selector: "app-modal-add-contact",
    templateUrl: "./modal-add-contact.component.html",
    styleUrls: ["./modal-add-contact.component.scss"]
})
export class ModalAddContactComponent extends ModalComponentBase<IModalAddContactParameters, ContactView> implements OnInit {
    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    contact: Contact;

    isReadOnly: boolean;

    protected override parameters?: IModalAddContactParameters;

    isLoading: boolean;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    closeIcon: SafeHtml;

    constructor(
        private readonly contactService: ContactService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.contact = new Contact();
        this.isReadOnly = false;
        this.configItems = [];
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.configItems = [
            {
                label: "First Name",
                componentId: EditorType.textInput,
                fieldName: "firstName",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Last Name",
                componentId: EditorType.textInput,
                fieldName: "lastName",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "E-mail",
                componentId: EditorType.multiInput,
                fieldName: "email",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.email }, { type: ValidatorType.string, args: { maxLength: 200 } }]
            },
            {
                label: "Phone",
                componentId: EditorType.multiInput,
                fieldName: "phone",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 20 } }]
            },
            {
                label: "Address",
                componentId: EditorType.multiInput,
                fieldName: "address",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Linkedin",
                componentId: EditorType.link,
                fieldName: "linkedin",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Position",
                componentId: EditorType.textInput,
                fieldName: "position",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            }
        ];

        if (this.parameters?.setCompany) {
            this.configItems.push({
                label: "Company",
                componentId: EditorType.company,
                fieldName: "companyId",
                bindContext: this.contact,
                isReadOnly: this.isReadOnly,
                additionalInfo: { isActive: true }
            });
        }
    }

    private validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveContact(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving Contact...",
            async () => {
                const response = await this.contactService.save(this.contact);
                if (response) this.snackbarNotificationService.success("Contact created successfully.");
                this.close(response);
            },
            loading => (this.isLoading = loading)
        );
    }
}
