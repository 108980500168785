import { BuildingContactRoleView } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../entity-interfaces/editor-entity.interface";
import { BuildingContactRoleService } from "projects/app/src/app/services/http/clients/building-contact-role.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

export abstract class BuildingContactRoleEntity<TEntityDestination> implements IComponentEditorEntity<BuildingContactRoleView, TEntityDestination> {
    constructor(readonly buildingContactRoleService: BuildingContactRoleService) {}

    abstract toDestination(entity: BuildingContactRoleView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): BuildingContactRoleView;

    abstract getSource(entityType: EntityTypes): Promise<BuildingContactRoleView[]>;
}
