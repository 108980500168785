<div class="wrapper">
    <div class="addCompany">
        <app-type-ahead-companies
            [editorInfoAllowAction]="true"
            [searchParameters]="typeAheadSearchByEntityParameters"
            (selectedCompanyChange)="onSelectedCompany($event)"
        >
        </app-type-ahead-companies>
    </div>
</div>
<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Companies available.'"></lib-no-data>
</ng-container>
<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'companyName', label: 'Name', sortable: true, tooltip: true },
                { key: 'companyRoleDescriptions', label: 'Roles', sortable: true, tooltip: true },
                { key: 'fullAddress', label: 'Main Office Address', sortable: false, tooltip: true },
                { key: 'companyTypeName', label: 'Type', sortable: true },
                { key: 'markets', label: 'Markets', sortable: true, tooltip: true },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [routerLinkConfig]="routerLinkConfig"
        [source]="opportunityCompanies"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #lastContactTemplate let-data>
            {{ data.object.lastContactDate | date: "mediumDate" }}
        </ng-template>
        <ng-template #followUpTemplate let-data>
            {{ data.object.followUpDate | date: "medium" }}
        </ng-template>
        <ng-template #kebabMenu let-data>
            <app-kebab-menu *ngIf="isActionAllowed">
                <button role="menuitem" class="resetStyles" (click)="editItem(data.object)">Edit</button>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>
        <ng-template #formatEmailArray let-data>
            {{ data.object.contactEmail | commaSeparated }}
        </ng-template>
        <ng-template #formatPhoneArray let-data>
            {{ data.object.contactPhone | commaSeparated }}
        </ng-template>
    </lib-grid-view>
</ng-template>
