import { Injectable } from "@angular/core";
import { customTheme } from "../components/shared/themes/chart-themes";
import { AgChartTheme } from "ag-charts-community";

@Injectable({
    providedIn: "root"
})
export class ChartService {
    private theme = customTheme;

    getTheme(): AgChartTheme {
        return this.theme;
    }

    setTheme(newTheme: AgChartTheme): void {
        this.theme = newTheme;
    }
}
