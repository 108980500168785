<div
    class="baseContainer"
    class="{{ parameters?.type }}"
    [ngClass]="{ hide: !showNotification }"
    [style.top.px]="getPosition()"
    [id]="snackbarNotificationId | idGenerator: 'ngx-snackbarNotification-'"
>
    <div class="elementsWrapper">
        <div [ngSwitch]="parameters?.type" class="center">
            <div *ngSwitchCase="'info'" class="icon" [innerHTML]="infoIcon"></div>
            <div *ngSwitchCase="'warning'" class="icon" [innerHTML]="warningIcon"></div>
            <div *ngSwitchCase="'error'" class="icon" [innerHTML]="errorIcon"></div>
            <div *ngSwitchCase="'success'" class="icon" [innerHTML]="successIcon"></div>
        </div>

        <span>{{ parameters?.message }}</span>

        <div class="notificationActions">
            <button class="closeWrapper" (click)="closeWithAnimation()" aria-label="close-notification">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>
    </div>
</div>
