import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

export type ColorType = "success" | "error" | "pending" | "info" | "idle";

@Component({
    selector: "lib-status-indicator",
    templateUrl: "./status-indicator.component.html",
    styleUrls: ["./status-indicator.component.scss"]
})
export class StatusIndicatorComponent implements OnChanges {
    @Input()
    iconName!: string;

    @Input()
    text: string;

    @Input()
    colorType: ColorType;

    @Input()
    statusIndicatorId?: string;

    svg?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.text = "";
        this.colorType = "idle";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["iconName"]) this.svg = this.iconSanitizer.getIcon(this.iconName);
    }
}
