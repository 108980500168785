import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

@Component({
    selector: "app-generic-overlay",
    templateUrl: "./generic-overlay.component.html",
    styleUrls: ["./generic-overlay.component.scss"]
})
export class GenericOverlayComponent {
    @Input({ required: true }) overlayOrigin!: CdkOverlayOrigin;
    @Input() actionButtonsRef?: TemplateRef<unknown>;
    @Input() headerTemplate?: TemplateRef<unknown>;
    @Input() mainActionable?: TemplateRef<unknown>;
    @Output() isOverlayOpenChange: EventEmitter<boolean>;

    isOverlayOpen = false;

    constructor() {
        this.isOverlayOpenChange = new EventEmitter<boolean>();
    }

    closeOverlay() {
        this.isOverlayOpen = false;
        this.onOverlayChange();
    }

    openCloseOverlay() {
        this.isOverlayOpen = !this.isOverlayOpen;
        this.onOverlayChange();
    }

    onOverlayChange() {
        this.isOverlayOpenChange.emit(this.isOverlayOpen);
    }

    openOverlay() {
        this.isOverlayOpen = true;
    }
}
