<div class="notificationsWrapper">
    <ng-container *ngFor="let notification of notifications">
        <app-notification-item [notification]="notification" (notificationChange)="onNotificationChange()" [isPanel]="isPanel"></app-notification-item>
    </ng-container>
    <div *ngIf="paginationConfig && !canGoNext()" class="allLoaded">
        <div class="content">
            <div class="icon" [innerHTML]="notificationIcon"></div>
            <div class="text">That’s all your notifications from the last 30 days.</div>
        </div>
    </div>
    <div class="scrollRef" *ngIf="paginationConfig" libIntersectionListener (intersectionListener)="goNext()"></div>
</div>
