import { Injectable } from "@angular/core";
import { Messages } from "../messages/messages";
import { LoadingMessage } from "../messages/loading.message";
import { MessageBusService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AwaiterService {
    constructor(
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly messageBus: MessageBusService,
        private readonly router: Router
    ) {}

    async await<T>(message: string, action: Promise<T>, loadingAction?: (x: boolean) => void): Promise<T> {
        try {
            this.loading(true, message, loadingAction);
            return await action;
        } catch (error) {
            this.snackbarNotificationService.error(this.processError(error));
            throw error;
        } finally {
            this.loading(false, message, loadingAction);
        }
    }

    async awaitAction<T>(message: string, action: () => Promise<T>, loadingAction?: (x: boolean) => void): Promise<T | undefined> {
        try {
            this.loading(true, message, loadingAction);
            return await action();
        } catch (error) {
            this.snackbarNotificationService.error(this.processError(error));
            return undefined;
        } finally {
            this.loading(false, message, loadingAction);
        }
    }

    private loading(loading: boolean, message: string, loadingAction?: (x: boolean) => void): void {
        if (loadingAction) loadingAction(loading);
        this.messageBus.send(Messages.Loading, new LoadingMessage(loading, message));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processError(error: any): string {
        let errorMessage = "";

        if (error instanceof Error || ("message" in error && error.message)) errorMessage = error.message;

        if ("response" in error && error.response) {
            const innerError = JSON.parse(error.response);

            if ("title" in innerError && innerError.title) {
                errorMessage += `\n${innerError.title}`;
            }

            if ("Message" in innerError && innerError.Message) {
                errorMessage += `\n${innerError.Message}`;
            }

            if ("errors" in innerError && innerError.errors) {
                for (const property in innerError.errors) {
                    const propertyErrors = innerError.errors[property];
                    errorMessage += `\n  Errors for property ${property}:`;

                    for (const message of propertyErrors) {
                        errorMessage += `\n   - ${message}`;
                    }
                }
            }

            if ("status" in error && error.status === 403) {
                this.router.navigate([`/forbidden`]);
            }

            if ("status" in error && error.status === 400) {
                this.router.navigate([`/dashboard`]);
            }
        } else {
            if ("status" in error && error.status === 404) {
                this.router.navigate([`/forbidden`]);
            }
        }

        return errorMessage;
    }
}
