import { Injectable } from "@angular/core";
import { ApplicationInfoDto, IUserClient, SearchUsersParameters, UserPersonalInformationDto, UserClient, UserView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class UserService {
    private client: IUserClient;
    constructor() {
        this.client = new UserClient();
    }

    async getById(id: number): Promise<UserView> {
        return await this.client.userGetByExternalId(id);
    }

    async search(body: SearchUsersParameters): Promise<UserView[]> {
        return await this.client.userSearch(body);
    }

    async changeUserInformation(body: UserPersonalInformationDto): Promise<void> {
        return await this.client.userUpdatePersonalInformation(body);
    }

    async getUserInformation(): Promise<UserPersonalInformationDto> {
        return await this.client.userGetPersonalInformation();
    }

    async getAuthenticatedUser(): Promise<UserView> {
        return await this.client.userGetAuthenticatedUser();
    }

    async getUserApplications(): Promise<ApplicationInfoDto[]> {
        return await this.client.userGetApplications();
    }

    async isLoggedIn(): Promise<boolean> {
        return await this.client.userIsLoggedIn();
    }
}
