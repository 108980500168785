<section class="mainFiltersContainer">
    <lib-slide-toggle class="filter" label="Starred" [(checked)]="mainFilters.onlyFavorites" (checkedChange)="onFiltersChanged()"> </lib-slide-toggle>

    <lib-search-input class="filter" [placeholderText]="'ID or Name'" [(text)]="mainFilters.searchText" (textChange)="onFiltersChanged()"> </lib-search-input>

    <lib-datepicker-dropdown
        class="filter"
        defaultTitle="Due date"
        [(dateFrom)]="mainFilters.dateFrom"
        [(dateTo)]="mainFilters.dateTo"
        (filterChanged)="onFiltersChanged()"
        [dateRangeEnabled]="true"
        [rangeDirection]="'forward'"
        *ngIf="currentTab !== 'Calendar'"
    >
    </lib-datepicker-dropdown>

    <lib-generic-dropdown
        [source]="activeStatuses"
        [config]="{
            multiple: false,
            defaultText: 'Select Condition',
            itemDisplayKey: 'name'
        }"
        [(selectedItem)]="mainFilters.activeStatus"
        (selectedItemChange)="onFiltersChanged()"
    >
    </lib-generic-dropdown>
</section>
