<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Edit {{ companyName }}</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <div *ngFor="let configItem of configItems">
                <lib-editor-item [configItem]="configItem" [factory]="factory"></lib-editor-item>
            </div>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="onClose()" [disabled]="isLoading">Cancel</button>
            <app-loading-button [isLoading]="isLoading" (clickEvent)="saveCompany()" text="Save"></app-loading-button>
        </div>
    </div>
</div>
