import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input, OnInit } from "@angular/core";
import { UserOrganizationView } from "../../../services/http/clients/api-proxies";
import { TooltipPosition } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-organization-item",
    templateUrl: "./organization-item.component.html",
    styleUrls: ["./organization-item.component.scss"]
})
export class OrganizationItemComponent implements OnInit {
    @Input()
    organization?: UserOrganizationView;

    @Input()
    orgNameTooltipPosition?: TooltipPosition;

    organizationLogoIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.organizationLogoIcon = this.iconSanitizer.getIcon("organizationLogo");
    }

    ngOnInit(): void {
        if (!this.orgNameTooltipPosition) this.orgNameTooltipPosition = TooltipPosition.ABOVE;
    }
}
