<div class="container">
    <h1>{{ title }}</h1>
    <div class="items-wrapper">
        <app-list-element
            *ngFor="let item of items"
            [itemId]="item.id"
            [title]="item.title"
            [subtitle]="item.subtitle"
            [link]="item.link"
            [canRemoveItem]="item.canRemoveItem"
            [isFavorite]="item.isFavorite"
            (isFavoriteChange)="onFavoriteChange(item)"
            (removeElementRequest)="onRemoveItem($event)"
        ></app-list-element>
    </div>
</div>
