import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";

@Component({
    selector: "app-contacts-single",
    templateUrl: "./contacts-single.component.html",
    styleUrls: ["./contacts-single.component.scss"]
})
export class ContactsSingleComponent implements OnInit {
    private contactId: number;

    contact?: ContactView;

    currentTab: string;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    tabsCounters: TabCounterType = {
        Opportunities: 0,
        Buildings: 0,
        Notes: 0
    };

    constructor(
        private readonly contactService: ContactService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.contactId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = "Overview";
        this.isActive = true;
        this.entityTypeId = EntityTypes.Contact;
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting Contact Info", async () => {
            this.contact = await this.contactService.getById(this.contactId);
            this.isActive = this.contact.isActive;
            await this.updateTabCounters();
        });
    }

    onEditContact() {
        this.router.navigate([`/contacts-edit/${this.contactId}`], { skipLocationChange: true });
    }

    async archiveContact(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this contact?",
            title: "Archive Contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Contact", async () => {
            if (!this.contact?.id) return;

            await this.contactService.deactivate(this.contact?.id);
            this.isActive = false;
        });
    }

    async restoreContact(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this contact?",
            title: "Restore Contact"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Contact", async () => {
            if (!this.contact?.id) return;
            await this.contactService.activate(this.contact.id);
            this.isActive = true;
        });
    }

    async onUpdateEntity(): Promise<void> {
        await this.updateTabCounters();
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.contactId, this.tabsCounters);
    }
}
