<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <article class="modal-content" *ngIf="!confirmModalOpen">
        <section class="modal-header">
            <h2>{{ modalTitle }}</h2>
            <div class="bar"></div>
            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </section>
        <section
            class="schedule-status"
            [ngClass]="{ 'schedule-status-active': schedulerStatusId === 1, 'schedule-status-paused': schedulerStatusId === 2 }"
            *ngIf="schedulerId && schedulerStatusId"
        >
            <section class="section-description">
                <span class="title-schedule">
                    Schedule
                    <span [ngClass]="{ 'schedule-status-active': schedulerStatusId === 1, 'schedule-status-paused': schedulerStatusId === 2 }">
                        {{ schedulerStatusId === 1 ? "Active" : schedulerStatusId === 2 ? "Stand by" : "" }}
                    </span>
                </span>
                <span class="description-schedule">Last run: {{ lastRun ? (lastRun | date: "shortDate") : "-" }}</span>
            </section>
            <section class="section-btn">
                <button (click)="statusSchedule()" class="secondary-btn pause-btn">{{ schedulerStatusId === 1 ? "Pause" : "Reactivate" }}</button>
                <button (click)="deleteSchedule()" class="tertiary-btn delete-btn">Delete</button>
            </section>
        </section>
        <section class="modal-body" [ngClass]="{ 'modal-edit': schedulerId }">
            <form class="form">
                <section class="form-section">
                    <h2 class="title-section">
                        Report Data Period <span class="icon" [innerHTML]="infoIcon" libTooltip [tooltip]="reportDataPeriodTooltip"></span>
                    </h2>
                    <div class="form-group">
                        <label for="dynamicDate" class="label">
                            <span class="label-title">Dynamic Date</span>
                            <span class="label-subtitle">Required</span>
                        </label>
                        <lib-generic-dropdown
                            [source]="optionsDynamicDate ? optionsDynamicDate : []"
                            [selectedItem]="bindContext.dynamicDate"
                            (selectedItemChange)="onDynamicDateChange($event)"
                            [placeholder]="'No date selected'"
                            [config]="{ defaultText: 'No date selected', itemDisplayKey: 'name' }"
                            class="form-input"
                        >
                        </lib-generic-dropdown>
                    </div>
                    <div class="form-group">
                        <label for="schedulerDateRangeId" class="label">
                            <span class="label-title">Time Period</span>
                            <span class="label-subtitle">Required</span>
                        </label>
                        <lib-generic-dropdown
                            [source]="optionsSchedulerDateRange ? optionsSchedulerDateRange : []"
                            [selectedItem]="bindContext.schedulerDateRangeId"
                            [disabled]="!bindContext.dynamicDate?.value"
                            (selectedItemChange)="onSchedulerDateRangeChange($event)"
                            [placeholder]="'Select Time Period'"
                            [config]="{ defaultText: 'Select Time Period', itemDisplayKey: 'name' }"
                            class="form-input"
                        >
                        </lib-generic-dropdown>
                    </div>
                </section>
                <section class="form-section">
                    <h2 class="title-section">
                        Sending Frequency & People <span class="icon" [innerHTML]="infoIcon" libTooltip [tooltip]="sendingFrequencyPeopleTooltip"></span>
                    </h2>
                    <div class="form-group">
                        <label for="schedulerFrequency" class="label">
                            <span class="label-title">Frequency</span>
                            <span class="label-subtitle">Required</span>
                        </label>
                        <lib-generic-dropdown
                            [source]="optionsFrequency ? optionsFrequency : []"
                            [selectedItem]="bindContext.schedulerFrequency"
                            (selectedItemChange)="onFrequencyChange($event)"
                            [placeholder]="'Select Frequency'"
                            [config]="{ defaultText: 'Select Frequency', itemDisplayKey: 'name' }"
                            class="form-input"
                        >
                        </lib-generic-dropdown>
                    </div>
                    <div class="form-group">
                        <label for="startDate" class="label">
                            <span class="label-title">Start Date</span>
                            <span class="label-subtitle">Required</span>
                        </label>
                        <lib-datepicker-dropdown
                            [selectedDate]="bindContext.startDate"
                            [minDate]="today"
                            (selectedDateChange)="onStartDateChange($event)"
                            [defaultTitle]="'Select Start Date'"
                            [disabled]="false"
                            [isReadOnly]="false"
                            class="form-input"
                        >
                        </lib-datepicker-dropdown>
                    </div>
                    <div class="form-group">
                        <label for="endDate" class="label">
                            <span class="label-title">End Date</span>
                        </label>
                        <lib-datepicker-dropdown
                            [selectedDate]="bindContext.endDate"
                            [minDate]="bindContext.startDate"
                            (selectedDateChange)="onEndDateChange($event)"
                            [defaultTitle]="'Select End Date'"
                            [disabled]="!bindContext.startDate"
                            [isReadOnly]="false"
                            class="form-input"
                        >
                        </lib-datepicker-dropdown>
                    </div>

                    <div class="form-group">
                        <label for="schedulerFrequency" class="label">
                            <span class="label-title">Occurrences</span>
                        </label>
                        <span class="form-input occurrences-input">{{ occurrences }}</span>
                    </div>

                    <div class="form-group">
                        <label class="label">
                            <span class="label-title">Recipients</span>
                            <span class="label-subtitle">Required</span>
                        </label>
                        <lib-generic-type-ahead-multiselect
                            [source]="userItems"
                            [selectedItems]="selectedUsers"
                            (selectedItemsChange)="onSelectedUserChange($event)"
                            [config]="{ defaultText: 'Add User', itemDisplayKey: 'fullName', itemTemplate: userTemplate }"
                            [placeholder]="'Add User'"
                            (textChange)="onUserTextChange($event)"
                            removeKey="userId"
                            class="form-input"
                        >
                        </lib-generic-type-ahead-multiselect>

                        <ng-template #userTemplate let-data>
                            <app-custom-menu-item [inputText]="data.inputText" [title]="data.object?.fullName"> </app-custom-menu-item>
                        </ng-template>
                    </div>

                    <div class="form-group checkbox-group">
                        <div class="checkbox">
                            <input id="testEmail" type="checkbox" (change)="bindContext.testEmail = !bindContext.testEmail" />

                            <label class="label" for="testEmail"> On save send me a quick test to my email </label>
                        </div>
                    </div>
                </section>
            </form>
        </section>

        <section class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)">Cancel</button>
            <button class="modal-button confirm" (click)="saveSchedule()" [disabled]="isSaveDisabled">Save</button>
        </section>
    </article>
</div>
