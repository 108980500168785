import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";

@Component({
    selector: "app-modal-confirm",
    templateUrl: "./modal-confirm.component.html",
    styleUrls: ["./modal-confirm.component.scss"]
})
export class ModalConfirmComponent extends ModalComponentBase<IModalConfirmParameters, boolean> implements OnInit {
    @ViewChild("acceptButton")
    acceptButton?: ElementRef<HTMLButtonElement>;
    override parameters?: IModalConfirmParameters;

    closeIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        super();
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
    }

    ngOnInit(): void {
        this.acceptButton?.nativeElement.focus();
    }

    @HostListener("document:keydown", ["$event"])
    override onKeydownHandler(event: KeyboardEvent) {
        super.onKeydownHandler(event);
    }

    get isContentArray() {
        return Array.isArray(this.parameters?.content);
    }

    get contentAsArray(): Array<string> {
        if (this.isContentArray) {
            return this.parameters?.content as Array<string>;
        } else {
            throw Error("parameter content is not an array");
        }
    }
}

export interface IModalConfirmParameters {
    title?: string;
    acceptCaption: string;
    cancelCaption?: string;
    bodyTitle?: string;
    content: string | string[];
    hideCloseButton?: boolean;
    isReject?: boolean;
}
