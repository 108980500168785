import { ActivatedRoute } from "@angular/router";
import { TabViewComponent } from "projects/ngx-lib/src/public-api";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

const normalizedTabs = Object.keys(TabNames).reduce(
    (acc, tabName) => {
        acc[tabName.toLowerCase()] = tabName;
        return acc;
    },
    {} as Record<string, string>
);

export function getTab(key: string | null): string | undefined {
    if (!key) return undefined;

    const normalizedKey = key.toLowerCase();

    return normalizedTabs[normalizedKey];
}

export function processTabParameter(route: ActivatedRoute, tabControl: TabViewComponent, defaultTab = "Overview"): string {
    const paramTabName = route.snapshot.paramMap.get("tab") ?? "";
    const tabs = tabControl.getTabsNames();

    const normalizedParamTabName = paramTabName.toLowerCase().replace(/\s+/g, "");
    const normalizedTabsNames = tabs.map(tab => tab.toLowerCase().replace(/\s+/g, ""));

    const matchingTabIndex = normalizedTabsNames.findIndex(x => x.includes(normalizedParamTabName));

    return matchingTabIndex !== -1 ? tabs[matchingTabIndex] : defaultTab;
}
