<div class="container">
    <div class="editorItem">
        <div class="label">Contact</div>
        <ng-container *ngIf="selectedItem && isReadOnly; else selectItemTemplate">
            <app-internal-navigator [path]="routeToSingle" [label]="selectedItem.fullName"></app-internal-navigator>
        </ng-container>
        <ng-template #selectItemTemplate>
            <app-type-ahead-contacts
                [companyId]="companyId"
                [displaySelected]="displaySelectedContact"
                [selectedContact]="selectedItem"
                (selectedContactChange)="onSelectedContact($event)"
                [isReadOnly]="isReadOnly"
            >
            </app-type-ahead-contacts>
        </ng-template>
    </div>

    <div class="infoWrapper" *ngIf="selectedItem">
        <ul>
            <li>
                <div class="infoLabel">Position</div>
                <div class="infoValue">{{ contactInfo.position }}</div>
            </li>
            <li>
                <div class="infoLabel">Email</div>
                <div class="infoValue">{{ contactInfo.email }}</div>
            </li>
            <li>
                <div class="infoLabel">Phone</div>
                <div class="infoValue">{{ contactInfo.phone }}</div>
            </li>
        </ul>
    </div>
</div>
