<article class="container">
    <section class="imageSection">
        <img src="../../../../assets/images/login-side-image.png" alt="Image showing Building" />
    </section>

    <section class="loginSection">
        <div class="icon" [innerHTML]="loginSectionIcon"></div>
        <div class="greeting">Hello,</div>
        <div class="welcomeMessage">Welcome</div>
        <div class="signInPrompt">Please sign in to continue</div>
        <button class="primary-btn loginButton" (click)="redirectToLogin()" tabindex="0">
            <div class="buttonContent">
                Sign in
                <div class="icon" [innerHTML]="singInIcon"></div>
            </div>
        </button>
    </section>
</article>
