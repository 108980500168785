<div class="input-container">
    <input
        #inputElement
        type="text"
        class="searchInput"
        [ngClass]="{ withSvg: searchIcon, removeBorder: removeBorder, error: error }"
        [value]="text ?? ''"
        [placeholder]="placeholderText"
        (keyup)="keyUp($event)"
        (blur)="emitBlurEvent()"
        [disabled]="disabled"
        tabindex="0"
        [id]="searchInputId | idGenerator: 'ngx-searchInput-'"
    />
    <div *ngIf="clearValueButton && text" class="clearValueButton" tabindex="0" (keyup.enter)="clearValue()" (click)="clearValue()">
        <div class="icon" [innerHTML]="closeIcon"></div>
    </div>
</div>
