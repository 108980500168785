import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { ModalService, TabViewComponent } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { getTab, processTabParameter } from "projects/app/src/app/utils/tab-navigation";

@Component({
    selector: "app-companies-single",
    templateUrl: "./companies-single.component.html",
    styleUrls: ["./companies-single.component.scss"]
})
export class CompaniesSingleComponent implements OnInit, AfterViewInit {
    @ViewChild(TabViewComponent)
    tabControl!: TabViewComponent;

    companyId: number;

    company?: CompanyView;

    currentTab: string;

    listViewSelectedItem?: CompanyView;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    tabsCounters: TabCounterType = {
        Buildings: 0,
        Contacts: 0,
        Opportunities: 0,
        Notes: 0
    };

    constructor(
        private readonly companyService: CompanyService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.companyId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = getTab(this.route.snapshot.paramMap.get("tab")) ?? TabNames[TabNames.Overview];
        this.isActive = true;
        this.entityTypeId = EntityTypes.Company;
    }

    async ngOnInit(): Promise<void> {
        await this.handleCompanyChange();

        this.route.paramMap.subscribe(async params => {
            const newId = parseInt(params.get("id") ?? "0");
            const newTab = getTab(params.get("tab"));

            if (newId && newId !== this.companyId) {
                this.companyId = newId;
                await this.handleCompanyChange();
            }

            if (newTab && newTab !== this.currentTab && this.tabControl) {
                this.currentTab = processTabParameter(this.route, this.tabControl);
            }
        });
    }

    ngAfterViewInit(): void {
        this.currentTab = processTabParameter(this.route, this.tabControl);
    }

    onEditCompany(): void {
        this.router.navigate([`/companies-edit/${this.companyId}`]);
    }

    async archiveCompany(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this company?",
            title: "Archive Company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Company", async () => {
            if (!this.company?.id) return;

            await this.companyService.deactivate(this.company.id);
            this.isActive = false;
        });
    }

    async restoreCompany(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this company?",
            title: "Restore Company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Company", async () => {
            if (!this.company?.id) return;
            await this.companyService.activate(this.company.id);
            this.isActive = true;
        });
    }

    async onTabCountersChange(): Promise<void> {
        await this.updateTabCounters();
    }

    tabChange(tabName: string) {
        this.router.navigate(["/companies-single", this.companyId, tabName]);
    }

    async handleCompanyChange(): Promise<void> {
        if (!this.companyId) return;

        await this.updateCompanyInfo();
        await this.updateTabCounters();
    }

    private async updateCompanyInfo(): Promise<void> {
        await this.awaiter.awaitAction("Getting Company Info", async () => {
            this.company = await this.companyService.getById(this.companyId);
            this.isActive = this.company.isActive;
        });
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.companyId, this.tabsCounters);
    }
}
