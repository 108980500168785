import { ProductTypeService } from "projects/app/src/app/services/http/clients/product-type.service";
import { ProductTypeEntity } from "./abstract/product-type-entity";
import { AccountProductTypeView, ProductTypeView } from "projects/app/src/app/services/http/clients/api-proxies";

export class AccountProductTypeEntity extends ProductTypeEntity<AccountProductTypeView> {
    constructor(productTypeService: ProductTypeService) {
        super(productTypeService);
    }

    toDestination(entity: ProductTypeView): AccountProductTypeView {
        return { id: 0, productTypeId: entity.id, productTypeName: entity.name };
    }

    toSource(entity: AccountProductTypeView): ProductTypeView {
        return {
            id: entity.productTypeId,
            name: entity.productTypeName,
            isActive: true,
            order: 1,
            isReserved: true
        };
    }
}
