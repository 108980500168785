import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "app-password-input",
    templateUrl: "./password-input.component.html",
    styleUrls: ["./password-input.component.scss"]
})
export class PasswordInputComponent {
    @Input()
    control!: FormControl;

    @Input()
    inputId?: string;

    @Input()
    inputName?: string;

    @Input()
    placeholder?: string;

    @Input()
    minLength?: number;

    @Input()
    maxLength?: number;

    isPasswordVisible: boolean;

    constructor() {
        this.isPasswordVisible = false;
        this.placeholder = "Enter your password";
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
