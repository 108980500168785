import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-kebab-menu",
    templateUrl: "./kebab-menu.component.html",
    styleUrls: ["./kebab-menu.component.scss"]
})
export class KebabMenuComponent {
    @ViewChild("kebabButton") public kebabButton!: ElementRef;

    protected panelOpen = false;

    protected scrollStrategy: ScrollStrategy;

    kebabIcon: SafeHtml;

    constructor(
        private overlay: Overlay,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.kebabIcon = this.iconSanitizer.getIcon("kebab");
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    @HostListener("document:click", ["$event"])
    private onDocumentClicked(event: MouseEvent): void {
        if (!this.kebabButton.nativeElement.contains(event.target) && this.panelOpen) {
            this.panelOpen = false;
        }
    }
}
