import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseDtoService, IBaseDto } from "projects/app/src/app/services/http/clients/reporting-app/base-dto.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-single-dropdown-value-editor",
    templateUrl: "./single-dropdown-value-editor.component.html",
    styleUrls: ["./single-dropdown-value-editor.component.scss"]
})
export class SingleDropdownValueEditorComponent extends EditorComponentBase<number | null> implements OnInit, OnDestroy {
    items: IBaseDto[];
    selectedItem?: IBaseDto;
    defaultText: string;
    subscription?: Subscription;

    constructor(
        private readonly baseDtoService: BaseDtoService,
        private readonly reportsService: ReportsService
    ) {
        super();
        this.items = [];
        this.defaultText = "";
    }

    async ngOnInit(): Promise<void> {
        this.items = await this.baseDtoService.getAll();

        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (this.items?.length && this.bindingProperty !== null && ready) {
                const foundItem = this.items.find(x => x.id === this.bindingProperty);
                this.selectedItem = foundItem;
            } else if (ready && (this.bindingProperty === null || this.bindingProperty === undefined)) {
                this.selectedItem = undefined;
            }
        });
    }

    onSelectedItemChange() {
        this.bindingProperty = this.selectedItem?.id ?? null;
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
