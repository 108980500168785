<lib-generic-dropdown
    [source]="statuses"
    [config]="{
        multiple: false,
        defaultText: 'Select Condition',
        itemDisplayKey: 'name'
    }"
    [(selectedItem)]="selectedStatus"
    (selectedItemChange)="onSelectStatus()"
>
</lib-generic-dropdown>
