<a class="list-element" tabindex="0" (keydown.enter)="navigate(link)" [href]="link">
    <div class="textWrapper">
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="actions">
        <button *ngIf="canRemoveItem" class="resetStyles trashButton" (click)="onRemoveElement($event)" (keydown.enter)="onRemoveElement($event)">
            <div class="icon" [innerHTML]="trashIcon"></div>
        </button>
        <span *ngIf="isScheduleItem" class="resetStyles scheduleButton" libTooltip [tooltip]="scheduleTooltip">
            <div *ngIf="schedulerStatusId === 1" class="icon" [innerHTML]="scheduleActiveIcon"></div>
            <div *ngIf="schedulerStatusId === 2" class="icon" [innerHTML]="schedulePauseIcon"></div>
        </span>
        <app-cell-favorite [ngClass]="{ isFavorite: isFavorite }" [favorite]="isFavorite" (favoriteChange)="onFavoriteChange($event)" (keydown.enter)="$event.stopPropagation()"></app-cell-favorite>
    </div>
</a>
