<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedAddress"
    [placeholder]="'Add Full Address'"
    (selectedItemChange)="onAddressChange($event)"
    [displaySelected]="true"
    [(text)]="searchText"
    [source]="source"
    (textChange)="onAddressTextChanged()"
    [config]="{
        defaultText: 'Add Full Address',
        itemDisplayKey: 'fullAddress',
        itemTemplate: addressesTemplate
    }"
    [isReadOnly]="isReadOnly"
    [error]="error"
>
</lib-generic-type-ahead-dropdown>

<ng-template #addressesTemplate let-data>
    <app-custom-menu-item
        [inputText]="data.inputText"
        [icon]="addressTypes[data.object.addressType].icon"
        [title]="data.object.fullAddress"
        [subtitle]="addressTypes[data.object.addressType].typeName">
    </app-custom-menu-item>
</ng-template>