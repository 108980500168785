import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    NotificationTypeUserConfigurationView,
    OpportunityStatusNotificationTypeUserConfigurationView
} from "projects/app/src/app/services/http/clients/api-proxies";
import { NotificationTypeUserConfigurationService } from "projects/app/src/app/services/http/clients/notification-type-user-configuration.service";
import { ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";

type NotificationType = "includeEmailNotification" | "includeInAppNotification";

@Component({
    selector: "app-notifications-edit",
    templateUrl: "./notifications-edit.component.html",
    styleUrls: ["./notifications-edit.component.scss"]
})
export class NotificationsEditComponent implements OnInit {
    notificationTypeUserConfiguration?: NotificationTypeUserConfigurationView[];

    notificationTypeUserConfigurationInitialState?: NotificationTypeUserConfigurationView[];

    checkedAllInApp: boolean;
    checkedAllEmail: boolean;

    constructor(
        private readonly notificationTypeUserConfigurationService: NotificationTypeUserConfigurationService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        this.checkedAllInApp = false;
        this.checkedAllEmail = false;
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Loading Notifications Options", async () => {
            this.notificationTypeUserConfiguration = await this.notificationTypeUserConfigurationService.getUserConfigurations();
            this.notificationTypeUserConfigurationInitialState = this.objectExtensionService.clone(
                this.notificationTypeUserConfiguration
            ) as NotificationTypeUserConfigurationView[];
            this.updateCheckedAllStatus("includeInAppNotification");
            this.updateCheckedAllStatus("includeEmailNotification");
        });
    }

    async onCancel(): Promise<void> {
        this.notificationTypeUserConfiguration = this.objectExtensionService.clone(
            this.notificationTypeUserConfigurationInitialState
        ) as NotificationTypeUserConfigurationView[];
        this.updateCheckedAllStatus("includeInAppNotification");
        this.updateCheckedAllStatus("includeEmailNotification");
    }

    async onSave(): Promise<void> {
        await this.awaiter.awaitAction("Saving Notifications", async () => {
            if (this.notificationTypeUserConfiguration) {
                this.notificationTypeUserConfiguration = await this.notificationTypeUserConfigurationService.save(this.notificationTypeUserConfiguration);
                this.snackbarNotificationService.success("Notifications edited successfully.");
            }
        });
    }

    checkAllInApp(event: boolean) {
        this.checkedAllInApp = event;
        this.checkAll(event, "includeInAppNotification");
    }

    checkAllEmail(event: boolean) {
        this.checkedAllEmail = event;
        this.checkAll(event, "includeEmailNotification");
    }

    checkAll(checkValue: boolean, notificationType: NotificationType) {
        for (const item of this.notificationTypeUserConfiguration ?? []) {
            item[notificationType] = checkValue;
            if (item.opportunityStatusNotificationTypeUserConfigurations?.length) {
                for (const subItem of item.opportunityStatusNotificationTypeUserConfigurations) {
                    subItem[notificationType] = checkValue;
                }
            }
        }
    }

    onNotificationItemChange(event: boolean, item: NotificationTypeUserConfigurationView, notificationType: NotificationType) {
        item[notificationType] = event;

        if (!event) {
            this.uncheckAllIfRequired(notificationType);
        } else {
            this.updateCheckedAllStatus(notificationType);
        }

        if (item.opportunityStatusNotificationTypeUserConfigurations?.length) {
            for (const status of item.opportunityStatusNotificationTypeUserConfigurations) {
                status[notificationType] = event;
            }
        }
    }

    onNotificationSubItemChange(
        event: boolean,
        item: NotificationTypeUserConfigurationView,
        subItem: OpportunityStatusNotificationTypeUserConfigurationView,
        notificationType: NotificationType
    ) {
        subItem[notificationType] = event;

        if (!event) {
            this.uncheckAllIfRequired(notificationType);
            item[notificationType] = false;
        } else {
            const allSubItemsChecked = item.opportunityStatusNotificationTypeUserConfigurations?.every(s => s[notificationType]);
            item[notificationType] = !!allSubItemsChecked;
            this.updateCheckedAllStatus(notificationType);
        }
    }

    uncheckAllIfRequired(notificationType: NotificationType) {
        if (notificationType === "includeInAppNotification") {
            this.checkedAllInApp = false;
        } else if (notificationType === "includeEmailNotification") {
            this.checkedAllEmail = false;
        }
    }

    updateCheckedAllStatus(notificationType: NotificationType) {
        const allChecked = this.notificationTypeUserConfiguration?.every(n => n[notificationType]);
        if (notificationType === "includeInAppNotification") {
            this.checkedAllInApp = !!allChecked;
        } else if (notificationType === "includeEmailNotification") {
            this.checkedAllEmail = !!allChecked;
        }
    }
}
