import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingView, SearchBuildingsForContactParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";

@Component({
    selector: "app-contacts-single-buildings",
    templateUrl: "./contacts-single-buildings.component.html",
    styleUrls: ["./contacts-single-buildings.component.scss"]
})
export class ContactsSingleBuildingsComponent implements OnChanges {
    @Input()
    contactId?: number;

    buildings: BuildingView[];

    orderConfig?: IGenericGridOrderConfig<BuildingView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchBuildingsForContactParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router
    ) {
        this.buildings = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            contactId: this.contactId
        } as SearchBuildingsForContactParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.contactId && changes["contactId"]) {
            this.searchConfig.contactId = this.contactId;
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Buildings",
            async () => {
                const response = await this.buildingService.searchBuildingsForContact(this.searchConfig);
                const { pageInfo, results } = response;
                this.buildings = this.buildings?.concat(results ?? []);

                this.noResults = this.buildings.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            contactId: this.contactId
        } as SearchBuildingsForContactParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.buildings = [];
        await this.refreshGridView();
    }

    onRowClicked(building: BuildingView) {
        this.router.navigate([`/buildings-single/${building.id}`]);
    }

    onMouseWheelClicked(building: BuildingView) {
        if (building?.id && building.id > 0) {
            window.open(`/buildings-single/${building.id}`, "_blank");
        }
    }
}
