export enum OverviewLayout {
    /**
     * Grid: "boxes metrics" / "chart chart"
     */
    TwoPlusOne = "overview-two-plus-one",

    /**
     * Grid: "boxes" / "metrics" / "chart"
     */
    VerticalStack = "overview-vertical-stack",

    /**
     * Grid: "boxes metrics" / "chart chart" / "chart2 chart2"
     */
    ExtendedTwoPlusOne = "overview-extended-two-plus-one",

    /**
     * Grid: "boxes" / "metrics" / "chart" / "chart2"
     */
    FourRowStack = "overview-four-row-stack"
}

export enum GridDisplayScheme {
    Standard = "grid-standard"
}

export type LayoutType = OverviewLayout | GridDisplayScheme;
