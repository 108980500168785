<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Account Settings</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <div *ngFor="let configItem of configItems">
                <lib-editor-item [configItem]="configItem" [factory]="factory" #editorItem></lib-editor-item>
            </div>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)">Cancel</button>
            <button class="modal-button confirm" (click)="saveSettings()">Update</button>
        </div>
    </div>
</div>
W
