import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-link-editor",
    templateUrl: "./link-editor.component.html",
    styleUrls: ["./link-editor.component.scss"]
})
export class LinkEditorComponent extends EditorComponentBase<string> {
    profileIcon: SafeHtml;
    websiteIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        super();
        this.profileIcon = this.iconSanitizer.getIcon("profile");
        this.websiteIcon = this.iconSanitizer.getIcon("website");
    }

    dataChanged() {
        this.validate();
    }
}
