import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { FinalEstimatesService } from "projects/app/src/app/services/http/clients/reporting-app/final-estimates.service";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";
import { WidgetType } from "../../types/report-widget-type.enum";
import { salesMainFilters } from "./shared/main-filters";
import {
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    projectDueDateEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    projectStatusEditorConfig
} from "./shared/additional-filters";
import { truncateValue, percentageFormatter } from "projects/app/src/app/utils/format-functions";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";

export const AwardedFinalEstimates: IReportConfiguration<FinalEstimatesService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: FinalEstimatesService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getAwardedFinalEstimatesBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getAwardedFinalEstimatesMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "average", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "median", label: "Med" }
                    ],
                    rows: [
                        { name: "TDC", label: "TDC ($)", format: "currency" },
                        { name: "GrossMarginPercent", label: "Gross Margin (%)", format: "customPercent" },
                        { name: "SellPrice", label: "Sell Price", format: "currency" }
                    ]
                }
            },
            {
                methodName: "getFinalEstimatesLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Awarded Final Estimates by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "sellPrice",
                            yName: "Sell Price"
                        },
                        {
                            type: "line",
                            xKey: "projectName",
                            yKey: "grossMargin",
                            yName: "Gross Margin (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice"],
                            title: {
                                text: "Amount"
                            }
                        },
                        {
                            type: "number",
                            position: "right",
                            keys: ["grossMargin"],
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            },
                            title: {
                                text: "Percentage"
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getFinalEstimatesColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "sellPrice",
                            yName: "Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "tdc",
                            yName: "TDC ($)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice", "tdc"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.ExtendedTwoPlusOne
    },
    gridTab: {
        widgets: [
            {
                methodName: "getAwardedFinalEstimatesValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "projectId", headerName: "Project ID", lockVisible: true },
                    { field: "projectName", headerName: "Project Name", enableRowGroup: true },
                    { field: "projectDueDate", headerName: "Project Due Date", type: "date" },
                    { field: "projectStatus", headerName: "Project Status", enableRowGroup: true },
                    { field: "organization", headerName: "Organization", enableRowGroup: true },
                    { field: "salesPerson", headerName: "Salesperson", enableRowGroup: true },
                    { field: "alternateQty", headerName: "Alternates Qty", aggFunc: "sum" },
                    { field: "finalEstimateId", headerName: "Final Estimate ID" },
                    { field: "finalEstimateName", headerName: "Final Estimate Name", enableRowGroup: true },
                    { field: "grossMargin", headerName: "Gross Margin (%)", type: "customPercentage", aggFunc: "avg" },
                    { field: "sellPrice", headerName: "Sell Price", type: "currency", aggFunc: "sum" },
                    { field: "tdc", headerName: "TDC ($)", type: "currency", aggFunc: "sum" },
                    { field: "markets", headerName: "Markets", type: "array" },
                    { field: "lead", headerName: "Opportunity Source", enableRowGroup: true },
                    { field: "division", headerName: "Division", enableRowGroup: true },
                    { field: "customer", headerName: "Customer", enableRowGroup: true },
                    { field: "account", headerName: "Account", enableRowGroup: true },
                    { field: "probability", headerName: "Probability", aggFunc: "avg" },
                    { field: "opportunityLastUpdatedNote", headerName: "Last Updated Note", tooltipField: "opportunityLastUpdatedNote" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
