import { Injectable } from "@angular/core";
import {
    CompanyClient,
    CompanyView,
    ICompanyClient,
    QuickSearchCompaniesParameters,
    SearchCompaniesParameters,
    CompanyViewPaginatedResultDto,
    SearchAvailableCompanyBuildingsParameters,
    BuildingView,
    CompanyBuildingViewPaginatedResultDto,
    SearchCompaniesForBuildingParameters,
    SearchAvailableAccountCompaniesParameters,
    SearchCompaniesForAccountParameters,
    SaveAccountParameters,
    SearchCompaniesMapParameters,
    CompanyMapResult
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CompanyService {
    private client: ICompanyClient;
    constructor() {
        this.client = new CompanyClient();
    }

    async save(body: CompanyView): Promise<CompanyView> {
        return await this.client.companySave(body);
    }

    async getById(id: number): Promise<CompanyView> {
        return await this.client.companyGetById(id);
    }

    async searchForBuilding(body: SearchCompaniesForBuildingParameters): Promise<CompanyBuildingViewPaginatedResultDto> {
        return await this.client.companySearchCompaniesForBuilding(body);
    }

    async searchForAccount(body: SearchCompaniesForAccountParameters): Promise<CompanyViewPaginatedResultDto> {
        return await this.client.companySearchCompaniesForAccount(body);
    }

    async searchCompanies(body: SearchCompaniesParameters | undefined): Promise<CompanyViewPaginatedResultDto> {
        return await this.client.companySearch(body);
    }

    async search(body: QuickSearchCompaniesParameters | undefined): Promise<CompanyView[]> {
        return await this.client.companyQuickSearch(body);
    }

    async searchMap(body: SearchCompaniesMapParameters): Promise<CompanyMapResult[]>{
        return await this.client.companyMapSearch(body);
    }

    async searchAvailableBuildings(body: SearchAvailableCompanyBuildingsParameters): Promise<BuildingView[]> {
        return await this.client.companySearchAvailableBuildings(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.companyActivate(id);
    }

    async searchAvailableCompanies(body: SearchAvailableAccountCompaniesParameters): Promise<CompanyView[]> {
        return await this.client.companySearchAvailableAccountCompanies(body);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.companyDeactivate(id);
    }

    async setAccount(body: SaveAccountParameters): Promise<CompanyView> {
        return await this.client.companySetAccount(body);
    }

    async removeAccount(id: number): Promise<void> {
        await this.client.companyRemoveAccount(id);
    }
}
