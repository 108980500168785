<div class="container" [id]="dropdownRangeValuesId | idGenerator: 'ngx-dropdownRangeValues-'">
    <div class="dropdown">
        <div *ngIf="svgIcon && rangeValuesText && rangeValuesText !== dropdownPlaceholder" class="iconContainer" [innerHTML]="svgIcon"></div>
        <button
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            class="resetStyles toggleDropdownButton"
            (click)="toggleDropdown()"
            (keydown)="onKeyPress($event)"
            [ngClass]="{
                placeholder: !rangeValuesText || rangeValuesText === dropdownPlaceholder,
                icon: svgIcon && rangeValuesText && rangeValuesText !== dropdownPlaceholder
            }"
        >
            {{ rangeValuesText ?? dropdownPlaceholder }}
            <span class="arrow" [ngClass]="{ up: opened }">
                <div class="icon" [innerHTML]="bottomArrowIcon"></div>
            </span>
        </button>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (detach)="opened = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="opened = false"
    (overlayOutsideClick)="opened = false"
>
    <div class="itemsContainer" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="rangeContainer">
            <div class="valuesContainer">
                <div class="valueWrapper">
                    <div class="label">
                        <span>From</span>
                    </div>
                    <div class="inputValue">
                        <lib-custom-input-with-icon
                            [(value)]="valueFrom"
                            (ngModelChange)="handleFromValueChange($event)"
                            [type]="'number'"
                            [min]="minValue"
                            [max]="maxValue"
                            [svgIcon]="svgIcon"
                            [restrictNegativeNumbers]="true"
                            (inputError)="handleInputError($event)"
                        ></lib-custom-input-with-icon>
                        <div *ngIf="fromErrorMessage" class="errorMessage">{{ fromErrorMessage }}</div>
                    </div>
                </div>
                <div class="valueWrapper">
                    <div class="label">
                        <span>To</span>
                    </div>
                    <div class="inputValue">
                        <lib-custom-input-with-icon
                            [(value)]="valueTo"
                            (ngModelChange)="handleToValueChange($event)"
                            [type]="'number'"
                            [min]="minValue"
                            [max]="maxValue"
                            [svgIcon]="svgIcon"
                            [restrictNegativeNumbers]="true"
                            (inputError)="handleInputError($event)"
                        ></lib-custom-input-with-icon>
                        <div *ngIf="toErrorMessage" class="errorMessage">{{ toErrorMessage }}</div>
                    </div>
                </div>
            </div>

            <div class="rangeButtons">
                <button class="secondary-btn" (click)="onClear()" tabindex="0" [disabled]="isDisableButton">Clear</button>
                <button class="primary-btn" (click)="onApply()" tabindex="0" [disabled]="isDisableButton">Apply</button>
            </div>
        </div>
    </div>
</ng-template>
