import { Injectable } from '@angular/core';
import { CompanyBuildingClient, CompanyBuildingView, ICompanyBuildingClient } from './api-proxies';

@Injectable({
    providedIn: 'root'
})
export class CompanyBuildingService {
    private client: ICompanyBuildingClient;
    constructor() {
        this.client = new CompanyBuildingClient();
    }

    async add(body: CompanyBuildingView): Promise<CompanyBuildingView> {
        return await this.client.companyBuildingAdd(body);
    }

    async remove(body: CompanyBuildingView): Promise<void> {
        return await this.client.companyBuildingRemoveCompanyBuilding(body);
    }

    async setAsMain(body: CompanyBuildingView): Promise<void> {
        return await this.client.companyBuildingSetBuildingAsMain(body);
    }

    async unsetAsMain(body: CompanyBuildingView): Promise<void> {
        return await this.client.companyBuildingUnsetAsMain(body);
    }
}