import { Component } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-currency-editor",
    templateUrl: "./currency-editor.component.html",
    styleUrls: ["./currency-editor.component.scss"]
})
export class CurrencyEditorComponent extends EditorComponentBase<number | null> {
    constructor() {
        super();
    }

    dataChanged(event: string) {
        this.bindingProperty = this.bindingProperty ? parseFloat(event) : null;
        this.valueChange.emit();
        this.validate();
    }
}
