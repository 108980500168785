import { Injectable } from "@angular/core";
import {
    BoxResultType,
    AccountClient,
    GetAccountLineChartValuesResultType,
    GetAccountValuesParameters,
    GetAccountColumnChartValuesResultType,
    GetAccountMetricsValuesResultType,
    GetAccountValuesResultType,
    CustomReportAccountView,
    SharedReportDto
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ReportingAccountService extends CustomReportServiceBase<AccountClient, CustomReportAccountView> {
    constructor() {
        super(new AccountClient());
    }

    async getAccountBoxesValues(parameters: GetAccountValuesParameters): Promise<BoxResultType[]> {
        return await this.client.accountGetAccountBoxesValues(parameters);
    }

    async getAccountColumnChartValues(parameters: GetAccountValuesParameters): Promise<GetAccountColumnChartValuesResultType[]> {
        return await this.client.accountGetAccountColumnChartValues(parameters);
    }

    async getAccountLineChartValues(parameters: GetAccountValuesParameters): Promise<GetAccountLineChartValuesResultType[]> {
        return await this.client.accountGetAccountLineChartValues(parameters);
    }

    async getAccountMetricsValues(parameters: GetAccountValuesParameters): Promise<GetAccountMetricsValuesResultType> {
        return await this.client.accountGetAccountMetricsValues(parameters);
    }

    async getAccountValues(parameters: GetAccountValuesParameters): Promise<GetAccountValuesResultType[]> {
        return await this.client.accountGetAccountValues(parameters);
    }
    override async saveCustomReport(body: CustomReportAccountView): Promise<CustomReportAccountView> {
        if (body.isPublic)
            return await this.client.accountSaveCatalogReport(body);
        else
            return await this.client.accountSaveCustomReport(body);
    }


    override async getCustomReport(id: number): Promise<CustomReportAccountView> {
        return await this.client.accountGetCustomReport(id);
    }

    override async shareReport(body: CustomReportAccountView): Promise<SharedReportDto> {
        return await this.client.accountCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportAccountView> {
        return await this.client.accountGetSharedReport(id);
    }
}
