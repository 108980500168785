<div class="header">
    <lib-section-header [title]="title" [titleStyle]="'tertiary'" [iconName]="type === 'newProjects' ? 'flare' : 'hourglass'"> </lib-section-header>
</div>

<div class="grid">
    <lib-grid-view
        [gridConfig]="
            type === 'newProjects'
                ? {
                      columnsConfig: [
                          { key: 'name', label: 'Name', sortable: false, tooltip: true },
                          { key: 'probabilityName', label: 'Probability', sortable: false, cellTemplate: cellProbability },
                          { key: 'leadSourceName', label: 'Lead Source', sortable: false },
                          { key: 'potentialValue', label: 'Potential Value ($)', sortable: false, cellTemplate: cellPotentialValue },
                          { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: false, cellTemplate: cellPotentialGrossMargin },
                          { key: 'actualValue', label: 'Actual Value ($)', sortable: false, cellTemplate: cellActualValue },
                          { key: 'actualGrossMarginPercentage', label: 'Actual GM (%)', sortable: false, cellTemplate: cellActualGrossMargin }
                      ]
                  }
                : {
                      columnsConfig: [
                          { key: 'name', label: 'Name', sortable: false, tooltip: true },
                          { key: 'dueDate', label: 'Due Date', sortable: false, cellTemplate: cellDueDate },
                          { key: 'companyName', label: 'Customer', sortable: false, tooltip: true },
                          { key: 'potentialValue', label: 'Potential Value ($)', sortable: false, cellTemplate: cellPotentialValue },
                          { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: false, cellTemplate: cellPotentialGrossMargin },
                          { key: 'actualValue', label: 'Actual Value ($)', sortable: false, cellTemplate: cellActualValue },
                          { key: 'actualGrossMarginPercentage', label: 'Actual GM (%)', sortable: false, cellTemplate: cellActualGrossMargin }
                      ]
                  }
        "
        [source]="dashboardOpportunities"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #cellDueDate let-data>
            {{ data.object.dueDate | date: "MM/dd/y" }}
        </ng-template>

        <ng-template #cellProbability let-data>
            {{ data.object.probabilityName }}
        </ng-template>

        <ng-template #cellPotentialGrossMargin let-data>
            {{ data.object.proposedGrossMarginPercentage | customPercentage }}
        </ng-template>

        <ng-template #cellPotentialValue let-data>
            {{ data.object.potentialValue | currency }}
        </ng-template>

        <ng-template #cellActualGrossMargin let-data>
            {{ data.object.actualGrossMarginPercentage | customPercentage }}
        </ng-template>

        <ng-template #cellActualValue let-data>
            {{ data.object.actualValue | currency }}
        </ng-template>
    </lib-grid-view>
</div>
