<button
    #kebabButton
    id="menubutton"
    aria-haspopup="true"
    aria-controls="menu"
    aria-label="Open and close menu button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="kebabButton noNavigate"
    [ngClass]="{ panelOpen: panelOpen }"
    (click)="openPanel($event)"
>
    <div class="icon" [innerHTML]="kebabIcon"></div>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="panelOpen"
    (overlayOutsideClick)="panelOpen = false"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (detach)="panelOpen = false"
    [cdkConnectedOverlayPositions]="[
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: -5
        },
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetX: -5
        }
    ]"
>
    <section id="menu" role="menu" aria-labelledby="menubutton" class="kebabMenu" libFocusTrap>
        <div role="menubar" tabindex="-1" cdkFocusInitial>
            <ng-content></ng-content>
        </div>
    </section>
</ng-template>
