import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { ReportTypeFilterView, ReportTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ReportTypeService } from "projects/app/src/app/services/http/clients/report-type.service";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { ReportFilterTypes } from "projects/app/src/app/models/enums/ReportFilterTypes";
import { ValidationError } from "projects/ngx-lib/src/lib/interfaces/validator.interface";

export interface IReportExecutionParams {
    reportTypeId?: number;
    id?: number;
    description: string;
    hasMultipleOrganizations: boolean;
    isMultipleOrganizationAllowed: boolean;
    reportExecutionFilters: ReportTypeFilterView[];
}

@Component({
    selector: "app-reports-executions-filters",
    templateUrl: "./reports-executions-filters.component.html",
    styleUrls: ["./reports-executions-filters.component.scss"]
})
export class ReportsExecutionsFiltersComponent implements OnChanges {
    @Input()
    reportType?: ReportTypeView;

    @Input()
    showMultiOrganization?: boolean;

    @Input()
    reportExecution: IReportExecutionParams;

    @Output()
    reportExecutionChange: EventEmitter<IReportExecutionParams>;

    factory: AppEditorFactory;

    filtersCardConfig: IEditorItemConfig[];

    disableContextOnEdit: boolean;

    isValid: boolean;

    fieldValidationErrors: ValidationError[];

    constructor(
        private readonly reportTypeService: ReportTypeService,
        private readonly modalService: ModalService
    ) {
        this.factory = new AppEditorFactory();
        this.filtersCardConfig = [];
        this.reportExecution = {
            description: "",
            hasMultipleOrganizations: false,
            isMultipleOrganizationAllowed: false,
            reportExecutionFilters: []
        };
        this.reportExecutionChange = new EventEmitter<IReportExecutionParams>();

        this.disableContextOnEdit = false;
        this.isValid = true;
        this.fieldValidationErrors = [];
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["reportType"]) {
            if (this.reportType?.id) {
                this.reportExecution.reportTypeId = this.reportType?.id;
                const filtersByReportType = await this.reportTypeService.getFiltersByReportType(this.reportType?.id);
                if (filtersByReportType.length) {
                    this.reportExecution.reportExecutionFilters = filtersByReportType;
                    this.buildFiltersCard(filtersByReportType);
                }
            }
        }

        if (changes["reportExecution"] && !changes["reportExecution"].firstChange) {
            const filtersData = changes["reportExecution"].currentValue?.reportExecutionFilters;
            this.buildFiltersCard(filtersData);

            if (changes["reportExecution"].currentValue?.hasMultipleOrganizations && this.showMultiOrganization) {
                this.removeFilters(filtersData);
            }
            this.disableContextOnEdit = true;
        }

        if (changes["showMultiOrganization"]) {
            this.showMultiOrganization = changes["showMultiOrganization"].currentValue;
        }
    }

    emitValueChanged(): void {
        if (!this.reportExecution.id) return;

        this.reportExecutionChange.emit(this.reportExecution);
    }

    onDescriptionChange(text: string) {
        this.emitValueChanged();

        this.validateField(text);
    }

    async onCheckboxChange(isChecked: boolean): Promise<void> {
        if (isChecked) {
            const responseOk = await this.modalService.open(ModalConfirmComponent, {
                acceptCaption: "Continue",
                cancelCaption: "Cancel",
                bodyTitle: "You have just configured the report for multiple organizations. Are you sure?",
                content: "By continuing, some fields related to the Organization will lose their information if they were previously populated.",
                title: "Multi Organization Report"
            });

            if (!responseOk) {
                this.reportExecution.hasMultipleOrganizations = false;
            } else {
                this.removeFilters(this.reportExecution.reportExecutionFilters);
            }
        } else {
            this.buildFiltersCard(this.reportExecution.reportExecutionFilters);
        }
    }

    validateField(description: string): boolean {
        this.fieldValidationErrors.length = 0;

        this.isValid = Boolean(description.trim());

        if (!this.isValid) {
            this.fieldValidationErrors.push({ errorMessage: "*Field required" });
        }

        return this.isValid;
    }

    private buildFiltersCard(filters: ReportTypeFilterView[]): void {
        this.filtersCardConfig = filters.map((filter: ReportTypeFilterView) => {
            return {
                label: filter.reportFilterDisplayName,
                componentId: filter.editor,
                bindContext: filter,
                fieldName: "filterValue",
                additionalInfo: filter.additionalInfo
            } as IEditorItemConfig;
        });
    }

    private removeFilters(filters: ReportTypeFilterView[]) {
        const unsetFilters = filters.filter(
            (f: ReportTypeFilterView) =>
                f.reportFilterTypeId === ReportFilterTypes.Division ||
                f.reportFilterTypeId === ReportFilterTypes.Salesperson ||
                f.reportFilterTypeId === ReportFilterTypes.Company
        );

        if (!unsetFilters.length) return;

        if (unsetFilters.length) {
            for (const filter of unsetFilters) {
                filter.filterValue = null;
                const filterIndexInCard = this.filtersCardConfig.findIndex(f => f.label === filter.reportFilterDisplayName);
                this.filtersCardConfig.splice(filterIndexInCard, 1);
            }
        }
    }
}
