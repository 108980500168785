import { Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges } from "@angular/core";
import { GridFilterDirective } from "../../directives";
import { IGenericGridColumnConfig, IGenericGridConfig, IGenericGridOrderConfig, IGenericGridPaginationConfig } from "../generic-grid/generic-grid.component";
import { IGridAction } from "../grid-actions/grid-actions.component";

export interface IGridViewGridConfig<T> {
    columnsConfig?: IGenericGridColumnConfig<T>[];
    config?: IGenericGridConfig;
}

export interface IGridViewCachedPages<T> {
    page: number;
    items: T[];
}

@Component({
    selector: "lib-grid-view",
    templateUrl: "./grid-view.component.html",
    styleUrls: ["./grid-view.component.scss"]
})
export class GridViewComponent<TSource> implements OnChanges {
    @Input()
    source?: TSource[];

    @Input()
    gridConfig?: IGridViewGridConfig<TSource>;

    @Input()
    title: string;

    @Input()
    actions: IGridAction[];

    _selectedItems?: TSource[];

    @Input()
    selectedItems?: TSource[];

    @Output()
    selectedItemsChange = new EventEmitter<TSource[]>();

    @Input()
    orderConfig?: IGenericGridOrderConfig<TSource>;

    @Output()
    orderConfigChange = new EventEmitter<IGenericGridOrderConfig<TSource> | undefined>();

    @Input()
    paginationConfig?: IGenericGridPaginationConfig;

    @Output()
    paginationConfigChange: EventEmitter<IGenericGridPaginationConfig> = new EventEmitter<IGenericGridPaginationConfig>();

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identifier: any;

    @Output()
    rowClicked: EventEmitter<TSource> = new EventEmitter<TSource>();

    @Output()
    mouseWheelClick: EventEmitter<TSource> = new EventEmitter<TSource>();

    @Input()
    readOnly?: boolean;

    @ContentChildren(GridFilterDirective) filterComponents!: QueryList<GridFilterDirective>;

    cachedPages?: IGridViewCachedPages<TSource>[];

    @Input()
    hasExternalLink: boolean;

    @Input()
    defaultMaxLines: number;

    constructor() {
        this.title = "";
        this.actions = [];
        this.selectedItems = [];
        this._selectedItems = [];
        this.cachedPages = [];
        this.hasExternalLink = false;
        this.defaultMaxLines = 2;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItems"] && changes["selectedItems"].currentValue) {
            this._selectedItems = this.selectedItems ? [...this.selectedItems] : undefined;
        }
        if (changes["identifier"] && changes["identifier"].currentValue) {
            this.cachedPages = [];
        }
    }

    onRowClicked(item: TSource) {
        this.rowClicked.emit(item);
    }

    selectedItemsChanged(): void {
        this.selectedItemsChange.emit(this._selectedItems);
    }

    orderConfigChanged(): void {
        this.orderConfigChange.emit(this.orderConfig);
    }

    pageChanged(): void {
        this.paginationConfigChange.emit(this.paginationConfig);
    }
}
