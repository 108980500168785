import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Opportunity } from "projects/app/src/app/models/Opportunity";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { ContactPositions } from "projects/app/src/app/models/enums/ContactPositions";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { GetOpportunityActualValuesResultDto } from "projects/app/src/app/services/http/clients/api-proxies";
import { GenericCardComponent } from "projects/ngx-lib/src/public-api";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { OpportunityActualValue } from "projects/app/src/app/models/OpportunityActualValue";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityComponentType } from "../../../../shared/card-editors/entity-editor/entity-enums/entity";
@Component({
    selector: "app-opportunities-single-overview",
    templateUrl: "./opportunities-single-overview.component.html",
    styleUrls: ["./opportunities-single-overview.component.scss"]
})
export class OpportunitiesSingleOverviewComponent implements OnChanges {
    @Input()
    opportunity: Opportunity;

    @Input()
    isReadOnly: boolean;

    @Output()
    valueChange: EventEmitter<boolean>;

    @ViewChild("mainInfoCard")
    mainInfoCard?: GenericCardComponent;

    @ViewChild("organizationCard")
    organizationCard?: GenericCardComponent;

    @ViewChild("buildingCard")
    buildingCard?: GenericCardComponent;

    @ViewChild("detailsCard")
    detailsCard?: GenericCardComponent;

    factory: AppEditorFactory;

    @Input()
    isCreateEstimate: boolean;

    @Input()
    actualValue: GetOpportunityActualValuesResultDto;

    mainInfoCardConfig!: IEditorItemConfig[];
    buildingCardConfig!: IEditorItemConfig[];
    organizationCardConfig!: IEditorItemConfig[];
    customerCardConfig!: IEditorItemConfig[];
    detailsCardConfig!: IEditorItemConfig[];
    bidInfoCardConfig!: IEditorItemConfig[];
    contractingTeamCardConfig!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    constructor(private readonly entityCustomFieldService: EntityTypeCustomFieldService) {
        this.opportunity = new Opportunity();
        this.isReadOnly = false;
        this.factory = new AppEditorFactory();
        this.valueChange = new EventEmitter<boolean>();
        this.isCreateEstimate = false;
        this.actualValue = new OpportunityActualValue();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["opportunity"] || changes["isCreateEstimate"] || changes["isReadOnly"]) {
            this.mainInfoCardConfig = [
                {
                    label: "Name",
                    componentId: EditorType.textInput,
                    fieldName: "name",
                    bindContext: this.opportunity,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 200 } }],
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Due Date & Time",
                    componentId: EditorType.date,
                    fieldName: "dueDate",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { timeEnabled: true },
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                },
                {
                    label: "(Expected) Response Date",
                    componentId: EditorType.date,
                    fieldName: "expectedResponseDate",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { timeEnabled: true }
                },
                {
                    label: "Actual Value ($)",
                    componentId: EditorType.currencyInput,
                    fieldName: "actualValue",
                    bindContext: this.actualValue,
                    isReadOnly: true
                },
                {
                    label: "Actual GM (%)",
                    componentId: EditorType.percentageInput,
                    fieldName: "actualGrossMarginPercentage",
                    bindContext: this.actualValue,
                    isReadOnly: true
                },
                {
                    label: "Potential Value ($)",
                    componentId: EditorType.currencyInput,
                    fieldName: "potentialValue",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [
                        { apply: this.isCreateEstimate, type: ValidatorType.required },
                        { type: ValidatorType.number, args: { maxValue: 99999999999999999n } }
                    ]
                },
                {
                    label: "Potential GM (%)",
                    componentId: EditorType.percentageInput,
                    fieldName: "proposedGrossMarginPercentage",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.number, args: { maxValue: 99.99, customMessage: "*Input exceeds the maximum value of 9999%" } }]
                },
                {
                    label: "Project ID",
                    componentId: EditorType.textInput,
                    fieldName: "projectId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 25 } }]
                },
                {
                    label: "Description",
                    componentId: EditorType.textarea,
                    fieldName: "description",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 1000 } }]
                }
            ];

            this.organizationCardConfig = [
                { label: "Organization", componentId: EditorType.textInput, fieldName: "organizationName", bindContext: this.opportunity, isReadOnly: true },
                {
                    label: "Division",
                    componentId: EditorType.division,
                    fieldName: "divisionId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { isActive: true },
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                }
            ];

            this.buildingCardConfig = [
                {
                    componentId: EditorType.building,
                    fieldName: "buildingId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                }
            ];

            this.customerCardConfig = [
                {
                    label: "Company",
                    componentId: EditorType.company,
                    fieldName: "companyId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    // this property 'additionalInfo: { allowAction: true }' must be set if we want to allow the user to create a new company by opening quick add modal
                    additionalInfo: { allowAction: true, isActive: true }
                },
                {
                    label: "Company Role",
                    componentId: EditorType.entity,
                    fieldName: "opportunityBuildingContactRoles",
                    entityId: "opportunityBuildingContactRole",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        entityType: EntityTypes.Company
                    }
                },
                {
                    label: "Contacts",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.Customer,
                    isReadOnly: this.isReadOnly
                }
            ];

            this.detailsCardConfig = [
                {
                    label: "Team Members",
                    componentId: EditorType.opportunityUser,
                    fieldName: "opportunityUsers",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                },
                {
                    label: "Probability",
                    componentId: EditorType.opportunityProbability,
                    fieldName: "opportunityProbabilityId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                },
                {
                    label: "Opportunity Source",
                    componentId: EditorType.leadSource,
                    fieldName: "leadSourceId",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Markets",
                    componentId: EditorType.entity,
                    fieldName: "opportunityMarkets",
                    bindContext: this.opportunity,
                    entityId: "opportunityMarket",
                    isReadOnly: this.isReadOnly,
                    validations: [{ apply: this.isCreateEstimate, type: ValidatorType.required }]
                },
                {
                    label: "Product Types",
                    componentId: EditorType.entity,
                    fieldName: "opportunityProductTypes",
                    entityId: "productType",
                    bindContext: this.opportunity,
                    additionalInfo: {
                        entityType: EntityTypes.Opportunity,
                        entityComponentType: EntityComponentType.typeAheadMultiselect
                    },
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Tags",
                    componentId: EditorType.entity,
                    fieldName: "opportunityTags",
                    bindContext: this.opportunity,
                    entityId: "opportunityTag",
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        entityType: EntityTypes.Opportunity,
                        entityComponentType: EntityComponentType.typeAheadMultiselect,
                        allowToSaveNewItem: true
                    }
                },
                {
                    label: "Project Start",
                    componentId: EditorType.date,
                    fieldName: "projectStart",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { minDate: new Date() }
                },
                {
                    label: "Project End",
                    componentId: EditorType.date,
                    fieldName: "projectEnd",
                    bindContext: this.opportunity,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: { minDate: new Date() }
                }
            ];

            this.bidInfoCardConfig = [
                {
                    label: "Competing Bidders",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.CompetingBidder,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Bid Recipients",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.BidRecipient,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Bid Categories",
                    componentId: EditorType.entity,
                    fieldName: "opportunityBidCategories",
                    bindContext: this.opportunity,
                    entityId: "opportunityBidCategory",
                    isReadOnly: this.isReadOnly
                }
            ];

            this.contractingTeamCardConfig = [
                {
                    label: "Architect",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.Architect,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Consulting Engineer",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.ConsultingEngineer,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Prime Contractor",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.PrimeContractor,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Contract With",
                    componentId: EditorType.opportunityContact,
                    fieldName: "opportunityContacts",
                    bindContext: this.opportunity,
                    additionalInfo: ContactPositions.ContractWith,
                    isReadOnly: this.isReadOnly
                }
            ];

            if (!this.opportunity.customFieldValues?.length)
                this.opportunity.customFieldValues = await this.entityCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Opportunity);

            this.customFieldsConfig =
                this.opportunity.customFieldValues.map(item => {
                    return {
                        label: item.name,
                        componentId: item.editor,
                        bindContext: item,
                        fieldName: "value",
                        isReadOnly: this.isReadOnly
                    } as IEditorItemConfig;
                }) ?? [];
        }
    }

    validate(): boolean {
        const mainInfoCardValid = this.mainInfoCard ? this.mainInfoCard.validate() : false;
        const organizationCardValid = this.organizationCard ? this.organizationCard.validate() : false;
        const buildingCardValid = this.buildingCard ? this.buildingCard.validate() : false;
        const detailsCardValid = this.detailsCard ? this.detailsCard.validate() : false;

        const allValid = mainInfoCardValid && organizationCardValid && buildingCardValid && detailsCardValid;

        return allValid;
    }

    clearValidationErrors(): void {
        this.mainInfoCard?.clearValidationErrors();
        this.organizationCard?.clearValidationErrors();
        this.buildingCard?.clearValidationErrors();
        this.detailsCard?.clearValidationErrors();
    }

    scrollToFirstErrorElement(): void {
        setTimeout(() => {
            document.querySelectorAll(".error")[0]?.scrollIntoView({ behavior: "smooth" });
        }, 1);
    }

    emitValueChanged(): void {
        this.valueChange.emit();
    }
}
