export enum TabNames {
    Overview = 1,
    Estimates,
    Contacts,
    Team,
    ActivityLog,
    Notes,
    Opportunities,
    Buildings,
    Offices
}
