import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { SearchOpportunitiesParameters, SearchOpportunitiesResult } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { FilterValueOpportunities } from "../../../shared/opportunities-filters/opportunities-filters.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-opportunities-list",
    templateUrl: "./opportunities-list.component.html",
    styleUrls: ["./opportunities-list.component.scss"]
})
export class OpportunitiesListComponent implements OnChanges {
    @Input()
    filters?: FilterValueOpportunities;

    opportunities: SearchOpportunitiesResult[];

    orderConfig?: IGenericGridOrderConfig<SearchOpportunitiesResult>;

    paginationConfig!: IGenericGridPaginationConfig;

    searchConfig!: SearchOpportunitiesParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService
    ) {
        this.opportunities = [];
        this.noResults = true;
        this.isLoading = true;

        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            isActive: true
        } as SearchOpportunitiesParameters;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["filters"]?.currentValue) {
            this.resetOpportunitiesList();
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        await this.awaiter.awaitAction(
            "Loading Opportunities",
            async () => {
                this.buildSearchConfig();
                const response = await this.opportunityService.search(this.searchConfig);
                const { pageInfo, results } = response;
                if (this.searchConfig.pageNumber === 1 && this.opportunities.length) this.resetOpportunitiesList();
                this.opportunities = this.opportunities?.concat(results ?? []);

                this.noResults = this.opportunities.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.resetOpportunitiesList();
        await this.refreshGridView();
    }

    onRowClicked(opportunity: SearchOpportunitiesResult) {
        this.router.navigate(["/opportunities-single", opportunity.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(opportunity: SearchOpportunitiesResult) {
        if (opportunity?.id && opportunity.id > 0) {
            window.open(`/opportunities-single/${opportunity.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onFavoriteChanged(item: SearchOpportunitiesResult) {
        await this.awaiter.awaitAction("Setting Favorite", async () => {
            try {
                if (!item.isFavorite) {
                    await this.opportunityService.setAsFavorite(item.id);
                    item.isFavorite = true;
                } else {
                    await this.opportunityService.removeFromFavorite(item.id);
                    item.isFavorite = false;
                }
            } catch {
                throw new Error("Error changing favorite mode");
            }
        });
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortBy: this.orderConfig?.key ?? undefined,
            sortDirection: this.orderConfig?.order ?? undefined,

            filterText: this.filters?.searchText ?? undefined,
            opportunityStatusIds: this.filters?.statuses?.map(s => s.id) ?? undefined,
            divisionIds: this.filters?.divisions?.map(d => d.id) ?? undefined,
            probabilityValueFrom: this.filters?.probabilityFrom,
            probabilityValueTo: this.filters?.probabilityTo,
            onlyFavorites: this.filters?.onlyFavorites,
            companyIds: this.filters?.companies?.map(c => c.id) ?? undefined,
            salesUserId: this.filters?.salesperson?.externalId,
            dueDateFrom: this.filters?.dateFrom,
            dueDateTo: this.filters?.dateTo,
            potentialValueFrom: this.filters?.potentialValueFrom,
            potentialValueTo: this.filters?.potentialValueTo,
            proposedGrossMarginFrom: this.filters?.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.filters?.proposedGrossMarginTo,
            isActive: this.filters?.activeStatus?.value
        } as SearchOpportunitiesParameters;
    }

    private resetOpportunitiesList() {
        this.paginationConfig.currentPage = 1;
        this.opportunities = [];
    }
}
