import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from "@angular/core";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig,
    Order
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { SearchContactsParameters, ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IFilterValueContacts } from "../contacts-main/contacts-main.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-contacts-list",
    templateUrl: "./contacts-list.component.html",
    styleUrls: ["./contacts-list.component.scss"]
})
export class ContactsListComponent implements OnChanges, OnInit {
    contacts: ContactView[];

    @Input()
    filters?: IFilterValueContacts;

    @Output()
    sortChanged = new EventEmitter<IGenericGridOrderConfig<ContactView>>();

    orderConfig?: IGenericGridOrderConfig<ContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsParameters;

    noResults: boolean;

    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<ContactView> = {
        base: "/contacts-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly contactService: ContactService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly awaiter: AwaiterService
    ) {
        this.contacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchContactsParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnInit(): Promise<void> {
        const params = this.route.snapshot.queryParams;

        const sortBy = params["sortBy"] as string | undefined;
        const sortDirection = params["sortDirection"] as Order | undefined;

        if (sortBy && sortDirection) {
            const newOrderConfig: IGenericGridOrderConfig<ContactView> = {
                key: this.getKeyFromLabel(sortBy),
                displayName: sortBy,
                order: sortDirection
            };

            await this.onOrderChanged(newOrderConfig);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.contacts = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContacts(this.searchConfig);
                const { pageInfo, results } = response;
                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;
        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.companyId = this.filters?.company?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    async onOrderChanged(newOrderConfig: IGenericGridOrderConfig<ContactView> | undefined): Promise<void> {
        this.orderConfig = newOrderConfig;

        this.searchConfig.sortBy = newOrderConfig?.key;
        this.searchConfig.sortDirection = newOrderConfig?.order;

        if (this.filters) {
            this.filters.sortBy = newOrderConfig?.displayName;
            this.filters.sortDirection = newOrderConfig?.order;
        }
        this.paginationConfig.currentPage = 1;

        this.contacts = [];

        await this.refreshGridView();

        this.sortChanged.emit(this.orderConfig);
    }

    onRowClicked(contact: ContactView) {
        this.router.navigate(["/contacts-single", contact.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(contact: ContactView) {
        if (contact?.id && contact.id > 0) {
            window.open(`/contacts-single/${contact.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }
    private getKeyFromLabel(label: string): keyof ContactView {
        const mapping: Record<string, keyof ContactView> = {
            "First Name": "firstName",
            "Last Name": "lastName",
            Phone: "phone",
            Email: "email",
            Company: "companyName",
            Condition: "isActive"
        };

        return mapping[label] ?? "firstName";
    }
}
