<section>
    <button class="resetStyles organizationSelected" (click)="openSwitcher()">
        <div *ngIf="!selectedOrganization" class="wrapper">
            <span>{{ placeholder }}</span>
        </div>
        <div *ngIf="selectedOrganization" class="wrapper">
            <app-organization-item [organization]="selectedOrganization" [orgNameTooltipPosition]="selectedOrganizationTooltipPosition"></app-organization-item>
        </div>
    </button>
    <div *ngIf="opened" class="panelWrapper" cdkTrapFocus cdkTrapFocusAutoCapture [ngClass]="{ opened: opened, closed: !opened }">
        <div class="panelContent" [ngClass]="{ opened: opened, closed: !opened }">
            <div class="panelHeader">
                <h2>Select Organization</h2>
            </div>
            <div class="panelBody">
                <ul class="organizationItems">
                    <li *ngFor="let organization of organizations">
                        <button
                            class="organizationItem"
                            [ngClass]="{ selected: organization === selectedOrganization }"
                            (click)="selectItem(organization)"
                            (keyup)="keyup($event, organization)"
                            tabindex="0"
                        >
                            <app-organization-item [organization]="organization"></app-organization-item>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
