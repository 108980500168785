import { IconSanitizerService } from "./../../../../../../../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ReportBoxValueDataTypeEnum } from "projects/app/src/app/components/pages/reports/types/report-box-value-data-type.enum";

@Component({
    selector: "app-box",
    templateUrl: "./box.component.html",
    styleUrls: ["./box.component.scss"]
})
export class BoxComponent implements OnChanges {
    @Input()
    numericValue: number;

    @Input()
    titleValue?: string;

    @Input()
    formatType: ReportBoxValueDataTypeEnum;

    icon?: SafeHtml;

    public readonly formatTypes = ReportBoxValueDataTypeEnum;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.numericValue = 0;
        this.titleValue = "";
        this.formatType = ReportBoxValueDataTypeEnum.String;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["formatType"]) {
            this.updateIcon();
        }
    }

    updateIcon(): void {
        let iconHtml: SafeHtml | undefined;

        switch (this.formatType) {
            case ReportBoxValueDataTypeEnum.Currency:
                iconHtml = this.iconSanitizer.getIcon("dollar");
                break;

            default:
                iconHtml = this.iconSanitizer.getIcon("bubbles");
                break;
        }

        this.icon = iconHtml;
    }
}
