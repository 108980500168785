import { IconSanitizerService } from "./../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, ContentChildren, OnInit, QueryList } from "@angular/core";
import { IDropdownPanel, IGenericGridOrderConfig, IGenericGridPaginationConfig, SnackbarNotificationService, Order } from "projects/ngx-lib/src/public-api";
import { User } from "../../../models/security/user";
import { EditorType } from "../../../factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "../../../factories/app-editor-factory/app-editor-factory";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SafeHtml } from "@angular/platform-browser";
import { BoxComponent } from "../reports/reports-single/report-section/report-section-instance/report-section-instance-widgets/box-widget/box/box.component";

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}

interface IItem {
    name: string;
    surname: string;
    id: number;
    isFavorite: boolean;
    role: string;
}
@Component({
    selector: "app-playground",
    templateUrl: "./playground.component.html",
    styleUrls: ["./playground.component.scss"]
})
export class PlaygroundComponent implements OnInit {
    displayedColumns: string[] = ["position", "name", "weight", "symbol"];
    dataSource: PeriodicElement[] = [
        { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
        { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
        { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
        { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
        { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
        { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
        { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
        { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
        { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
        { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" }
    ];

    user: User;
    panel: IDropdownPanel;
    icon?: string;
    entity?: IItem;
    page?: string;

    noRowItems: IItem[] = [];
    currentNoRowSelectedItems: IItem[] = [];
    infiniteScrollItems: IItem[] = [];

    listViewSelectedItem?: IItem;

    notificationType: "info" | "error" | "warn" | "success" = "info";

    items: IItem[] = [
        {
            id: 1,
            isFavorite: true,
            name: "Michael",
            surname: "Smith",
            role: "Manager"
        },
        {
            id: 2,
            isFavorite: false,
            name: "David",
            surname: "Johnson",
            role: "Customer"
        },
        {
            id: 3,
            isFavorite: false,
            name: "Chris",
            surname: "Brown",
            role: "Admin"
        },
        {
            id: 4,
            isFavorite: false,
            name: "Emily",
            surname: "White",
            role: "HQ Manager"
        },
        {
            id: 5,
            isFavorite: false,
            name: "Sarah",
            surname: "Davis",
            role: "Manager"
        },
        {
            id: 6,
            isFavorite: false,
            name: "Kevin",
            surname: "Garcia",
            role: "Customer"
        }
    ];

    selectedItem = this.items.length ? this.items[0] : undefined;
    selectedItems = this.items.length ? [this.items[0]] : [];
    pageSize = 2;
    pagedItems: IItem[] = this.items.slice(this.pageSize * (1 - 1), this.pageSize * 1);
    currentSelectedItems: IItem[] = [];
    orderConfig?: IGenericGridOrderConfig<IItem> = undefined;

    paginationConfig: IGenericGridPaginationConfig = {
        currentPage: 1,
        pagesCount: Math.floor(this.items.length / this.pageSize),
        totalCount: 10,
        pageSize: this.pageSize
    };

    paginationConfigAutoLoad: IGenericGridPaginationConfig = {
        currentPage: 1,
        pagesCount: 8,
        totalCount: 15,
        pageSize: this.pageSize,
        autoLoad: true
    };

    factory: AppEditorFactory;
    testConfigCard!: IEditorItemConfig[];

    numericValue: number;

    percentageIcon?: SafeHtml;

    isOverlayOpen = true;
    isChildOverlayOpen = true;
    @ContentChildren(BoxComponent) particularComponents!: QueryList<BoxComponent>;

    constructor(
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.numericValue = 0;
        this.user = new User("1a2as23", 1, "John", "Smith", "johnsmith@smiths.com", 1, "Manager");
        this.icon = "opportunities";
        this.entity = {
            isFavorite: true,
            name: "John",
            surname: "Smith",
            id: 1,
            role: "Manager"
        };
        this.page = "Opportunities";

        this.panel = {
            items: [
                {
                    id: "Item 1",
                    name: "Item 1"
                },
                {
                    id: "Item 2",
                    name: "Item 2"
                }
            ]
        };

        this.factory = new AppEditorFactory();

        this.testConfigCard = [
            { label: "Name", componentId: EditorType.textInput, fieldName: "name", bindContext: this.items[0] },
            { label: "Last Name", componentId: EditorType.textInput, fieldName: "surname", bindContext: this.items[0] },
            { label: "Role", componentId: EditorType.textInput, fieldName: "role", bindContext: this.items[0] }
        ];

        this.loadMore();
    }

    async ngOnInit(): Promise<void> {
        this.percentageIcon = this.iconSanitizer.getIcon("percentageInput");
    }

    selectedItemsChanged(items: IItem[]) {
        const currentSelectedItems = this.selectedItems;
        const currentOnViewItems = this.pagedItems;

        const removedSelectedItems = currentOnViewItems.filter(x => currentSelectedItems.includes(x) && !items.includes(x));
        const addSelectedItems = currentOnViewItems.filter(x => !currentSelectedItems.includes(x) && items.includes(x));

        let newSelectedItems = currentSelectedItems.filter(x => !removedSelectedItems.includes(x));
        newSelectedItems = [...newSelectedItems, ...addSelectedItems];

        this.selectedItems = newSelectedItems;
    }

    onRowClicked(item: IItem) {
        console.log(item.name);
    }

    updatePage() {
        this.pagedItems = this.items.slice(this.pageSize * (this.paginationConfig.currentPage - 1), this.pageSize * this.paginationConfig.currentPage);
        this.currentSelectedItems = this.pagedItems.filter(x => this.selectedItems.includes(x));
    }

    gridOrderChanged() {
        this.infiniteScrollItems = this.infiniteScrollItems.sort((a: IItem, b: IItem) => {
            const firstItem = this.orderConfig?.order === Order.asc ? a : b;
            const secondItem = this.orderConfig?.order === Order.asc ? b : a;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let firstValue = firstItem[this.orderConfig!.key];
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let secondValue = secondItem[this.orderConfig!.key];

            if (typeof firstValue === "string") firstValue = firstValue.toLocaleLowerCase() as unknown as IItem[keyof IItem];
            if (typeof secondValue === "string") secondValue = secondValue.toLocaleLowerCase() as unknown as IItem[keyof IItem];

            if (firstValue > secondValue) {
                return 1;
            }
            if (firstValue < secondValue) {
                return -1;
            }
            return 0;
        });

        this.paginationConfig.currentPage = 1;

        this.updatePage();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFavoriteChange(isFavorite: boolean, item: any) {
        item.isFavorite = isFavorite;
    }

    loadMore() {
        setTimeout(() => {
            const items = this.getItems(this.paginationConfigAutoLoad.currentPage);
            this.infiniteScrollItems = [...this.infiniteScrollItems, ...items];
        }, 500);
    }

    getItems(page: number) {
        const items: IItem[] = [];

        for (let i = 1; i <= 15; i++) {
            items.push({
                id: i,
                isFavorite: false,
                name: `Name ${i}`,
                surname: `Surname ${i}`,
                role: "Manager"
            });
        }

        const startIndex = (page - 1) * (this.paginationConfigAutoLoad.pageSize ?? this.pageSize);
        return items.slice(startIndex, startIndex + (this.paginationConfigAutoLoad.pageSize ?? this.pageSize));
    }

    get itemsTotalNum(): number {
        return this.items.length;
    }

    showNotification() {
        switch (this.notificationType) {
            case "info":
                this.snackbarNotificationService.info("Info: This is an information notification.");
                break;
            case "warn":
                this.snackbarNotificationService.warn("Warning: This is a warning notification.");
                break;
            case "error":
                this.snackbarNotificationService.error("Error: This is an error notification.");
                break;
            case "success":
                this.snackbarNotificationService.success("Success: This is a success notification.");
                break;
        }
    }

    closeOverlay() {
        this.isOverlayOpen = false;
    }

    openCloseOverlay(event: Event) {
        event.stopPropagation();
        this.isOverlayOpen = !this.isOverlayOpen;
    }

    onOverlayOpenChange(event: boolean) {
        this.isOverlayOpen = event;
    }

    openCloseChildOverlay(event: Event) {
        event.stopPropagation();
        this.isChildOverlayOpen = !this.isChildOverlayOpen;
    }

    onChildOverlayOpenChange(event: boolean) {
        this.isChildOverlayOpen = event;
    }
}
