/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from "@angular/core";

import { AddressView, BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-address-editor",
    templateUrl: "./address-editor.component.html",
    styleUrls: ["./address-editor.component.scss"]
})
export class AddressEditorComponent extends EditorComponentBase<string> implements OnInit {
    @Input()
    selectedAddress?: AddressView;

    showStaticImageMap?: boolean;

    constructor() {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.showStaticImageMap = this.additionalInfo?.staticImageMap;
        if (this.bindingProperty) {
            this.mapBuildingViewToAddressView();
        }
    }

    onSelectedAddress(address: AddressView) {
        this.selectedAddress = address;
        this.bindingProperty = this.selectedAddress?.addressLines ?? "";
        this.mapAddressViewToBuildingView();
        this.validate();
    }

    mapBuildingViewToAddressView() {
        const {
            addressId,
            line1,
            line2,
            addressLines,
            zipCode,
            latitude,
            longitude,
            countryId = 0,
            countryName,
            countryCode,
            stateId = 0,
            stateName,
            stateCode,
            cityId = 0,
            cityName,
            fullAddress,
            addressType
        } = this.bindContext as BuildingView;

        this.selectedAddress = {
            id: addressId,
            line1,
            line2,
            addressLines,
            zipCode,
            latitude,
            longitude,
            countryId,
            countryName,
            countryCode,
            stateId,
            stateName,
            stateCode,
            cityId,
            cityName,
            fullAddress,
            addressType
        };
    }

    mapAddressViewToBuildingView() {
        if (this.selectedAddress && this.bindContext) {
            for (const key in this.selectedAddress) {
                if (key in (this.bindContext as BuildingView) && key !== "id") {
                    (this.bindContext as any)[key] = (this.selectedAddress as any)[key];
                }
            }

            (this.bindContext as BuildingView).addressId = this.selectedAddress.id;
        }
    }
}
