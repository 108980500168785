import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { SearchCompaniesParameters, CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { IFilterValueCompanies } from "../companies-main/companies-main.component";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-companies-list",
    templateUrl: "./companies-list.component.html",
    styleUrls: ["./companies-list.component.scss"]
})
export class CompaniesListComponent implements OnChanges {
    companies: CompanyView[];

    @Input()
    filters?: IFilterValueCompanies;

    orderConfig?: IGenericGridOrderConfig<CompanyView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchCompaniesParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService
    ) {
        this.companies = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchCompaniesParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.companies = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Companies",
            async () => {
                const response = await this.companyService.searchCompanies(this.searchConfig);
                const { pageInfo, results } = response;
                this.companies = this.companies?.concat(results ?? []);
                this.noResults = this.companies.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;

        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.companyTypeId = this.filters?.companyType?.id;
        this.searchConfig.marketId = this.filters?.market?.id;
        this.searchConfig.contactId = this.filters?.contact?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.companies = [];

        await this.refreshGridView();
    }

    onRowClicked(company: CompanyView) {
        this.router.navigate(["/companies-single", company.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(company: CompanyView) {
        if (company?.id && company.id > 0) {
            window.open(`/companies-single/${company.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }
}
