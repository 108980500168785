import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ChangePasswordDto } from "projects/app/src/app/services/http/clients/api-proxies";
import { LoginService } from "projects/app/src/app/services/http/clients/login.service";
import { ModalComponentBase, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { passwordsMatchValidator, requiredTrimValidator } from "../../password-input/password-input-validators/password-input-validators";

@Component({
    selector: "app-modal-update-password",
    templateUrl: "./modal-update-password.component.html",
    styleUrls: ["./modal-update-password.component.scss"]
})
export class ModalUpdatePasswordComponent extends ModalComponentBase<undefined, undefined> {
    updatePasswordForm: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        private readonly awaiter: AwaiterService,
        private readonly loginService: LoginService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        super();
        this.updatePasswordForm = this.fb.group(
            {
                currentPassword: ["", [requiredTrimValidator()]],
                newPassword: ["", [requiredTrimValidator(), Validators.minLength(6), Validators.maxLength(100)]],
                confirmPassword: ["", [requiredTrimValidator()]]
            },
            {
                validators: passwordsMatchValidator("newPassword", "confirmPassword")
            }
        );
    }

    get currentPasswordControl(): FormControl {
        return this.updatePasswordForm.get("currentPassword") as FormControl;
    }

    get newPasswordControl(): FormControl {
        return this.updatePasswordForm.get("newPassword") as FormControl;
    }

    get confirmPasswordControl(): FormControl {
        return this.updatePasswordForm.get("confirmPassword") as FormControl;
    }

    async submitUpdateForm(): Promise<void> {
        if (!this.updatePasswordForm.valid) return;

        const bodyRequestDto: ChangePasswordDto = {
            currentPassword: this.updatePasswordForm.value["currentPassword"],
            newPassword: this.updatePasswordForm.value["newPassword"],
            confirmNewPassword: this.updatePasswordForm.value["confirmPassword"]
        };

        await this.awaiter.awaitAction("Saving new password ...", async () => {
            await this.loginService.sendChangePasswordRequest(bodyRequestDto);
            this.snackbarNotificationService.success("Password successfully updated");
            this.close(undefined);
        });
    }
}
