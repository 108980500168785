<div class="tabContainer" [ngClass]="{ column: direction === 'column' }" [id]="tabViewId | idGenerator: 'ngx-tabView-'">
    <div
        class="nav"
        [ngClass]="{
            hasSteps: hasSteps,
            hasQuantity: hasQuantity,
            column: direction === 'column',
            alignStart: alignment === 'start',
            alignCenter: alignment === 'center',
            alignEnd: alignment === 'end',
            narrowSeparation: separation === 'narrow',
            normalSeparation: separation === 'normal',
            wideSeparation: separation === 'wide',
            containerBackground: containerBackground
        }"
    >
        <div
            [ngClass]="{ active: tab === getActiveComponent(), disabled: tab.disabled, containerBackground: containerBackground }"
            class="nav-item"
            *ngFor="let tab of tabs; index as i"
        >
            <span class="separator" *ngIf="hasSteps && i !== 0">
                <div class="icon" [innerHTML]="separatorIcon"></div>
            </span>
            <button
                class="tabName"
                (click)="setActiveComponent(tab)"
                [disabled]="tab.disabled"
                tabindex="0"
                [ngClass]="{ row: direction === 'row', column: direction === 'column' }"
            >
                <div class="content">
                    <div>
                        <span *ngIf="tab.icon" class="icon" [innerHTML]="tab.icon"></span>
                        <ng-container *ngIf="tab.headerTemplate">
                            <ng-container *ngTemplateOutlet="tab.headerTemplate; context: { tab: tab }"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="!tab.headerTemplate">
                            <span class="text">{{ tab.tabName }}</span>
                        </ng-container>
                    </div>
                </div>
            </button>
        </div>
        <div class="scroll-ref" *ngIf="paginationConfig" libIntersectionListener (intersectionListener)="goNext()">.</div>
        <div *ngIf="direction === 'row'" class="horizontalLine"></div>
    </div>

    <div class="content" [ngClass]="{ fixedContentHeader: fixedContentHeader }">
        <ng-container *ngIf="!renderAll">
            <ng-container *ngIf="fixedContentHeader">
                <ng-container *ngTemplateOutlet="fixedContentHeader"></ng-container>
            </ng-container>
            <ng-template [ngTemplateOutlet]="getActiveComponent().bodyTemplate"></ng-template>
        </ng-container>

        <ng-container *ngIf="renderAll">
            <ng-container *ngIf="fixedContentHeader">
                <ng-container *ngTemplateOutlet="fixedContentHeader"></ng-container>
            </ng-container>
            <ng-container *ngFor="let tab of tabs">
                <div [hidden]="tab !== getActiveComponent()">
                    <ng-template [ngTemplateOutlet]="tab.bodyTemplate"></ng-template>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
