import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BuildingView, QuickSearchBuildingParameters, SearchAvailableCompanyBuildingsParameters } from "../../../services/http/clients/api-proxies";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { ModalAddBuildingComponent } from "../modals/modal-add-building/modal-add-building.component";
import { BuildingService } from "../../../services/http/clients/building.service";
import { ITypeAheadDropdownEntityParameters } from "../../../interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "../../../models/enums/EntityTypes";
import { CompanyService } from "../../../services/http/clients/company.service";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";

@Component({
    selector: "app-type-ahead-buildings",
    templateUrl: "./type-ahead-buildings.component.html",
    styleUrls: ["./type-ahead-buildings.component.scss"]
})
export class TypeAheadBuildingsComponent implements OnInit {
    @Input()
    selectedBuilding?: BuildingView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    searchParameters!: ITypeAheadDropdownEntityParameters;

    @Input()
    isActive?: boolean;

    @Output()
    selectedBuildingChange = new EventEmitter<BuildingView>();

    @Input()
    error?: boolean;

    searchText: string;

    source?: BuildingView[];

    addBuildingAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly companyService: CompanyService,
        private readonly modalService: ModalService
    ) {
        this.searchText = "";
        this.isReadOnly = false;
        this.displaySelected = false;
        this.placeholder = "Add Building";
        this.error = false;
    }

    async ngOnInit(): Promise<void> {
        this.addBuildingAction = {
            includeAction: true,
            name: "+ Add new building",
            action: () => this.openModal()
        };
    }

    async openModal(): Promise<void> {
        this.selectedBuilding = await this.modalService.open(ModalAddBuildingComponent);
        if (this.selectedBuilding) {
            this.selectedBuildingChange.emit(this.selectedBuilding);
        }
    }

    async searchByEntity(entityId?: EntityTypes): Promise<BuildingView[]> {
        const searchConfig: unknown = {
            filterText: this.searchText,
            ...this.searchParameters?.entity,
            isActive: this.isActive
        };

        this.source = [];

        if (entityId === EntityTypes.Company)
            return await this.companyService.searchAvailableBuildings(searchConfig as SearchAvailableCompanyBuildingsParameters);

        return await this.buildingService.search(searchConfig as QuickSearchBuildingParameters);
    }

    async onBuildingTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.searchByEntity(this.searchParameters?.entityKeyName);
    }

    onBuildingChange(itemSelected: BuildingView): void {
        this.selectedBuilding = itemSelected;
        this.selectedBuildingChange.emit(itemSelected);
    }
}
