<div class="wrapper">
    <div class="addCompany">
        <app-type-ahead-companies [searchParameters]="typeAheadSearchByEntityParameters" (selectedCompanyChange)="onSelectedCompany($event)">
        </app-type-ahead-companies>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Companies available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'companyName', label: 'Company Name', sortable: true, tooltip: true },
                { key: 'contactFullName', label: 'Main Contact', sortable: false, tooltip: true },
                { key: 'line2', label: 'Address line 2', sortable: false, tooltip: true },
                { key: 'companyTypeName', label: 'Type', sortable: true },
                { key: 'companyMarkets', label: 'Markets', sortable: true },
                { key: 'isMain', sortable: false, cellTemplate: mainOffice },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [source]="companies"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #mainOffice let-data>
            <lib-badge *ngIf="data.object.isMain" text="Main Office"></lib-badge>
        </ng-template>

        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="editAddress(data.object)">Edit Address Line 2</button>
                <button *ngIf="!data.object.isMain" role="menuitem" class="resetStyles" (click)="setAsMainOffice(data.object)">Set as Main Office</button>
                <button *ngIf="data.object.isMain" role="menuitem" class="resetStyles" (click)="unsetAsMainOffice(data.object)">Unset as Main Office</button>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>
    </lib-grid-view>
</ng-template>
