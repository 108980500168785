import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-opportunities-main",
    templateUrl: "./opportunities-main.component.html",
    styleUrls: ["./opportunities-main.component.scss"]
})
export class OpportunitiesMainComponent implements OnInit {
    currentTab: string;

    kanbanIcon?: SafeHtml;
    listIcon?: SafeHtml;
    mapIcon?: SafeHtml;
    calendarIcon?: SafeHtml;

    constructor(
        private readonly router: Router,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.currentTab = "List";
    }

    ngOnInit(): void {
        this.kanbanIcon = this.iconSanitizer.getIcon("kanban");
        this.listIcon = this.iconSanitizer.getIcon("list");
        this.mapIcon = this.iconSanitizer.getIcon("map");
        this.calendarIcon = this.iconSanitizer.getIcon("calendar");
    }

    onCreateNewOpportunityClicked() {
        this.router.navigate([`/opportunities-create`]);
    }
}
