import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { Component, OnInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ModalComponentBase, ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    SchedulerDateRangeDto,
    SchedulerFrequencyDto,
    SchedulerTargetUserView,
    SchedulerView
} from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";
import { UserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { SchedulerService } from "projects/app/src/app/services/http/clients/reporting-app/scheduler.services";
import { SchedulerFrequencyService } from "projects/app/src/app/services/http/clients/reporting-app/scheduler-frequency.services";
import { SchedulerDateRangeService } from "projects/app/src/app/services/http/clients/reporting-app/scheduler-date-range.services";
import { EntityTargetUser } from "projects/app/src/app/models/EntityTargetUser ";
import { ReportingUserService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-user.service";
import { ModalConfirmComponent } from "../modal-confirm/modal-confirm.component";

export interface IModalScheduleParameters {
    schedulerId?: number;
    customReportId?: number;
    optionsDynamicDate?: IOptionsDynamicDate[];
    lastRun?: Date;
}

export interface IOptionsDynamicDate {
    value: string;
    name: string;
}

export interface ISchedulerResponse {
    schedulerStatusId?: number;
    changedScheduler?: SchedulerView;
    deletedScheduler?: boolean;
}

@Component({
    selector: "app-modal-schedule",
    templateUrl: "./modal-schedule.component.html",
    styleUrls: ["./modal-schedule.component.scss"]
})
export class ModalScheduleComponent extends ModalComponentBase<IModalScheduleParameters, ISchedulerResponse> implements OnInit {
    modalTitle: string;
    closeIcon: SafeHtml;
    message: string;
    isSaveDisabled: boolean;
    isLoading: boolean;
    confirmModalOpen: boolean;
    infoIcon?: SafeHtml;
    occurrences?: string;

    optionsFrequency?: SchedulerFrequencyDto[];
    optionsSchedulerDateRange?: SchedulerDateRangeDto[];
    optionsDynamicDate?: IOptionsDynamicDate[];

    bindContext: {
        dynamicDate?: IOptionsDynamicDate;
        schedulerDateRangeId?: SchedulerDateRangeDto;
        schedulerFrequency?: SchedulerFrequencyDto;
        startDate?: Date;
        endDate?: Date;
        recipients: SchedulerTargetUserView[];
        testEmail: boolean;
    };

    schedulerId?: number;
    customReportId?: number;

    private initialState: {
        dynamicDate?: IOptionsDynamicDate;
        schedulerDateRangeId?: SchedulerDateRangeDto;
        schedulerFrequency?: SchedulerFrequencyDto;
        startDate?: Date;
        endDate?: Date;
        recipients: SchedulerTargetUserView[];
        testEmail: boolean;
    };

    userItems: EntityTargetUser[] = [];
    selectedUsers: EntityTargetUser[] = [];
    schedulerStatusId?: number;
    lastRun?: Date;
    reportDataPeriodTooltip?: string;
    sendingFrequencyPeopleTooltip?: string;
    today?: Date;

    constructor(
        private readonly iconSanitizer: IconSanitizerService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService,
        private readonly userService: UserService,
        private readonly schedulerService: SchedulerService,
        private readonly schedulerFrequencyService: SchedulerFrequencyService,
        private readonly schedulerDateRangeService: SchedulerDateRangeService,
        private readonly reportingUserService: ReportingUserService,
        private readonly modalServices: ModalService,
        private readonly objectExtensionsService: ObjectExtensionsService
    ) {
        super();
        this.modalTitle = "Schedule Report Details";
        this.reportDataPeriodTooltip =
            "Select the time period for the data included in the report. You can choose a specific range or include all available data. This defines the scope of the information that will be included in the report.";
        this.sendingFrequencyPeopleTooltip =
            "Set when the report will be automatically generated and sent to the selected contacts. Choose the frequency (daily, weekly, monthly, etc.), the start and end dates if you wish, and the time when the report should be sent.";
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.infoIcon = this.iconSanitizer.getIcon("info");
        this.message = "";
        this.isSaveDisabled = true;
        this.isLoading = true;
        this.confirmModalOpen = false;
        this.schedulerId = undefined;
        this.customReportId = undefined;
        this.optionsFrequency = [];
        this.today = new Date();
        this.today.setHours(0, 0, 0);

        this.bindContext = {
            dynamicDate: undefined,
            schedulerDateRangeId: undefined,
            schedulerFrequency: undefined,
            startDate: undefined,
            endDate: undefined,
            recipients: [],
            testEmail: false
        };
        this.initialState = {
            dynamicDate: undefined,
            schedulerDateRangeId: undefined,
            schedulerFrequency: undefined,
            startDate: undefined,
            endDate: undefined,
            recipients: [],
            testEmail: false
        };
    }

    async ngOnInit(): Promise<void> {
        this.schedulerId = this.parameters?.schedulerId;
        this.customReportId = this.parameters?.customReportId;
        this.optionsDynamicDate = this.parameters?.optionsDynamicDate;
        this.lastRun = this.parameters?.lastRun;
        await this.awaiter.awaitAction(
            "Loading Modal Schedule...",
            async () => {
                const [frequencyOptions, dateRangeOptions] = await Promise.all([
                    this.schedulerFrequencyService.getAll(),
                    this.schedulerDateRangeService.getAll()
                ]);
                this.optionsFrequency = frequencyOptions;
                this.optionsSchedulerDateRange = dateRangeOptions;

                if (this.schedulerId) {
                    try {
                        const data: SchedulerView = await this.schedulerService.getById(this.schedulerId);
                        this.schedulerStatusId = data.schedulerStatusId;
                        this.modalTitle = "Edit Schedule Report Details";
                        data.isActive = true;

                        const selectedFrequency =
                            data.schedulerFrequencyId && this.optionsFrequency
                                ? this.optionsFrequency.find(o => o.id === data.schedulerFrequencyId)
                                : undefined;

                        const selectedSchedulerDateRange =
                            data.schedulerDateRangeId && this.optionsSchedulerDateRange
                                ? this.optionsSchedulerDateRange.find(o => o.id === data.schedulerDateRangeId)
                                : undefined;

                        const selectedOptionsDynamicDate =
                            data.schedulerDateRangeId && this.optionsDynamicDate
                                ? this.optionsDynamicDate.find(o => o.value === data.schedulerConfig)
                                : undefined;

                        this.bindContext.schedulerFrequency = selectedFrequency;
                        this.bindContext.schedulerDateRangeId = selectedSchedulerDateRange;
                        this.bindContext.dynamicDate = selectedOptionsDynamicDate;
                        this.bindContext.startDate = data.startDate ? new Date(data.startDate) : undefined;
                        this.bindContext.endDate = data.endDate ? new Date(data.endDate) : undefined;
                        this.bindContext.testEmail = data.sendTestExecution || false;
                        if (data.schedulerTargetUsers) {
                            this.selectedUsers = data.schedulerTargetUsers as unknown as EntityTargetUser[];
                            this.bindContext.recipients = data.schedulerTargetUsers;
                        }
                        this.updateInitialState();
                    } catch (err) {
                        console.error("Error retrieving schedule by id", err);
                    }
                } else {
                    try {
                        this.updateInitialState();
                        const user: UserView = await this.userService.getAuthenticatedUser();
                        if (user.externalId) {
                            const userInit = await this.reportingUserService.getById(user.externalId);
                            const authUser = EntityTargetUser.fromUserView(userInit);
                            this.selectedUsers = [authUser];
                            this.bindContext.recipients = [authUser];
                        }
                    } catch (err) {
                        console.error("Error retrieving authenticated user", err);
                    }
                }
            },
            loading => (this.isLoading = loading)
        );
        this.checkSaveDisabled();
    }

    private updateInitialState(): void {
        this.initialState = this.objectExtensionsService.clone(this.bindContext) as typeof this.bindContext;
    }

    private checkSaveDisabled(): void {
        const { dynamicDate, schedulerDateRangeId, schedulerFrequency, startDate, endDate, recipients } = this.bindContext;
        const requiredFieldsValid =
            dynamicDate && schedulerDateRangeId && schedulerFrequency && startDate && (this.schedulerId ? true : recipients && recipients.length > 0);

        if (!requiredFieldsValid) {
            this.isSaveDisabled = true;
        } else if (!this.bindContext.recipients || this.bindContext.recipients.length === 0) {
            this.isSaveDisabled = true;
        } else if (this.schedulerId && this.objectExtensionsService.isEqualTo(this.bindContext, this.initialState)) {
            this.isSaveDisabled = true;
        } else {
            this.isSaveDisabled = false;
        }

        if (startDate && endDate && schedulerFrequency?.id) {
            this.occurrences = this.calculateOccurrences(startDate, endDate, schedulerFrequency?.id);
        } else {
            this.occurrences = "-";
        }
    }

    calculateOccurrences(startDate: Date, endDate: Date, frequencyId: number): string {
        const normalizedStart = new Date(startDate);
        normalizedStart.setHours(0, 0, 0, 0);
        const normalizedEnd = new Date(endDate);
        normalizedEnd.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const effectiveStart = this.getEffectiveStart(normalizedStart, today, frequencyId);
        if (effectiveStart > normalizedEnd) return "0";
        const count = this.countIntervals(effectiveStart, normalizedEnd, frequencyId);
        return String(count);
    }

    getEffectiveStart(start: Date, today: Date, frequencyId: number): Date {
        if (start.getTime() > today.getTime()) return start;
        switch (frequencyId) {
            case 1:
                return this.addDays(today, 1);
            case 2:
                return this.addDays(today, 7);
            case 3:
                return this.addMonths(today, 1);
            case 4:
                return this.addMonths(today, 3);
            case 5:
                return this.addYears(today, 1);
            default:
                return today;
        }
    }

    countIntervals(start: Date, end: Date, frequencyId: number): number {
        let count = 0;
        let current = new Date(start);
        while (current <= end) {
            count++;
            current = this.addInterval(current, frequencyId);
        }
        return count;
    }

    addInterval(date: Date, frequencyId: number): Date {
        switch (frequencyId) {
            case 1:
                return this.addDays(date, 1);
            case 2:
                return this.addDays(date, 7);
            case 3:
                return this.addMonths(date, 1);
            case 4:
                return this.addMonths(date, 3);
            case 5:
                return this.addYears(date, 1);
            default:
                return date;
        }
    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    addMonths(date: Date, months: number): Date {
        const result = new Date(date);
        const day = result.getDate();
        result.setMonth(result.getMonth() + months);
        if (result.getDate() !== day) {
            result.setDate(0);
        }
        return result;
    }

    addYears(date: Date, years: number): Date {
        const result = new Date(date);
        result.setFullYear(result.getFullYear() + years);
        return result;
    }

    onDynamicDateChange(selected: IOptionsDynamicDate | null): void {
        this.bindContext.dynamicDate = selected || undefined;
        this.checkSaveDisabled();
    }

    onSchedulerDateRangeChange(selected: SchedulerDateRangeDto | null): void {
        this.bindContext.schedulerDateRangeId = selected || undefined;
        this.checkSaveDisabled();
    }

    onFrequencyChange(selected: SchedulerFrequencyDto | null): void {
        this.bindContext.schedulerFrequency = selected || undefined;
        this.checkSaveDisabled();
    }

    onSelectedUserChange(newSelected: EntityTargetUser[]): void {
        this.selectedUsers = newSelected;
        this.bindContext.recipients = newSelected;
        this.checkSaveDisabled();
    }

    async onUserTextChange(text: string): Promise<void> {
        const results = await this.reportingUserService.searchUsers({
            filterText: text,
            isActive: true,
            userIds: undefined
        });
        this.userItems = results.map(x => EntityTargetUser.fromUserView(x));
    }

    onStartDateChange(newDate: Date | undefined): void {
        this.bindContext.startDate = newDate;
        this.bindContext.endDate = undefined;
        this.checkSaveDisabled();
    }

    onEndDateChange(newDate: Date | undefined): void {
        this.bindContext.endDate = newDate;
        this.checkSaveDisabled();
    }

    async statusSchedule(): Promise<void> {
        this.confirmModalOpen = true;

        const responseOk = await this.modalServices.open(ModalConfirmComponent, {
            acceptCaption: this.schedulerStatusId === 1 ? "Pause" : this.schedulerStatusId === 2 ? "Reactivate" : "",
            cancelCaption: "Cancel",
            content:
                this.schedulerStatusId === 1
                    ? "Are you sure you want to pause this scheduled report? This will stop all future automatic report generations and email deliveries."
                    : this.schedulerStatusId === 2
                      ? "Are you sure you want to reactivate this scheduled report? This will activate all future automatic report generations and email deliveries."
                      : "",
            title: this.schedulerStatusId === 1 ? "Pause Scheduled Report" : this.schedulerStatusId === 2 ? "Reactivate Scheduled Report" : ""
        });
        this.confirmModalOpen = false;
        if (responseOk) {
            await this.awaiter.awaitAction(
                "loading",
                async () => {
                    try {
                        if (this.schedulerId) {
                            const statusId = await this.schedulerService.changeState(this.schedulerId);
                            this.close({ schedulerStatusId: statusId });

                            const message =
                                statusId === 2
                                    ? "The schedule has been changed to Standby."
                                    : statusId === 1
                                      ? "The schedule has been switched to Active."
                                      : "The schedule status has been updated.";

                            this.snackbarNotificationService.success(message);
                        }
                    } catch (error) {
                        console.error(error);
                        this.snackbarNotificationService.error("An error occurred while updating the schedule status.");
                    }
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    async deleteSchedule(): Promise<void> {
        this.confirmModalOpen = true;
        const responseOk = await this.modalServices.open(ModalConfirmComponent, {
            acceptCaption: "Delete",
            cancelCaption: "Cancel",
            content:
                "Are you sure you want to delete this scheduled report? This will stop all future automatic report generations and email deliveries. This action cannot be undone.",
            title: "Delete Scheduled Report",
            isReject: true
        });
        this.confirmModalOpen = false;

        if (responseOk) {
            await this.awaiter.awaitAction(
                "loading",
                async () => {
                    try {
                        if (this.schedulerId) {
                            await this.schedulerService.archive(this.schedulerId);
                            this.close({ deletedScheduler: true });
                            this.snackbarNotificationService.success("The scheduled report has been successfully deleted.");
                        }
                    } catch (error) {
                        console.error(error);
                        this.snackbarNotificationService.error("An error occurred while deleting the scheduled report.");
                    }
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    async saveSchedule(): Promise<void> {
        const { dynamicDate, schedulerDateRangeId, schedulerFrequency, startDate, endDate, testEmail } = this.bindContext;
        if (
            !dynamicDate ||
            !schedulerDateRangeId ||
            !schedulerFrequency ||
            !startDate ||
            !this.bindContext.recipients ||
            this.bindContext.recipients.length === 0
        )
            return;

        this.isSaveDisabled = true;

        const scheduleSave: SchedulerView = {
            id: this.schedulerId ?? 0,
            schedulerFrequencyId: schedulerFrequency.id,
            customReportId: this.customReportId ?? 0,
            schedulerStatusId: 1,
            schedulerDateRangeId: schedulerDateRangeId.id,
            schedulerConfig: dynamicDate.value,
            startDate: startDate,
            endDate: endDate,
            isActive: true,
            reportTemplateId: undefined,
            schedulerTargetUsers: this.bindContext.recipients,
            sendTestExecution: testEmail
        };

        try {
            const result: SchedulerView = await this.schedulerService.save(scheduleSave);
            this.snackbarNotificationService.success(`Schedule has been ${this.schedulerId ? "updated" : "created"} successfully`);
            this.close({ changedScheduler: result });
        } catch (error) {
            console.error("Error saving schedule: ", error);
            this.snackbarNotificationService.error("Error saving schedule");
        } finally {
            this.isSaveDisabled = false;
        }
    }
}
