import { Injectable } from "@angular/core";
import {
    BuildingClient,
    BuildingView,
    BuildingViewPaginatedResultDto,
    CompanyBuildingViewPaginatedResultDto,
    IBuildingClient,
    BuildingContactView,
    QuickSearchBuildingParameters,
    SearchBuildingsForCompanyParameters,
    SearchBuildingsForContactParameters,
    SearchBuildingsParameters,
    CompanyView,
    SearchAvailableBuildingCompaniesParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class BuildingService {
    private client: IBuildingClient;
    constructor() {
        this.client = new BuildingClient();
    }

    async getById(id: number): Promise<BuildingView> {
        return await this.client.buildingGetById(id);
    }

    async save(body: BuildingView): Promise<BuildingView> {
        return await this.client.buildingSave(body);
    }

    async buildingSaveContact(body: BuildingContactView): Promise<BuildingContactView> {
        return await this.client.buildingSaveContact(body);
    }

    async removeContact(body: BuildingContactView): Promise<void> {
        return await this.client.buildingRemoveContact(body);
    }

    async searchBuilding(body: SearchBuildingsParameters | undefined): Promise<BuildingViewPaginatedResultDto> {
        return await this.client.buildingSearch(body);
    }

    async searchBuildingsForCompany(body: SearchBuildingsForCompanyParameters | undefined): Promise<CompanyBuildingViewPaginatedResultDto> {
        return await this.client.buildingSearchForCompany(body);
    }

    async searchBuildingsForContact(body: SearchBuildingsForContactParameters | undefined): Promise<BuildingViewPaginatedResultDto> {
        return await this.client.buildingSearchForContact(body);
    }

    async searchAvailableCompanies(body: SearchAvailableBuildingCompaniesParameters): Promise<CompanyView[]> {
        return await this.client.buildingSearchAvailableCompanies(body);
    }

    async search(body: QuickSearchBuildingParameters): Promise<BuildingView[]> {
        return await this.client.buildingQuickSearch(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.buildingActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.buildingDeactivate(id);
    }
}
