<div *ngIf="finalEstimates" class="content" style="width: 100%; height: 100%">
    <ag-grid-angular
        style="width: 100%"
        class="ag-theme-alpine"
        [columnDefs]="colDefs"
        [rowData]="finalEstimates"
        (gridReady)="onGridReady($event)"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [enableCharts]="false"
        [theme]="agTheme"
        [rowGroupPanelShow]="'onlyWhenGrouping'"
        [groupTotalRow]="'bottom'"
        (columnVisible)="onColumnVisibleChange($event)"
        [gridOptions]="gridOptions"
        (columnRowGroupChanged)="onColumnRowGroupChanged()"
        [tooltipShowDelay]="0"
        [getMainMenuItems]="getMainMenuItems"
    >
    </ag-grid-angular>
</div>
