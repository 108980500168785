import { Injectable } from "@angular/core";
import { ChangePasswordDto, ForgotPasswordDto, ILoginClient, LoginClient, LoginDto, TokenResultDto } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class LoginService {
    private client: ILoginClient;
    constructor() {
        this.client = new LoginClient();
    }

    async authenticate(body: LoginDto | undefined): Promise<TokenResultDto> {
        return await this.client.loginAuthenticate(body);
    }

    async sendChangePasswordRequest(body: ChangePasswordDto): Promise<void> {
        return await this.client.loginChangeUserPassword(body);
    }

    async sendForgotPasswordRequest(body: ForgotPasswordDto): Promise<void> {
        await this.client.loginSendForgotPasswordRequest(body);
    }
}
