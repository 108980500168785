import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { Company } from "projects/app/src/app/models/Company";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EditorItemComponent, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-modal-add-company",
    templateUrl: "./modal-add-company.component.html",
    styleUrls: ["./modal-add-company.component.scss"]
})
export class ModalAddCompanyComponent extends ModalComponentBase<undefined, CompanyView> implements OnInit {
    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    company: Company;

    isReadOnly: boolean;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    isLoading: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.company = new Company();
        this.isReadOnly = false;
        this.configItems = [];
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.configItems = [
            {
                label: "Name",
                componentId: EditorType.textInput,
                fieldName: "name",
                bindContext: this.company,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Abbreviation",
                componentId: EditorType.textInput,
                fieldName: "abbreviation",
                bindContext: this.company,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 50 } }]
            },
            {
                label: "Website",
                componentId: EditorType.link,
                fieldName: "webSite",
                bindContext: this.company,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Linkedin",
                componentId: EditorType.link,
                fieldName: "linkedin",
                bindContext: this.company,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
            },
            {
                label: "Type",
                componentId: EditorType.companyType,
                fieldName: "companyTypeId",
                bindContext: this.company,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Legal Status",
                componentId: EditorType.legalStatus,
                fieldName: "legalStatusId",
                bindContext: this.company,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Markets",
                componentId: EditorType.entity,
                fieldName: "companyMarkets",
                bindContext: this.company,
                entityId: "companyMarket",
                isReadOnly: this.isReadOnly
            }
        ];
    }

    validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveCompany(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving Company...",
            async () => {
                const response = await this.companyService.save(this.company);
                if (response) this.snackbarNotificationService.success("Company created successfully.");
                this.close(response);
            },
            loading => (this.isLoading = loading)
        );
    }
}
