import { Injectable } from "@angular/core";
import {
    BoxResultType,
    ServiceSubCategoryClient,
    CustomReportServiceView,
    GetDataFilterValuesParameters,
    GetServiceSubCategoryColumnChartValuesResultType,
    GetServiceSubCategoryMetricsValuesResultType,
    GetServiceSubCategoryLineChartValuesResultType,
    GetServiceSubCategoryValuesResultType,
    SharedReportDto,
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceSubCategoryService extends CustomReportServiceBase<ServiceSubCategoryClient, CustomReportServiceView> {
    constructor() {
        super(new ServiceSubCategoryClient());
    }

    async getValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceSubCategoryValuesResultType[]> {
        return await this.client.serviceSubCategoryGetServiceSubCategoryValues(parameters);
    }

    async getBoxesValues(parameters: GetDataFilterValuesParameters): Promise<BoxResultType[]> {
        return await this.client.serviceSubCategoryGetServiceSubCategoryBoxesValues(parameters);
    }

    async getColumnChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceSubCategoryColumnChartValuesResultType[]> {
        return await this.client.serviceSubCategoryGetServiceSubCategoryColumnChartValues(parameters);
    }

    async getMetricsValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceSubCategoryMetricsValuesResultType> {
        return await this.client.serviceSubCategoryGetServiceSubCategoryMetricsValues(parameters);
    }

    async getLineChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceSubCategoryLineChartValuesResultType[]> {
        return await this.client.serviceSubCategoryGetServiceSubCategoryLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportServiceView): Promise<CustomReportServiceView> {
        if (body.isPublic)
            return await this.client.serviceSubCategorySaveCatalogReport(body);
        else
            return await this.client.serviceSubCategorySaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportServiceView> {
        return await this.client.serviceSubCategoryGetCustomReport(id);
    }

    override async shareReport(body: CustomReportServiceView): Promise<SharedReportDto> {
        return await this.client.serviceSubCategoryCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportServiceView> {
        return this.client.serviceSubCategoryGetSharedReport(id);
    }
}
