<div class="passwordContainer">
    <div class="inputWrapper">
        <input
            class="passwordInput"
            [id]="inputId"
            [name]="inputName"
            [type]="isPasswordVisible ? 'text' : 'password'"
            [formControl]="control"
            [placeholder]="placeholder"
            required
            [ngClass]="{ invalid: control.touched && control.invalid }"
            [appTrimSpaces]="true"
        />

        <button type="button" class="passwordVisibilityToggle" (click)="togglePasswordVisibility()">
            <div *ngIf="!isPasswordVisible" class="icon" [innerHTML]="passwordNotVisibleIcon"></div>
            <div *ngIf="isPasswordVisible" class="icon" [innerHTML]="passwordVisibleIcon"></div>
        </button>
    </div>

    <div *ngIf="control.touched && control.errors" class="error">
        <div *ngIf="control.errors['required']">* Field required</div>
        <div *ngIf="minLength && control.errors['minlength']">* Field is shorter than the minimum length of {{ minLength }} characters</div>
        <div *ngIf="maxLength && control.errors['maxlength']">* *Field exceeds the maximum length of {{ maxLength }} characters</div>
        <div *ngIf="control?.errors?.['passwordsMismatch']" class="error">* New password and Repeat password do not match</div>
    </div>
</div>
