import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { IDropdownPanel, IDropdownPanelItem } from "projects/ngx-lib/src/lib/components/dropdown-panel/dropdown-panel.component";
import { UserService } from "../../../services/http/clients/user.service";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-user-apps-panel",
    templateUrl: "./user-apps-panel.component.html",
    styleUrls: ["./user-apps-panel.component.scss"]
})
export class UserAppsPanelComponent implements OnInit {
    panel: IDropdownPanel;

    notificationPanelIcon: SafeHtml;

    constructor(
        private readonly userService: UserService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.panel = {
            items: []
        };
        this.notificationPanelIcon = this.iconSanitizer.getIcon("notificationPanel");
    }

    async ngOnInit(): Promise<void> {
        const result = await this.userService.getUserApplications();
        this.panel.items = result.map(
            item =>
                ({
                    id: item.name,
                    name: item.name,
                    action: () => window.open(item.url, "_blank")
                }) as IDropdownPanelItem
        );
    }
}
