import { Injectable } from "@angular/core";
import { DateTime, Settings } from "luxon";

enum Weekdays {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

@Injectable({
    providedIn: "root"
})
export class DateTimeService {
    private readonly minApiDate = "12/31/1969";
    private readonly maxApiDate = "12/31/9999";

    private readonly clientTZ: string;

    constructor() {
        this.clientTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        Settings.defaultLocale = "en-US";
        Settings.defaultWeekSettings = { firstDay: Weekdays.Sunday, minimalDays: 1, weekend: [Weekdays.Saturday, Weekdays.Sunday] };
    }

    getMinApiDateTime(): DateTime {
        return this.getDateTimeFromString(this.minApiDate);
    }

    now(): DateTime {
        return DateTime.local({ zone: this.clientTZ });
    }

    today(): DateTime {
        return this.now().startOf("day");
    }

    endOfWeek(): DateTime {
        return this.today().endOf("week");
    }

    endOfMonth(): DateTime {
        return this.today().endOf("month");
    }

    endOfQuarter(): DateTime {
        const currentMonth = this.today().month;
        const endOfQuarterMonth = currentMonth <= 3 ? 3 : currentMonth <= 6 ? 6 : currentMonth <= 9 ? 9 : 12;
        return this.today().set({ month: endOfQuarterMonth }).endOf("month");
    }

    /**
     * Returns the end of June, used as the fiscal year-end in some U.S. states
     * and organizations. If today is past June, it returns next year's end of June
     */
    endOfMonthJune(): DateTime {
        const now = this.today();
        const endOfJuneThisYear = this.today().set({ month: 6 }).endOf("month");

        if (now > endOfJuneThisYear) {
            return now.plus({ years: 1 }).set({ month: 6 }).endOf("month");
        }

        return endOfJuneThisYear;
    }

    endOfYear(): DateTime {
        return this.today().endOf("year");
    }

    formatDateTimeToDate(dateTime: DateTime, format?: string) {
        return new Date(this.formatToString(dateTime, format ? format : "MM/dd/yyyy HH:mm"));
    }

    getDateTimeFromString(date: string): DateTime {
        try {
            return this.getDateTimeFromISOString(new Date(date).toISOString());
        } catch (error) {
            const regexp = /([0-9]{2})-([0-9]{2})-([0-9]{4})/;
            const found = date.match(regexp);
            if (found && found[3] && found[2] && found[1]) {
                return this.getDateTimeFromISOString(new Date(Number(found[3]), Number(found[2]) - 1, Number(found[1])).toISOString());
            } else {
                return this.getDateTimeFromISOString(new Date().toISOString());
            }
        }
    }

    formatToString(date: DateTime, format: string): string {
        return date.toFormat(format).toUpperCase();
    }

    formatToStringWithOrdinal(date: DateTime): string {
        const ordinal = this.ordinal_suffix_of(parseInt(date.toFormat("d")));
        return `${date.monthLong} ${ordinal}, ${date.toFormat("yyyy")}`;
    }

    dateWithoutTime(date: Date) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    padTo2Digits(num: number) {
        return String(num).padStart(2, "0");
    }

    timeOnly(date: Date) {
        const d = new Date(date);

        return this.padTo2Digits(d.getHours()) + ":" + this.padTo2Digits(d.getMinutes());
    }

    getDateTimeFromISOString(date: string): DateTime {
        return DateTime.fromISO(date);
    }

    private ordinal_suffix_of(i: number) {
        const j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    updateDateTimeWithTime(dateTime: DateTime, timeInput = "00:00"): DateTime | undefined {
        const timeParts = timeInput?.split(":");

        let newDateTime;
        if (timeParts?.length >= 2) {
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);

            newDateTime = dateTime.set({ hour: hours, minute: minutes });
        }
        return newDateTime;
    }

    getTimeAgo(date: Date): string | null {
        const dateTime = this.getDateTimeFromString(date.toString());
        return dateTime.toRelative();
    }

    isWeekend(date: DateTime): boolean {
        const day = date.weekday;
        return day === Weekdays.Saturday || day === Weekdays.Sunday;
    }

    isToday(date: DateTime): boolean {
        return date.toISODate() === DateTime.local().toISODate();
    }

    getStartOfWeek(date: DateTime): DateTime<boolean> {
        return date.startOf("week", { useLocaleWeeks: true });
    }

    getEndOfWeek(date: DateTime): DateTime<boolean> {
        return date.endOf("week", { useLocaleWeeks: true });
    }

    /**
     * Gets the start of the month for the given date.
     * If alignToWeek is true, it returns the start of the week in which the first day of the month falls.
     * Otherwise, it returns the actual first day of the month.
     * @param date - The date for which the start of the month is to be calculated.
     * @param alignToWeek - Optional boolean to indicate if the start of the month should align with the start of the week.
     * @returns The start of the month or the start of the week, depending on the parameter.
     */
    getStartOfMonth(date: DateTime, alignToWeek = false): DateTime {
        return alignToWeek ? date.startOf("month").startOf("week", { useLocaleWeeks: true }) : date.startOf("month");
    }

    /**
     * Gets the end of the month for the given date.
     * If alignToWeek is true, it returns the end of the week in which the last day of the month falls.
     * Otherwise, it returns the actual last day of the month.
     * @param date - The date for which the end of the month is to be calculated.
     * @param alignToWeek - Optional boolean to indicate if the end of the month should align with the end of the week.
     * @returns The end of the month or the end of the week, depending on the parameter.
     */
    getEndOfMonth(date: DateTime, alignToWeek = false): DateTime {
        return alignToWeek ? date.endOf("month").endOf("week", { useLocaleWeeks: true }) : date.endOf("month");
    }
}
