import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ColDef } from "ag-grid-enterprise";
import { Subscription } from "rxjs";
import { GridService } from "projects/app/src/app/services/http/clients/reporting-app/grid.service";
import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";

export type SelectedColsType = Record<string, boolean>;

@Component({
    selector: "app-column-visibility-filter",
    templateUrl: "./column-visibility-filter.component.html",
    styleUrls: ["./column-visibility-filter.component.scss"]
})
export class ColumnVisibilityFilterComponent implements OnInit, OnDestroy {
    settingsPanel?: SafeHtml;
    lockedIcon: SafeHtml;

    @Input({ required: true }) cols: ColDef[] = [];
    @Output() visibilityChange = new EventEmitter<Record<string, boolean>>();

    unlockedCols: ColDef[];
    filteredUnlockedCols: ColDef[];

    lockedCols: ColDef[];
    filteredLockedCols: ColDef[];

    state: Record<string, boolean>;
    prevState: Record<string, boolean>;
    searchQuery: string;
    isApplyDisabled: boolean;
    allSelected: boolean;

    private visibilitySub?: Subscription;

    constructor(
        private readonly iconSanitizer: IconSanitizerService,
        private readonly gridService: GridService,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.settingsPanel = this.iconSanitizer.getIcon("settingsPanel");
        this.lockedIcon = this.iconSanitizer.getIcon("locked");
        this.unlockedCols = [];
        this.filteredUnlockedCols = [];
        this.lockedCols = [];
        this.filteredLockedCols = [];
        this.state = {};
        this.prevState = {};
        this.searchQuery = "";
        this.isApplyDisabled = true;
        this.allSelected = true;
    }

    ngOnInit(): void {
        this.unlockedCols = this.gridService.getUnlockedCols(this.cols);
        this.lockedCols = this.cols.filter(col => col.lockVisible);

        this.filteredUnlockedCols = [...this.unlockedCols];
        this.filteredLockedCols = [...this.lockedCols];

        this.updateSelected();
        this.allSelected = true;
        this.prevState = { ...this.state };

        const currentState = this.gridService.getColumnVisibilityState();
        if (Object.keys(currentState).length > 0) {
            this.state = { ...currentState };
            this.prevState = { ...currentState };
        }

        this.updateAllSelected();

        this.waitForGridApiReady();
    }

    ngOnDestroy(): void {
        this.visibilitySub?.unsubscribe();
    }

    private waitForGridApiReady(): void {
        if (this.gridService.getGridApi()) {
            this.visibilitySub = this.gridService.columnVisibilityState$.subscribe(newState => {
                this.state = { ...newState };
                this.prevState = { ...newState };
                this.updateAllSelected();
                this.updateApplyDisabled();
            });
            if (this.gridService.getHiddenState().length == 0) {
                this.onAllChange(true);
            }
            this.apply();
        } else {
            setTimeout(() => this.waitForGridApiReady(), 100);
        }
    }

    filterColumns(): void {
        const query = this.searchQuery.toLowerCase();
        this.filteredUnlockedCols = this.unlockedCols.filter(col => col.headerName?.toLowerCase().includes(query));
        this.filteredLockedCols = this.lockedCols.filter(col => col.headerName?.toLowerCase().includes(query));
    }

    onAllChange(checked: boolean): void {
        for (const col of this.unlockedCols) {
            if (col.field) {
                this.state[col.field] = checked;
            }
        }
        this.allSelected = checked;
        this.updateApplyDisabled();
    }

    onChange(val: boolean, checkbox: string): void {
        this.state[checkbox] = val;
        this.updateAllSelected();
        this.updateApplyDisabled();
    }

    apply(): void {
        if (Object.keys(this.state).length > 0) {
            this.gridService.setColumnsVisible(this.getVisibleState(), true);
            this.gridService.setColumnsVisible(this.getHiddenState(), false);
            this.gridService.updateColumnVisibilityState(this.state);
            this.prevState = { ...this.state };
            this.updateApplyDisabled();
            this.visibilityChange.emit(this.state);
        }
    }

    closeOverlay(): void {
        this.state = { ...this.prevState };
        this.updateAllSelected();
        this.updateApplyDisabled();
    }

    updateApplyDisabled(): void {
        this.isApplyDisabled = JSON.stringify(this.state) === JSON.stringify(this.prevState);
    }

    updateAllSelected(): void {
        this.allSelected = this.unlockedCols.every(col => (col.field ? this.state[col.field] === true : true));
    }

    updateSelected(): void {
        for (const col of this.unlockedCols) {
            if (col.field) {
                this.state[col.field] = true;
            }
        }
    }

    private getVisibleState(): string[] {
        return Object.keys(this.state).filter(key => this.state[key]);
    }

    private getHiddenState(): string[] {
        return Object.keys(this.state).filter(key => !this.state[key]);
    }

    onSearchTextChange(newText: string): void {
        this.searchQuery = newText;
        this.filterColumns();
    }
}
