import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { MockService } from "projects/app/src/app/services/http/clients/reporting-app/mock.service";

export const ServiceModuleExpenses: IReportConfiguration<MockService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: MockService
    },
    overviewTab: {
        widgets: [],
        layout: OverviewLayout.TwoPlusOne
    },
    gridTab: {
        widgets: [],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [],
        additional: []
    }
};
