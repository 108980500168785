import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { Component, Input, TemplateRef } from "@angular/core";

export interface IDropdownPanelItem {
    id: string;
    name: string;
    action?: () => void;
}

export interface IDropdownPanel {
    items: IDropdownPanelItem[];
}

@Component({
    selector: "lib-dropdown-panel",
    templateUrl: "./dropdown-panel.component.html",
    styleUrls: ["./dropdown-panel.component.scss"]
})
export class DropdownPanelComponent {
    protected scrollStrategy: ScrollStrategy;

    @Input()
    buttonTemplateRef!: TemplateRef<unknown>;

    @Input()
    contentTemplateRef!: TemplateRef<unknown>;

    @Input()
    textContent!: string;

    @Input()
    source: IDropdownPanel;

    opened: boolean;

    constructor(private readonly overlay: Overlay) {
        this.opened = false;
        this.source = {
            items: []
        };
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    openClose(event: Event) {
        event.stopPropagation();
        this.opened = !this.opened;
    }

    action(callback: () => void | undefined, event: Event) {
        event.stopPropagation();
        this.opened = false;
        callback();
    }
}
