<div class="wrapper">
    <div class="addBuilding">
        <app-type-ahead-buildings
            [contactId]="contactId"
            [searchParameters]="typeAheadSearchByEntityParameters"
            (selectedBuildingChange)="onSelectedBuilding($event)"
            [isActive]="true"
        ></app-type-ahead-buildings>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Buildings available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'buildingName', label: 'Name', sortable: true, tooltip: true },
                { key: 'buildingContactRoleName', label: 'Role', sortable: true },
                { key: 'buildingAddressLines', label: 'Address', sortable: true, tooltip: true },
                { key: 'buildingCityName', label: 'City', sortable: true },
                { key: 'buildingStateName', label: 'State', sortable: true },
                { key: 'buildingCountryName', label: 'Country', sortable: true },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [routerLinkConfig]="routerLinkConfig"
        [source]="buildings"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
    </lib-grid-view>

    <ng-template #kebabMenu let-data>
        <app-kebab-menu>
            <button role="menuitem" class="resetStyles" (click)="editItem(data.object)">Edit</button>
            <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
        </app-kebab-menu>
    </ng-template>
</ng-template>
