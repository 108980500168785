import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Opportunity } from "projects/app/src/app/models/Opportunity";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    OpportunityEstimateView,
    OpportunityView,
    DomainValidationResult,
    OpportunityContactView,
    GetOpportunityActualValuesResultDto,
    OpportunityCompanyView
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ModalService, SnackbarNotificationService, ObjectExtensionsService, TabViewComponent } from "projects/ngx-lib/src/public-api";
import { ModalCreateEstimateComponent } from "../../../shared/modals/modal-create-estimate/modal-create-estimate.component";
import { OpportunityStatuses } from "projects/app/src/app/models/enums/OpportunityStatuses";
import { OpportunitiesSingleOverviewComponent } from "./opportunities-single-overview/opportunities-single-overview.component";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { OpportunityActualValue } from "projects/app/src/app/models/OpportunityActualValue";
import { OpportunityUsersChange } from "./opportunities-single-team/opportunities-single-team.component";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { getTab, processTabParameter } from "projects/app/src/app/utils/tab-navigation";
import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
@Component({
    selector: "app-opportunities-single",
    templateUrl: "./opportunities-single.component.html",
    styleUrls: ["./opportunities-single.component.scss"]
})
export class OpportunitiesSingleComponent implements OnInit, AfterViewInit {
    private opportunityId: number;

    @ViewChild(TabViewComponent)
    tabControl!: TabViewComponent;

    opportunity: Opportunity;

    currentTab: string;

    isModelEqual: boolean;

    subtitle?: string;

    opportunityInitialState: OpportunityView;

    opportunityEstimates?: OpportunityEstimateView[];

    baseEstimateExternalUrl?: string;

    opportunityStatusId?: OpportunityStatuses;

    errors?: DomainValidationResult[];

    @ViewChild("overview")
    overview?: OpportunitiesSingleOverviewComponent;

    isCreateEstimate: boolean;

    canEditOpportunity: boolean;

    isActive?: boolean;

    canArchive: boolean;

    canRestore: boolean;

    canCreateEstimate: boolean;

    entityTypeId: EntityTypes;

    actualValue: GetOpportunityActualValuesResultDto;

    availableActions?: number[];

    isLoading: boolean;

    tabsCounters: TabCounterType = {
        Contacts: 0,
        Companies: 0,
        Teams: 0,
        Notes: 0
    };

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService,
        private readonly opportunityEstimateService: OpportunityEstimateService
    ) {
        this.opportunityId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = getTab(this.route.snapshot.paramMap.get("tab")) ?? TabNames[TabNames.Overview];
        this.isModelEqual = true;
        this.opportunity = new Opportunity();
        this.opportunityInitialState = new Opportunity();
        this.errors = undefined;
        this.isCreateEstimate = false;
        this.canEditOpportunity = false;
        this.isActive = true;
        this.canArchive = false;
        this.canRestore = false;
        this.canCreateEstimate = false;
        this.entityTypeId = EntityTypes.Opportunity;
        this.actualValue = new OpportunityActualValue();
        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        await this.handleOpportunityChange();

        this.route.paramMap.subscribe(async params => {
            const newId = parseInt(params.get("id") ?? "0");
            const newTab = getTab(params.get("tab"));

            if (newId && newId !== this.opportunityId) {
                this.opportunityId = newId;
                await this.handleOpportunityChange();
            }

            if (newTab && newTab !== this.currentTab && this.tabControl) {
                this.currentTab = processTabParameter(this.route, this.tabControl);
            }
        });
    }

    ngAfterViewInit(): void {
        this.currentTab = processTabParameter(this.route, this.tabControl);
    }

    async validateActions(opportunityId: number): Promise<void> {
        this.availableActions = await this.opportunityService.availableActions(opportunityId);
        this.canEditOpportunity = this.availableActions.includes(OpportunityAction.Edit);
        this.canArchive = this.availableActions.includes(OpportunityAction.Archive);
        this.canRestore = this.availableActions.includes(OpportunityAction.Restore);
        this.canCreateEstimate = this.availableActions.includes(OpportunityAction.CreateEstimate);
    }

    async saveOpportunity(): Promise<void> {
        this.isCreateEstimate = false;

        const areAllCardsValid = this.overview?.validate();
        if (areAllCardsValid) {
            await this.awaiter.awaitAction(
                "Saving Opportunity",
                async () => {
                    this.isLoading = true;
                    await this.opportunityService.save(this.opportunity);
                    this.snackbarNotificationService.success("Opportunity edited successfully.");
                    await this.updateTabCounters();
                    this.opportunityInitialState = this.objectExtensionService.clone(this.opportunity) as OpportunityView;
                    this.validateActions(this.opportunityInitialState.id);
                    this.isModelEqual = true;
                    this.overview?.clearValidationErrors();
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Cancel Editing",
            cancelCaption: "Continue Edition",
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Unsaved Changes"
        });

        if (!responseOk) return;

        this.opportunity = Opportunity.fromInterface(this.opportunityInitialState);
        this.isModelEqual = true;
    }

    onValueChanged(): void {
        this.isModelEqual = this.objectExtensionService.isEqualTo(this.opportunity, this.opportunityInitialState, ["id"], false);
    }

    async validateCreateEstimate(): Promise<void> {
        if (!this.isCreateEstimate) {
            this.errors = await this.opportunityService.validateForEstimateCreation(this.opportunityId);
            this.isCreateEstimate = true;
        }

        if (this.errors?.length !== 0) {
            this.currentTab = "Overview";
            setTimeout(() => {
                const areAllCardsValid = this.overview?.validate();
                if (!areAllCardsValid) this.overview?.scrollToFirstErrorElement();
            }, 1);
        } else {
            this.createBaseEstimate();
        }
    }

    private async createBaseEstimate(): Promise<void> {
        const responseOk = await this.modalService.open(ModalCreateEstimateComponent, {
            opportunityId: this.opportunityId
        });

        if (responseOk) {
            this.baseEstimateExternalUrl = responseOk.externalUrl;
            this.opportunityStatusId = responseOk.opportunityStatusId;
            await this.handleOpportunityChange();

            if (this.currentTab === "Estimates") {
                this.opportunityEstimates = [responseOk];
            } else {
                this.currentTab = "Estimates";
            }
        }
    }

    onOpportunityContactsChange(opportunityContacts: OpportunityContactView[]): void {
        this.opportunity.opportunityContacts = opportunityContacts;
        this.opportunityInitialState.opportunityContacts = opportunityContacts;
    }

    onOpportunityCompaniesChange(opportunityCompanies: OpportunityCompanyView[]): void {
        this.opportunity.opportunityCompanies = opportunityCompanies;
        this.opportunityInitialState.opportunityCompanies = opportunityCompanies;
        const companyWithRole = opportunityCompanies.find(company => company.opportunityCompanyRoles?.some(role => role.buildingContactRoleId === 11));
        if (companyWithRole) {
            this.opportunity.companyId = companyWithRole.companyId;
            this.opportunityInitialState.companyId = companyWithRole.companyId;
            this.opportunity.companyName = companyWithRole.companyName;
            this.opportunityInitialState.companyName = companyWithRole.companyName;

            const filteredRoles = companyWithRole.opportunityCompanyRoles?.filter(role => [1, 2, 3].includes(role.buildingContactRoleId)) ?? [];
            const currentRoles = this.opportunity.opportunityBuildingContactRoles ?? [];
            filteredRoles.forEach(role => {
                const exists = currentRoles.some(existing => existing.buildingContactRoleId === role.buildingContactRoleId);
                if (!exists) {
                    currentRoles.push({
                        id: 0,
                        buildingContactRoleId: role.buildingContactRoleId,
                        buildingContactRoleName: role.buildingContactRoleName
                    });
                }
            });
            this.opportunity.opportunityBuildingContactRoles = currentRoles;
            this.opportunityInitialState.opportunityBuildingContactRoles = currentRoles.map(role => ({ ...role }));
        } else {
            this.opportunity.companyId = undefined;
            this.opportunityInitialState.companyId = undefined;
            this.opportunity.companyName = undefined;
            this.opportunityInitialState.companyName = undefined;
            this.opportunity.opportunityBuildingContactRoles = [];
            this.opportunityInitialState.opportunityBuildingContactRoles = [];
        }
    }

    onOpportunityUsersChange(opportunityUsersChange: OpportunityUsersChange): void {
        this.opportunity.opportunityUsers = opportunityUsersChange.opportunityUsers;
        this.opportunityInitialState.opportunityUsers = opportunityUsersChange.opportunityUsers;

        if (opportunityUsersChange.opportunityUsers) {
            const newOpportunitySalespersons = opportunityUsersChange.opportunityUsers.filter(
                opportunity => opportunity.opportunityUserUserRoles?.some(role => role.userRoleId === 3)
            );
            this.opportunity.opportunitySalespersons = newOpportunitySalespersons;
            this.opportunityInitialState.opportunitySalespersons = newOpportunitySalespersons;
        }

        if (opportunityUsersChange.opportunityActions) {
            this.canEditOpportunity = opportunityUsersChange.opportunityActions.includes(OpportunityAction.Edit);
            this.canCreateEstimate = opportunityUsersChange.opportunityActions.includes(OpportunityAction.CreateEstimate);
        }
    }

    async archiveOpportunity(): Promise<void> {
        if (!this.canArchive) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this opportunity?",
            title: "Archive Opportunity"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Opportunity", async () => {
            await this.opportunityService.deactivate(this.opportunity.id);
            this.isActive = false;
            this.canRestore = true;
            this.canArchive = false;
        });
    }

    async unlinkEstimateOpportunity() {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Unlink",
            cancelCaption: "Cancel",
            content: "Are you sure you want to unlink this estimate? All the estimate information will be removed from the Opportunity.",
            title: "Unlink Estimate"
        });

        if (!responseOk) return;
        await this.awaiter.awaitAction("Unlinking Estimate", async () => {
            await this.opportunityEstimateService.unlinkBaseEstimate(this.opportunity.id);
            this.tabChange("Overview");
            await this.handleOpportunityChange();
        });
    }

    async restoreOpportunity(): Promise<void> {
        if (!this.canRestore) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this opportunity?",
            title: "Restore Opportunity"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Opportunity", async () => {
            await this.opportunityService.activate(this.opportunity.id);
            this.isActive = true;
            this.canArchive = true;
            this.canRestore = false;
        });
    }

    async onTabCountersChange(): Promise<void> {
        await this.updateTabCounters();
    }

    tabChange(tabName: string) {
        this.router.navigate(["/opportunities-single", this.opportunityId, tabName]);
    }

    private async handleOpportunityChange(): Promise<void> {
        if (!this.opportunityId) return;

        const res = await this.updateOpportunityInfo();
        if (res) await this.updateTabCounters();
    }

    private async updateOpportunityInfo(): Promise<boolean | undefined> {
        return await this.awaiter.awaitAction("Getting Opportunity Info", async () => {
            this.opportunityInitialState = await this.opportunityService.getById(this.opportunityId);
            await this.validateActions(this.opportunityInitialState.id);

            this.actualValue = OpportunityActualValue.fromInterface(await this.opportunityService.actualValues(this.opportunityInitialState.id));

            const opportunityFromInitialState = Opportunity.fromInterface(this.opportunityInitialState);
            this.opportunity = this.objectExtensionService.clone(opportunityFromInitialState) as Opportunity;

            this.isActive = this.opportunityInitialState.isActive;
            this.baseEstimateExternalUrl = this.opportunity.baseEstimateExternalUrl;
            this.opportunityStatusId = this.opportunity.opportunityStatusId;
            return true;
        });
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.opportunityId, this.tabsCounters);
    }
}
