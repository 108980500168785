<div
    class="badge"
    [ngClass]="{
        primaryColor: color === 'primary',
        secondaryColor: color === 'secondary',
        tertiaryColor: color === 'tertiary',
        readonly: readonly
    }"
    [id]="badgeId | idGenerator: 'ngx-badge-'"
>
    <span *ngIf="label"
        ><span>{{ label }}</span
        >:</span
    >&emsp;
    <span>{{ item && key ? item[key] : text }}</span>
    <div class="remove" *ngIf="removeButton" (click)="remove($event)" (keyup.enter)="remove($event)" tabindex="0">
        <div class="icon" [innerHTML]="closeIcon"></div>
    </div>
</div>
