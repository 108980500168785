import { Component, OnInit } from "@angular/core";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

import { ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-contacts-editor",
    templateUrl: "./contacts-editor.component.html",
    styleUrls: ["./contacts-editor.component.scss"]
})
export class ContactsEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedItem?: ContactView;

    displaySelectedContact: boolean;

    allowToNavigate?: boolean;

    routeToSingle?: string;

    constructor(private readonly contactService: ContactService) {
        super();
        this.displaySelectedContact = true;
    }

    async ngOnInit(): Promise<void> {
        this.allowToNavigate = this.additionalInfo?.allowToNavigate;
        if (this.bindingProperty) {
            this.selectedItem = await this.contactService.getById(this.bindingProperty);
            if (this.allowToNavigate) this.setRouteToNavigate(this.selectedItem);
        }
    }

    onSelectedContact(contact: ContactView) {
        this.selectedItem = contact;
        this.bindingProperty = this.selectedItem?.id;
    }

    setRouteToNavigate(contact: ContactView) {
        this.routeToSingle = `contacts-single/${contact.id}/${TabNames[TabNames.Overview]}`;
    }
}
