<ng-container *ngIf="additionalInfo?.singleSelection; else multipleSelection">
    <lib-generic-dropdown
        [source]="buildingClassItems"
        [config]="{
            multiple: false,
            defaultText: 'Select Class',
            itemDisplayKey: 'name'
        }"
        [(selectedItem)]="selectedBuildingClass"
        (selectedItemChange)="onSelectBuildingClass()"
    >
    </lib-generic-dropdown>
</ng-container>

<ng-template #multipleSelection>
    <lib-generic-dropdown-multiselect
        [source]="buildingClassItems"
        [(selectedItems)]="selectedBuildingClasses"
        (selectedItemsChange)="onSelectedProductTypeChange()"
        [config]="{
            multiple: true,
            defaultText: 'Add Class',
            itemDisplayKey: 'name'
        }"
        [isReadOnly]="isReadOnly"
    >
    </lib-generic-dropdown-multiselect>
</ng-template>
