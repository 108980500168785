import { Component, OnInit } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-textarea-editor",
    templateUrl: "./textarea-editor.component.html",
    styleUrls: ["./textarea-editor.component.scss"]
})
export class TextareaEditorComponent extends EditorComponentBase<string> implements OnInit {
    shouldTrimSpaces?: boolean;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.shouldTrimSpaces = this.additionalInfo?.shouldTrimSpaces;
    }

    dataChanged() {
        this.valueChange.emit(true);
        this.validate();
    }
}
