<div class="container">
    <button
        (click)="togglePanel($event)"
        id="notificationPanelButton"
        aria-haspopup="true"
        aria-controls="menu"
        aria-label="Open and close notification panel"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
    >
        <ng-container [ngTemplateOutlet]="notificationsButton"></ng-container>
    </button>

    <ng-template #notificationsButton>
        <span class="icon" [innerHTML]="notificationIcon"></span>
        <span *ngIf="hasUnread" class="unreadDot"></span>
    </ng-template>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (overlayOutsideClick)="opened = false"
    (detach)="opened = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="opened = false"
>
    <div class="panelContainer">
        <div class="header">
            <div class="title">Notifications</div>
            <button class="resetStyles" (click)="markAllAsRead()" [disabled]="isLoading || !hasUnread">Mark all as read</button>
        </div>

        <div class="body">
            <app-notifications-list
                *ngIf="hasUnread; else allRead"
                [notifications]="latestNotifications"
                (notificationChange)="updateUnreadStatus()"
                [isPanel]="true"
            >
            </app-notifications-list>

            <ng-template #allRead>
                <div class="allRead">
                    <div class="content">
                        <span class="icon" [innerHTML]="emptyNotificationIcon"></span>
                        <div class="text">
                            <div class="title">No notifications yet.</div>

                            Notifications about your activity will show up here.
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="footer">
            <button class="resetStyles" (click)="seeAllNotifications()">See all notifications</button>
        </div>
    </div>
</ng-template>
