<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedEntity"
    placeholder="Search"
    (selectedItemChange)="onSelectedEntity($event)"
    [displaySelected]="false"
    [(text)]="searchText"
    [source]="searchResult"
    (textChange)="onEntityTextChanged()"
    [config]="{
        defaultText: 'Search',
        itemDisplayKey: 'entityName',
        itemTemplate: entitiesTemplate
    }"
    >
</lib-generic-type-ahead-dropdown>

<ng-template #entitiesTemplate let-data>
    <div class="item">
        <app-custom-menu-item
            [inputText]="data.inputText"
            [icon]="entities[data.object.entityTypeId].icon"
            [title]="data.object.entityName"
            [subtitle]="data.object.entityTypeName">
        </app-custom-menu-item>
    </div>
</ng-template>