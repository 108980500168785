<lib-generic-type-ahead-multiselect
    [source]="items"
    [(selectedItems)]="selectedItems"
    (selectedItemsChange)="onSelectedItemsChange()"
    [config]="{
        defaultText: 'Select ' + label,
        multiple: true,
        itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name',
        itemTemplate: customItemTemplate
    }"
    [placeholder]=" additionalInfo?.placeholder ?? 'Search Account'"
    (textChange)="onEntityTextChange($event)"
>
</lib-generic-type-ahead-multiselect>
<ng-template #customItemTemplate let-data>
    <app-custom-menu-item
        [inputText]="data.inputText"
        [title]="data.object.name"
        [titlePrefix]="!data.object.isActive ? { text: 'Archived', textColor: 'danger' } : undefined"
    >
    </app-custom-menu-item>
</ng-template>