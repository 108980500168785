/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable } from "@angular/core";
import mapboxgl from "mapbox-gl";
import { GlobalConfigurationService } from "./global-configuration.service";
@Injectable({
    providedIn: "root"
})
export class MapService {
    private map!: mapboxgl.Map;
    private onMoveEndCallback?: () => void;
    private onLoadedCallback?: () => void;
    private enableClusters?: boolean;
    private featureListener!: string;
    markerSelected = new EventEmitter<any>();

    constructor(private readonly globalConfigurationService: GlobalConfigurationService) {}

    async initializeMap(
        containerId: string,
        center: [number, number],
        zoom: number,
        entityToObserve: string,
        enableClusters = true,
        navigationControl = true
    ): Promise<boolean> {
        try {
            const mapboxToken = this.globalConfigurationService.getKey("map Token");

            (mapboxgl as any).accessToken = mapboxToken;

            this.map = new mapboxgl.Map({
                container: containerId,
                style: "mapbox://styles/mapbox/streets-v12",
                center: center,
                zoom: zoom,
                attributionControl: false
            });

            this.setEnableCluster(enableClusters);

            if (navigationControl) this.addNavigationControl();

            this.featureListener = entityToObserve;

            this.map.on("load", () => {
                this.addMapListeners();
                this.addCustomMarkerImage();
                if (this.onLoadedCallback) {
                    this.onLoadedCallback();
                }
            });

            return true;
        } catch (error) {
            return false;
        }
    }

    setOnMoveEndCallback(callback: () => void) {
        this.onMoveEndCallback = callback;
    }

    setOnLoadedCallback(callback: () => void) {
        this.onLoadedCallback = callback;
    }

    getMapBounds(): mapboxgl.LngLatBounds | null {
        return this.map.getBounds();
    }

    fetchData(data: any[], sourceId: string) {
        if (!this.isStyleLoaded()) return;
        const geojsonData = this.createGeoJSONSource(data) as mapboxgl.GeoJSONSource;
        this.enableClusters ? this.addClustersLayers(sourceId, geojsonData) : this.addLayer(sourceId, geojsonData);
    }

    private setEnableCluster(value: boolean) {
        this.enableClusters = value;
    }

    private addNavigationControl() {
        const nav = new mapboxgl.NavigationControl({
            visualizePitch: true
        });
        this.map.addControl(nav, "bottom-right");
    }

    private createGeoJSONSource(data: any[]): any {
        if (!data?.length) {
            return {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            };
        }

        return {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: data.map(p => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [p.longitude, p.latitude]
                    },
                    properties: p
                }))
            },
            cluster: this.enableClusters,
            ...(this.enableClusters && { clusterMaxZoom: 14 }),
            ...(this.enableClusters && { clusterRadius: 50 })
        };
    }

    private checkLayerAndSource(layersIds: string[], sourceId: string, sourceData: mapboxgl.GeoJSONSource) {
        if (this.map.getSource(sourceId)) {
            if (layersIds.length) {
                for (const id of layersIds) {
                    if (this.map.getLayer(id)) {
                        this.map.removeLayer(id);
                    }
                }
            }
            this.map.removeSource(sourceId);
        }

        this.map.addSource(sourceId, sourceData as mapboxgl.GeoJSONSourceSpecification);
    }

    private addLayer(sourceId: string, geojsonData: mapboxgl.GeoJSONSource) {
        const layersIds = [`${sourceId}-no-cluster-layer`];

        if (!layersIds.length) return;

        this.checkLayerAndSource(layersIds, sourceId, geojsonData);

        const layerId = layersIds[0];

        this.map.addLayer({
            id: layerId,
            type: "symbol",
            source: sourceId,
            layout: {
                "icon-image": "custom-marker-icon",
                "icon-size": 0.5
            }
        });

        this.addLayerListeners(layerId);
    }

    private addLayerListeners(layerId: string) {
        this.map.on("click", layerId, e => {
            if (!e.features) return;
            const feature = e.features[0];

            if (!feature?.properties || feature.geometry.type !== "Point") return;

            this.markerSelected.emit(JSON.parse(feature.properties[this.featureListener]));
        });

        this.map.on("mouseenter", layerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
        });

        this.map.on("mouseleave", layerId, () => {
            this.map.getCanvas().style.cursor = "";
        });
    }

    private addClustersLayers(sourceId: string, geojsonData: mapboxgl.GeoJSONSource) {
        const layersIds = [`${sourceId}-cluster-layer`, `${sourceId}-cluster-count-layer`, `${sourceId}-unclustered-layer`];

        if (!layersIds.length) return;

        this.checkLayerAndSource(layersIds, sourceId, geojsonData);

        this.map.addLayer({
            id: `${sourceId}-cluster-layer`,
            type: "circle",
            source: sourceId,
            filter: ["has", "point_count"],
            paint: {
                //   * #175C89, 20px circles when point count is less than 50
                //   * #5E6162, 30px circles when point count is between 50 and 100
                //   * #0095FF, 40px circles when point count is greater than or equal to 100
                "circle-color": ["step", ["get", "point_count"], "#175C89", 50, "#5E6162", 100, "#0095FF"],
                "circle-radius": ["step", ["get", "point_count"], 20, 50, 30, 100, 40]
            }
        });

        this.map.addLayer({
            id: `${sourceId}-cluster-count-layer`,
            type: "symbol",
            source: sourceId,
            filter: ["has", "point_count"],
            layout: {
                "text-field": ["get", "point_count_abbreviated"],
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12
            },
            paint: {
                "text-color": "#ffffff"
            }
        });

        this.map.addLayer({
            id: `${sourceId}-unclustered-layer`,
            type: "symbol",
            source: sourceId,
            filter: ["!", ["has", "point_count"]],
            layout: {
                "icon-image": "custom-marker-icon",
                "icon-size": 0.5
            }
        });

        this.addClustersLayersListeners(layersIds, sourceId);
    }

    private addMapListeners() {
        this.map.on("moveend", () => {
            if (this.onMoveEndCallback) {
                this.onMoveEndCallback();
            }
        });
    }

    private addClustersLayersListeners(layersIds: string[], sourceId: string) {
        const clusterLayerId = layersIds.find(element => element.includes("cluster") && !element.includes("count")) ?? layersIds[0];

        this.map.on("click", clusterLayerId, e => {
            const features = this.map.queryRenderedFeatures(e.point, {
                layers: [clusterLayerId]
            });

            if (!features) return;

            const feature = features[0];

            if (!feature?.properties || feature.geometry.type !== "Point") return;

            const longitude = feature.geometry.coordinates[0];
            const latitude = feature.geometry.coordinates[1];

            const clusterId = feature.properties["cluster_id"];

            const source = this.map.getSource(sourceId) as mapboxgl.GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this.map.easeTo({
                    center: [longitude, latitude],
                    zoom: zoom ?? undefined
                });
            });
        });

        const unclusteredLayerId = layersIds.find(element => element.includes("unclustered"));

        if (unclusteredLayerId) {
            this.map.on("click", unclusteredLayerId, e => {
                if (!e.features) return;

                const feature = e.features[0];

                if (!feature?.properties || feature.geometry.type !== "Point") return;

                this.markerSelected.emit(JSON.parse(feature.properties[this.featureListener]));
            });
        }

        this.map.on("mouseenter", clusterLayerId, () => {
            this.map.getCanvas().style.cursor = "pointer";
        });

        this.map.on("mouseleave", clusterLayerId, () => {
            this.map.getCanvas().style.cursor = "";
        });
    }

    private addCustomMarkerImage() {
        this.map.loadImage("../../assets/images/map-marker-point.png", (error, image) => {
            if (error) throw error;
            if (!this.map.hasImage("custom-marker-icon")) {
                this.map.addImage("custom-marker-icon", image as HTMLImageElement);
            }
        });
    }

    private isStyleLoaded() {
        return this.map.isStyleLoaded();
    }
}
