import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-cell-favorite",
    templateUrl: "./cell-favorite.component.html",
    styleUrls: ["./cell-favorite.component.scss"]
})
export class CellFavoriteComponent {
    @Input()
    favorite: boolean;

    @Input()
    isHeader: boolean;

    @Output()
    favoriteChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    starIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.starIcon = this.iconSanitizer.getIcon("star");
        this.favorite = false;
        this.isHeader = false;
    }

    toggleFav(event: Event) {
        if (this.isHeader) return;
        event.preventDefault();
        event.stopPropagation();
        this.favoriteChange.emit(!this.favorite);
    }
}
