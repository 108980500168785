import { Component, OnInit } from "@angular/core";
import { OpportunityCompany } from "projects/app/src/app/models/OpportunityCompany";
import {
    OpportunityCompanyRoleView,
    OpportunityCompanyView,
    SearchAvailableOpportunityCompaniesParameters
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityCompanyService } from "projects/app/src/app/services/http/clients/opportunity-company.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";
import { ModalService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { ModalAddCompanyComponent } from "../../modals/modal-add-company/modal-add-company.component";
@Component({
    selector: "app-opportunity-company-editor",
    templateUrl: "./opportunity-company-editor.component.html",
    styleUrls: ["./opportunity-company-editor.component.scss"]
})
export class OpportunityCompanyEditorComponent extends EditorComponentBase<OpportunityCompanyView[]> implements OnInit {
    companyItems: OpportunityCompany[];
    selectedCompanyItems?: OpportunityCompany[];
    searchText: string;
    companyRoleId?: number;
    addCompanyAction!: ITypeAheadDropdownAction;
    constructor(
        private readonly opportunityCompanyService: OpportunityCompanyService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly modalService: ModalService
    ) {
        super();
        this.isReadOnly = !!this.bindContext;
        this.searchText = "";
        this.companyItems = [];
        this.companyRoleId = undefined;
    }
    ngOnInit(): void {
        this.companyRoleId = this.additionalInfo as number;
        this.addCompanyAction = {
            includeAction: true,
            name: "+ Add new company",
            action: () => this.openModal()
        };
        if (this.bindingProperty) this.filterCompaniesByRole();
    }
    async onCompanyTextChange(event: string) {
        const results = await this.opportunityCompanyService.searchAvailable({
            filterText: event
        } as SearchAvailableOpportunityCompaniesParameters);
        this.companyItems = results.map(x => OpportunityCompany.fromCompanyView(x));
    }
    onSelectedCompanyChange(event: OpportunityCompany[]) {
        this.selectedCompanyItems = event;
        const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityCompanyView[];
        event.forEach(currentCompany => {
            const existingCompany = clonedBindingProperty.find(company => company.companyId === currentCompany.companyId);
            if (existingCompany) {
                if (!existingCompany.opportunityCompanyRoles?.some(x => x.buildingContactRoleId === this.companyRoleId)) {
                    existingCompany.opportunityCompanyRoles?.push({
                        buildingContactRoleId: this.companyRoleId
                    } as OpportunityCompanyRoleView);
                }
            } else {
                clonedBindingProperty?.push(this.mapToOpportunityCompany(currentCompany));
            }
            this.bindingProperty = clonedBindingProperty;
            this.valueChange.emit();
        });
    }
    onRemoveCompanyChange(event: OpportunityCompany) {
        if (this.bindingProperty) {
            const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityCompanyView[];
            const companyIndex = clonedBindingProperty?.findIndex(company => company.companyId === event.companyId);
            if (companyIndex !== undefined && companyIndex !== -1) {
                const company = clonedBindingProperty[companyIndex];
                const roleIndex = company.opportunityCompanyRoles?.findIndex(role => role.buildingContactRoleId === this.companyRoleId);
                if (roleIndex !== undefined && roleIndex !== -1) {
                    clonedBindingProperty[companyIndex].opportunityCompanyRoles?.splice(roleIndex, 1);
                    if (company.opportunityCompanyRoles?.length === 0) {
                        clonedBindingProperty.splice(companyIndex, 1);
                    }
                    this.bindingProperty = clonedBindingProperty;
                }
                this.valueChange.emit();
            }
        }
    }

    async openModal(): Promise<void> {
        const company = await this.modalService.open(ModalAddCompanyComponent, { setCompany: true });
        if (company) {
            const clonedBindingProperty = this.objectExtensionService.clone(this.bindingProperty) as OpportunityCompanyView[];
            clonedBindingProperty.push(OpportunityCompany.fromCompanyView(company));
            this.bindingProperty = clonedBindingProperty;
            this.valueChange.emit();
        }
    }
    filterCompaniesByRole() {
        const mappedResults = this.bindingProperty?.map(x => OpportunityCompany.fromInterface(x));
        this.selectedCompanyItems = mappedResults?.filter(company =>
            company.opportunityCompanyRoles?.some(x => x.buildingContactRoleId === this.companyRoleId)
        );
    }
    mapToOpportunityCompany(company: OpportunityCompanyView): OpportunityCompany {
        return OpportunityCompany.fromInterface({
            companyId: company.companyId,
            companyName: company.companyName,
            opportunityCompanyRoles: [{ buildingContactRoleId: this.companyRoleId }]
        } as OpportunityCompanyView);
    }
}
