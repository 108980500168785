import { DateTime } from "luxon";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { IFullViewCalendarEvent } from "./interfaces/full-view-calendar.interface";
import { DateRanges, EventsForPeriod, FullViewCalendarViewType } from "./types/full-view-calendar.types";
import { DateTimeService } from "../../services/date-time.service";
import { CalendarType } from "../calendar/calendar.component";

@Component({
    selector: "lib-full-view-calendar",
    templateUrl: "./full-view-calendar.component.html",
    styleUrls: ["./full-view-calendar.component.scss"]
})
export class FullViewCalendarComponent<T> implements OnChanges {
    readonly hours: number[];

    @Input()
    showViewSwitcher: boolean;

    @Input()
    events?: IFullViewCalendarEvent<T>[];

    @Input()
    eventTemplate!: TemplateRef<any>;

    @Output()
    dateRangeSelected: EventEmitter<DateRanges>;

    @Input()
    currentView: FullViewCalendarViewType;

    @Output()
    currentViewChange: EventEmitter<FullViewCalendarViewType>;

    eventsForPeriod: EventsForPeriod<T>;

    eventCountPerDay: { [key: string]: number };

    datepickerCalendarType: CalendarType;

    today: DateTime;

    selectedDate: DateTime;

    startOfPeriod!: DateTime;

    endOfPeriod!: DateTime;

    currentPeriod?: DateTime[];

    isCurrentPeriod: boolean;

    customDateText: string;

    selectedDateString?: Date;

    constructor(private readonly dateTimeService: DateTimeService) {
        this.showViewSwitcher = true;
        this.hours = Array.from({ length: 24 }, (_, i) => i);
        this.selectedDate = this.dateTimeService.now();
        this.today = this.dateTimeService.now();
        this.isCurrentPeriod = false;
        this.currentPeriod = [];
        this.eventsForPeriod = {};
        this.eventCountPerDay = {};
        this.customDateText = "";
        this.currentView = "week";
        this.datepickerCalendarType = CalendarType.MONTH;
        this.dateRangeSelected = new EventEmitter<DateRanges>();
        this.currentViewChange = new EventEmitter<FullViewCalendarViewType>();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["events"]) {
            this.updatePeriod(this.selectedDate);
            this.updateEventsForPeriod();
        }
    }

    setView(view: FullViewCalendarViewType): void {
        if (view === this.currentView) return;
        this.currentView = view;
        this.currentViewChange.emit(view);
        this.emitDateRange();
    }

    retreatPeriod(): void {
        this.selectedDate = this.selectedDate.minus(this.getPeriodAdjustment());
        this.emitDateRange();
    }

    advancePeriod(): void {
        this.selectedDate = this.selectedDate.plus(this.getPeriodAdjustment());
        this.emitDateRange();
    }

    onViewMoreClicked(date: DateTime) {
        this.selectedDate = date;
        this.setView("day");
    }

    onDatepickerChange(event: any): void {
        if (!event) {
            // Clear button event
            this.selectedDate = this.dateTimeService.now();
            this.emitDateRange();
            return;
        }

        this.selectedDate = this.dateTimeService.getDateTimeFromString(event);
        this.emitDateRange();
    }

    goToToday(): void {
        if (this.dateTimeService.isToday(this.selectedDate)) return;
        this.selectedDate = this.dateTimeService.now();
        this.emitDateRange();
    }

    private getPeriodAdjustment() {
        if (this.currentView === "day") {
            return { days: 1 };
        } else if (this.currentView === "week") {
            return { weeks: 1 };
        } else if (this.currentView === "month") {
            return { months: 1 };
        }
        return { weeks: 1 };
    }

    private updateCustomDateText(): void {
        this.selectedDateString = this.dateTimeService.formatDateTimeToDate(this.selectedDate);
        this.datepickerCalendarType = CalendarType.MONTH;
        if (this.currentView === "day") {
            this.customDateText = this.selectedDate.toFormat("DDDD");
        } else if (this.currentView === "week") {
            this.customDateText = `${this.startOfPeriod.toFormat("yyyy")}, ${this.startOfPeriod.toFormat("MMMM d")} - ${this.endOfPeriod.toFormat("d")}`;
        } else if (this.currentView === "month") {
            this.datepickerCalendarType = CalendarType.YEAR;
            const firstDayOfMonth = this.dateTimeService.getStartOfMonth(this.selectedDate);
            this.customDateText = firstDayOfMonth.toFormat("MMMM yyyy");
        }
    }

    private updatePeriod(date: DateTime): void {
        if (this.currentView === "day") {
            this.startOfPeriod = date;
            this.endOfPeriod = date;
        } else if (this.currentView === "week") {
            this.startOfPeriod = this.dateTimeService.getStartOfWeek(date);
            this.endOfPeriod = this.dateTimeService.getEndOfWeek(date);
        } else if (this.currentView === "month") {
            this.startOfPeriod = this.dateTimeService.getStartOfMonth(date, true);
            this.endOfPeriod = this.dateTimeService.getEndOfMonth(date, true);
        }

        this.currentPeriod = Array.from({ length: this.getPeriodLength() }, (_, i) => this.startOfPeriod.plus({ days: i }));

        this.isCurrentPeriod = this.currentPeriod.some(day => {
            if (this.currentView === "month") {
                return date.hasSame(this.today, "month") && day.hasSame(this.today, "year");
            } else {
                return day.hasSame(this.today, "day");
            }
        });

        this.updateCustomDateText();
    }

    private getPeriodLength(): number {
        if (this.currentView === "day") {
            return 1;
        } else if (this.currentView === "week") {
            return 7;
        } else if (this.currentView === "month") {
            return this.endOfPeriod.diff(this.startOfPeriod, "days").days + 1;
        } else {
            return 7;
        }
    }

    private updateEventsForPeriod(): void {
        this.eventsForPeriod = {};

        if (this.currentPeriod) {
            for (const day of this.currentPeriod) {
                const dayKey = day.toISODate() || "";
                this.eventCountPerDay[dayKey] = 0;

                for (const hour of this.hours) {
                    const eventsForHour = this.events?.filter(event => {
                        if (event.date?.hasSame(day, "day")) {
                            const eventTime = DateTime.fromFormat(event.startTime ?? "", "HH:mm");
                            return eventTime.hour === hour || (eventTime.hour === hour && eventTime.minute > 0);
                        }
                        return false;
                    });

                    if (!this.eventsForPeriod[dayKey]) {
                        this.eventsForPeriod[dayKey] = {};
                    }

                    this.eventsForPeriod[dayKey][hour] = eventsForHour;

                    if (eventsForHour) this.eventCountPerDay[dayKey] += eventsForHour?.length;
                }
            }
        }
    }

    private emitDateRange(): void {
        let dateFrom: string | null = this.selectedDate.toISO();
        let dateTo: string | null = this.selectedDate.toISO();

        if (this.currentView === "day") {
            dateFrom = this.selectedDate.toISO();
            dateTo = this.selectedDate.toISO();
        } else if (this.currentView === "week") {
            dateFrom = this.dateTimeService.getStartOfWeek(this.selectedDate).toISO();
            dateTo = this.dateTimeService.getEndOfWeek(this.selectedDate).toISO();
        } else if (this.currentView === "month") {
            dateFrom = this.dateTimeService.getStartOfMonth(this.selectedDate, true).toISO();
            dateTo = this.dateTimeService.getEndOfMonth(this.selectedDate, true).toISO();
        }

        this.dateRangeSelected.emit({ dateFrom, dateTo });
    }
}
