<div class="container">
    <div class="map" *ngIf="selectedBuilding">
        <app-static-map [latitude]="selectedBuilding.latitude" [longitude]="selectedBuilding.longitude"></app-static-map>
    </div>
    <div class="editorItem" *ngIf="!isBuildingOverview">
        <div class="label">Building</div>
        <ng-container *ngIf="selectedBuilding && isReadOnly && allowToNavigate; else selectItemTemplate">
            <app-internal-navigator [path]="routeToSingle" [label]="selectedBuilding.name"></app-internal-navigator>
        </ng-container>
        <ng-template #selectItemTemplate>
            <div class="inputContainer">
                <app-type-ahead-buildings
                    [isReadOnly]="isReadOnly"
                    [displaySelected]="displaySelectedBuilding"
                    [selectedBuilding]="selectedBuilding"
                    (selectedBuildingChange)="onSelectedBuilding($event)"
                    [error]="!isValid"
                    [isActive]="true"
                >
                </app-type-ahead-buildings>
                <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
            </div>
        </ng-template>
    </div>
    <app-location-details
        [location]="selectedBuilding"
        [(line2)]="$any(bindContext).line2"
        (line2Change)="valueChange.emit()"
        [isReadOnly]="isReadOnly">
    </app-location-details>
</div>
