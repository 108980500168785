import { Injectable } from "@angular/core";
import { BuildingContactClient, BuildingContactView, ContactView, IBuildingContactClient, SearchAvailableBuildingContactsParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class BuildingContactService {
    private client: IBuildingContactClient;
    constructor() {
        this.client = new BuildingContactClient();
    }

    async buildingContactSearchAvailable(body: SearchAvailableBuildingContactsParameters): Promise<ContactView[]> {
        return await this.client.buildingContactSearchAvailable(body);
    }

    async buildingContactUpdateCompany(id: number) {
        return await this.client.buildingContactUpdateContactCompany(id)
    }

    async save(body: BuildingContactView): Promise<BuildingContactView> {
        return await this.client.buildingContactSaveContact(body);
    }

    async remove(body: BuildingContactView): Promise<void> {
        await this.client.buildingContactRemoveContact(body);
    }
}
