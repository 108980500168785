import { Injectable } from "@angular/core";
import {
    BoxResultType,
    ServiceCategoryClient,
    CustomReportServiceView,
    GetDataFilterValuesParameters,
    GetServiceCategoryColumnChartValuesResultType,
    GetServiceCategoryMetricsValuesResultType,
    GetServiceCategoryLineChartValuesResultType,
    GetServiceCategoryValuesResultType,
    SharedReportDto,
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceCategoryService extends CustomReportServiceBase<ServiceCategoryClient, CustomReportServiceView> {
    constructor() {
        super(new ServiceCategoryClient());
    }

    async getValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCategoryValuesResultType[]> {
        return await this.client.serviceCategoryGetServiceCategoryValues(parameters);
    }

    async getBoxesValues(parameters: GetDataFilterValuesParameters): Promise<BoxResultType[]> {
        return await this.client.serviceCategoryGetServiceCategoryBoxesValues(parameters);
    }

    async getColumnChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCategoryColumnChartValuesResultType[]> {
        return await this.client.serviceCategoryGetServiceCategoryColumnChartValues(parameters);
    }

    async getMetricsValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCategoryMetricsValuesResultType> {
        return await this.client.serviceCategoryGetServiceCategoryMetricsValues(parameters);
    }

    async getLineChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCategoryLineChartValuesResultType[]> {
        return await this.client.serviceCategoryGetServiceCategoryLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportServiceView): Promise<CustomReportServiceView> {
        if (body.isPublic)
            return await this.client.serviceCategorySaveCatalogReport(body);
        else
            return await this.client.serviceCategorySaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportServiceView> {
        return await this.client.serviceCategoryGetCustomReport(id);
    }

    override async shareReport(body: CustomReportServiceView): Promise<SharedReportDto> {
        return await this.client.serviceCategoryCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportServiceView> {
        return this.client.serviceCategoryGetSharedReport(id);
    }
}
