import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ColDef } from "ag-grid-enterprise";
import { Subscription } from "rxjs";
import { GridService } from "projects/app/src/app/services/http/clients/reporting-app/grid.service";
import { IconSanitizerService } from "projects/ngx-lib/src/public-api";

export interface ICol {
    name: string;
}

export interface IGroupLevel {
    name: string;
    level: number;
}

@Component({
    selector: "app-group-by-rows-filter",
    templateUrl: "./group-by-rows-filter.component.html",
    styleUrls: ["./group-by-rows-filter.component.scss"]
})
export class GroupByRowsFilterComponent implements OnInit, OnDestroy {
    @Input() cols?: ColDef[];

    isOverlayGroupsOpen: boolean;
    groupingLevels: IGroupLevel[];
    groupOpened: IGroupLevel | null;
    rowGroupingCols: ColDef[];
    appliedGroups: ColDef[];
    lastGroupsApplied: ColDef[];
    groupByIcon?: SafeHtml;
    arrowIcon?: SafeHtml;

    private groupingSub?: Subscription;

    constructor(
        private readonly gridService: GridService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.isOverlayGroupsOpen = false;
        this.groupingLevels = [
            { name: "Grouping level 1", level: 1 },
            { name: "Grouping level 2", level: 2 },
            { name: "Grouping level 3", level: 3 }
        ];
        this.groupOpened = null;
        this.rowGroupingCols = [];
        this.appliedGroups = [];
        this.lastGroupsApplied = [];
        this.groupByIcon = this.iconSanitizer.getIcon("groupBy");
        this.arrowIcon = this.iconSanitizer.getIcon("nextArrow");
    }

    ngOnInit(): void {
        this.rowGroupingCols = this.cols?.filter((col: ColDef) => col.enableRowGroup) ?? [];
        this.groupingSub = this.gridService.groupingState$.subscribe(newState => {
            if (newState && newState.length > 0) {
                this.appliedGroups = [...newState];
                this.lastGroupsApplied = [...newState];
            } else {
                this.appliedGroups = [];
                this.lastGroupsApplied = [];
            }
        });
    }

    ngOnDestroy(): void {
        this.groupingSub?.unsubscribe();
    }

    groupIsEnable(group: IGroupLevel): boolean {
        return group.level <= this.appliedGroups.length + 1;
    }

    restoreLastGroupsApplied(): void {
        this.appliedGroups = [...this.lastGroupsApplied];
    }

    getAvailableCols(groupOpened: IGroupLevel): ColDef[] {
        return (
            this.rowGroupingCols.filter(
                (col: ColDef) =>
                    !this.appliedGroups.filter(group => !!group).some(group => group.field === col.field) ||
                    this.appliedGroups[groupOpened.level - 1]?.headerName === col.headerName
            ) ?? []
        );
    }

    applyGroup(group: IGroupLevel, col: ColDef): void {
        this.appliedGroups[group.level - 1] = col;
        this.groupOpened = null;
    }

    openLevel(group: IGroupLevel, event: Event): void {
        event.stopPropagation();
        this.groupOpened = group;
    }

    applyGroups(): void {
        const appliedGroupNames = this.gridService.getColsNames(this.appliedGroups);
        this.gridService.setGroupingState(appliedGroupNames);
        this.gridService.setGroupingStateValue(this.appliedGroups);
        this.lastGroupsApplied = [...this.appliedGroups];
        this.isOverlayGroupsOpen = false;
    }

    closeOverlay(): void {
        this.groupOpened = null;
    }

    openCloseGroups(event: Event): void {
        event.stopPropagation();
        this.isOverlayGroupsOpen = !this.isOverlayGroupsOpen;
    }

    clearSelection(): void {
        this.appliedGroups = [];
        this.lastGroupsApplied = [];
    }
}
