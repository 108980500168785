import { Component, OnInit } from "@angular/core";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-custom-percentage-editor",
    templateUrl: "./custom-percentage-editor.component.html",
    styleUrls: ["./custom-percentage-editor.component.scss"]
})
export class CustomPercentageEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    displayValue?: string | null;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.displayValue = this.bindingProperty !== null && this.bindingProperty !== undefined ? (this.bindingProperty * 100).toFixed(2) : null;
    }

    dataChanged(event: string) {
        this.displayValue = event;
        this.bindingProperty = event !== null ? parseFloat(event) / 100 : null;
        this.valueChange.emit();
        this.validate();
    }
}
