import { Buildings } from "./Buildings";
import { ChangeOrders } from "./ChangeOrders";
import { Companies } from "./Companies";
import { CompetingBidders } from "./CompetingBidders";
import { EstimatesAwarded } from "./EstimatesAwarded";
import { EstimatesBid } from "./EstimatesBid";
import { AwardedFinalEstimates } from "./AwardedFinalEstimates";
import { Opportunities } from "./Opportunities";
import { PointListSystemsLabor } from "./PointListSystemsLabor";
import { PointListSystems } from "./PointListSystemsService";
import { ProjectModuleCustom } from "./ProjectModuleCustom";
import { ProjectModuleExpenses } from "./ProjectModuleExpenses";
import { ProjectModuleLabor } from "./ProjectModuleLabor";
import { ProjectModuleMaterial } from "./ProjectModuleMaterial";
import { ProjectModules } from "./ProjectModules";
import { ProjectModuleSubcontractor } from "./ProjectModuleSubcontractor";
import { SalesLeads } from "./SalesLeads";
import { ServiceCategories } from "./ServiceCategories";
import { ServiceCosts } from "./ServiceCosts";
import { ServiceModuleCustom } from "./ServiceModuleCustom";
import { ServiceModuleExpenses } from "./ServiceModuleExpenses";
import { ServiceModuleLabor } from "./ServiceModuleLabor";
import { ServiceModuleMaterial } from "./ServiceModuleMaterial";
import { ServiceModules } from "./ServiceModules";
import { ServiceModuleSubcontractor } from "./ServiceModuleSubcontractor";
import { ServiceSubcategories } from "./ServiceSubcategories";
import { ServiceTerms } from "./ServiceTerms";
import { UserProjects } from "./UserProjects";
import { Accounts } from "./Accounts";

export const configs: Record<string, any> = {
    "awarded-final-estimates": AwardedFinalEstimates,
    buildings: Buildings,
    "change-orders": ChangeOrders,
    "user-projects": UserProjects,
    "service-modules": ServiceModules,
    "service-terms": ServiceTerms,
    "service-costs": ServiceCosts,
    "point-list-systems": PointListSystems,
    "point-list-systems-labor": PointListSystemsLabor,
    "competing-bidders": CompetingBidders,
    "service-categories": ServiceCategories,
    "service-subcategories": ServiceSubcategories,
    "service-module-subcontractors": ServiceModuleSubcontractor,
    "service-module-expenses": ServiceModuleExpenses,
    "service-module-custom": ServiceModuleCustom,
    "service-module-labor": ServiceModuleLabor,
    "service-module-material": ServiceModuleMaterial,
    "project-modules": ProjectModules,
    "project-module-subcontractors": ProjectModuleSubcontractor,
    "project-module-expenses": ProjectModuleExpenses,
    "project-module-customs": ProjectModuleCustom,
    "project-module-labors": ProjectModuleLabor,
    "project-module-materials": ProjectModuleMaterial,
    "sales-leads": SalesLeads,
    "estimate-bid": EstimatesBid,
    "estimate-awarded": EstimatesAwarded,
    opportunities: Opportunities,
    companies: Companies,
    accounts: Accounts
};
