import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "app-internal-navigator",
    templateUrl: "./internal-navigator.component.html",
    styleUrls: ["./internal-navigator.component.scss"]
})
export class InternalNavigatorComponent implements OnInit {
    @Input()
    path?: string;

    @Input()
    label?: string;

    constructor(private readonly router: Router) {}

    normalizedPath?: string;

    ngOnInit() {
        if (this.path) {
            this.normalizedPath = this.path.startsWith("/") ? this.path : `/${this.path}`;
        }
    }

    navigateTo() {
        this.router.navigate([this.path]);
    }

    handleAuxClick(event: MouseEvent) {
        if (event.button === 1 && this.path) {
            window.open(this.path, "_blank");
        }
    }
}
