import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-password-input",
    templateUrl: "./password-input.component.html",
    styleUrls: ["./password-input.component.scss"]
})
export class PasswordInputComponent {
    @Input()
    control!: FormControl;

    @Input()
    inputId?: string;

    @Input()
    inputName?: string;

    @Input()
    placeholder?: string;

    @Input()
    minLength?: number;

    @Input()
    maxLength?: number;

    isPasswordVisible: boolean;
    passwordVisibleIcon: SafeHtml;
    passwordNotVisibleIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.passwordVisibleIcon = this.iconSanitizer.getIcon("passwordVisible");
        this.passwordNotVisibleIcon = this.iconSanitizer.getIcon("passwordNotVisible");

        this.isPasswordVisible = false;
        this.placeholder = "Enter your password";
    }

    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }
}
