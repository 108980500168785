import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SearchBaseEstimateResultDto } from "../../services/http/clients/api-proxies";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

@Component({
    selector: "app-type-ahead-uuids",
    templateUrl: "./type-ahead-uuids.component.html",
    styleUrls: ["./type-ahead-uuids.component.scss"]
})
export class TypeAheadUuidsComponent {
    @Input()
    selectedUUID?: SearchBaseEstimateResultDto;

    @Input()
    isReadOnly?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    placeholder?: string;

    @Input()
    isActive?: boolean;

    @Output()
    selectedUUIDChange = new EventEmitter<SearchBaseEstimateResultDto>();

    @Input()
    error?: boolean;

    searchText: string;
    sourceUUID?: SearchBaseEstimateResultDto[];
    disabled: boolean;
    isLoading: boolean;

    constructor(
        private readonly opportunityEstimateService: OpportunityEstimateService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.searchText = "";
        this.isReadOnly = false;
        this.displaySelected = false;
        this.placeholder = "Select UUID";
        this.error = false;
        this.disabled = false;
        this.isLoading = false;
    }

    async onUUIDTextChanged(): Promise<void> {
        if (!this.searchText) return;

        try {
            this.sourceUUID = await this.opportunityEstimateService.searchAvailableBaseEstimates({
                filterText: this.searchText
            });

            for (const item of this.sourceUUID) {
                this.isItemDisabled(item);
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying search available base estimates");
        }
    }

    onUUIDChange(itemSelected: SearchBaseEstimateResultDto): void {
        this.selectedUUID = itemSelected;
        this.selectedUUIDChange.emit(itemSelected);
    }

    isItemDisabled(item: SearchBaseEstimateResultDto): void {
        this.disabled = !!item.opportunityId;
    }
}
