import { IconSanitizerService } from "./../../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { IDropdownPanel, ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { configs } from "./configs/configs";
import { ActivatedRoute, Router } from "@angular/router";
import { mapping } from "./filters/mapping";
import { ReportTemplateService } from "projects/app/src/app/services/http/clients/reporting-app/report-template.service";
import { CustomReportUserFavoriteService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-user-favorite.service";
import { AbstractReportFactory } from "projects/app/src/app/factories/report-factory/abstract-report.factory";
import { IFilterItem, IFilters, IReportConfiguration } from "../types/report-config.interface";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { ModalReportSaveComponent } from "../../../shared/modals/modal-report-save/modal-report-save.component";
import { IReportFactory } from "../types/report-factory.interface";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { SafeHtml } from "@angular/platform-browser";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { CustomReportSearchService } from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-search.service";
import { ModalReportShareComponent } from "../../../shared/modals/modal-report-share/modal-report-share.component";
import { GridService } from "projects/app/src/app/services/http/clients/reporting-app/grid.service";
import { WidgetType } from "../types/report-widget-type.enum";
import { ModalScheduleComponent } from "../../../shared/modals/modal-schedule/modal-schedule.component";

export type FilterPropType<T> = null | undefined | T;

export interface IFilterValues {
    organizationIds?: FilterPropType<number[]>;
    salesPersonIds?: FilterPropType<number[]>;
    opportunityIds?: FilterPropType<number[]>;
    opportunityCreationDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    opportunityDueDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    projectStartDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    projectEndDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    markets?: FilterPropType<number[]>;
    leads?: FilterPropType<number[]>;
    divisionIds?: FilterPropType<number[]>;
    customerIds?: FilterPropType<number[]>;
    potentialValue?: { from: FilterPropType<number>; to: FilterPropType<number> };
    probabilityValue?: { from: FilterPropType<number>; to: FilterPropType<number> };
    expectedResponseDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    bidDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    condition?: FilterPropType<boolean>;
    awardedAmount?: { from: FilterPropType<number>; to: FilterPropType<number> };
    bidAmount?: { from: FilterPropType<number>; to: FilterPropType<number> };
    stateId?: FilterPropType<number>;
    buildingClassId?: FilterPropType<number>;
    companyPropertyManagerIds?: FilterPropType<number[]>;
    companyTypeId?: FilterPropType<number>;
    legalStatusId?: FilterPropType<number>;
    awardedDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    relatedOpportunitiesCondition?: FilterPropType<boolean>;
    estimateTypeId?: FilterPropType<number>;
    opportunityStatusIds?: FilterPropType<number[]>;
    competingBidderIds?: FilterPropType<number[]>;
    projectDueDate?: { from: FilterPropType<Date>; to: FilterPropType<Date> };
    projectStatusIds?: FilterPropType<number[]>;
    changeOrderStatusId?: FilterPropType<number>;
    projectIds?: FilterPropType<number[]>;
    accountIds?: FilterPropType<number[]>;
    relatedOpportunitiesSalesPersonIds?: FilterPropType<number[]>;
}

@Component({
    selector: "app-reports-single",
    templateUrl: "./reports-single.component.html",
    styleUrls: ["./reports-single.component.scss"]
})
export class ReportsSingleComponent implements OnInit, OnDestroy {
    currentTab = "Overview";
    configuration?: IReportConfiguration<unknown>;
    filterParamsSelected: any = {};
    filterValues: any;
    reportFactoryOverview!: IReportFactory;
    reportFactoryGrid!: IReportFactory;
    reportData?: any;
    reportService?: any;
    customReportId?: number;
    reportTemplateName?: string;
    reportSubtitle?: string;
    reportId?: number | string;
    isLoading: boolean;
    templateRun?: boolean;
    saveIcon?: SafeHtml;
    shareIcon?: SafeHtml;
    downloadIcon?: SafeHtml;
    scheduleIcon?: SafeHtml;
    canUpdateReport: boolean;
    canDeleteReport: boolean;
    canScheduleReport: boolean;
    canAddToCatalog: boolean;
    canUpdateCatalog: boolean;
    catalogReportId?: string;
    panel: IDropdownPanel;
    allowGrouping: boolean;
    gridSettings?: Record<string, any>[];
    groupedCols?: string[];
    hiddenColumns: string[];

    constructor(
        private readonly modalService: ModalService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly route: ActivatedRoute,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly router: Router,
        private readonly reportTemplateService: ReportTemplateService,
        private readonly reportsService: ReportsService,
        private readonly customReportUserFavoriteService: CustomReportUserFavoriteService,
        private readonly customReportService: CustomReportSearchService,
        private readonly gridService: GridService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.isLoading = true;
        this.saveIcon = this.iconSanitizer.getIcon("save");
        this.shareIcon = this.iconSanitizer.getIcon("share");
        this.downloadIcon = this.iconSanitizer.getIcon("download");
        this.scheduleIcon = this.iconSanitizer.getIcon("schedule");
        this.canUpdateReport = false;
        this.canDeleteReport = false;
        this.canAddToCatalog = false;
        this.canScheduleReport = false;
        this.canUpdateCatalog = false;
        this.filterValues = {
            organizationIds: null,
            salesPersonIds: null,
            opportunityIds: null,
            opportunityCreationDate: { from: undefined, to: undefined },
            opportunityDueDate: { from: undefined, to: undefined },
            projectStartDate: { from: undefined, to: undefined },
            projectEndDate: { from: undefined, to: undefined },
            markets: null,
            leads: null,
            divisionIds: undefined,
            customerIds: null,
            potentialValue: { from: undefined, to: undefined },
            probabilityValue: { from: undefined, to: undefined },
            expectedResponseDate: { from: undefined, to: undefined },
            bidDate: { from: undefined, to: undefined },
            condition: undefined,
            awardedAmount: { from: undefined, to: undefined },
            bidAmount: { from: undefined, to: undefined },
            stateId: undefined,
            buildingClassId: undefined,
            companyPropertyManagerIds: null,
            companyTypeId: undefined,
            legalStatusId: undefined,
            awardedDate: { from: undefined, to: undefined },
            relatedOpportunitiesCondition: undefined,
            estimateTypeId: undefined,
            opportunityStatusIds: null,
            competingBidderIds: null,
            projectDueDate: { from: undefined, to: undefined },
            projectStatusIds: null,
            changeOrderStatusId: null,
            projectIds: null,
            accountIds: null,
            relatedOpportunitiesSalesPersonIds: null
        };
        this.templateRun = false;
        this.panel = {
            items: [
                {
                    id: "csv-file",
                    name: "CSV File",
                    action: () => this.downloadCSV(this.reportData?.name)
                }
            ]
        };
        this.allowGrouping = false;
        this.hiddenColumns = [];
    }

    async ngOnInit(): Promise<void> {
        this.route.paramMap.subscribe(async params => {
            this.customReportId = parseInt(params.get("id") ?? "0");
            this.check404Redirect(params.get("id"));
            this.reportTemplateName = params.get("reportTemplate") ?? "";
            this.catalogReportId = params.get("token") ?? "";
            if (this.reportTemplateName) {
                this.setFactoryConfig(this.reportTemplateName);
                this.bindFiltersToEditors();
                await this.initializeReport();
            }
        });
        if (this.configuration) {
            const factory = new AbstractReportFactory();
            this.reportFactoryOverview = factory.getConcreteFactory(this.configuration);
            this.reportFactoryGrid = factory.getConcreteFactory(this.configuration);
            this.setAllowGrouping();
            this.setGridSettings();
        }
    }

    private async waitForGridApiReady(): Promise<void> {
        if (this.gridService.getGridApi()) {
            this.handleGridApiReady();
        } else {
            setTimeout(() => this.waitForGridApiReady(), 100);
        }
    }

    private handleGridApiReady(): void {
        if (this.groupedCols && this.groupedCols.length) {
            this.gridService.setGroupingState(this.groupedCols);
        }
        if (this.hiddenColumns && this.hiddenColumns.length) {
            this.applyHiddenColumns();
        }
    }

    async run() {
        this.templateRun = true;
        this.hiddenColumns = [];
        this.filtersMapping();
        await this.awaiter.awaitAction(
            "Running template...",
            async () => {
                if (!this.configuration) return;
                const instanceOverview = this.reportFactoryOverview.getReportInstance(this.configuration, this.viewContainerRef);
                const instanceGrid = this.reportFactoryGrid.getReportInstance(this.configuration, this.viewContainerRef);
                await instanceOverview.update(this.filterParamsSelected);
                await instanceGrid.update(this.filterParamsSelected);
            },
            loading => (this.isLoading = loading)
        );
    }

    async openSaveModal(editModal?: boolean): Promise<void> {
        this.filtersMapping();
        const reportResponse = await this.modalService.open(ModalReportSaveComponent, {
            id: editModal ? this.customReportId : undefined,
            canAddToCatalog: this.checkAddToCatalogPermission(),
            canUpdateCatalog: this.checkUpdateCatalogPermission(),
            reportService: this.reportService,
            reportData: this.reportData,
            filterParameters: this.filterParamsSelected,
            editMode: editModal,
            hiddenColumns: this.gridService.getHiddenState(),
            groupingColumns: this.gridService.getColsNames(this.gridService.getGroupingState())
        });

        if (reportResponse && (reportResponse as any).id === this.customReportId) {
            this.reportData = reportResponse;
            this.checkAllowedActions();
        }
    }

    openShareModal() {
        this.modalService.open(ModalReportShareComponent, {
            reportService: this.reportService,
            reportData: this.reportData,
            filterParameters: this.filterParamsSelected
        });
    }

    async openScheduleModal() {
        const optionsDynamicDate = this.getOptionsDynamicDate();

        const response = await this.modalService.open(ModalScheduleComponent, {
            schedulerId: this.reportData?.schedulerId,
            customReportId: this.customReportId,
            optionsDynamicDate: optionsDynamicDate,
            lastRun: this.reportData?.lastRun
        });

        if (response) {
            if (response.schedulerStatusId) {
                this.reportData.schedulerStatusId = response.schedulerStatusId;
                this.reportData.schedulerStatusName = this.getSchedulerStatusName(response.schedulerStatusId);
            } else if (response.deletedScheduler) {
                this.reportData.schedulerId = undefined;
                this.reportData.schedulerStatusId = undefined;
                this.reportData.schedulerStatusName = undefined;
                this.reportData.schedulerFrequencyId = undefined;
            } else if (response.changedScheduler) {
                this.reportData.schedulerId = response.changedScheduler.id;
                this.reportData.schedulerStatusId = response.changedScheduler.schedulerStatusId;
                this.reportData.schedulerStatusName = this.getSchedulerStatusName(response.changedScheduler.schedulerStatusId);
                this.reportData.schedulerFrequencyId = response.changedScheduler.schedulerFrequencyId;
            }
        }
    }

    getSchedulerStatusName(statusId: number) {
        if (statusId == 1) {
            return "Active";
        } else if (statusId == 2) {
            return "Paused";
        } else {
            return "";
        }
    }

    getOptionsDynamicDate() {
        return this.configuration?.filters?.additional
            .filter(filter => filter.componentId === "date-range" && filter.fieldName)
            .map(filter => {
                const field = filter.fieldName;
                return {
                    value: field.charAt(0).toUpperCase() + field.slice(1),
                    name: field.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase())
                };
            });
    }

    async toggleFavorite(id: number | string) {
        try {
            if (!this.reportData) return;
            const intId: number = typeof id === "string" ? parseInt(id) : id;
            this.reportData.isFavorite = this.customReportId
                ? await this.customReportUserFavoriteService.toggleFavorite(intId)
                : await this.reportTemplateService.toggleFavorite(intId);

            this.snackbarNotificationService.success(`Report ${this.reportData.isFavorite ? "added" : "removed"} from favorites`);
        } catch (err) {
            this.snackbarNotificationService.error("An error occurred on favorite change");
        }
    }

    async updateReport(): Promise<void> {
        if (!this.checkUpdatePermission()) throw new Error("You do not have permission to update this report");

        await this.awaiter.awaitAction("Updating Report", async () => {
            this.filtersMapping();
            this.reportData = await this.reportService.saveCustomReport({
                id: this.reportData?.id,
                name: this.reportData?.name,
                reportTemplateId: this.reportData?.reportTemplateId,
                filterParameters: this.filterParamsSelected,
                isFavorite: this.reportData?.isFavorite,
                isPublic: this.reportData?.isPublic,
                organizations: this.reportData?.organizations?.length > 0 && this.reportData?.isPublic ? this.reportData.organizations : null,
                groupingColumns: this.gridService.getColsNames(this.gridService.getGroupingState()),
                hiddenColumns: this.gridService.getHiddenState()
            });
            this.snackbarNotificationService.success("The report has been successfully updated");
        });
    }

    async deleteReport(): Promise<void> {
        if (!this.checkUpdatePermission()) throw new Error("You do not have permission to delete this report");

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Delete",
            cancelCaption: "Cancel",
            content: "This report will be deleted including all the data. This action cannot be undone. Are you sure you want to delete it?",
            title: "Delete Report",
            isReject: true
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Deleting Report", async () => {
            await this.customReportService.deactivate(this.reportData?.id);
            this.snackbarNotificationService.success("The report has been successfully deleted");
            this.router.navigate(["/reports"]);
        });
    }

    onClearAdditionalFilters() {
        if (!this.configuration) return;
        const additionalFilters = this.configuration.filters["additional"];
        if (additionalFilters.length) {
            for (const filter of additionalFilters) {
                if (filter.mapping) {
                    Object.keys(filter.mapping).forEach((sourceKey: string) => {
                        const value = undefined;
                        const mappedKey = mapping[sourceKey];
                        mappedKey.split(".").reduce((acc, key, idx, arr) => {
                            if (idx === arr.length - 1) {
                                acc[key] = value;
                            } else {
                                acc[key] = acc[key] || {};
                            }
                            return acc[key];
                        }, this.filterValues);
                    });
                } else {
                    this.filterValues[filter.fieldName] = undefined;
                }
            }
        }
        this.reportsService.setSelectedProperty(true);
    }

    private check404Redirect(paramId: string | null) {
        if (!this.checkIfCustomReportIdIsValid(paramId)) {
            this.router.navigate(["/not-found"]);
        }
    }

    private checkIfCustomReportIdIsValid(paramId: string | null): boolean {
        return (
            (!this.customReportId && this.customReportId == 0) || // check is template or shared
            (!!this.customReportId && !isNaN(this.customReportId) && paramId === this.customReportId.toString()) // check is valid custom report id
        );
    }

    private setFactoryConfig(template: string) {
        if (configs[template]) {
            this.configuration = configs[template];
        } else {
            this.router.navigate(["/reports"]);
        }
    }

    private async initializeReport(): Promise<void> {
        this.gridService.clearColumnVisibilityState();

        if (!this.configuration?.base.serviceType) {
            throw new Error("Unable to instantiate the report component.");
        }
        this.reportService = this.reportsService.getService(this.configuration.base.serviceType);
        const isSharedRoute = this.route.snapshot.url.some(segment => segment.path === "shared");

        if (isSharedRoute) {
            await this.initializeCatalogReport();
        } else if (this.customReportId) {
            await this.initializeCustomReport();
        } else {
            await this.initializeTemplateReport();
        }

        this.checkAllowedActions();
    }

    private async initializeCatalogReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportService.getSharedReport(this.catalogReportId);
            this.reportSubtitle = `${this.reportData.reportTemplateName} - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.catalogReportId;
            if (this.reportData.filterParameters) {
                this.filterParamsSelected = { ...this.reportData.filterParameters };
                this.initializeFilterValues();
                this.run();
            }
            if (this.reportData.hiddenColumns && this.reportData.hiddenColumns.length) {
                this.hiddenColumns = this.reportData.hiddenColumns;
                if (this.gridService.getGridApi()) {
                    this.applyHiddenColumns();
                } else {
                    await this.waitForGridApiReady();
                }
            }
        });
    }

    private async initializeCustomReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportService.getCustomReport(this.customReportId);
            this.reportSubtitle = `${this.reportData.reportTemplateName} - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.customReportId;
            if (this.reportData && this.reportData.groupingColumns && this.reportData.groupingColumns.length) {
                this.groupedCols = this.reportData.groupingColumns;
            }
            if (this.reportData.filterParameters) {
                this.filterParamsSelected = { ...this.reportData.filterParameters };
                this.initializeFilterValues();
                this.run();
            }
            if (this.reportData.hiddenColumns && this.reportData.hiddenColumns.length) {
                this.hiddenColumns = this.reportData.hiddenColumns;
                if (this.gridService.getGridApi()) {
                    this.applyHiddenColumns();
                } else {
                    await this.waitForGridApiReady();
                }
            }
        });
    }

    private applyHiddenColumns(): void {
        if (this.hiddenColumns.length && this.gridService.getGridApi()) {
            let currentState = this.gridService.getColumnVisibilityState();

            if (Object.keys(currentState).length === 0) {
                const api = this.gridService.getGridApi();
                if (api) {
                    const colDefs = api.getColumnDefs() || [];
                    currentState = colDefs.reduce(
                        (acc, col) => {
                            if ("field" in col && col.field) {
                                acc[col.field] = true;
                            }
                            return acc;
                        },
                        {} as Record<string, boolean>
                    );
                }
            }

            this.hiddenColumns.forEach((col: string) => {
                currentState[col] = false;
            });

            this.gridService.updateColumnVisibilityState(currentState);
        }
    }

    private async initializeTemplateReport(): Promise<void> {
        await this.awaiter.awaitAction("Getting Report", async () => {
            this.reportData = await this.reportTemplateService.getByRoute(`reports/${this.reportTemplateName}`);
            this.reportSubtitle = `Base Template - ${this.reportData?.reportTemplateGroupName}`;
            this.reportId = this.reportData.id;
        });
    }

    private bindFiltersToEditors() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                filter.bindContext = this.filterValues;
            });
        });
    }

    private initializeFilterValues() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                if (filter.mapping) {
                    Object.keys(filter.mapping).forEach((sourceKey: string) => {
                        const value = this.filterParamsSelected[sourceKey];
                        const mappedKey = mapping[sourceKey];
                        mappedKey.split(".").reduce((acc, key, idx, arr) => {
                            if (idx === arr.length - 1) {
                                acc[key] = value;
                            } else {
                                acc[key] = acc[key] || {};
                            }
                            return acc[key];
                        }, this.filterValues);
                    });
                } else {
                    this.filterValues[filter.fieldName] = this.filterParamsSelected[filter.fieldName];
                }
            });
        });
        this.reportsService.setSelectedProperty(true);
    }

    private filtersMapping() {
        if (!this.configuration) return;
        Object.keys(this.configuration.filters).forEach((filterCategory: string) => {
            this.configuration?.filters[filterCategory as keyof IFilters].forEach((filter: IFilterItem) => {
                if (filter.mapping) {
                    Object.keys(filter.mapping).forEach(key => {
                        this.filterParamsSelected[key] = this.getFilterValue(filter.mapping[key]);
                    });
                } else {
                    this.filterParamsSelected[filter.fieldName] = this.filterValues[filter.fieldName];
                }
            });
        });
    }

    private getFilterValue(key: string) {
        return key.split(".").reduce((acc, key) => acc && acc[key], this.filterValues);
    }

    private checkAllowedActions(): void {
        this.canUpdateReport = this.checkUpdatePermission();
        this.canDeleteReport = this.checkUpdatePermission();
        this.canScheduleReport = this.checkScheduleReportPermission();
        this.canAddToCatalog = this.checkAddToCatalogPermission();
        this.canUpdateCatalog = this.checkUpdateCatalogPermission();
    }

    private checkUpdatePermission(): boolean {
        // * User can execute the update report action if the report type is not a template, which means it is a custom report
        // * and if it is a user custom report or if it is a catalog custom report and only if he/she can edit the catalog
        return !!this.customReportId && (!this.reportData?.isPublic || (this.reportData?.isPublic && this.reportData?.canEditCatalogReport));
    }

    private checkScheduleReportPermission() {
        return !this.reportData?.isPublic && this.canUpdateReport;
    }

    private checkAddToCatalogPermission(): boolean {
        return this.reportData?.canAddToCatalog;
    }

    private checkUpdateCatalogPermission(): boolean {
        // * To update catalog (edit or remove) the report must be public
        return !!this.customReportId && this.reportData?.isPublic && this.reportData?.canEditCatalogReport;
    }

    private downloadCSV(filename: string) {
        this.gridService.downloadCSV(filename);
    }

    private setAllowGrouping(): void {
        this.allowGrouping = !!this.gridService.getGroupingAvailableCols(this.configuration?.gridTab?.widgets?.[0]?.settings ?? []).length;
    }

    private setGridSettings(): void {
        this.gridSettings = this.configuration?.gridTab.widgets.find(el => el.type === WidgetType.Grid)?.settings;
    }

    ngOnDestroy(): void {
        this.gridService.removeGridApi();
    }

    onColumnVisibilityChange(state: Record<string, boolean>): void {
        this.hiddenColumns = Object.keys(state).filter(key => !state[key]);
    }
}
