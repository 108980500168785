import { Injectable } from "@angular/core";
import { CompanyView, ContactView, IOpportunityCompanyClient, OpportunityCompanyClient, OpportunityCompanyViewPaginatedResultDto, SearchAvailableAccountCompaniesParameters, SearchAvailableOpportunityCompaniesParameters, SearchAvailableOpportunityContactsParameters, SearchCompaniesForOpportunityParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class OpportunityCompanyService {
    private client: IOpportunityCompanyClient;
    constructor() {
        this.client = new OpportunityCompanyClient();
    }

    async search(body: SearchCompaniesForOpportunityParameters): Promise<OpportunityCompanyViewPaginatedResultDto> {
        return await this.client.opportunityCompanySearchForOpportunity(body);
    }

    async searchAvailable(body: SearchAvailableOpportunityCompaniesParameters): Promise<CompanyView[]> {
        return await this.client.opportunityCompanySearchAvailable(body);
    }

    async delete(id: number): Promise<void> {
        return await this.client.opportunityCompanyDelete(id);
    }
}
