import { Injectable } from "@angular/core";
import { ITagClient, SearchTagsParameters, TagClient, TagView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class TagService {
    private client: ITagClient;
    constructor() {
        this.client = new TagClient();
    }

    async search(body: SearchTagsParameters): Promise<TagView[]> {
        return await this.client.tagSearch(body);
    }

    async save(body: TagView): Promise<TagView> {
        return await this.client.tagSave(body);
    }
}
