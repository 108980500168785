import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EditorItemComponent, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { Note } from "projects/app/src/app/models/Note";
import { NoteView } from "projects/app/src/app/services/http/clients/api-proxies";
import { NoteService } from "projects/app/src/app/services/http/clients/note.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

interface IModalEditNote {
    note?: NoteView;
    entityTypeId: EntityTypes;
    entityId: number;
}

@Component({
    selector: "app-modal-edit-note",
    templateUrl: "./modal-edit-note.component.html",
    styleUrls: ["./modal-edit-note.component.scss"]
})
export class ModalEditNoteComponent extends ModalComponentBase<IModalEditNote, NoteView> implements OnInit {
    protected override parameters!: IModalEditNote;

    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    note?: Note;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    isLoading: boolean;

    constructor(
        private readonly noteService: NoteService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.note = (this.parameters.note && Note.fromInterface(this.parameters.note)) || new Note();
        this.note.entityId = this.parameters.entityId;
        this.note.entityTypeId = this.parameters.entityTypeId;
        this.configItems = [
            {
                label: "Note Title",
                componentId: EditorType.textInput,
                fieldName: "title",
                bindContext: this.note,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 200 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Addressed to",
                componentId: EditorType.noteUser,
                fieldName: "noteUsers",
                bindContext: this.note
            },
            {
                componentId: EditorType.textarea,
                fieldName: "message",
                bindContext: this.note,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 70000 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            }
        ];
    }

    validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveNote(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving Note...",
            async () => {
                const response = await this.noteService.save(this.note);
                if (response) this.snackbarNotificationService.success(this.parameters.note ? "Note edited successfully." : "Note created successfully.");
                this.close(response);
            },
            loading => (this.isLoading = loading)
        );
    }
}
