import { AgChartTheme } from "ag-charts-community";

export enum CHART_COLORS {
    primary = "#175c89",
    secondary = "#0095ff",
    tertiary = "#85b1ce",
    quaternary = "#98b7d2",
    black = "#000000"
}

export enum CHART_FONTS {
    Roboto = "Roboto"
}

export enum CHART_BORDER_RADIUS {
    primary = 4,
    secondary = 8,
    tertiary = 12
}

export const customTheme: AgChartTheme = {
    palette: {
        fills: [CHART_COLORS.primary, CHART_COLORS.secondary, CHART_COLORS.tertiary],
        strokes: [CHART_COLORS.primary]
    },
    overrides: {
        common: {
            title: {
                textAlign: "left",
                fontFamily: CHART_FONTS.Roboto,
                fontWeight: 600,
                color: CHART_COLORS.primary
            },
            subtitle: {
                textAlign: "left",
                fontFamily: CHART_FONTS.Roboto,
                color: CHART_COLORS.secondary
            },
            animation: {
                enabled: true
            },
            contextMenu: {
                enabled: true
            },
            axes: {
                number: {
                    title: {
                        fontFamily: CHART_FONTS.Roboto,
                        color: CHART_COLORS.primary
                    },
                    label: {
                        fontFamily: CHART_FONTS.Roboto,
                        color: CHART_COLORS.quaternary,
                        fontSize: 8
                    }
                },
                category: {
                    label: {
                        fontFamily: CHART_FONTS.Roboto,
                        color: CHART_COLORS.primary,
                        fontSize: 12
                    }
                }
            },
            legend: {
                position: "left",
                item: {
                    label: {
                        fontFamily: CHART_FONTS.Roboto,
                        fontSize: 12
                    },
                    marker: {
                        shape: "circle",
                        size: 10
                    }
                }
            }
        },
        bar: {
            series: {
                cornerRadius: CHART_BORDER_RADIUS.primary,
                label: {
                    placement: "outside",
                    fontFamily: CHART_FONTS.Roboto,
                    fontWeight: 600,
                    color: CHART_COLORS.primary
                }
            }
        }
    }
};
