import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyBuildingView, CompanyView, SearchCompaniesForBuildingParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyBuildingService } from "projects/app/src/app/services/http/clients/company-building.service";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { ModalConfirmComponent } from "../../../../shared/modals/modal-confirm/modal-confirm.component";

@Component({
    selector: "app-buildings-single-companies",
    templateUrl: "./buildings-single-companies.component.html",
    styleUrls: ["./buildings-single-companies.component.scss"]
})
export class BuildingsSingleCompaniesComponent implements OnChanges {
    @Input()
    buildingId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    companies: CompanyBuildingView[];

    orderConfig?: IGenericGridOrderConfig<CompanyBuildingView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchCompaniesForBuildingParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly companyBuildingService: CompanyBuildingService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.companies = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            buildingId: this.buildingId
        } as SearchCompaniesForBuildingParameters;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Building
        };

        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.buildingId && changes["buildingId"]) {
            this.searchConfig.buildingId = this.buildingId;
            this.typeAheadSearchByEntityParameters.entity = {
                buildingId: this.buildingId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Companies",
            async () => {
                const response = await this.companyService.searchForBuilding(this.searchConfig);

                const { pageInfo, results } = response;

                this.companies = this.companies?.concat(results ?? []);

                this.noResults = this.companies.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.companies = [];

        await this.refreshGridView();
    }

    onRowClicked(companyBuilding: CompanyBuildingView) {
        this.router.navigate([`/companies-single/${companyBuilding.companyId}`]);
    }

    onMouseWheelClicked(companyBuilding: CompanyBuildingView) {
        if (companyBuilding?.companyId) {
            window.open(`/companies-single/${companyBuilding.companyId}`, "_blank");
        }
    }

    async onSelectedCompany(company: CompanyView): Promise<void> {
        if (!company || !this.buildingId) return;

        try {
            const companySaved = await this.companyBuildingService.add({
                companyId: company.id,
                buildingId: this.buildingId
            } as CompanyBuildingView);

            if (companySaved) {
                this.tabCounterChange.emit();
                this.companies.unshift(companySaved);
            }
            this.noResults = false;
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the company");
        }
    }

    editAddress(companyBuilding: CompanyBuildingView) {
        console.log(companyBuilding);
    }

    async setAsMainOffice(companyBuilding: CompanyBuildingView) {
        console.log(companyBuilding);

        await this.awaiter.awaitAction(
            "Setting as Main Office",
            async () => {
                if (!companyBuilding) return;

                await this.companyBuildingService.setAsMain({
                    companyId: companyBuilding.companyId,
                    buildingId: this.buildingId
                } as CompanyBuildingView);
                companyBuilding.isMain = true;
            },
            loading => (this.isLoading = loading)
        );
    }

    async removeItem(companyBuilding: CompanyBuildingView): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove company",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this company from the company list?",
            title: "Remove company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction(
            "Removing company",
            async () => {
                if (!companyBuilding) return;

                await this.companyBuildingService.remove({
                    companyId: companyBuilding.companyId,
                    buildingId: this.buildingId
                } as CompanyBuildingView);

                const companyBuildingIndex = this.companies.findIndex(company => company.companyId === companyBuilding.companyId);
                this.companies.splice(companyBuildingIndex, 1);
                this.noResults = this.companies.length === 0;
                this.tabCounterChange.emit();
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            buildingId: this.buildingId
        } as SearchCompaniesForBuildingParameters;
    }
}
