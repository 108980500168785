<div class="container">
    <lib-section-header
        title="Notification Settings"
        subTitle="Edit Notifications for the current Organization"
        [actionsTemplate]="actionsTemplate"
        [iconName]="'goBack'"
        [goBackAction]="'base'"
    >
        <ng-template #actionsTemplate>
            <div class="actions">
                <button class="secondary-btn" (click)="onCancel()">Cancel</button>
                <button class="primary-btn" (click)="onSave()">Save</button>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="notificationsContainer">
        <div class="cardWrapper">
            <div class="cardHeader">
                <h2>In-app notifications</h2>
                <lib-slide-toggle [checked]="checkedAllInApp" (checkedChange)="checkAllInApp($event)"></lib-slide-toggle>
            </div>
            <div class="cardBody">
                <div class="cardItem" *ngFor="let item of notificationTypeUserConfiguration">
                    <div class="ItemWrapper">
                        <div class="label">
                            <span>{{ item.notificationTypeName }}</span>
                        </div>
                        <div class="editor">
                            <lib-slide-toggle
                                [checked]="item.includeInAppNotification"
                                (checkedChange)="onNotificationItemChange($event, item, 'includeInAppNotification')"
                            ></lib-slide-toggle>
                        </div>
                    </div>
                    <ng-container *ngIf="item.opportunityStatusNotificationTypeUserConfigurations?.length">
                        <div class="cardSubItem" *ngFor="let subItem of item.opportunityStatusNotificationTypeUserConfigurations">
                            <div class="subItemWrapper">
                                <div class="label">
                                    <span>{{ subItem.opportunityStatusName }}</span>
                                </div>
                                <div class="editor">
                                    <lib-slide-toggle
                                        [checked]="subItem.includeInAppNotification"
                                        (checkedChange)="onNotificationSubItemChange($event, item, subItem, 'includeInAppNotification')"
                                    ></lib-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="cardWrapper">
            <div class="cardHeader">
                <h2>Email notifications</h2>
                <lib-slide-toggle [checked]="checkedAllEmail" (checkedChange)="checkAllEmail($event)"></lib-slide-toggle>
            </div>
            <div class="cardBody">
                <div class="cardItem" *ngFor="let item of notificationTypeUserConfiguration">
                    <div class="ItemWrapper">
                        <div class="label">
                            <span>{{ item.notificationTypeName }}</span>
                        </div>
                        <div class="editor">
                            <lib-slide-toggle
                                [checked]="item.includeEmailNotification"
                                (checkedChange)="onNotificationItemChange($event, item, 'includeEmailNotification')"
                            ></lib-slide-toggle>
                        </div>
                    </div>
                    <ng-container *ngIf="item.opportunityStatusNotificationTypeUserConfigurations?.length">
                        <div class="cardSubItem" *ngFor="let subItem of item.opportunityStatusNotificationTypeUserConfigurations">
                            <div class="subItemWrapper">
                                <div class="label">
                                    <span>{{ subItem.opportunityStatusName }}</span>
                                </div>
                                <div class="editor">
                                    <lib-slide-toggle
                                        [checked]="subItem.includeEmailNotification"
                                        (checkedChange)="onNotificationSubItemChange($event, item, subItem, 'includeEmailNotification')"
                                    ></lib-slide-toggle>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
