<div class="wrapper">
    <div class="avatar">
        <ng-container *ngIf="organization && organization.organizationImageUrl; else placeholder">
            <img [ngSrc]="organization.organizationImageUrl" alt="Organization logo" width="35" height="35" />
        </ng-container>

        <ng-template #placeholder>
            <div class="icon" [innerHTML]="organizationLogoIcon"></div>
        </ng-template>
    </div>

    <div class="information" libTooltip [tooltip]="organization?.organizationName" [position]="orgNameTooltipPosition">
        <span class="name">{{ organization?.organizationName }}</span>
        <div class="role">{{ organization?.userRoleDescription }}</div>
    </div>
</div>
