<lib-generic-type-ahead-dropdown
    [(selectedItem)]="selectedState"
    (selectedItemChange)="onSelectState()"
    placeholder="Add State"
    [(text)]="searchText"
    [source]="states"
    (textChange)="onStateTextChanged()"
    [config]="{
        defaultText: 'Add State',
        itemDisplayKey: 'name'
    }"
>
</lib-generic-type-ahead-dropdown>
