import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { Building } from "projects/app/src/app/models/Building";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { EditorItemComponent } from "projects/ngx-lib/src/lib/components/editor-item/editor-item.component";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

@Component({
    selector: "app-modal-add-building",
    templateUrl: "./modal-add-building.component.html",
    styleUrls: ["./modal-add-building.component.scss"]
})
export class ModalAddBuildingComponent extends ModalComponentBase<undefined, BuildingView> implements OnInit {
    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    building: Building;

    isReadOnly: boolean;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    isLoading: boolean;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.building = new Building();
        this.isReadOnly = false;
        this.configItems = [];
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.configItems = [
            {
                label: "Name",
                componentId: EditorType.textInput,
                fieldName: "name",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Class",
                componentId: EditorType.buildingClass,
                fieldName: "buildingBuildingClasses",
                bindContext: this.building,
                isReadOnly: this.isReadOnly
            },
            {
                label: "Website",
                componentId: EditorType.link,
                fieldName: "website",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.string, args: { maxLength: 500 } }]
            },
            {
                componentId: EditorType.address,
                fieldName: "addressLines",
                bindContext: this.building,
                isReadOnly: this.isReadOnly,
                validations: [{ type: ValidatorType.required }]
            }
        ];
    }

    private validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveBuilding(): Promise<void> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction(
            "Saving Building...",
            async () => {
                const response = await this.buildingService.save(this.building);
                if (response) this.snackbarNotificationService.success("Building created successfully.");
                this.close(response);
            },
            loading => (this.isLoading = loading)
        );
    }
}
