/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconSanitizerService } from "./../../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FilterValueOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { SearchOpportunitiesMapParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { MapService } from "projects/app/src/app/services/map.service";
import { IOpportunityCardDetails } from "../../../shared/opportunity-card/opportunity-card-expanded/opportunity-card-expanded.component";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { OrganizationService } from "projects/app/src/app/services/http/clients/organization.service";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { Router } from "@angular/router";
import { SafeHtml } from "@angular/platform-browser";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-opportunities-map",
    templateUrl: "./opportunities-map.component.html",
    styleUrls: ["./opportunities-map.component.scss"]
})
export class OpportunitiesMapComponent implements AfterViewInit, OnInit, OnDestroy {
    filters?: FilterValueOpportunities;
    opportunities: IOpportunityCardDetails[];
    searchConfig: SearchOpportunitiesMapParameters;
    mapInstanceCreated: boolean;
    mapContainerId: string;
    initialCenterCoordinates: [number, number];
    zoomLevel: number;
    selectedMarkerData: any;
    opportunitiesCounter?: number;
    closeIcon?: SafeHtml;

    private filterSubscription?: Subscription;
    private isDestroyMapComponent: boolean;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly opportunityService: OpportunityService,
        private readonly mapService: MapService,
        private readonly basicUserOrganizationService: BasicUserOrganizationService,
        private readonly organizationService: OrganizationService,
        private readonly router: Router,
        private readonly iconSanitizer: IconSanitizerService,
        private readonly filterStateService: FilterStateService<FilterValueOpportunities>
    ) {
        this.opportunities = [];
        this.searchConfig = {} as SearchOpportunitiesMapParameters;
        this.mapInstanceCreated = false;
        this.isDestroyMapComponent = false;
        this.mapContainerId = "map";
        this.initialCenterCoordinates = [-103.5917, 40.6699]; // center of US
        this.zoomLevel = 4;
        this.closeIcon = this.iconSanitizer.getIcon("close");
    }

    async ngOnInit(): Promise<void> {
        this.filterSubscription = this.filterStateService.stateFilters$.subscribe(async newFilters => {
            if (!newFilters) return;
            this.filters = { ...newFilters };

            if (!this.mapInstanceCreated) {
                await this.setInitialCenterCoordinates();
                this.mapInstanceCreated = await this.mapService.initializeMap(
                    this.mapContainerId,
                    this.initialCenterCoordinates,
                    this.zoomLevel,
                    "opportunities"
                );
            }

            this.closePanel();
            this.opportunities = [];
            this.refreshMapView();
        });
    }

    async ngAfterViewInit(): Promise<void> {
        if (this.isDestroyMapComponent) return;

        await this.setInitialCenterCoordinates();
        this.mapInstanceCreated = await this.mapService.initializeMap(this.mapContainerId, this.initialCenterCoordinates, this.zoomLevel, "opportunities");

        if (this.mapInstanceCreated) {
            this.mapService.markerSelected.subscribe(data => {
                this.openPanel(data);
            });

            // Set the callback for load event
            this.mapService.setOnLoadedCallback(async () => {
                await this.refreshMapView();
            });

            // Set the callback for moveend event
            this.mapService.setOnMoveEndCallback(async () => {
                await this.refreshMapView();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (this.mapService) {
            this.mapService.markerSelected.unsubscribe();
        }

        this.isDestroyMapComponent = true;
        this.mapInstanceCreated = false;
    }

    openPanel(data: any): void {
        if (data.length) {
            this.selectedMarkerData = [];
            this.opportunitiesCounter = data.length;
            for (const element of data) {
                this.selectedMarkerData.push({
                    id: element.id,
                    name: element.name,
                    code: element.code,
                    eventType: OpportunityCalendarEvent.DueDate,
                    eventDate: element.dueDate,
                    opportunityStatusName: element.opportunityStatusName,
                    companyName: element.companyName,
                    opportunityValue: element.potentialValue,
                    opportunityUsers: element.opportunityUsers
                });
            }
        }
    }

    closePanel(): void {
        this.selectedMarkerData = null;
    }

    onGoToClicked(id?: number): void {
        this.router.navigate([`/opportunities-single/${id}/${TabNames[TabNames.Overview]}`]);
    }

    async refreshMapView(): Promise<void> {
        if (this.isDestroyMapComponent) return;

        this.buildSearchConfig();
        await this.awaiter.awaitAction("Loading Opportunities", async () => {
            this.opportunities = await this.opportunityService.searchMap(this.searchConfig);
            this.mapService.fetchData(this.opportunities, "opportunities-data");
        });
    }

    private buildSearchConfig(): void {
        const bounds = this.mapService.getMapBounds();
        if (!bounds) return;

        this.searchConfig = {
            latitudeFrom: bounds.getSouth(),
            latitudeTo: bounds.getNorth(),
            longitudeFrom: bounds.getWest(),
            longitudeTo: bounds.getEast(),
            filterText: this.filters?.searchText ?? undefined,
            opportunityStatusIds: this.filters?.statuses?.length ? this.filters?.statuses.map(s => s.id) : undefined,
            divisionIds: this.filters?.divisions?.length ? this.filters?.divisions.map(d => d.id) : undefined,
            probabilityValueFrom: this.filters?.probabilityFrom,
            probabilityValueTo: this.filters?.probabilityTo,
            onlyFavorites: this.filters?.onlyFavorites,
            companyIds: this.filters?.companies?.length ? this.filters?.companies.map(c => c.id) : undefined,
            salesUserId: this.filters?.salesperson?.externalId,
            dueDateFrom: this.filters?.dateFrom,
            dueDateTo: this.filters?.dateTo,
            potentialValueFrom: this.filters?.potentialValueFrom,
            potentialValueTo: this.filters?.potentialValueTo,
            proposedGrossMarginFrom: this.filters?.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.filters?.proposedGrossMarginTo,
            isActive: this.filters?.activeStatus?.value
        } as SearchOpportunitiesMapParameters;
    }

    private async setInitialCenterCoordinates(): Promise<void> {
        const userOrganization = this.basicUserOrganizationService.getUserOrganization();
        if (userOrganization?.id) {
            const organizationInfo = await this.organizationService.getById(userOrganization.id);
            if (organizationInfo.longitude && organizationInfo.latitude) {
                this.initialCenterCoordinates = [organizationInfo.longitude, organizationInfo.latitude];
            }
        }
    }
}
