import { Component, OnInit } from "@angular/core";
import { MarketView } from "projects/app/src/app/services/http/clients/api-proxies";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-market-editor",
    templateUrl: "./market-editor.component.html",
    styleUrls: ["./market-editor.component.scss"]
})
export class MarketEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    markets: MarketView[];
    selectedMarket?: MarketView;

    constructor(private marketService: MarketService) {
        super();
        this.markets = [];
    }

    async ngOnInit(): Promise<void> {
        this.markets = await this.marketService.getAll();
        this.selectedMarket = this.markets?.find(x => x.id === this.bindingProperty);
    }

    onSelectMarket() {
        this.bindingProperty = this.selectedMarket?.id ?? null;
        this.valueChange.emit();
    }
}
