import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyBuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyBuildingService } from "projects/app/src/app/services/http/clients/company-building.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

interface IModalEditAddressParameters {
    companyBuilding: CompanyBuildingView;
}

interface ILocationDetails {
    line2?: string;
    line1?: string;
    countryName?: string;
    stateName?: string;
    cityName?: string;
    zipCode?: string;
}

@Component({
    selector: "app-modal-edit-address",
    templateUrl: "./modal-edit-address.component.html",
    styleUrls: ["./modal-edit-address.component.scss"]
})
export class ModalEditAddressComponent extends ModalComponentBase<IModalEditAddressParameters, CompanyBuildingView> implements OnInit {
    isLoading: boolean;
    location!: ILocationDetails;

    closeIcon: SafeHtml;

    constructor(
        private readonly companyBuildingService: CompanyBuildingService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.location = {
            line1: this.parameters?.companyBuilding.line1 ?? "",
            line2: this.parameters?.companyBuilding.line2 ?? "",
            countryName: this.parameters?.companyBuilding.countryName ?? "",
            stateName: this.parameters?.companyBuilding.stateName ?? "",
            cityName: this.parameters?.companyBuilding.cityName ?? "",
            zipCode: this.parameters?.companyBuilding.zipCode ?? ""
        };
    }

    updateLine2(line2?: string) {
        this.location.line2 = line2;
    }

    async saveAddress(): Promise<void> {
        await this.awaiter.awaitAction(
            "Saving Address Line 2...",
            async () => {
                const companyBuilding = await this.companyBuildingService.save({
                    ...this.parameters?.companyBuilding,
                    line2: this.location.line2
                } as CompanyBuildingView);

                if (companyBuilding) {
                    this.snackbarNotificationService.success("Address Line 2 updated successfully.");
                    this.close(companyBuilding);
                }
            },
            loading => (this.isLoading = loading)
        );
    }
}
