import { Component, Input } from "@angular/core";

@Component({
    selector: "app-counter",
    templateUrl: "./counter.component.html",
    styleUrls: ["./counter.component.scss"]
})
export class CounterComponent {
    @Input()
    counterLabel: string;

    @Input()
    counter: number;

    constructor() {
        this.counterLabel = "";
        this.counter = 0;
    }
}
