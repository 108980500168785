import { Component, OnInit } from "@angular/core";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { BuildingView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-building-editor",
    templateUrl: "./building-editor.component.html",
    styleUrls: ["./building-editor.component.scss"]
})
export class BuildingEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedBuilding?: BuildingView;

    displaySelectedBuilding: boolean;

    isBuildingOverview?: boolean;

    allowToNavigate?: boolean;

    routeToSingle?: string;

    constructor(private readonly buildingService: BuildingService) {
        super();
        this.displaySelectedBuilding = true;
    }

    async ngOnInit(): Promise<void> {
        this.isBuildingOverview = this.additionalInfo?.isBuildingOverview;
        this.allowToNavigate = this.additionalInfo?.allowToNavigate;
        if (this.bindingProperty) {
            this.selectedBuilding = await this.buildingService.getById(this.bindingProperty);
            if (this.allowToNavigate) this.setRouteToNavigate(this.selectedBuilding);
        }
    }

    onSelectedBuilding(building: BuildingView) {
        this.selectedBuilding = building;
        if (this.selectedBuilding) (this.bindContext as any)["line2"] = building.line2;
        this.bindingProperty = this.selectedBuilding?.id;
        this.validate();
        this.valueChange.emit();
    }

    setRouteToNavigate(building: BuildingView) {
        this.routeToSingle = `buildings-single/${building.id}/${TabNames[TabNames.Overview]}`;
    }
}
