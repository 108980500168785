import { IconSanitizerService } from "./../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
// dashboard.component.ts

import { Component, OnInit } from "@angular/core";
import { AwaiterService } from "../../../services/awaiter.service";
import { OpportunityDashboardService } from "../../../services/http/clients/opportunity-dashboard.service";
import {
    DashboardComparisonOptionView,
    DivisionView,
    GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto,
    SearchDivisionsParameters
} from "../../../services/http/clients/api-proxies";
import { DashboardPeriodOptions } from "../../../models/enums/DashboardPeriodOptions";
import { ISalesFunnelChartConfig } from "../../shared/charts/sales-funnel-chart/sales-funnel-chart.component";
import { Router } from "@angular/router";
import { DivisionService } from "../../../services/http/clients/division.service";
import { IBidVsAwardedChartConfig } from "../../shared/charts/bid-vs-awarded-chart/bid-vs-awarded-chart.component";
import { SafeHtml } from "@angular/platform-browser";
import { BasicUserService, StorageService } from "projects/ngx-lib/src/public-api";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { User } from "../../../models/security/user";
import { SkeletonConfig } from "projects/ngx-lib/src/lib/components/skeleton/skeleton.component";

interface IFilterValueDashboard {
    period?: DashboardComparisonOptionView;
    divisions?: DivisionView[];
}

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
    private currentOrganizationId?: number;
    private currentUserId?: number;

    comparisonOptions?: DashboardComparisonOptionView[];

    divisions?: DivisionView[];

    opportunityStat?: GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto;

    salesFunnelChartConfig?: ISalesFunnelChartConfig;

    bidVsAwardedChartConfig?: IBidVsAwardedChartConfig;

    filters: IFilterValueDashboard;

    infoIcon?: SafeHtml;

    skeleton: boolean;

    rectangleSkeletonConfig: SkeletonConfig = {
        count: 1,
        type: "rectangle",
        hasBox: false,
        width: "100%",
        height: "252px",
        boxStyle: {
            backgroundColor: "#ffffff",
            border: "1px solid #98b7d2",
            borderRadius: "8px",
            padding: "1rem",
            width: "100%",
            height: "252px"
        },
        rectangleStyle: {
            width: "100%",
            height: "100%",
            borderRadius: "8px"
        }
    };

    TextSkeletonConfig: SkeletonConfig = {
        count: 2,
        type: "text",
        hasBox: false,
        width: "50%"
    };

    constructor(
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly opportunityDashboardService: OpportunityDashboardService,
        private readonly divisionService: DivisionService,
        private readonly iconSanitizer: IconSanitizerService,
        private readonly storageService: StorageService,
        private readonly basicUserOrganizationService: BasicUserOrganizationService,
        private readonly basicUserService: BasicUserService
    ) {
        this.divisions = [];
        this.filters = {
            period: undefined,
            divisions: undefined
        };
        this.skeleton = true;
    }

    async ngOnInit(): Promise<void> {
        this.skeleton = true;

        this.infoIcon = this.iconSanitizer.getIcon("info");

        await this.awaiter.awaitAction("Getting Filters Options", async () => {
            this.comparisonOptions = await this.opportunityDashboardService.getComparisonOptions();
            this.divisions = await this.divisionService.search({ isActive: true } as SearchDivisionsParameters);
        });

        this.currentOrganizationId = this.basicUserOrganizationService.getUserOrganization()?.id;

        this.currentUserId = (this.basicUserService.getUser() as User)?.id;

        const filterSaved = this.getDashboardFiltersFromStorage(this.currentUserId, this.currentOrganizationId);

        if (filterSaved?.divisions) {
            const divisions = await this.divisionService.search({} as SearchDivisionsParameters);
            const updatedDivisions = filterSaved.divisions.map(savedDivision => {
                const updatedDivision = divisions.find(division => division.id === savedDivision.id);
                return updatedDivision || savedDivision;
            });
            filterSaved.divisions = updatedDivisions;
        }

        this.saveDashboardFiltersToStorage(this.currentUserId, this.currentOrganizationId, filterSaved);

        this.filters.period = filterSaved?.period ?? this.comparisonOptions?.find(x => x.id === DashboardPeriodOptions.Last365Days);
        this.filters.divisions = filterSaved?.divisions;

        await this.getOpportunityStat(
            this.filters?.period?.id ?? DashboardPeriodOptions.Last365Days,
            this.filters?.divisions?.map(d => d.id)
        );

        this.buildChartsConfig();

        this.skeleton = false;
    }

    async getOpportunityStat(comparisonId: number, divisionIds?: number[]): Promise<GetOpportunityStatsByPeriodResultOpportunityTotalsResultDto> {
        this.opportunityStat = await this.opportunityDashboardService.getOpportunityStats(comparisonId, divisionIds);
        return this.opportunityStat;
    }

    async onFilterChange(): Promise<void> {
        const period = this.filters?.period?.id ?? this.comparisonOptions?.find(x => x.id === DashboardPeriodOptions.Last365Days)?.id;
        const divisionIds = this.filters?.divisions?.map(d => d.id);
        this.saveDashboardFiltersToStorage(this.currentUserId, this.currentOrganizationId, this.filters);

        if (period) {
            await this.getOpportunityStat(period, divisionIds);
            this.buildChartsConfig();
        }
    }

    onCreateNewOpportunityClicked() {
        this.router.navigate([`/opportunities-create`]);
    }

    private buildChartsConfig(): void {
        if (!this.opportunityStat) return;

        this.salesFunnelChartConfig = {
            title: "Sales Funnel",
            labels: {
                current: this.opportunityStat?.dashboardComparisonOptionView?.currentPeriodDescription,
                previous: this.opportunityStat?.dashboardComparisonOptionView?.previousPeriodDescription
            },
            data: [
                {
                    category: "Opportunities",
                    current: this.opportunityStat?.currentResult.opportunitiesAmount,
                    previous: this.opportunityStat?.previousResult.opportunitiesAmount
                },
                { category: "Bid", current: this.opportunityStat.currentResult.bidAmount, previous: this.opportunityStat.previousResult.bidAmount },
                { category: "Awarded", current: this.opportunityStat.currentResult.awardedAmount, previous: this.opportunityStat.previousResult.awardedAmount }
            ],
            descriptionHelpText: `
                ${this.createChartTooltipContent("Opportunities", "Total potential value of opportunities created in the period.")}
                ${this.createChartTooltipContent("Bid", "Total bid amount for estimates bid in the period.")}
                ${this.createChartTooltipContent("Awarded", "Total awarded amount for estimates awarded in the period.")}
            `
        };

        this.bidVsAwardedChartConfig = {
            title: "Bid vs. Awarded",
            data: [
                {
                    period: "Previous",
                    bidTotal: this.opportunityStat.previousResult.totalBidCount,
                    awardedTotal: this.opportunityStat.previousResult.totalAwardedCount,
                    awardedBid: this.opportunityStat.previousResult.awardedBidCount,
                    awardedRate: this.opportunityStat.previousResult.awardedRate
                },
                {
                    period: "Current",
                    bidTotal: this.opportunityStat.currentResult.totalBidCount,
                    awardedTotal: this.opportunityStat.currentResult.totalAwardedCount,
                    awardedBid: this.opportunityStat.currentResult.awardedBidCount,
                    awardedRate: this.opportunityStat.currentResult.awardedRate
                }
            ],
            descriptionHelpText: `
                ${this.createChartTooltipContent("Bid Total", " Number of estimates bid in the period.")}
                ${this.createChartTooltipContent("Awarded Bid", "Number of estimates bid and awarded in the period.")}
                ${this.createChartTooltipContent("Awarded Total", "Number of estimates awarded in the period.")}
                ${this.createChartTooltipContent("Awarded Rate", "Awarded Bid / Bid Total")}
            `
        };
    }

    private createChartTooltipContent(title: string, description: string): string {
        return `
            <span>${title}:</span>
            <span class="tooltip-content">${description}</span><br />
        `;
    }

    private saveDashboardFiltersToStorage(userId?: number, organizationId?: number, filters?: IFilterValueDashboard): void {
        if (!userId || !organizationId || !filters || !Object.keys(filters).length) return;
        const key = `dashboardFilters-user:${userId}-org:${organizationId}`;
        this.storageService.setItem(key, filters);
    }

    private getDashboardFiltersFromStorage(userId?: number, organizationId?: number): IFilterValueDashboard | undefined {
        if (!userId || !organizationId) return;
        const key = `dashboardFilters-user:${userId}-org:${organizationId}`;
        return this.storageService.getItem(key);
    }
}
