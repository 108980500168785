import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { NoteView, SearchNotesParameters, SearchUsersParameters, UserView } from "../../../services/http/clients/api-proxies";
import { NoteService } from "../../../services/http/clients/note.service";
import { ModalEditNoteComponent } from "../modals/modal-edit-note/modal-edit-note.component";
import { ITabPaginationConfig, ModalService } from "projects/ngx-lib/src/public-api";
import { INoteEdit } from "./note-content/note-content.component";
import { AwaiterService } from "../../../services/awaiter.service";
import { UserService } from "../../../services/http/clients/user.service";
import { ITypeAheadService } from "../generic-type-ahead/generic-type-ahead.component";

interface IFilterValueNotes {
    searchText: string;
    user?: UserView;
    noteUser?: UserView;
    dateFrom?: Date;
    dateTo?: Date;
}
@Component({
    selector: "app-notes",
    templateUrl: "./notes.component.html",
    styleUrls: ["./notes.component.scss"]
})
export class NotesComponent implements OnChanges {
    @Input()
    entityTypeId?: number;

    @Input()
    entityId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    notes?: NoteView[];

    currentTab?: string;

    paginationConfig: ITabPaginationConfig;

    searchConfig: SearchNotesParameters;

    filterValue: IFilterValueNotes;

    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;

    constructor(
        private readonly noteService: NoteService,
        private readonly modalService: ModalService,
        private readonly awaiter: AwaiterService,
        private readonly userService: UserService
    ) {
        this.notes = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchNotesParameters;

        this.filterValue = {
            searchText: ""
        };

        this.userServiceFilter = userService;
        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["entityId"] || changes["entityTypeId"]) {
            if (this.entityId) {
                this.refreshTabView();
            }
        }
    }

    async refreshTabView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction("Loading Notes", async () => {
            const response = await this.noteService.search(this.searchConfig);
            const { pageInfo, results } = response;
            this.notes = this.notes?.concat(results ?? []);

            this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
            this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
            this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;

            this.currentTab = this.notes?.length ? this.notes[0].noteKey : "";
        });
    }

    async onFilterChanged(): Promise<void> {
        this.buildFilters();
        this.resetTabList();
        await this.refreshTabView();
    }

    resetTabList(): void {
        this.paginationConfig.currentPage = 1;
        this.notes = [];
    }

    buildSearchConfig(): void {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            filterText: this.filterValue.searchText,
            createdByUserId: this.filterValue.user?.id,
            dateTo: this.filterValue.dateTo,
            dateFrom: this.filterValue.dateFrom,
            noteUsers: this.filterValue.noteUser?.id && [this.filterValue.noteUser?.id],
            entityTypeId: this.entityTypeId,
            entityId: this.entityId
        } as SearchNotesParameters;
    }

    buildFilters(): void {
        this.filterValue = {
            user: this.filterValue?.user,
            dateFrom: this.filterValue.dateFrom,
            dateTo: this.filterValue.dateTo,
            searchText: this.filterValue.searchText,
            noteUser: this.filterValue.noteUser
        };
    }

    async createNote(): Promise<void> {
        if (this.entityId && this.entityTypeId) {
            const note = await this.modalService.open(ModalEditNoteComponent, {
                entityId: this.entityId,
                entityTypeId: this.entityTypeId
            });

            if (note && this.notes) {
                this.tabCounterChange.emit();
                this.notes?.unshift(note);
                this.currentTab = "";
                setTimeout(() => {
                    this.currentTab = note.noteKey;
                }, 1);
            }
        }
    }

    onNoteChanged(note: INoteEdit): void {
        if (this.notes) {
            this.notes?.splice(note.index, 1);
            if (note.editedNote) {
                this.notes?.unshift(note.editedNote);
                this.currentTab = "";
                setTimeout(() => {
                    this.currentTab = this.notes && this.notes[0].noteKey;
                }, 1);
            } else {
                this.tabCounterChange.emit();

                if (this.notes.length > 0) {
                    if (note.index < this.notes.length) {
                        this.currentTab = this.notes[note.index].noteKey;
                    } else {
                        this.currentTab = this.notes[note.index - 1].noteKey;
                    }
                } else {
                    this.currentTab = "";
                }
            }
        }
    }
}
