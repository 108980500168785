import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportingBuildingService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-building.service";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    buildingClassEditorConfig,
    companyPropertyManagerEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    marketsEditorConfig,
    opportunityDueDateEditorConfig,
    opportunitySourceEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    relatedOpportunitiesConditionEditorConfig,
    salesPersonsEditorConfig,
    stateEditorConfig
} from "./shared/additional-filters";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";
import { truncateValue } from "projects/app/src/app/utils/format-functions";

export const Buildings: IReportConfiguration<ReportingBuildingService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ReportingBuildingService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getBuildingBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getBuildingMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [
                        { name: "BuildingSqFt", label: "Building Sq. Ft." },
                        { name: "AwardedAmountPerSqFt", label: "Awarded Amount / Sq. Ft." }
                    ]
                }
            },
            {
                methodName: "getBuildingLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Buildings by Awarded Amount"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "buildingName",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        },
                        {
                            type: "line",
                            xKey: "buildingName",
                            yKey: "avgAwardedAmountPerSqFt",
                            yName: "Awarded Amount / Sq. Ft."
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Amount / Sq. Ft."
                            },
                            keys: ["avgAwardedAmountPerSqFt"]
                        }
                    ]
                }
            },
            {
                methodName: "getBuildingColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        },
                        {
                            type: "line",
                            xKey: "salesPerson",
                            yKey: "avgAwardedAmountPerSqFt",
                            yName: "Awarded Amount / Sq. Ft."
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Amount / Sq. Ft."
                            },
                            keys: ["avgAwardedAmountPerSqFt"]
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.ExtendedTwoPlusOne
    },
    gridTab: {
        widgets: [
            {
                methodName: "getBuildingValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "buildingName", headerName: "Building Name", enableRowGroup: true, lockVisible: true },
                    { field: "alternateName", headerName: "Alternate Name (comma separated)", enableRowGroup: true },
                    { field: "buildingContact", headerName: "Property Manager", enableRowGroup: true },
                    { field: "address", headerName: "Full Address", enableRowGroup: true },
                    { field: "buildingClass", headerName: "Class", enableRowGroup: true },
                    { field: "numberOfFloors", headerName: "Number Of Floors", enableRowGroup: true },
                    { field: "size", headerName: "Building Sq. Ft." },
                    { field: "opportunityQty", headerName: "Opportunities (qty)", aggFunc: "sum" },
                    { field: "salesPersons", headerName: "Salespersons", enableRowGroup: true },
                    { field: "awardedCount", headerName: "Awarded Count", aggFunc: "sum" },
                    { field: "awardedAmount", headerName: "Awarded Amount", type: "currency", aggFunc: "sum" },
                    { field: "awardedAmountPerSqFt", headerName: "Awarded Amount / Sq. Ft." },
                    { field: "organization", headerName: "Organization", enableRowGroup: true },
                    { field: "condition", headerName: "Condition", enableRowGroup: true },
                    { field: "buildingLastUpdatedNote", headerName: "Last Updated Note", tooltipField: "buildingLastUpdatedNote" },
                    { field: "modificationDate", headerName: "Modification Date", type: "date" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            opportunityDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            companyPropertyManagerEditorConfig,
            accountEditorConfig,
            stateEditorConfig,
            buildingClassEditorConfig,
            relatedOpportunitiesConditionEditorConfig,
            conditionEditorConfig,
            salesPersonsEditorConfig
        ]
    }
};
