<app-auth-wrapper [title]="'Welcome, please sign in.'">
    <form #loginForm="ngForm" novalidate>
        <div class="formGroup">
            <div class="label">User</div>
            <input
                type="email"
                id="email"
                name="email"
                #email="ngModel"
                ngModel
                required
                email
                placeholder="example@ats.com"
                class="formControl"
                [ngClass]="{ invalid: loginForm.submitted && email.invalid }"
            />
            <div *ngIf="loginForm.submitted && email.errors" class="error">
                <div *ngIf="email.errors?.['email']">*Email is invalid</div>
                <div *ngIf="email.errors?.['required']">*Email is required</div>
            </div>
        </div>

        <div class="formGroup">
            <div class="label">Password</div>
            <div class="passwordContainer">
                <input
                    type="{{ isPasswordVisible ? 'text' : 'password' }}"
                    id="password"
                    name="password"
                    #password="ngModel"
                    ngModel
                    required
                    placeholder="Enter your password"
                    class="formControl"
                    [ngClass]="{ invalid: loginForm.submitted && password.invalid }"
                />
                <div *ngIf="loginForm.submitted && password.errors" class="error">
                    <div *ngIf="password.errors?.['required']">*Password is required</div>
                </div>

                <button
                    type="button"
                    class="passwordToggle"
                    (click)="togglePasswordVisibility($event)"
                    [ngClass]="{ invalid: loginForm.submitted && password.invalid }"
                >
                    <div *ngIf="!isPasswordVisible" class="icon" [innerHTML]="passwordNotVisibleIcon"></div>
                    <div *ngIf="isPasswordVisible" class="icon" [innerHTML]="passwordVisibleIcon"></div>
                </button>

                <button type="button" class="forgotPasswordButton" (click)="onForgotPassword($event)">Forgot Password?</button>
            </div>
        </div>

        <div class="buttonContainer">
            <button class="primary-btn" (click)="login(loginForm)">Sign in</button>
        </div>
    </form>
</app-auth-wrapper>
