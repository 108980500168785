<div class="container" *ngIf="bindingProperty && fieldName">
    <ng-container *ngFor="let item of bindingProperty; let i = index; trackBy: trackByFn">
        <div class="inputWrapper">
            <input
                type="text"
                [id]="fieldName + i"
                placeholder="Add {{ label }}"
                [(ngModel)]="bindingProperty[i]"
                (ngModelChange)="dataChanged()"
                [name]="fieldName + i"
                [readOnly]="isReadOnly"
                [ngClass]="{ error: errorIndexes.hasOwnProperty(i) }"
                [appTrimSpaces]="additionalInfo?.shouldTrimSpaces"
            />
            <app-validation-error-message [show]="errorIndexes.hasOwnProperty(i)" [errors]="[{ errorMessage: errorIndexes[i] }]"></app-validation-error-message>
            <button *ngIf="!isReadOnly" class="removeIcon resetStyles" (click)="removeField(i)">
                <div class="icon" [innerHTML]="removeFieldIcon"></div>
            </button>
        </div>
    </ng-container>
    <button *ngIf="!isReadOnly" class="action resetStyles" (click)="addNewField()">+ Add {{ label }}</button>
</div>
