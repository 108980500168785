import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig,
    Order
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { BuildingView, SearchBuildingsParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IFilterValueBuildings } from "../buildings-main/buildings-main.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-buildings-list",
    templateUrl: "./buildings-list.component.html",
    styleUrls: ["./buildings-list.component.scss"]
})
export class BuildingsListComponent implements OnChanges, OnInit {
    @Input() filters?: IFilterValueBuildings;

    @Output() sortChanged = new EventEmitter<IGenericGridOrderConfig<BuildingView>>();

    buildings: BuildingView[];
    orderConfig?: IGenericGridOrderConfig<BuildingView>;
    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchBuildingsParameters;

    noResults: boolean;

    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<BuildingView> = {
        base: "/buildings-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly buildingService: BuildingService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly awaiter: AwaiterService
    ) {
        this.buildings = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchBuildingsParameters;
        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnInit(): Promise<void> {
        const params = this.route.snapshot.queryParams;

        const sortBy = params["sortBy"] as string | undefined;
        const sortDirection = params["sortDirection"] as Order | undefined;

        if (sortBy && sortDirection) {
            const newOrderConfig: IGenericGridOrderConfig<BuildingView> = {
                key: this.getKeyFromLabel(sortBy),
                displayName: sortBy,
                order: sortDirection
            };

            await this.onOrderChanged(newOrderConfig);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.buildings = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();
        await this.awaiter.awaitAction(
            "Loading Buildings",
            async () => {
                const response = await this.buildingService.searchBuilding(this.searchConfig);
                const { pageInfo, results } = response;
                this.buildings = this.buildings?.concat(results ?? []);
                this.noResults = this.buildings.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(newOrderConfig: IGenericGridOrderConfig<BuildingView> | undefined): Promise<void> {
        this.orderConfig = newOrderConfig;

        this.searchConfig.sortBy = newOrderConfig?.key;
        this.searchConfig.sortDirection = newOrderConfig?.order;

        if (this.filters) {
            this.filters.sortBy = newOrderConfig?.displayName;
            this.filters.sortDirection = newOrderConfig?.order;
        }

        this.paginationConfig.currentPage = 1;
        this.buildings = [];

        await this.refreshGridView();

        this.sortChanged.emit(this.orderConfig);
    }

    onRowClicked(building: BuildingView) {
        this.router.navigate(["/buildings-single", building.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(building: BuildingView) {
        if (building?.id && building.id > 0) {
            window.open(`/buildings-single/${building.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;

        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.stateId = this.filters?.state?.id;
        this.searchConfig.countryId = this.filters?.country?.id;
        this.searchConfig.cityId = this.filters?.city?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    private getKeyFromLabel(label: string): keyof BuildingView {
        const mapping: Record<string, keyof BuildingView> = {
            Name: "name",
            "Property Manager": "propertyManagerCompanyName",
            Address: "addressLines",
            City: "cityName",
            State: "stateName",
            Country: "countryName",
            Condition: "isActive"
        };

        return mapping[label] ?? "name";
    }
}
