<div class="container">
    <lib-section-header
        title="{{ company?.name }}"
        subTitle="Companies"
        [actionsTemplate]="actionsTemplate"
        iconName="goBack"
        [mainAdditionalTemplate]="statusTemplate"
    >
        <ng-template #statusTemplate>
            <lib-status-indicator *ngIf="!isActive" [iconName]="'archived'" [colorType]="'error'" [text]="'Archived'"></lib-status-indicator>
        </ng-template>

        <ng-template #actionsTemplate>
            <div class="actions">
                <button *ngIf="currentTab === 'Overview'" class="primary-btn" (click)="onEditCompany()">Edit</button>
                <app-kebab-menu>
                    <button role="menuitem" *ngIf="isActive" class="resetStyles" (click)="archiveCompany()">Archive</button>
                    <button role="menuitem" *ngIf="!isActive" class="resetStyles" (click)="restoreCompany()">Restore</button>
                </app-kebab-menu>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" (currentTabChange)="tabChange($event)" alignment="start" separation="wide">
            <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
                <ng-template #overviewTab>
                    <div class="tab">
                        <app-companies-single-overview [company]="company" [isReadOnly]="true"></app-companies-single-overview>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Buildings" [bodyTemplate]="buildingsTab" [headerTemplate]="headerTemplate">
                <ng-template #buildingsTab>
                    <div class="tab">
                        <app-companies-single-buildings
                            [companyId]="company?.id"
                            (companyChange)="handleCompanyChange()"
                            (tabCounterChange)="onTabCountersChange()"
                        ></app-companies-single-buildings>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Contacts" [bodyTemplate]="contactsTab" [headerTemplate]="headerTemplate">
                <ng-template #contactsTab>
                    <div class="tab">
                        <app-companies-single-contacts
                            [companyId]="company?.id"
                            (companyChange)="handleCompanyChange()"
                            (tabCounterChange)="onTabCountersChange()"
                        ></app-companies-single-contacts>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Opportunities" [bodyTemplate]="opportunitiesTab" [headerTemplate]="headerTemplate">
                <ng-template #opportunitiesTab>
                    <div class="tab">
                        <app-opportunities-grid-single [companyId]="companyId"></app-opportunities-grid-single>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Notes" [bodyTemplate]="notesTab" [headerTemplate]="headerTemplate">
                <ng-template #notesTab>
                    <div class="tab">
                        <app-notes [entityTypeId]="entityTypeId" [entityId]="company?.id" (tabCounterChange)="onTabCountersChange()"></app-notes>
                    </div>
                </ng-template>
            </lib-tab-item>

            <ng-template #headerTemplate let-tab="tab">
                <lib-counter-badge [label]="tab.tabName" [count]="tabsCounters[tab.tabName] || 0"></lib-counter-badge>
            </ng-template>
        </lib-tab-view>
    </div>
</div>
