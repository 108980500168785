<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div #typeAheadContainer class="container" tabindex="0" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="activateFilter($event)">
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="filterActive && !!text"
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
        [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
        (overlayOutsideClick)="closePanel()"
        (detach)="closePanel()"
        (backdropClick)="closePanel()"
    >
    <div class="optionsMenu">
        <lib-options-menu
            [source]="source"
            [config]="config"
            [text]="text"
            [noResultsText]="noResultsText"
            [focusList]="focusList"
            [action]="action"
            [(selectedItem)]="selectedItem"
            [(menuOpen)]="opened"
            (runActionEmitter)="onRunAction()"
            (selectedItemChange)="selectItem($event)"
        ></lib-options-menu>
    </div>
    </ng-template>

    <ng-container *ngIf="(filterActive && !selectedItem) || (filterActive && selectedItem && !displaySelected); else placeholderContent">
        <lib-search-input
            #searchInput
            class="select"
            [(text)]="text"
            [removeBorder]="true"
            [placeholderText]="displayPlaceholder"
            [clearValueButton]="true"
            [disabled]="disabled"
            (textChange)="onTextChanged()"
            (keydown)="onKeyDown($event)"
        >
        </lib-search-input>
    </ng-container>

    <ng-template #placeholderContent>
        <div
            class="selectedItem"
            tabindex="0"
            (click)="activateFilter($event)"
            (keydown)="onKeyDownActivateFilter($event)"
            [ngClass]="{ itemDisabled: disabled || isReadOnly, error: error }"
        >
            <ng-container *ngIf="selectedItem && displaySelected; else displayPlaceholderTemplate">
                <ng-container *ngIf="config?.itemTemplate && opened; else displaySelectedItem">
                    <ng-container
                        *ngTemplateOutlet="
                            getTemplate();
                            context: {
                                $implicit: { object: selectedItem, config, inputText: text }
                            }
                        "
                    >
                    </ng-container>
                </ng-container>

                <ng-template #displaySelectedItem>
                    <span *ngIf="config && config.itemDisplayKey">{{ selectedItem[config.itemDisplayKey] }}</span>
                </ng-template>

                <button
                    *ngIf="selectedItem && !isReadOnly"
                    class="clear-filter"
                    (click)="clearFilter()"
                    (keydown)="onKeyDownClearFilter($event)"
                    [innerHTML]="closeIcon"
                    tabindex="0"
                    aria-label="Clear selection"
                ></button>
            </ng-container>

            <ng-template #displayPlaceholderTemplate>
                <button class="select placeholder" (click)="focusInput()">
                    {{ isReadOnly && !selectedItem ? "" : displayPlaceholder }}
                </button>
            </ng-template>
        </div>
    </ng-template>
</div>
