<div class="container">
    <lib-generic-type-ahead-multiselect
        [source]="userItems"
        [selectedItems]="selectedUsers"
        (selectedItemsChange)="onSelectedUserChange($event)"
        [config]="{
            defaultText: 'Add User',
            itemDisplayKey: 'userFullName',
            itemTemplate: userTemplate
        }"
        [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add User'"
        (textChange)="onUserTextChange($event)"
        removeKey="userExternalId"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
    </lib-generic-type-ahead-multiselect>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>

<ng-template #userTemplate let-data>
    <app-custom-menu-item [inputText]="data.inputText" [title]="data.object?.userFullName" [subtitle]="data.object?.userEmail"> </app-custom-menu-item>
</ng-template>
