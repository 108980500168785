import { Injectable } from "@angular/core";
import { 
    ISchedulerDateRangeClient, 
    SchedulerDateRangeClient, 
    SchedulerDateRangeDto
} from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class SchedulerDateRangeService {
    private client: ISchedulerDateRangeClient;
    constructor() {
        this.client = new SchedulerDateRangeClient();
    }

    async getAll(): Promise<SchedulerDateRangeDto[]> {
        return await this.client.schedulerDateRangeGetAll();
    }
}