<div class="title-section">
    <button class="resetStyles" (click)="closeOverlay()">
        <span class="icon backArrow" [innerHTML]="arrowIcon"></span>
    </button>
    <span class="group-title">
        {{ group.name }}
    </span>
</div>
<div class="content">
    <div class="radio-option" *ngFor="let col of cols">
        <input (click)="selectColumn(col)" class="input-option" type="radio" [id]="col.field" name="cols" [value]="col.field" [checked]="isSelectedColumn(col)" />
        <label [for]="col.field" class="custom-label">
            <span class="icon " [innerHTML]="checkMarkIcon"></span>
        </label>
        <label class="option-title" [for]="col.field">{{ col.headerName }}</label>
    </div>
</div>
<div class="buttons-section">
    <button class="resetStyles" (click)="applySelection()">Apply</button>
</div>
