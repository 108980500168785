<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Link Estimate</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>
        <div class="modal-body">
            <p>How would you like to link the estimate?</p>

            <div class="radioGroup" *ngIf="canCreateBlankEstimate">
                <label>
                    <input
                        type="radio"
                        name="estimateType"
                        [(ngModel)]="estimateType"
                        value="blank"
                        checked
                        (click)="removeSelectedTemplate()"
                        (change)="onEstimateTypeChange()"
                    />
                    Create blank estimate
                </label>
                <label>
                    <input type="radio" name="estimateType" [(ngModel)]="estimateType" value="template" (change)="onEstimateTypeChange()" />
                    Create template-based estimate
                </label>
                <label>
                    <input type="radio" name="estimateType" [(ngModel)]="estimateType" value="linkToExisting" (change)="onEstimateTypeChange()" />
                    Link to existing estimate
                </label>
            </div>

            <div [hidden]="canCreateBlankEstimate && estimateType !== 'template'">
                <div class="wrapper">
                    <div class="label">
                        <span>Select Template</span>
                    </div>
                    <div class="editor">
                        <lib-generic-type-ahead-dropdown
                            [(selectedItem)]="selectedTemplate"
                            placeholder="Select"
                            (selectedItemChange)="onTemplateChange($event)"
                            [(text)]="searchText"
                            [source]="source"
                            (textChange)="onTemplateTextChanged()"
                            [config]="{
                                defaultText: 'Select',
                                itemDisplayKey: 'name'
                            }"
                        >
                        </lib-generic-type-ahead-dropdown>
                    </div>
                </div>
            </div>

            <div *ngIf="estimateType === 'linkToExisting'" class="wrapper">
                <div class="label">
                    <span>UUID</span>
                </div>
                <div class="editor">
                    <app-type-ahead-uuids
                        [(selectedUUID)]="selectedTemplateUUID"
                        placeholder="Select UUID"
                        (selectedUUIDChange)="onTemplateUUIDsChange($event)"
                        [displaySelected]="true"
                    >
                    </app-type-ahead-uuids>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)" [disabled]="isLoading">Cancel</button>
            <app-loading-button
                [isLoading]="isLoading"
                [disabled]="disabled"
                (clickEvent)="onConfirm()"
                [text]="estimateType === 'linkToExisting' ? 'Link' : 'Create'"
            ></app-loading-button>
        </div>
    </div>
</div>
