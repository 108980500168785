<div libFocusTrap class="modal-wrapper">
    <div class="modal-content">
        <div class="modal-header" *ngIf="parameters?.title">
            <h2>{{ parameters?.title }}</h2>
            <div class="bar"></div>

            <button class="close-wrapper" tabindex="0" (click)="close(false)" aria-label="Close modal" *ngIf="!parameters?.hideCloseButton">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body" *ngIf="!isContentArray">
            <p class="title" *ngIf="parameters?.bodyTitle" [innerHTML]="parameters?.bodyTitle"></p>
            <p [innerHtml]="parameters?.content ?? 'Cancel'"></p>
        </div>
        <ng-container *ngIf="isContentArray">
            <div class="modal-body" *ngFor="let content of contentAsArray">
                <p [innerHtml]="content"></p>
            </div>
        </ng-container>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(false)" *ngIf="parameters?.cancelCaption" tabindex="0">
                {{ parameters?.cancelCaption }}
            </button>
            <button class="modal-button confirm" #acceptButton (click)="close(true)" tabindex="0" [ngClass]="{ reject: parameters?.isReject === true }">
                {{ parameters?.acceptCaption || "Accept" }}
            </button>
        </div>
    </div>
</div>
