import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { BuildingContactService } from "projects/app/src/app/services/http/clients/building-contact.service";
import { SafeHtml } from "@angular/platform-browser";

interface IModalEditBuildingContactParameters {
    contact: BuildingContactView;
}

@Component({
    selector: "app-modal-edit-building-contact",
    templateUrl: "./modal-edit-building-contact.component.html",
    styleUrls: ["./modal-edit-building-contact.component.scss"]
})
export class ModalEditBuildingContactComponent extends ModalComponentBase<IModalEditBuildingContactParameters, BuildingContactView> implements OnInit {
    protected override parameters!: IModalEditBuildingContactParameters;

    contactFullName!: string;

    contact!: BuildingContactView;

    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    contactInitialState!: BuildingContactView;

    isLoading: boolean;

    closeIcon: SafeHtml;

    constructor(
        private readonly buildingContactService: BuildingContactService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.contactFullName = this.parameters?.contact.contactFullName ?? "";
        this.contact = this.parameters?.contact;
        this.contactInitialState = this.objectExtensionService.clone(this.parameters?.contact) as BuildingContactView;

        this.configItems = [
            {
                label: "Role",
                componentId: EditorType.buildingContactRole,
                fieldName: "buildingContactRoleId",
                bindContext: this.contact
            }
        ];
    }

    async saveContact(): Promise<void> {
        await this.awaiter.awaitAction(
            "Saving Contact...",
            async () => {
                const buildingContactSaved = await this.buildingContactService.save(this.contact);

                if (buildingContactSaved) {
                    this.snackbarNotificationService.success("Contact saved successfully.");
                    this.close(buildingContactSaved);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    onClose() {
        this.contact = this.contactInitialState;
        this.close(this.contactInitialState);
    }
}
