import { Component, OnInit } from "@angular/core";
import { SearchStateParameters, StateView } from "projects/app/src/app/services/http/clients/api-proxies";
import { StateService } from "projects/app/src/app/services/http/clients/state.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-state-editor",
    templateUrl: "./state-editor.component.html",
    styleUrls: ["./state-editor.component.scss"]
})
export class StateEditorComponent extends EditorComponentBase<number | null> implements OnInit {
    searchText: string;
    states: StateView[];
    selectedState?: StateView;

    constructor(private stateService: StateService) {
        super();
        this.searchText = "";
        this.states = [];
    }

    async ngOnInit(): Promise<void> {
        if (this.bindingProperty) {
            this.selectedState = await this.stateService.getById(this.bindingProperty);
        }
    }

    async onStateTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.states = await this.stateService.search({
            filterText: this.searchText
        } as SearchStateParameters);
    }

    onSelectState() {
        this.bindingProperty = this.selectedState?.id ?? null;
        this.valueChange.emit();
    }
}
