import { ModelBase } from "./ModelBase";
import { SchedulerTargetUserView, SearchUserDto } from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";

export class EntityTargetUser extends ModelBase implements SchedulerTargetUserView {
    userId: number;
    firstName: string | undefined;
    lastName: string | undefined;
    fullName: string | undefined;
    isActive: boolean;

    constructor() {
        super();
        this.id = 0;
        this.userId = 0;
        this.firstName = undefined;
        this.lastName = undefined;
        this.fullName = undefined;
        this.isActive = true;
    }

    static fromInterface(dto: SearchUserDto): EntityTargetUser {
        const targetUser = new EntityTargetUser();
        targetUser.mapFromInterface(dto);
        return targetUser;
    }

    static fromUserView(user: SearchUserDto): EntityTargetUser {
        const targetUser = new EntityTargetUser();
        targetUser.userId = user.id;
        targetUser.firstName = user.firstName;
        targetUser.lastName = user.lastName;
        targetUser.fullName = user.fullName;
        targetUser.isActive = user.isActive;
        return targetUser;
    }
}
