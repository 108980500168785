<div class="wrapper">
    <div class="addTeamMember" *ngIf="isActionAllowed">
        <lib-generic-type-ahead-dropdown
            [(selectedItem)]="selectedUser"
            placeholder="Add Team Member"
            (selectedItemChange)="onSelectedUser()"
            [displaySelected]="false"
            [(text)]="usersSearchText"
            [source]="usersSearchResult"
            (textChange)="onUsersTextChanged()"
            [config]="{
                defaultText: 'Add Team Member',
                itemDisplayKey: 'fullName',
                itemTemplate: userTemplate
            }"
        >
        </lib-generic-type-ahead-dropdown>
        <ng-template #userTemplate let-data>
            <div class="userContainer">
                <p class="userName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText: data.inputText"></p>
                <p class="userEmail" [innerHTML]="data.object?.email | highlightText: data.inputText"></p>
            </div>
        </ng-template>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Team Members available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'userFullName', label: 'Name', sortable: true, cellTemplate: nameTemplate },
                { key: 'userRoleDescriptions', label: 'Roles', sortable: true },
                { key: 'email', label: 'Details', sortable: true },
                { key: 'commissionPercentage', label: 'Percentage of Commission', sortable: true, cellTemplate: commissionPercentageTemplate },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [source]="opportunityUsers"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
    >
        <ng-template #nameTemplate let-data>
            <app-user-avatar [users]="[data.object]"></app-user-avatar>
        </ng-template>

        <ng-template #commissionPercentageTemplate let-data>
            {{ data.object.commissionPercentage ? data.object.commissionPercentage + "%" : "" }}
        </ng-template>

        <ng-template #kebabMenu let-data>
            <app-kebab-menu *ngIf="isActionAllowed">
                <button role="menuitem" class="resetStyles" (click)="editUser(data.object)">Edit</button>
                <button role="menuitem" class="resetStyles" (click)="removeUser(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>
    </lib-grid-view>
</ng-template>
