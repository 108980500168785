import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    CompanyView,
    QuickSearchCompaniesParameters,
    SearchAvailableAccountCompaniesParameters,
    SearchAvailableBuildingCompaniesParameters,
    SearchAvailableOpportunityCompaniesParameters
} from "../../../services/http/clients/api-proxies";
import { CompanyService } from "../../../services/http/clients/company.service";
import { ModalAddCompanyComponent } from "../modals/modal-add-company/modal-add-company.component";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ITypeAheadDropdownAction } from "projects/ngx-lib/src/lib/interfaces/type-ahead-dropdown-config.interface";
import { ITypeAheadDropdownEntityParameters } from "../../../interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "../../../models/enums/EntityTypes";
import { BuildingService } from "../../../services/http/clients/building.service";
import { OpportunityCompanyService } from "../../../services/http/clients/opportunity-company.service";

@Component({
    selector: "app-type-ahead-companies",
    templateUrl: "./type-ahead-companies.component.html",
    styleUrls: ["./type-ahead-companies.component.scss"]
})
export class TypeAheadCompaniesComponent implements OnInit {
    @Input()
    selectedCompany?: CompanyView;

    @Input()
    isReadOnly?: boolean;

    @Input()
    editorInfoAllowAction?: boolean;

    @Input()
    isActive?: boolean;

    @Input()
    displaySelected?: boolean;

    @Input()
    searchParameters!: ITypeAheadDropdownEntityParameters;

    @Output()
    selectedCompanyChange = new EventEmitter<CompanyView>();

    source?: CompanyView[];

    searchText: string;

    addCompanyAction!: ITypeAheadDropdownAction;

    constructor(
        private readonly companyService: CompanyService,
        private readonly buildingService: BuildingService,
        private readonly modalService: ModalService,
        private readonly opportunityCompanyService: OpportunityCompanyService
    ) {
        this.searchText = "";
        this.displaySelected = false;
    }

    ngOnInit(): void {
        this.addCompanyAction = {
            includeAction: !!this.editorInfoAllowAction,
            name: "+ Add new company",
            action: () => this.openModal()
        };
    }

    async openModal(): Promise<void> {
        this.selectedCompany = await this.modalService.open(ModalAddCompanyComponent);
        if (this.selectedCompany) {
            this.selectedCompanyChange.emit(this.selectedCompany);
        }
    }

    async onCompanyTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.searchByEntity(this.searchParameters?.entityKeyName);
    }

    onCompanyChange(itemSelected: CompanyView): void {
        this.selectedCompany = itemSelected;
        this.selectedCompanyChange.emit(itemSelected);
    }

    async searchByEntity(entityId?: EntityTypes): Promise<CompanyView[]> {
        const searchConfig = {
            filterText: this.searchText,
            ...this.searchParameters?.entity
        };

        this.source = [];

        switch (entityId) {
            case EntityTypes.Building:
                return await this.buildingService.searchAvailableCompanies(searchConfig as SearchAvailableBuildingCompaniesParameters);
            case EntityTypes.Account:
                return await this.companyService.searchAvailableCompanies(searchConfig as SearchAvailableAccountCompaniesParameters);
            case EntityTypes.Opportunity:
                return await this.opportunityCompanyService.searchAvailable(searchConfig as SearchAvailableOpportunityCompaniesParameters);

            default:
                return await this.companyService.search({
                    filterText: this.searchText,
                    isActive: this.isActive
                } as QuickSearchCompaniesParameters);
        }
    }
}
