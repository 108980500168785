import { ProductTypeView, SearchProductTypesParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IComponentEditorEntity } from "../../entity-interfaces/editor-entity.interface";
import { ProductTypeService } from "projects/app/src/app/services/http/clients/product-type.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

export abstract class ProductTypeEntity<TEntityDestination> implements IComponentEditorEntity<ProductTypeView, TEntityDestination> {
    constructor(private readonly productTypeService: ProductTypeService) {}

    abstract toDestination(entity: ProductTypeView): TEntityDestination;

    abstract toSource(entity: TEntityDestination): ProductTypeView;

    async getSource(entityType: EntityTypes, filterText = ""): Promise<ProductTypeView[]> {
        const productTypes = await this.productTypeService.searchProductTypes({
            filterText,
        } as SearchProductTypesParameters);
        return productTypes ?? [];
    }
}
