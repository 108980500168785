import { Component, OnInit } from "@angular/core";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { CompanyView, ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

interface ContactInfo {
    position: string;
    email: string;
    phone: string;
}

@Component({
    selector: "app-company-main-contact-editor",
    templateUrl: "./company-main-contact-editor.component.html",
    styleUrls: ["./company-main-contact-editor.component.scss"]
})
export class CompanyMainContactEditorComponent extends EditorComponentBase<number | undefined> implements OnInit {
    selectedItem?: ContactView;

    companyId: number | undefined;

    contactInfo: ContactInfo;

    displaySelectedContact: boolean;

    routeToSingle?: string;

    constructor(private readonly contactService: ContactService) {
        super();
        this.displaySelectedContact = true;
        this.contactInfo = {
            position: "-",
            email: "-",
            phone: "-"
        };
    }

    async ngOnInit(): Promise<void> {
        this.companyId = (this.bindContext as CompanyView).id;

        if (this.bindingProperty) {
            this.selectedItem = await this.contactService.getById(this.bindingProperty);
            this.setContactInfo(this.selectedItem);
            this.setRouteToNavigate(this.selectedItem);
        }
    }

    onSelectedContact(contact: ContactView) {
        this.selectedItem = contact;
        this.bindingProperty = this.selectedItem?.id;
        this.setContactInfo(contact);
    }

    setContactInfo(contact: ContactView) {
        const defaultEmptyText = "-";
        this.contactInfo.position = contact.position ?? defaultEmptyText;
        this.contactInfo.email = contact.email?.length ? contact.email[0] : defaultEmptyText;
        this.contactInfo.phone = contact.phone?.length ? contact.phone[0] : defaultEmptyText;
    }

    setRouteToNavigate(contact: ContactView) {
        this.routeToSingle = `contacts-single/${contact.id}/${TabNames[TabNames.Overview]}`;
    }
}
