<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>{{ modalTitle }}</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <div *ngFor="let configItem of configItems">
                <lib-editor-item [configItem]="configItem" [factory]="factory" #editorItem (valueChange)="onEditorsChange()"></lib-editor-item>
            </div>
            <lib-message-box *ngIf="showPrivateReportMessage" [message]="message" [type]="messageType"></lib-message-box>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)">Cancel</button>
            <button
                [disabled]="hasCatalogPermissions && bindContext.isPublic && (!bindContext.organizationIds || !bindContext.organizationIds.length)"
                class="modal-button confirm"
                (click)="saveSettings()"
            >
                Save
            </button>
        </div>
    </div>
</div>
