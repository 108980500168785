import { Injectable } from "@angular/core";
import { NoteClient, NoteViewPaginatedResultDto, INoteClient, SearchNotesParameters, NoteView } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class NoteService {
    private client: INoteClient;
    constructor() {
        this.client = new NoteClient();
    }

    async search(parameters: SearchNotesParameters): Promise<NoteViewPaginatedResultDto> {
        return await this.client.noteSearch(parameters);
    }

    async getById(id: number): Promise<NoteView> {
        return await this.client.noteGetById(id);
    }

    async save(body: NoteView): Promise<NoteView> {
        return await this.client.noteSave(body);
    }

    async delete(id: number): Promise<void> {
        return await this.client.noteDelete(id);
    }
}
