<div class="card">
    <div class="icon" [innerHTML]="icon"></div>
    <div class="value-container">
        <div class="value">
            <ng-container [ngSwitch]="formatType">
                <span *ngSwitchCase="formatTypes.Currency">
                    ${{ numericValue | formatNumberWithSuffix }}
                </span>
                <span *ngSwitchCase="formatTypes.Percentage">
                    {{ numericValue | customPercentage }}
                </span>
                <span *ngSwitchCase="formatTypes.Number">
                    {{ numericValue | number: (numericValue % 1 === 0 ? '1.0-0' : '1.2-2') }}
                </span>
                <span *ngSwitchCase="formatTypes.String">
                    {{ numericValue }}
                </span>
                <span *ngSwitchDefault>
                    {{ numericValue  }}
                </span>
            </ng-container>
        </div>
        <div class="label">
            {{ titleValue }}
        </div>
    </div>
</div>
