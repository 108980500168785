<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-container *ngIf="columnsConfig">
    <div class="grid-wrapper" cdkScrollable [id]="genericGridId | idGenerator: 'ngx-genericGrid-'">
        <div class="grid-container">
            <div class="grid-header">
                <div *ngIf="source && source.length > 0 && config?.allowSelection">
                    <input [disabled]="isReadOnly" type="checkbox" [checked]="isAllSelected" (change)="selectAll()" />
                </div>
                <div *ngFor="let column of columnsConfig; let i = index" (click)="headerClicked(column)" [ngClass]="columnClasses[i]">
                    <div>
                        <label *ngIf="!column.headerTemplate; else headerTemplate">{{ column.label }}</label>
                        <ng-template #headerTemplate>
                            <ng-container *ngTemplateOutlet="column.headerTemplate || null"></ng-container>
                        </ng-template>
                        <div class="order-asc-desc" [ngClass]="getColumnClasses(column)">
                            <div *ngIf="column.sortable" class="icon" [innerHTML]="sortIcon"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-body">
                <ng-container *ngIf="source && source.length > 0; else noRows">
                    <a
                        *ngFor="let item of source; let i = index"
                        [routerLink]="routerLinkConfig ? [routerLinkConfig.base, item[routerLinkConfig.idKey]].concat(routerLinkConfig.extra || []) : null"
                        [attr.href]="routerLinkConfig ? null : '#'"
                        (click)="onRowClicked(item, $event)"
                        (mousedown)="onMouseDown($event, item)"
                        class="grid-body-item"
                    >
                        <div *ngIf="config?.allowSelection">
                            <input [disabled]="isReadOnly" type="checkbox" [checked]="isSelected(item)" (change)="selectItem(item)" />
                        </div>
                        <div *ngFor="let column of columnsConfig; let i = index" [ngClass]="columnClasses[i]">
                            <span *ngIf="hasExternalLink && column.key === 'externalLink'" class="externalLinkIcon" [innerHTML]="externalLinkIcon"></span>
                            <div *ngIf="!column.cellTemplate; else customBlock" class="grid-item">
                                <span
                                    [ngStyle]="{ '-webkit-line-clamp': column.maxLines ? column.maxLines : defaultMaxLines }"
                                    libTooltip
                                    [tooltip]="column.tooltip ? item[column.key] : ''"
                                >
                                    {{ item[column.key] }}
                                </span>
                            </div>
                            <ng-template #customBlock>
                                <div class="grid-item">
                                    <span
                                        [ngStyle]="{ '-webkit-line-clamp': column.maxLines ? column.maxLines : defaultMaxLines }"
                                        libTooltip
                                        [tooltip]="column.tooltip ? item[column.key] : ''"
                                    >
                                        <ng-container *ngTemplateOutlet="getTemplate(column); context: { $implicit: { object: item, column, index: i } }">
                                        </ng-container>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </a>
                </ng-container>

                <ng-template #noRows>
                    <div class="noRows">
                        <div *ngIf="!config?.noRowsTemplate; else noRowsCustomTemplate">No data was retrieved</div>
                    </div>
                </ng-template>
                <ng-template #noRowsCustomTemplate>
                    <ng-container *ngTemplateOutlet="getNoRowTemplate()"></ng-container>
                </ng-template>
            </div>
        </div>
        <div class="scroll-ref" *ngIf="paginationConfig?.autoLoad" libIntersectionListener (intersectionListener)="goNext()"></div>
    </div>

    <div *ngIf="paginationConfig && !paginationConfig.autoLoad" class="pagination">
        <button (click)="goPrevious()" class="next-prev" [ngClass]="{ disabled: !canGoPrevious() }">
            <div class="icon" [innerHTML]="previousIcon"></div>
        </button>
        <ng-container *ngIf="showDotsBefore()">
            <div class="page" (click)="changePage(1)">1</div>
            <div>...</div>
        </ng-container>
        <button
            *ngFor="let item of getPaginationItems()"
            (click)="changePage(item)"
            class="page"
            [ngClass]="{ current: item === paginationConfig.currentPage }"
        >
            {{ item }}
        </button>
        <ng-container *ngIf="showDotsAfter()">
            <div>...</div>
            <div class="page" (click)="changePage(paginationConfig.pagesCount)">{{ paginationConfig.pagesCount }}</div>
        </ng-container>
        <button (click)="goNext()" class="next-prev" [ngClass]="{ disabled: !canGoNext() }">
            <div class="icon" [innerHTML]="nextIcon"></div>
        </button>
    </div>
</ng-container>
