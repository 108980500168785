<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Share report</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <div class="link-container">
                <span class="title-url">{{ shareLInk }}</span>
                <button class="tertiary-btn url-btn" (click)="copyToClipboard()">Copy link</button>
            </div>
        </div>
    </div>
</div>
