import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Company } from "projects/app/src/app/models/Company";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { ModalService } from "projects/ngx-lib/src/lib/services/modal.service";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { CompaniesSingleOverviewComponent } from "../companies-single/companies-single-overview/companies-single-overview.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-companies-edit",
    templateUrl: "./companies-edit.component.html",
    styleUrls: ["./companies-edit.component.scss"]
})
export class CompaniesEditComponent implements OnInit {
    company: Company;

    companyId: number;

    buttonLabel?: string;

    continueButtonLabel?: string;

    title?: string;

    @ViewChild("overview")
    overview?: CompaniesSingleOverviewComponent;

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly companyService: CompanyService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly modalService: ModalService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.company = new Company();
        this.companyId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.buttonLabel = "Create";
        this.continueButtonLabel = "Continue Creation";
        this.title = "Create New Company";
    }

    async ngOnInit(): Promise<void> {
        if (this.companyId) {
            this.buttonLabel = "Save";
            this.continueButtonLabel = "Continue Edition";
            this.title = "Edit Company";

            await this.awaiter.awaitAction("Loading Company", async () => {
                const view = await this.companyService.getById(this.companyId);

                if (view) {
                    this.company = Company.fromInterface(view);
                }
            });
        }
    }

    async saveCompany(): Promise<void> {
        if (!this.overview?.mainInfoCard?.validate()) return;

        await this.awaiter.awaitAction("Saving Company", async () => {
            await this.companyService.save(this.company);
            this.snackbarNotificationService.success("Company saved successfully.");
            this.router.navigate(this.companyId ? [`/companies-single/${this.companyId}/${TabNames[TabNames.Overview]}`] : ["/companies"]);
        });
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Cancel Editing",
            cancelCaption: this.continueButtonLabel,
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Cancel Save Company"
        });

        if (responseOk) {
            this.router.navigate(this.companyId ? [`/companies-single/${this.companyId}/${TabNames[TabNames.Overview]}`] : ["/companies"]);
        }
    }
}
