import {
    BuildingAlternateNameView,
    BuildingBuildingClassView,
    BuildingContactView,
    BuildingProductTypeView,
    BuildingView,
    CompanyBuildingView,
    EntityCustomFieldValueView,
    EntityTypeUserView
} from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";

export class Building extends ModelBase implements BuildingView {
    organizationId: number | undefined;
    code: string | undefined;
    name: string | undefined;
    alternateName: string | undefined;
    website: string | undefined;
    size: number | undefined;
    floorSize: number | undefined;
    isActive: boolean;
    createdByUserId: number | undefined;
    materialId: number | undefined;
    materialName: string | undefined;
    addressId: number;
    line1: string | undefined;
    line2: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    zipCode: string | undefined;
    countryId: number | undefined;
    countryName: string | undefined;
    stateId: number | undefined;
    stateName: string | undefined;
    cityId: number | undefined;
    cityName: string | undefined;
    addressLines: string | undefined;
    fullAddress: string | undefined;
    organizationName: string | undefined;
    propertyManagerCompanyName: string | undefined;
    propertyManagerCompanyId: number | undefined;
    buildingBuildingClasses: BuildingBuildingClassView[] | undefined;
    buildingContacts: BuildingContactView[] | undefined;
    buildingProductTypes: BuildingProductTypeView[] | undefined;
    companyBuildings: CompanyBuildingView[] | undefined;
    customFieldValues: EntityCustomFieldValueView[] | undefined;
    buildingAlternateNames: BuildingAlternateNameView[] | undefined;
    alternateNames: string[] | undefined;
    propertyManagerContactName: string | undefined;
    propertyManagerContactId: number | undefined;
    stateCode: string | undefined;
    countryCode: string | undefined;
    addressType: number | undefined;
    buildingSalespersons: EntityTypeUserView[] | undefined;
    numberOfFloors: number;
    description: string | undefined;

    constructor() {
        super();
        this.organizationId = undefined;
        this.code = "";
        this.name = "";
        this.alternateName = "";
        this.website = "";
        this.size = undefined;
        this.floorSize = undefined;
        this.isActive = true;
        this.createdByUserId = undefined;
        this.materialId = undefined;
        this.materialName = "";
        this.addressId = 0;
        this.line1 = "";
        this.line2 = "";
        this.latitude = undefined;
        this.longitude = undefined;
        this.zipCode = "";
        this.countryId = undefined;
        this.countryName = "";
        this.countryCode = "";
        this.stateId = undefined;
        this.stateName = "";
        this.stateCode = "";
        this.cityId = undefined;
        this.cityName = "";
        this.addressLines = "";
        this.fullAddress = "";
        this.organizationName = "";
        this.propertyManagerCompanyName = "";
        this.propertyManagerCompanyId = undefined;
        this.propertyManagerContactName = "";
        this.propertyManagerContactId = undefined;
        this.buildingBuildingClasses = [];
        this.buildingAlternateNames = [];
        this.alternateNames = [];
        this.buildingContacts = [];
        this.buildingProductTypes = [];
        this.companyBuildings = [];
        this.customFieldValues = [];
        this.addressType = undefined;
        this.buildingSalespersons = undefined;
        this.description = "";
        this.numberOfFloors = 0;
    }

    static fromInterface(dto: BuildingView): Building {
        const contact = new Building();
        contact.mapFromInterface(dto);
        return contact;
    }
}
