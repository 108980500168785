import { Injectable } from "@angular/core";
import {
    CustomReportServiceView,
    SharedReportDto,
    ServiceCostsClient,
    GetServiceCostMetricsResultType,
    GetServiceCostLineChartResultType,
    GetDataFilterValuesParameters,
    GetServiceCostColumnChartResultType,
    BoxResultType,
    GetServiceCostValuesResultType
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceCostsService extends CustomReportServiceBase<ServiceCostsClient, CustomReportServiceView> {
    constructor() {
        super(new ServiceCostsClient());
    }

    async getValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCostValuesResultType[]> {
        return await this.client.serviceCostsGetServiceCostValues(parameters);
    }

    async getBoxesValues(parameters: GetDataFilterValuesParameters): Promise<BoxResultType[]> {
        return await this.client.serviceCostsGetServiceCostBoxes(parameters);
    }

    async getColumnChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCostColumnChartResultType[]> {
        return await this.client.serviceCostsGetServiceCostColumnChartValues(parameters);
    }

    async getMetricsValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCostMetricsResultType> {
        return await this.client.serviceCostsGetServiceCostMetricsValues(parameters);
    }

    async getLineChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceCostLineChartResultType[]> {
        return await this.client.serviceCostsGetServiceCostLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportServiceView): Promise<CustomReportServiceView> {
        if (body.isPublic) return await this.client.serviceCostsSaveCatalogReport(body);
        return await this.client.serviceCostsSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportServiceView> {
        return await this.client.serviceCostsGetCustomReport(id);
    }

    override async shareReport(body: CustomReportServiceView): Promise<SharedReportDto> {
        return await this.client.serviceCostsCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportServiceView> {
        return await this.client.serviceCostsGetSharedReport(id);
    }
}
