import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportingCompanyService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-company.service";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    companyTypeEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    legalStatusEditorConfig,
    marketsEditorConfig,
    opportunityDueDateEditorConfig,
    opportunitySourceEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    relatedOpportunitiesConditionEditorConfig
} from "./shared/additional-filters";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";

export const Companies: IReportConfiguration<ReportingCompanyService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ReportingCompanyService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getCompanyBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getCompanyMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [{ name: "AwardedAmount", label: "Awarded Amount", format: "currency" }]
                }
            },
            {
                methodName: "getCompanyLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Companies by Awarded Amount"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "companyName",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        },
                        {
                            type: "line",
                            xKey: "companyName",
                            yKey: "awardedCount",
                            yName: "Awarded Count"
                        },
                        {
                            type: "line",
                            xKey: "companyName",
                            yKey: "opportunityQty",
                            yName: "Opportunities Count"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Quantity"
                            },
                            keys: ["awardedCount", "opportunityQty"]
                        }
                    ]
                }
            },
            {
                methodName: "getCompanyColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview3
    },
    gridTab: {
        widgets: [
            {
                methodName: "getCompanyValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "companyName", headerName: "Company Name", lockVisible: true },
                    { field: "account", headerName: "Account" },
                    { field: "mainOfficeFullAddress", headerName: "Main Office Full Address", lockVisible: true },
                    { field: "mainContact", headerName: "Main Contact" },
                    { field: "companyType", headerName: "Company Type" },
                    { field: "legalStatus", headerName: "Legal Status" },
                    { field: "market", headerName: "Company Market" },
                    { field: "opportunityQty", headerName: "Opportunities (qty)" },
                    { field: "awardedCount", headerName: "Awarded Count" },
                    { field: "awardedAmount", headerName: "Awarded Amount", type: "currency" },
                    { field: "organization", headerName: "Organization" },
                    { field: "condition", headerName: "Condition" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            opportunityDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            companyTypeEditorConfig,
            accountEditorConfig,
            legalStatusEditorConfig,
            relatedOpportunitiesConditionEditorConfig,
            conditionEditorConfig
        ]
    }
};
