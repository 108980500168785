<div class="wrapper">
    <div class="addBuilding">
        <app-type-ahead-buildings
            [searchParameters]="typeAheadSearchByEntityParameters"
            (selectedBuildingChange)="onSelectedBuilding($event)"
            [isActive]="true"
        ></app-type-ahead-buildings>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Buildings available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'buildingName', label: 'Name', sortable: true, tooltip: true },
                { key: 'addressLines', label: 'Address', sortable: true, tooltip: true },
                { key: 'cityName', label: 'City', sortable: true },
                { key: 'stateName', label: 'State', sortable: true },
                { key: 'countryName', label: 'Country', sortable: true },
                { key: 'isMain', sortable: false, cellTemplate: mainOffice },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [source]="buildings"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="editAddress(data.object)">Edit Address Line 2</button>
                <button *ngIf="!data.object.isMain" role="menuitem" class="resetStyles" (click)="setAsMainOffice(data.object)">Set as Main Office</button>
                <button *ngIf="data.object.isMain" role="menuitem" class="resetStyles" (click)="unsetAsMainOffice(data.object)">Unset as Main Office</button>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>

        <ng-template #mainOffice let-data>
            <lib-badge *ngIf="data.object.isMain" text="Main Office"></lib-badge>
        </ng-template>
    </lib-grid-view>
</ng-template>
