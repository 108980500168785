<div
    #dropdownContainer
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="container"
    [ngClass]="{
        downArrow: !disabled && !menuOpen && !isReadOnly,
        upArrow: !disabled && !selectedItems?.length && menuOpen,
        active: menuOpen,
        multiple: true,
        readonly: isReadOnly,
        error: error
    }"
    (click)="openOptionsMenu()"
    (keydown)="onKeyDown($event)"
    tabindex="0"
>
    <span class="displayText" [ngClass]="{ placeholder: !selectedItems?.length }" *ngIf="!selectedItems?.length && !menuOpen">
        {{ !isReadOnly ? this.config?.defaultText ?? "Select an item" : "" }}
    </span>

    <div class="selectedItem" *ngIf="selectedItems?.length" [ngClass]="{ upArrow: menuOpen, disabled: disabled }">
        <div *ngIf="!config?.selectedItemTemplate && config?.itemDisplayKey" class="select">
            <div class="item" *ngFor="let item of selectedItems">
                <lib-badge [item]="item" [key]="config?.itemDisplayKey" [removeButton]="!isReadOnly" (removeItem)="removeItem($event)"></lib-badge>
            </div>
        </div>
        <div *ngIf="config?.selectedItemTemplate" class="selectTemplate">
            <div *ngFor="let item of selectedItems">
                <ng-container *ngIf="config?.selectedItemTemplate">
                    <ng-container
                        *ngTemplateOutlet="
                            this.config?.selectedItemTemplate ?? null;
                            context: {
                                $implicit: { object: selectedItems, config }
                            }
                        "
                    >
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!isReadOnly && menuOpen">
        <lib-search-input
            #searchInput
            class="select"
            (textChange)="onTextChanged($event)"
            [(text)]="text"
            [searchIcon]="true"
            [removeBorder]="true"
            [clearValueButton]="true"
            [error]="error"
        >
        </lib-search-input>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="menuOpen"
            [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
            [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
            (detach)="closePanel()"
            (overlayOutsideClick)="closePanel()"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayPush]="true"
            [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
            (backdropClick)="closePanel()"
        >
        <div class="optionsMenu">
            <lib-options-menu
                [source]="sourceItems"
                [(selectedItems)]="selectedItems"
                [config]="config"
                [(menuOpen)]="menuOpen"
                [focusList]="focusList"
                [noResultsText]="noResultsText"
                (selectedItemChange)="selectItem($event)"
            ></lib-options-menu>
        </div>
        </ng-template>
    </ng-container>
</div>
