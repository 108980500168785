import { Injectable } from "@angular/core";
import {
    ContactClient,
    ContactView,
    ContactViewPaginatedResultDto,
    IContactClient,
    SearchContactsForBuildingParameters,
    SearchContactsForCompanyParameters,
    QuickSearchContactsParameters,
    SearchContactsParameters,
    BuildingContactViewPaginatedResultDto,
    SearchAvailableContactBuildingsParameters,
    BuildingView
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ContactService {
    private client: IContactClient;
    constructor() {
        this.client = new ContactClient();
    }

    async getById(id: number): Promise<ContactView> {
        return await this.client.contactGetById(id);
    }

    async save(body: ContactView): Promise<ContactView> {
        return await this.client.contactSave(body);
    }

    async searchContacts(body: SearchContactsParameters | undefined): Promise<ContactViewPaginatedResultDto> {
        return await this.client.contactSearch(body);
    }

    async searchContactsForBuilding(body: SearchContactsForBuildingParameters | undefined): Promise<BuildingContactViewPaginatedResultDto> {
        return await this.client.contactSearchForBuilding(body);
    }

    async searchContactsForCompany(body: SearchContactsForCompanyParameters | undefined): Promise<ContactViewPaginatedResultDto> {
        return await this.client.contactSearchForCompany(body);
    }

    async search(body: QuickSearchContactsParameters | undefined): Promise<ContactView[]> {
        return await this.client.contactQuickSearch(body);
    }

    async searchAvailableBuildings(body: SearchAvailableContactBuildingsParameters): Promise<BuildingView[]> {
        return await this.client.contactSearchAvailableBuildings(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.contactActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.contactDeactivate(id);
    }

    async setAsMain(id: number): Promise<void> {
        return await this.client.contactSetAsMain(id);
    }

    async unsetAsMain(id: number): Promise<void> {
        return await this.client.contactUnsetAsMain(id);
    }
}
