import { IconSanitizerService } from "./../../../../../../ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component } from "@angular/core";
import { EntityTypeService } from "../../../services/http/clients/entity-type.service";
import { SearchMainEntitiesParameters, SearchMainEntitiesResultDto } from "../../../services/http/clients/api-proxies";
import { SafeHtml } from "@angular/platform-browser";
import { EntityTypes } from "../../../models/enums/EntityTypes";
import { Router } from "@angular/router";
import { TabNames } from "../../../models/enums/TabNames";

interface IEntity {
    icon: SafeHtml;
    route: string;
}

@Component({
    selector: "app-search-main-entities",
    templateUrl: "./search-main-entities.component.html",
    styleUrls: ["./search-main-entities.component.scss"]
})
export class SearchMainEntitiesComponent {
    selectedEntity?: SearchMainEntitiesResultDto;

    searchResult?: SearchMainEntitiesResultDto[];

    searchText: string;

    entities: { [key: number]: IEntity };

    constructor(
        private readonly entityTypeService: EntityTypeService,
        private readonly iconSanitizer: IconSanitizerService,
        private readonly router: Router
    ) {
        this.searchText = "";

        this.entities = {
            [EntityTypes.Company]: {
                icon: this.iconSanitizer.getIcon("companies"),
                route: "/companies-single"
            },
            [EntityTypes.Contact]: {
                icon: this.iconSanitizer.getIcon("contacts"),
                route: "/contacts-single"
            },
            [EntityTypes.Building]: {
                icon: this.iconSanitizer.getIcon("buildings"),
                route: "/buildings-single"
            },
            [EntityTypes.Opportunity]: {
                icon: this.iconSanitizer.getIcon("opportunities"),
                route: "/opportunities-single"
            },
            [EntityTypes.Account]: {
                icon: this.iconSanitizer.getIcon("accounts"),
                route: "/accounts-single"
            }
        };
    }

    async onEntityTextChanged(): Promise<void> {
        if (!this.searchText) {
            this.searchResult = undefined;
            return;
        }
        this.searchResult = await this.entityTypeService.searchMainEntities({
            filterText: this.searchText
        } as SearchMainEntitiesParameters);
    }

    onSelectedEntity(entity: SearchMainEntitiesResultDto): void {
        this.selectedEntity = entity;
        const route = this.entities[entity.entityTypeId]?.route;
        this.router.navigate([route, entity.entityId, TabNames[TabNames.Overview]], { skipLocationChange: false });
    }
}
