<lib-section-header [title]="'Buildings'" [iconName]="'buildings'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" (click)="onCreateNewBuildingClicked()">+ New Building</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="filters">
    <lib-grid-filters>
        <lib-search-input *libGridFilter [(text)]="filterValue.searchText" [placeholderText]="'Name or Address'" name="search" (textChange)="onFilterChanged()">
        </lib-search-input>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.city"
            placeholder="City"
            (filterChanged)="onFilterChanged()"
            [service]="cityServiceFilter"
            [config]="{
                defaultText: 'City',
                itemDisplayKey: 'name'
            }"
            [filter]="{ countryId: filterValue.country?.id, stateId: filterValue.state?.id }"
        >
        </app-generic-type-ahead>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.state"
            placeholder="State"
            (filterChanged)="onFilterChanged()"
            [service]="stateServiceFilter"
            [config]="{
                defaultText: 'State',
                itemDisplayKey: 'name'
            }"
            [filter]="{ countryId: filterValue.country?.id }"
        >
        </app-generic-type-ahead>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.country"
            placeholder="Country"
            (filterChanged)="onFilterChanged()"
            [service]="countryServiceFilter"
            [config]="{
                defaultText: 'Country',
                itemDisplayKey: 'name'
            }"
        >
        </app-generic-type-ahead>
        <lib-generic-dropdown
            *libGridFilter
            [source]="activeStatuses"
            [(selectedItem)]="filterValue.activeStatus"
            (selectedItemChange)="onFilterChanged()"
            [config]="{
                multiple: false,
                defaultText: 'Select Condition',
                itemDisplayKey: 'name'
            }"
        >
        </lib-generic-dropdown>
    </lib-grid-filters>
</div>

<div class="list">
    <app-buildings-list [filters]="filterValue" (sortChanged)="onSortChangedFromList($event)"></app-buildings-list>
</div>
