import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
    private readonly titleService = inject(Title);

    override updateTitle(snapshot: RouterStateSnapshot) {
        const routeTitle = this.buildTitle(snapshot);
        this.titleService.setTitle(routeTitle ? `${routeTitle}` : "CRM");
    }
}
