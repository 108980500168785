import { Component } from "@angular/core";
import { ReportWidgetComponent } from "../report-widget.component";

@Component({
    selector: "app-grid-widget",
    templateUrl: "./grid-widget.component.html",
    styleUrls: ["./grid-widget.component.scss"]
})
export class GridWidgetComponent<TService, TParameters> extends ReportWidgetComponent<TService, TParameters> {
    gridData: any;

    override setResultData(result: unknown): void {
        this.gridData = result;
    }

    onVisibleColsChange(e: any) {}
}
