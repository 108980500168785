import { Injectable } from "@angular/core";
import { ConfigurationEntry } from "./http/clients/api-proxies";

@Injectable({
    providedIn: "root"
})
export class GlobalConfigurationService {
    private keys: Map<string, string | undefined>;

    constructor() {
        this.keys = new Map();
    }

    async setKeys(keys: ConfigurationEntry[]): Promise<void> {
        this.keys = new Map(keys.map((entry: ConfigurationEntry) => [entry.key as string, entry.value]));
    }

    getKey(key: string): string {
        const result = this.keys.get(key);
        return result ?? "";
    }
}
