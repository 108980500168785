import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingView, CompanyBuildingView, SearchBuildingsForCompanyParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { CompanyBuildingService } from "projects/app/src/app/services/http/clients/company-building.service";

@Component({
    selector: "app-companies-single-buildings",
    templateUrl: "./companies-single-buildings.component.html",
    styleUrls: ["./companies-single-buildings.component.scss"]
})
export class CompaniesSingleBuildingsComponent implements OnChanges {
    @Input()
    companyId?: number;

    @Input()
    mainOfficeId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    buildings: CompanyBuildingView[];

    orderConfig?: IGenericGridOrderConfig<CompanyBuildingView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchBuildingsForCompanyParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    building?: CompanyBuildingView;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly companyService: CompanyService,
        private readonly companyBuildingService: CompanyBuildingService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.buildings = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            companyId: this.companyId
        } as SearchBuildingsForCompanyParameters;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Company
        };

        this.building = undefined;
        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.companyId && changes["companyId"]) {
            this.searchConfig.companyId = this.companyId;
            this.typeAheadSearchByEntityParameters.entity = {
                companyId: this.companyId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Buildings",
            async () => {
                const response = await this.buildingService.searchBuildingsForCompany(this.searchConfig);

                const { pageInfo, results } = response;

                this.buildings = this.buildings?.concat(results ?? []);

                this.noResults = this.buildings.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.buildings = [];

        await this.refreshGridView();
    }

    onRowClicked(building: CompanyBuildingView) {
        this.router.navigate([`/buildings-single/${building.buildingId}`]);
    }

    onMouseWheelClicked(building: CompanyBuildingView) {
        if (building?.buildingId && building.buildingId > 0) {
            window.open(`/buildings-single/${building.buildingId}`, "_blank");
        }
    }

    async setAsMainOffice(companyBuilding: CompanyBuildingView) {
        await this.awaiter.awaitAction(
            "Setting as Main Office",
            async () => {
                if (!companyBuilding) return;

                await this.companyBuildingService.setAsMain({
                    companyId: companyBuilding.companyId,
                    buildingId: companyBuilding.buildingId
                } as CompanyBuildingView);
                companyBuilding.isMain = true;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onSelectedBuilding(building: BuildingView): Promise<void> {
        if (!building || !this.companyId) return;

        try {
            const buildingSaved = await this.companyBuildingService.add({
                companyId: this.companyId,
                buildingId: building.id
            } as CompanyBuildingView);

            if (buildingSaved) {
                this.tabCounterChange.emit();
                this.buildings.unshift(buildingSaved);
                this.noResults = false;
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the building");
        }
    }

    async removeItem(companyBuilding: CompanyBuildingView): Promise<void> {
        const { buildingId } = companyBuilding;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove building",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this building from the building list?",
            title: "Remove building"
        });

        if (!responseOk) return;

        try {
            if (!buildingId) return;
            await this.companyBuildingService.remove({
                id: 0,
                isMain: this.mainOfficeId === buildingId,
                companyId: this.companyId ?? 0,
                buildingId: buildingId
            } as CompanyBuildingView);

            const buildingIndex = this.buildings.findIndex(currentBuilding => currentBuilding.buildingId === buildingId);
            this.buildings.splice(buildingIndex, 1);
            this.tabCounterChange.emit();
            this.noResults = this.buildings.length === 0;
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to delete the building");
        }
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            companyId: this.companyId
        } as SearchBuildingsForCompanyParameters;
    }
}
