<app-generic-overlay
    [overlayOrigin]="overlayOrigin"
    [headerTemplate]="headerTemplate"
    [mainActionable]="mainActionable"
    [actionButtonsRef]="actionButtonsRef"
    (isOverlayOpenChange)="onOverlayChange($event)"
>
    <ng-template #headerTemplate let-close="close">
        <div class="header">
            <button class="resetStyles close-btn" aria-label="Close panel" (click)="close()">
                <div [innerHTML]="closeIcon" class="icon"></div>
            </button>
        </div>
    </ng-template>

    <div class="container">
        <div *ngFor="let configItem of filters">
            <lib-editor-item [configItem]="configItem" [factory]="factory" (valueChange)="onChangeAppliedFilters()" #editorItem></lib-editor-item>
        </div>
        <ng-template #actionButtonsRef let-close="close">
            <div class="action-buttons">
                <button (click)="onClearFilters(); close()" class="tertiary-btn clear-btn" [disabled]="!hasAppliedFilters">Clear All</button>
                <button (click)="close()" class="tertiary-btn apply-btn" [disabled]="!hasAppliedFilters">Apply</button>
            </div>
        </ng-template>
    </div>
</app-generic-overlay>
<div cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
    <ng-template #mainActionable let-openClose="openClose">
        <button (click)="openClose()" [ngClass]="{opened: opened}" class="additional-btn tertiary-btn">
            <span class="icon" [innerHTML]="!opened && hasAppliedFilters ? appliedAdditionalFiltersIcon : additionalFiltersIcon"></span>
            <span>Additional filters</span>
        </button>
    </ng-template>
</div>
