import { Component, Input, OnInit } from "@angular/core";
import { UserOrganizationView } from "../../../services/http/clients/api-proxies";
import { TooltipPosition } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-organization-item",
    templateUrl: "./organization-item.component.html",
    styleUrls: ["./organization-item.component.scss"]
})
export class OrganizationItemComponent implements OnInit {
    @Input()
    organization?: UserOrganizationView;

    @Input()
    orgNameTooltipPosition?: TooltipPosition;

    ngOnInit(): void {
        if (!this.orgNameTooltipPosition) this.orgNameTooltipPosition = TooltipPosition.ABOVE;
    }
}
