<button
    class="select"
    #dropdownButton
    [id]="dropdownButtonId | idGenerator: 'ngx-dropdownButton-'"
    aria-haspopup="true"
    [attr.aria-expanded]="menuOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="toggleDropdown()"
    (keydown)="onKeyDown($event)"
    [disabled]="disabled"
    [ngClass]="{
        downArrow: !disabled && !menuOpen && !isReadOnly,
        upArrow: !disabled && menuOpen,
        disabled: disabled,
        menuOpen: menuOpen,
        placeholder: !selectedItem,
        readonly: isReadOnly,
        error: error
    }"
>
    <span>{{ displayText }}</span>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="menuOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth"
    (detach)="closePanel()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
    (backdropClick)="closePanel()"
    (overlayOutsideClick)="closePanel()"
>
    <div class="optionsMenu">
        <lib-options-menu
            [focusList]="focusList"
            [source]="source"
            [(selectedItem)]="selectedItem"
            [config]="config"
            [noResultsText]="noResultsText"
            (selectedItemChange)="selectItem($event)"
            [(menuOpen)]="menuOpen"
        ></lib-options-menu>
    </div>
</ng-template>
