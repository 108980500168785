import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportingOpportunityService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-opportunity.service";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    competingBidderEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    expectedResponseDateEditorConfig,
    marketsEditorConfig,
    opportunityDueDateEditorConfig,
    opportunitySourceEditorConfig,
    potentialValueEditorConfig,
    probabilityEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig
} from "./shared/additional-filters";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";
import { truncateValue, percentageFormatter } from "projects/app/src/app/utils/format-functions";

export const Opportunities: IReportConfiguration<ReportingOpportunityService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ReportingOpportunityService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getOpportunityBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getOpportunityMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [
                        { name: "PotentialValue", label: "Potential Value ($)", format: "currency" },
                        { name: "ProposedGrossMarginPercentage", label: "Potential GM (%)", format: "customPercent" },
                        { name: "ActualValue", label: "Actual Value ($)", format: "currency" },
                        { name: "ActualGrossMarginPercentage", label: "Actual GM (%)", format: "customPercent" }
                    ]
                }
            },
            {
                methodName: "getOpportunityLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Opportunities by Actual Value"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "opportunityName",
                            yKey: "potentialValue",
                            yName: "Potential Value ($)"
                        },
                        {
                            type: "line",
                            xKey: "opportunityName",
                            yKey: "actualValue",
                            yName: "Actual Value ($)"
                        },
                        {
                            type: "line",
                            xKey: "opportunityName",
                            yKey: "proposedGrossMarginPercentage",
                            yName: "Potential GM (%)"
                        },
                        {
                            type: "line",
                            xKey: "opportunityName",
                            yKey: "actualGrossMarginPercentage",
                            yName: "Actual GM (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["potentialValue", "actualValue"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["proposedGrossMarginPercentage", "actualGrossMarginPercentage"],
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getOpportunityColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "bidAmount",
                            yName: "Bid Amount"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        },
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "opportunityValue",
                            yName: "Opportunity Value"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["bidAmount", "awardedAmount", "opportunityValue"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.ExtendedTwoPlusOne
    },
    gridTab: {
        widgets: [
            {
                methodName: "getOpportunityValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "opportunityCode", headerName: "Opportunity ID", lockVisible: true },
                    { field: "opportunityName", headerName: "Opportunity Name", enableRowGroup: true },
                    { field: "dueDate", headerName: "Due Date", type: "date" },
                    { field: "expectedResponseDate", headerName: "(Expected) Response Date", type: "date" },
                    { field: "customer", headerName: "Customer", enableRowGroup: true },
                    { field: "account", headerName: "Account", enableRowGroup: true },
                    { field: "actualValue", headerName: "Actual Value ($)", type: "currency", aggFunc: "sum" },
                    { field: "actualGRossMarginPercentage", headerName: "Actual GM (%)", type: "customPercentage", aggFunc: "avg" },
                    { field: "division", headerName: "Division", enableRowGroup: true },
                    { field: "salesPerson", headerName: "Salesperson", enableRowGroup: true },
                    { field: "status", headerName: "Status", enableRowGroup: true },
                    { field: "probability", headerName: "Probability", aggFunc: "avg" },
                    { field: "buildingName", headerName: "Building Name", enableRowGroup: true },
                    { field: "potentialValue", headerName: "Potential Value ($)", type: "currency", aggFunc: "sum" },
                    { field: "proposedGrossMarginPercent", headerName: "Potential GM (%)", type: "customPercentage", aggFunc: "avg" },
                    { field: "speculativeValue", headerName: "Speculative Value", type: "currency", aggFunc: "sum" },
                    { field: "totalBuildingSqFt", headerName: "Total Building Sq. Ft." },
                    { field: "sellPriceSqFt", headerName: "Sell Price / Sq. Ft.", aggFunc: "sum" },
                    { field: "market", headerName: "Market" },
                    { field: "leadSource", headerName: "Opportunity Source", enableRowGroup: true },
                    { field: "organization", headerName: "Organization", enableRowGroup: true },
                    { field: "competingBidder", headerName: "Competing Bidder", enableRowGroup: true },
                    { field: "condition", headerName: "Condition", enableRowGroup: true },
                    { field: "opportunityLastUpdatedNote", headerName: "Last Updated Note", tooltipField: "opportunityLastUpdatedNote" },
                    { field: "modificationDate", headerName: "Modification Date", type: "date" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            opportunityDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            accountEditorConfig,
            competingBidderEditorConfig,
            potentialValueEditorConfig,
            probabilityEditorConfig,
            expectedResponseDateEditorConfig,
            conditionEditorConfig
        ]
    }
};
