import { IGetById } from "./../../../../../../ngx-lib/src/lib/services/filter.service";
import { CompanyView, DivisionView, OpportunityStatusView, UserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import {
    ArchiveEntityOptionView,
    QuickSearchCompaniesParameters,
    SearchDivisionsParameters,
    SearchUsersParameters
} from "../../../services/http/clients/api-proxies";
import { FilterService } from "projects/ngx-lib/src/lib/services/filter.service";
import { FilterStateService } from "../../../services/filter-state.service";
import { IMainFiltersOpportunities, FilterValueOpportunities, IAdditionalFiltersOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { Subscription } from "rxjs";
import { ArchiveEntityService } from "../../../services/http/clients/archive-entity.service";
import { Condition } from "projects/app/src/app/models/enums/Condition";
import { ITypeAheadService } from "../generic-type-ahead/generic-type-ahead.component";
import { CompanyService } from "../../../services/http/clients/company.service";
import { UserService } from "../../../services/http/clients/user.service";
import { OpportunityStatusService } from "../../../services/http/clients/opportunity-status.service";
import { DivisionService } from "../../../services/http/clients/division.service";

@Component({
    selector: "app-opportunities-filters",
    templateUrl: "./opportunities-filters.component.html",
    styleUrls: ["./opportunities-filters.component.scss"]
})
export class OpportunitiesFiltersComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    currentTab?: string;

    disabledClearFiltersBtn: boolean;
    activeStatuses?: ArchiveEntityOptionView[];
    companies?: CompanyView[];
    divisions?: DivisionView[];
    statuses?: OpportunityStatusView[];
    companiesServiceFilter: ITypeAheadService<CompanyView, QuickSearchCompaniesParameters>;
    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;

    private filters: FilterValueOpportunities;
    private filterSubscription?: Subscription;

    constructor(
        private readonly filterService: FilterService,
        private readonly filterStateService: FilterStateService<IMainFiltersOpportunities, IAdditionalFiltersOpportunities>,
        private readonly archiveEntityService: ArchiveEntityService,
        private readonly opportunityStatusService: OpportunityStatusService,
        private readonly divisionService: DivisionService,

        private readonly companyService: CompanyService,
        private readonly userService: UserService
    ) {
        this.companiesServiceFilter = companyService;
        this.userServiceFilter = userService;
        this.filters = {} as FilterValueOpportunities;
        this.disabledClearFiltersBtn = false;
    }
    async ngOnInit(): Promise<void> {
        this.statuses = await this.opportunityStatusService.getAll();
        this.divisions = await this.divisionService.search({ isActive: true } as SearchDivisionsParameters);

        const archiveEntity = await this.archiveEntityService.getArchiveOptions();
        this.activeStatuses = archiveEntity;
        await this.deserializeUrlParams();

        this.filters = {
            ...this.filters,
            ...(this.activeStatuses?.length ? { activeStatus: this.activeStatuses.find(c => c.id === Condition.Active) } : {})
        };

        this.filterStateService.updateFilters(this.filters);

        this.filterSubscription = this.filterStateService.stateFilters$.subscribe(async newFilters => {
            if (!newFilters) return;

            this.filters = { ...newFilters };
            await this.updateFilterUrlParams();
            this.disabledClearFiltersBtn = !this.someFilterApplied();
        });
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["currentTab"] && !changes["currentTab"].firstChange && this.currentTab == "Calendar") {
            delete this.filters.dateFrom;
            delete this.filters.dateTo;
            this.filterStateService.updateFilters(this.filters);
        }
    }

    async onClearAllFilters(): Promise<void> {
        this.filterStateService.clearAllFilters();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "sortBy");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "sortDirection");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "searchText");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "onlyFavorites");
        await this.filterService.serializeDate<FilterValueOpportunities>(this.filters, "dateFrom");
        await this.filterService.serializeDate<FilterValueOpportunities>(this.filters, "dateTo");

        await this.filterService.serializeArray<FilterValueOpportunities, ArchiveEntityOptionView>(this.filters, "activeStatus", "id");
        await this.filterService.serializeTypeAhead<FilterValueOpportunities, CompanyView>("companies", this.filters.companies, "id", true);
        await this.filterService.serializeArray<FilterValueOpportunities, DivisionView>(this.filters, "divisions", "id", true);
        await this.filterService.serializeTypeAhead<FilterValueOpportunities, UserView>("salesperson", this.filters.salesperson, "externalId");
        await this.filterService.serializeArray<FilterValueOpportunities, OpportunityStatusView>(this.filters, "statuses", "id", true);
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "probabilityFrom");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "probabilityTo");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "potentialValueFrom");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "potentialValueTo");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "proposedGrossMarginFrom");
        await this.filterService.serialize<FilterValueOpportunities>(this.filters, "proposedGrossMarginTo");
    }

    private async deserializeUrlParams(): Promise<void> {
        this.filterService.deserialize<FilterValueOpportunities>(this.filters, "sortBy");
        this.filterService.deserialize<FilterValueOpportunities>(this.filters, "sortDirection");
        this.filterService.deserialize<FilterValueOpportunities>(this.filters, "searchText");
        this.filterService.deserializeBoolean<FilterValueOpportunities>(this.filters, "onlyFavorites");
        this.filterService.deserializeDate<FilterValueOpportunities>(this.filters, "dateFrom");
        this.filterService.deserializeDate<FilterValueOpportunities>(this.filters, "dateTo");
        this.filterService.deserializeArray<FilterValueOpportunities, ArchiveEntityOptionView>(this.filters, "activeStatus", "id", this.activeStatuses ?? []);

        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "probabilityFrom");
        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "probabilityTo");
        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "potentialValueFrom");
        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "potentialValueTo");
        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "proposedGrossMarginFrom");
        this.filterService.deserializeNumber<FilterValueOpportunities>(this.filters, "proposedGrossMarginTo");
        await this.filterService.deserializeTypeAhead<IAdditionalFiltersOpportunities, CompanyView>(
            this.filters,
            "companies",
            this.companyService as IGetById,
            true
        );
        this.filterService.deserializeArray<IAdditionalFiltersOpportunities, DivisionView>(this.filters, "divisions", "id", this.divisions ?? [], true);
        await this.filterService.deserializeTypeAhead<IAdditionalFiltersOpportunities, UserView>(this.filters, "salesperson", this.userService as IGetById);
        this.filterService.deserializeArray<IAdditionalFiltersOpportunities, OpportunityStatusView>(this.filters, "statuses", "id", this.statuses ?? [], true);
    }

    private someFilterApplied(): boolean {
        return Object.values(this.filters).some(f => (Array.isArray(f) ? f.length : f));
    }
}
