<div class="filters">
    <lib-grid-filters>
        <lib-search-input *libGridFilter [(text)]="filterValue.searchText" (textChange)="onFilterChanged()" [placeholderText]="'Details'"> </lib-search-input>
        <lib-datepicker-dropdown
            *libGridFilter
            defaultTitle="Date"
            [(dateFrom)]="filterValue.dateFrom"
            [(dateTo)]="filterValue.dateTo"
            (filterChanged)="onFilterChanged()"
            [dateRangeEnabled]="true"
            [isReadOnly]="false"
        >
        </lib-datepicker-dropdown>
        <app-generic-type-ahead
            *libGridFilter
            [(selectedItem)]="filterValue.user"
            placeholder="User"
            (filterChanged)="onFilterChanged()"
            [service]="userServiceFilter"
            [config]="{
                defaultText: 'User',
                itemDisplayKey: 'fullName',
                itemTemplate: userTemplate
            }"
        >
        </app-generic-type-ahead>
        <ng-template #userTemplate let-data>
            <div class="userContainer">
                <p class="userCondition" *ngIf="!data.object?.isActive">Inactive</p>
                <p class="userName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText: data.inputText"></p>
                <p class="userEmail" [innerHTML]="data.object?.email | highlightText: data.inputText"></p>
            </div>
        </ng-template>
    </lib-grid-filters>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Activity logs available.'"></lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'date', label: 'Date and time', sortable: true, cellTemplate: cellDate },
                { key: 'userFullName', label: 'User', sortable: true, cellTemplate: cellUser },
                { key: 'message', label: 'Details', sortable: false, tooltip: true }
            ]
        }"
        [source]="activityLogs"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
    >
        <ng-template #cellDate let-data>
            {{ data.object.date | date: "MM/dd/y hh:mm a" }}
        </ng-template>

        <ng-template #cellUser let-data>
            <app-user-avatar
                [users]="[
                    {
                        firstName: data.object.userFirstName,
                        lastName: data.object.userLastName
                    }
                ]"
            ></app-user-avatar>
        </ng-template>
    </lib-grid-view>
</ng-template>
