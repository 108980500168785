import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";

export interface IListElement {
    id: number;
    title?: string;
    subtitle?: string;
    link?: string;
    canRemoveItem: boolean;
    isFavorite: boolean;
}
@Component({
    selector: "app-list-element",
    templateUrl: "./list-element.component.html",
    styleUrls: ["./list-element.component.scss"]
})
export class ListElementComponent {
    @Input()
    itemId?: number;

    @Input()
    title?: string;

    @Input()
    subtitle?: string;

    @Input()
    link?: string;

    @Input()
    canRemoveItem: boolean;

    @Input()
    isFavorite: boolean;

    @Output()
    isFavoriteChange: EventEmitter<boolean>;

    @Output()
    removeElementRequest: EventEmitter<number>;

    trashIcon: SafeHtml;

    constructor(
        private readonly router: Router,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.link = "";
        this.isFavorite = false;
        this.canRemoveItem = false;
        this.isFavoriteChange = new EventEmitter<boolean>();
        this.removeElementRequest = new EventEmitter<number>();
        this.trashIcon = this.iconSanitizer.getIcon("trash");
    }

    navigate(url?: string) {
        this.router.navigate([url]);
    }

    onFavoriteChange(event: boolean) {
        this.isFavoriteChange.emit(event);
    }

    onRemoveElement(event: Event) {
        event.stopPropagation();
        this.removeElementRequest.emit(this.itemId);
    }
}
