import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IMainFiltersOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";
import { ArchiveEntityOptionView } from "projects/app/src/app/services/http/clients/api-proxies";
import { Subscription } from "rxjs";

@Component({
    selector: "app-opportunities-main-filters",
    templateUrl: "./opportunities-main-filters.component.html",
    styleUrls: ["./opportunities-main-filters.component.scss"]
})
export class OpportunitiesMainFiltersComponent implements OnInit, OnDestroy {
    @Input()
    currentTab?: string;

    @Input()
    activeStatuses?: ArchiveEntityOptionView[];

    mainFilters: IMainFiltersOpportunities;
    disabledClearFiltersBtn: boolean;

    private subscription?: Subscription;

    constructor(private readonly filterStateService: FilterStateService<IMainFiltersOpportunities>) {
        this.mainFilters = {};
        this.disabledClearFiltersBtn = false;
    }

    async ngOnInit(): Promise<void> {
        this.subscription = this.filterStateService.stateFilters$.subscribe(async filters => {
            if (!filters) {
                this.mainFilters = {} as IMainFiltersOpportunities;
            } else {
                this.mainFilters = filters as IMainFiltersOpportunities;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    async onFiltersChanged(): Promise<void> {
        this.filterStateService.updateMainFilters(this.mainFilters);
    }
}
