import { IconSanitizerService } from "./../../services/icon-sanitizer.service";
import { Component, Input, OnInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/icons";

@Component({
    selector: "lib-helper-info",
    templateUrl: "./helper-info.component.html",
    styleUrls: ["./helper-info.component.scss"]
})
export class HelperInfoComponent implements OnInit {
    readonly icons = icons;

    @Input()
    message: string;

    @Input()
    helperInfoId?: string;

    svg?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.message = "";
    }

    ngOnInit(): void {
        this.svg = this.iconSanitizer.getIcon("info");
    }
}
