<div class="container">
    <div *ngIf="avatar" class="avatar" [ngClass]="{ small: size === 'small' }">
        <span>{{ avatar }}</span>
    </div>
    <div class="userInfo">
        <h2 *ngIf="heading && showHeading" class="heading">{{ heading }}</h2>
        <div *ngIf="subheading && showSubheading" class="subheading" libTooltip [tooltip]="tooltip">
            <span>{{ subheading }}</span>
        </div>
    </div>
</div>
