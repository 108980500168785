import { IconSanitizerService } from "./../../services/icon-sanitizer.service";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { icons } from "../../assets/icons";
import { SafeHtml } from "@angular/platform-browser";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";

@Component({
    selector: "lib-dropdown-range-values",
    templateUrl: "./dropdown-range-values.component.html",
    styleUrls: ["./dropdown-range-values.component.scss"]
})
export class DropdownRangeValuesComponent implements OnInit, OnChanges {
    protected scrollStrategy: ScrollStrategy;

    @Input()
    placeholder?: string;

    @Input()
    valueFrom?: number;

    @Input()
    valueTo?: number;

    @Input()
    minValue?: number;

    @Input()
    maxValue?: number;

    @Input()
    iconType?: string;

    @Input()
    dropdownRangeValuesId?: string;

    @Output()
    valueFromChange: EventEmitter<number>;

    @Output()
    valueToChange: EventEmitter<number>;

    @Output()
    applyClicked: EventEmitter<{ valueFrom?: number; valueTo?: number }>;

    @Output()
    clearClicked: EventEmitter<void>;

    readonly icons = icons;

    opened: boolean;

    isDisableButton: boolean;

    dropdownPlaceholder?: string;

    defaultPlaceholder: string;

    rangeValuesText?: string;

    fromErrorMessage?: string;

    toErrorMessage?: string;

    svgIcon?: SafeHtml;

    bottomArrowIcon: SafeHtml;

    constructor(
        private overlay: Overlay,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.bottomArrowIcon = this.iconSanitizer.getIcon("bottomArrow");
        this.opened = false;
        this.isDisableButton = false;
        this.defaultPlaceholder = "Set an item";
        this.valueFromChange = new EventEmitter<number>();
        this.valueToChange = new EventEmitter<number>();
        this.applyClicked = new EventEmitter<{ valueFrom?: number; valueTo?: number }>();
        this.clearClicked = new EventEmitter<void>();
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes["valueFrom"] && changes["valueFrom"].currentValue !== changes["valueFrom"].previousValue) ||
            (changes["valueTo"] && changes["valueTo"].currentValue !== changes["valueTo"].previousValue)
        ) {
            this.setDropdownSelectedValuesText();
        }
    }

    ngOnInit(): void {
        this.dropdownPlaceholder = this.placeholder ?? this.defaultPlaceholder;
        if (this.iconType) this.setIcon(this.iconType);
    }

    toggleDropdown(): void {
        this.opened = !this.opened;
    }

    onKeyPress(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === "Escape") {
            this.toggleDropdown();
        }
    }

    closeDropdown() {
        this.opened = false;
    }

    onClear() {
        this.valueFrom = undefined;
        this.valueTo = undefined;
        this.emitValueFromChange(this.valueFrom);
        this.emitValueToChange(this.valueTo);
        this.fromErrorMessage = this.toErrorMessage = "";
        this.isDisableButton = false;
        this.rangeValuesText = this.dropdownPlaceholder;
        this.opened = false;
        this.clearClicked.emit();
    }

    onApply() {
        this.emitValueFromChange(this.valueFrom);
        this.emitValueToChange(this.valueTo);
        this.setDropdownSelectedValuesText();
        this.applyClicked.emit({ valueFrom: this.valueFrom, valueTo: this.valueTo });
        this.opened = false;
    }

    emitValueFromChange(valueFrom?: number) {
        this.valueFromChange.emit(valueFrom);
    }

    emitValueToChange(valueTo?: number) {
        this.valueToChange.emit(valueTo);
    }

    handleFromValueChange(newFromValue: number | undefined): void {
        this.valueFrom = newFromValue;
        this.fromErrorMessage = "";
        this.toErrorMessage = "";
        if (this.valueTo && newFromValue && newFromValue > this.valueTo) {
            this.fromErrorMessage = "From value must be less than or equal to To value";
        }
        this.disableButton();
        this.valueFromChange.emit(this.valueFrom);
    }

    handleToValueChange(newValueTo: number | undefined): void {
        this.valueTo = newValueTo;
        this.fromErrorMessage = "";
        this.toErrorMessage = "";
        if (this.valueFrom && newValueTo && newValueTo < this.valueFrom) {
            this.toErrorMessage = "To value must be greater than or equal to From value";
        }
        this.disableButton();
        this.valueToChange.emit(this.valueTo);
    }

    handleInputError(hasError: boolean) {
        this.disableButton(hasError);
    }

    private disableButton(inputError?: boolean) {
        this.isDisableButton = !!inputError || (!!this.valueFrom && !!this.fromErrorMessage) || (!!this.valueTo && !!this.toErrorMessage);
    }

    private setIcon(icon: string) {
        switch (icon) {
            case "percentage":
                this.svgIcon = this.iconSanitizer.getIcon("percentageInput");
                break;
            case "dollar":
                this.svgIcon = this.iconSanitizer.getIcon("dollarInput");
                break;
            default:
                this.svgIcon = undefined;
        }
    }

    private setDropdownSelectedValuesText() {
        if (this.valueFrom && this.valueTo) this.rangeValuesText = `${this.valueFrom} - ${this.valueTo}`;
        else if (this.valueFrom && !this.valueTo) this.rangeValuesText = `${this.valueFrom}`;
        else if (!this.valueFrom && this.valueTo) this.rangeValuesText = `${this.valueTo}`;
        else this.rangeValuesText = this.dropdownPlaceholder;
    }
}
