import { Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { IMenuGroup } from "projects/ngx-lib/src/lib/components/generic-menu/generic-menu.component";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { BasicUserService, MessageBusService, ModalService, RegistrationToken } from "projects/ngx-lib/src/public-api";
import { LoginMessage } from "./messages/login.message";
import { Messages } from "./messages/messages";
import { User } from "./models/security/user";
import { DomSanitizer } from "@angular/platform-browser";
import { LoadingMessage } from "./messages/loading.message";
import { UserRoles } from "./models/enums/UserRoles";
import { BasicUserOrganizationService } from "projects/ngx-lib/src/lib/services/basic-user-organization.service";
import { IUserOrganization } from "projects/ngx-lib/src/lib/interfaces/user-organization.interface";
import { OrganizationChangedMessage } from "./messages/organization-changed.message";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    private loggedInToken?: RegistrationToken;
    private loadingToken?: RegistrationToken;
    private organizationChangedToken?: RegistrationToken;

    title = "app";

    isLoggedIn: boolean;

    menu: IMenuGroup[];

    user?: User;

    userOrganization?: IUserOrganization;

    logoExpanded?: string;

    logoCollapsed?: string;

    isLoading: boolean;

    fade: boolean;

    loadingCaption?: string;

    currentUrl: string;

    isSidenavOpen: boolean;

    constructor(
        private readonly modalService: ModalService,
        private readonly userService: BasicUserService,
        private readonly messageBusService: MessageBusService,
        private readonly viewContainer: ViewContainerRef,
        private readonly sanitizer: DomSanitizer,
        private readonly userOrganizationService: BasicUserOrganizationService
    ) {
        this.menu = [];
        this.isLoggedIn = false;
        this.modalService.setViewContainer(this.viewContainer);
        this.fade = false;
        this.isLoading = false;
        this.currentUrl = "/dashboard";
        this.isSidenavOpen = false;
    }

    ngOnInit(): void {
        this.loggedInToken = this.messageBusService.register<LoginMessage>(Messages.LoggedIn, () => this.checkLoggedInUser());
        this.loadingToken = this.messageBusService.register<LoadingMessage>(Messages.Loading, message => this.onLoading(message));
        this.organizationChangedToken = this.messageBusService.register<OrganizationChangedMessage>(Messages.OrganizationChanged, message => {
            message.organizationChanged && this.checkLoggedInUser();
        });
        this.checkLoggedInUser();
    }

    ngOnDestroy(): void {
        if (this.loggedInToken) this.messageBusService.unregister(this.loggedInToken);
        if (this.loadingToken) this.messageBusService.unregister(this.loadingToken);
        if (this.organizationChangedToken) this.messageBusService.unregister(this.organizationChangedToken);
    }

    async checkLoggedInUser(): Promise<void> {
        this.logoExpanded = icons.div25whiteHorizontal;
        this.logoCollapsed = icons.div25whiteVertical;
        this.user = this.userService.getUser();
        this.userOrganization = this.userOrganizationService.getUserOrganization();

        this.isLoggedIn = !!this.user;
        this.menu = [
            {
                id: "General",
                name: "",
                isVisible: true,
                menuItems: [
                    {
                        id: "Dashboard",
                        name: "Dashboard",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.dashboard),
                        link: "dashboard",
                        isVisible: true
                    },
                    {
                        id: "Opportunities",
                        name: "Opportunities",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.opportunities),
                        isVisible: true,
                        link: "opportunities"
                    },
                    {
                        id: "Contacts",
                        name: "Contacts",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.contacts),
                        isVisible: true,
                        link: "contacts"
                    },
                    {
                        id: "Buildings",
                        name: "Buildings",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.buildings),
                        isVisible: true,
                        link: "buildings"
                    },
                    {
                        id: "Companies",
                        name: "Companies",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.companies),
                        isVisible: true,
                        link: "companies"
                    },
                    {
                        id: "Reports",
                        name: "Reports",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.reports),
                        isVisible: true,
                        link: "reports"
                    }
                ]
            },
            {
                id: "Footer",
                name: "Footer",
                isFooter: true,
                isVisible: this.userOrganization?.roleId ? this.userOrganization?.roleId >= UserRoles.OrganizationAdmin : false,
                menuItems: [
                    {
                        id: "Configuration",
                        name: "Configuration",
                        icon: this.sanitizer.bypassSecurityTrustHtml(icons.configuration),
                        isVisible: true,
                        link: "configuration"
                    }
                ]
            }
        ];
    }

    onSidenavToggle(isExpanded: boolean): void {
        this.isSidenavOpen = isExpanded;
    }

    private onLoading(message: LoadingMessage): void {
        this.fade = message.loading;
        setTimeout(
            () => {
                this.isLoading = message.loading;
                this.loadingCaption = message.message;
            },
            message.loading ? 0 : 350
        );
    }
}
