import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { EditorComponentBase } from "../../classes/editor-component-base";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { IGenericTypeAheadDropdownConfig } from "../../interfaces/type-ahead-dropdown-config.interface";
@Component({
    selector: "lib-generic-dropdown",
    templateUrl: "./generic-dropdown.component.html",
    styleUrls: ["./generic-dropdown.component.scss"]
})
export class GenericDropdownComponent<T> extends EditorComponentBase<T> implements OnInit, OnChanges {
    protected scrollStrategy: ScrollStrategy;

    @ViewChild("dropdownButton", { static: true }) dropdownButton!: ElementRef<HTMLButtonElement>;

    @Input()
    source?: T[];

    @Input()
    config?: IGenericTypeAheadDropdownConfig<T>;

    @Input()
    disabled: boolean;

    @Input()
    selectedItem?: T;

    @Output()
    selectedItemChange = new EventEmitter<T>();

    @Input()
    override isReadOnly?: boolean;

    @Input()
    noResultsText?: string;

    @Input()
    placeholder?: string;

    @Input()
    error?: boolean;

    menuOpen: boolean;

    displayText: string;

    focusList?: boolean;

    constructor(private readonly overlay: Overlay) {
        super();
        this.menuOpen = false;
        this.disabled = false;
        this.noResultsText = "No matches found";
        this.error = false;
        this.displayText = "";
        this.scrollStrategy = this.overlay.scrollStrategies.close();
        this.source = [];
        this.focusList = false;
    }

    ngOnInit(): void {
        this.config = {
            ...this.config,
            clearSelection: this.config?.clearSelection ?? true
        } as IGenericTypeAheadDropdownConfig<T>;
        this.updateDisplayText();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItem"]) {
            this.selectedItem = changes["selectedItem"]?.currentValue;
            this.updateDisplayText();
        }

        if (changes["source"]?.currentValue && !changes["source"].firstChange) {
            this.source = changes["source"].currentValue;
        }
    }

    selectItem(item: T): void {
        this.selectedItem = item;
        this.emitItemChanged(item);
        this.updateDisplayText();
        this.closePanel();
        this.dropdownButton.nativeElement.focus();
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.source && this.source.length > 0 && this.menuOpen) {
            if (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === "Enter") {
                event.preventDefault();
                this.focusList = true;
            } else if (event.key === "Escape") {
                this.closePanel();
            } else if (event.key === "Tab") {
                this.closePanel();
            }
        }
    }

    emitItemChanged(item?: T): void {
        this.selectedItemChange.emit(item);
    }

    toggleDropdown(): void {
        if (this.isReadOnly) return;
        this.menuOpen = !this.menuOpen;
    }

    closePanel() {
        this.menuOpen = false;
        this.focusList = false;
    }

    private updateDisplayText(): void {
        if (this.selectedItem && this.config && this.selectedItem[this.config.itemDisplayKey] !== "") {
            this.displayText = this.selectedItem[this.config.itemDisplayKey] as unknown as string;
        } else if (!this.selectedItem && this.config && !this.isReadOnly) {
            this.displayText = this.config?.defaultText ?? "Select an item";
        } else if (this.isReadOnly) {
            this.displayText = "";
        }
    }
}
