/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { IAdditionalFiltersApplied, IAdditionalFiltersOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-opportunities-additional-filters-applied",
    templateUrl: "./opportunities-additional-filters-applied.component.html",
    styleUrls: ["./opportunities-additional-filters-applied.component.scss"]
})
export class OpportunitiesAdditionalFiltersAppliedComponent implements OnInit, OnDestroy {
    additionalFiltersApplied: IAdditionalFiltersApplied[] = [];

    private subscription?: Subscription;

    private readonly mainFilterKeys = ["searchText", "onlyFavorites", "dateFrom", "dateTo", "activeStatus", "sortBy", "sortDirection"];

    constructor(private readonly filterStateService: FilterStateService<any, IAdditionalFiltersOpportunities>) {}

    ngOnInit(): void {
        this.subscription = this.filterStateService.stateFilters$.subscribe(filters => {
            if (!filters) {
                this.additionalFiltersApplied = [];
                return;
            }
            const additionalFilters: IAdditionalFiltersOpportunities = {} as IAdditionalFiltersOpportunities;
            Object.keys(filters).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(filters, key) && !this.mainFilterKeys.includes(key)) {
                    (additionalFilters as any)[key] = (filters as any)[key];
                }
            });
            this.buildFiltersApplied(additionalFilters);
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    removeItem(filter: IAdditionalFiltersApplied): void {
        const currentMerged = this.filterStateService.current || {};
        const updatedAdditionalFilters: IAdditionalFiltersOpportunities = {} as IAdditionalFiltersOpportunities;

        const rangeMapping: { [baseKey: string]: string[] } = {
            proposedGrossMargin: ["proposedGrossMarginFrom", "proposedGrossMarginTo"],
            probability: ["probabilityFrom", "probabilityTo"],
            potentialValue: ["potentialValueFrom", "potentialValueTo"]
        };

        if (rangeMapping[filter.key]) {
            Object.keys(currentMerged).forEach(key => {
                if (!this.mainFilterKeys.includes(key)) {
                    if (rangeMapping[filter.key].includes(key)) {
                        (updatedAdditionalFilters as any)[key] = undefined;
                    } else {
                        (updatedAdditionalFilters as any)[key] = (currentMerged as any)[key];
                    }
                }
            });
        } else {
            Object.keys(currentMerged).forEach(key => {
                if (!this.mainFilterKeys.includes(key)) {
                    const value = (currentMerged as any)[key];
                    if (Array.isArray(value)) {
                        let removed = false;
                        const updatedArray = value
                            .map((item: any) => {
                                const itemValue = typeof item === "object" && item.name ? String(item.name) : String(item);
                                if (!removed && itemValue === filter.value) {
                                    removed = true;
                                    return undefined;
                                }
                                return item;
                            })
                            .filter(item => item !== undefined);
                        (updatedAdditionalFilters as any)[key] = updatedArray.length ? updatedArray : undefined;
                    } else if (value && typeof value === "object") {
                        const objValue = value.fullName ? String(value.fullName) : String(value);
                        (updatedAdditionalFilters as any)[key] = objValue === filter.value ? undefined : value;
                    } else {
                        (updatedAdditionalFilters as any)[key] = String(value) === filter.value ? undefined : value;
                    }
                }
            });
        }

        this.filterStateService.updateAdditionalFilters({ ...updatedAdditionalFilters });
    }

    private buildFiltersApplied(additionalFilters: IAdditionalFiltersOpportunities): void {
        this.additionalFiltersApplied = [];
        const additionalFiltersArray = Object.entries(additionalFilters ?? {})
            .map(([key, value]) => ({ key, value }))
            .filter(af => af.value !== undefined && af.value !== null && (Array.isArray(af.value) ? af.value.length > 0 : String(af.value).trim() !== ""));

        if (additionalFiltersArray.length) {
            const filtersAppliedRange = additionalFiltersArray.filter(af => af.key.toLowerCase().includes("from") || af.key.toLowerCase().includes("to"));
            if (filtersAppliedRange.length) {
                this.buildFiltersAppliedRange(filtersAppliedRange);
            }

            const filtersAppliedNoRange = additionalFiltersArray.filter(af => !af.key.toLowerCase().includes("from") && !af.key.toLowerCase().includes("to"));
            if (filtersAppliedNoRange.length) {
                this.buildFiltersAppliedNoRange(filtersAppliedNoRange);
            }
        }
    }

    private buildFiltersAppliedRange(rangeFiltersArray: { key: string; value: any }[]): void {
        if (!rangeFiltersArray.length) return;

        const probabilityFilters = rangeFiltersArray.filter(af => af.key.includes("probability"));
        if (probabilityFilters.length) {
            const probabilityRange = this.rangeFilterApplied(probabilityFilters, "Probability", 7);
            if (probabilityRange) this.additionalFiltersApplied.push(probabilityRange);
        }

        const potentialValueFilters = rangeFiltersArray.filter(af => af.key.includes("potentialValue"));
        if (potentialValueFilters.length) {
            const potentialValueRange = this.rangeFilterApplied(potentialValueFilters, "Potential Value ($)", 2, "potentialValue");
            if (potentialValueRange) this.additionalFiltersApplied.push(potentialValueRange);
        }

        const proposedGMFilters = rangeFiltersArray.filter(af => af.key.includes("proposedGrossMargin"));
        if (proposedGMFilters.length) {
            const proposedGMRange = this.rangeFilterApplied(proposedGMFilters, "Potential GM (%)", 3, "proposedGrossMargin");
            if (proposedGMRange) this.additionalFiltersApplied.push(proposedGMRange);
        }
    }

    private buildFiltersAppliedNoRange(noRangeFiltersArray: { key: string; value: any }[]): void {
        if (!noRangeFiltersArray.length) return;

        for (const filter of noRangeFiltersArray) {
            const { key, value } = filter;
            if (key === "companies" && Array.isArray(value) && value.length) {
                for (const item of value) {
                    const companyApplied = this.getFilterApplied(key, item, "name", "Customer", 1);
                    this.additionalFiltersApplied.push(companyApplied);
                }
            } else if (key === "salesperson") {
                const salesPersonApplied = this.getFilterApplied(key, value, "fullName", "Salesperson", 5);
                this.additionalFiltersApplied.push(salesPersonApplied);
            } else if (key === "divisions" || key === "statuses") {
                const order = key === "divisions" ? 4 : 6;
                const label = key === "divisions" ? "Division" : "Status";
                if (Array.isArray(value)) {
                    for (const item of value) {
                        const f = this.getFilterApplied(key, item, "name", label, order);
                        this.additionalFiltersApplied.push(f);
                    }
                } else {
                    const f = this.getFilterApplied(key, value, "name", label, order);
                    this.additionalFiltersApplied.push(f);
                }
            } else if (key === "activeStatus" && value && value.name) {
                this.additionalFiltersApplied.push({ key, name: "Condition", value: value.name, order: 8 });
            } else {
                this.additionalFiltersApplied.push({
                    key,
                    name: this.formatKey(key),
                    value: String(value),
                    order: this.additionalFiltersApplied.length + 1
                });
            }
        }
    }

    private getFilterApplied(key: string, value: any, propertyAsValue: string, label: string, order: number): IAdditionalFiltersApplied {
        return { key, name: this.formatKey(label), value: value[propertyAsValue], order };
    }

    private formatKey(key: string): string {
        const withSpaces = key.replace(/([A-Z])/g, " $1").trim();
        return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
    }

    private rangeFilterApplied(list: { key: string; value: any }[], label: string, order: number, keyProp?: string): IAdditionalFiltersApplied | undefined {
        if (list.length === 1) {
            const item = list[0];
            return {
                key: item.key,
                name: label,
                value: `${item.key.substring(keyProp ? keyProp.length : label.length)} ${item.value}`,
                order: order
            };
        } else if (list.length === 2) {
            const rangeValues = list.map(item => item.value);
            return {
                key: keyProp ?? label.toLowerCase(),
                name: label,
                value: `${rangeValues[0]} - ${rangeValues[1]}`,
                order: order
            };
        }
        return undefined;
    }
}
