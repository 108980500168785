import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { formatCurrency } from "@angular/common";
import { AgChartOptions, AgChartLabelFormatterParams, AgCartesianSeriesTooltipRendererParams } from "ag-charts-community";
import { ChartService } from "projects/app/src/app/services/chart.service";
import { FormatNumberWithSuffixPipe } from "projects/ngx-lib/src/lib/pipes/format-number-with-suffix.pipe";

export interface ISalesFunnelChartConfig {
    title?: string;
    labels?: {
        current?: string;
        previous?: string;
    };
    data: ISalesFunnelChartData[];
}
export interface ISalesFunnelChartData {
    category: string;
    current: number;
    previous: number;
}

@Component({
    selector: "app-sales-funnel-chart",
    templateUrl: "./sales-funnel-chart.component.html",
    styleUrls: ["./sales-funnel-chart.component.scss"]
})
export class SalesFunnelChartComponent implements OnChanges {
    chartOptions!: AgChartOptions;

    @Input()
    config?: ISalesFunnelChartConfig;

    constructor(
        private readonly chartService: ChartService,
        private formatNumberWithSuffixPipe: FormatNumberWithSuffixPipe
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.config && !changes["config"].firstChange) {
            this.buildChart();
        }
    }

    commonConfig = {
        label: {
            formatter: ({ value }: AgChartLabelFormatterParams<number>) => {
                return value > 0 ? this.formatNumberWithSuffixPipe.transform(value) : "";
            }
        },
        tooltip: {
            renderer: this.renderer
        }
    };

    private buildChart(): void {
        if (!this.config) return;

        this.chartOptions = {
            theme: this.chartService.getTheme(),
            height: 250,
            legend: {
                toggleSeries: false
            },
            title: {
                text: this.config.title
            },
            contextMenu: {
                enabled: true
            },
            data: this.config.data,
            series: [
                {
                    type: "bar",
                    xKey: "category",
                    yKey: "previous",
                    yName: this.config.labels?.previous,
                    ...this.commonConfig
                },
                {
                    type: "bar",
                    xKey: "category",
                    yKey: "current",
                    yName: this.config.labels?.current,
                    ...this.commonConfig
                }
            ],
            axes: [
                {
                    position: "left",
                    title: {
                        text: "Amount"
                    },
                    type: "number",
                    label: {
                        formatter: ({ value }) => {
                            return this.formatNumberWithSuffixPipe.transform(value);
                        }
                    }
                },
                {
                    position: "bottom",
                    type: "category"
                }
            ]
        };
    }

    private renderer({ datum, xKey, yKey }: AgCartesianSeriesTooltipRendererParams) {
        return {
            title: datum[xKey],
            content: formatCurrency(Number(datum[yKey]), "en-US", "$")
        };
    }
}
