import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "lib-section-sub-header",
    templateUrl: "./section-sub-header.component.html",
    styleUrls: ["./section-sub-header.component.scss"]
})
export class SectionSubHeaderComponent implements OnInit {
    @Input()
    title?: string;

    @Input()
    subTitle?: string;

    @Input()
    iconName!: string;

    @Input()
    info!: TemplateRef<unknown>;

    @Input()
    selected?: boolean;

    @Input()
    sectionSubHeaderId?: string;

    @Output()
    subHeaderClicked: EventEmitter<void>;

    svg?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.selected = false;
        this.subHeaderClicked = new EventEmitter<void>();
    }

    ngOnInit(): void {
        this.svg = this.iconSanitizer.getIcon(this.iconName);
    }

    onClick() {
        this.subHeaderClicked.emit();
    }
}
