import { Directive, HostListener, ElementRef, Input, OnInit, Inject } from "@angular/core";
import { formatCurrency, formatNumber } from "@angular/common";
import { NgControl } from "@angular/forms";
import { LOCALE_ID } from "@angular/core";

@Directive({
    selector: "[libInputFormatter]"
})
export class InputFormatterDirective implements OnInit {
    format = "N0";
    digitsInfo = "1.0-0";
    @Input() currency = "$";
    @Input() prefix = "";
    @Input() suffix = "";
    @Input() decimalCharacter = null;
    @Input("libInputFormatter") set _(value: string) {
        this.format = value;
        if (this.format == "N2") this.digitsInfo = "1.2-2";

        const parts = value.split(":");
        if (parts.length > 1) {
            this.format = parts[0];
            this.digitsInfo = parts[1];
        }
    }

    @HostListener("blur", ["$event.target"]) blur(target: any) {
        if (target.value) target.value = this.parse(target.value);
    }

    @HostListener("focus", ["$event.target"]) focus(target: any) {
        if (target.value) target.value = this.control.value;
    }

    @HostListener("input", ["$event.target"]) onInput(target: any) {
        if (target.value) {
            // Allow only numbers and decimals
            const sanitizedInput = target.value.replace(/[^0-9.]/g, "");
            target.value = sanitizedInput;
            this.control.control?.setValue(sanitizedInput);
        }
    }

    ngOnInit() {
        setTimeout(() => {
            if (this.el.nativeElement.value) this.el.nativeElement.value = this.parse(this.el.nativeElement.value);
        });
    }
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private el: ElementRef,
        private control: NgControl
    ) {}

    /**
     * ### Standard Numeric Format Strings
     * Formats the input value based on the specified format and adds a suffix if provided
     * Examples of formats:
     * 'C2' - Currency with two decimal places (e.g., 1234.56 => '$1,234.56')
     * 'N2' - Numeric with two decimal places (e.g., 1234.56 => '1,234.56')
     * 'N0' - Numeric with no decimal places (e.g., 1234 => '1,234')
     * 'NX' - Custom numeric format (e.g., 123456 => '123,456') depending on digitsInfo
     * Adds a suffix to the value if provided (e.g., 100 => '100.00%' if suffix = '%')
     * Adds a prefix to the value if provided (e.g., 100 => '@100.00' if prefix = '@')
     * Replaces the decimal separator (e.g., 1234.56 => '1.234,56' if decimalCharacter = ',')
     */
    parse(value: any) {
        let newValue = value;

        if (this.format == "C2") newValue = formatCurrency(value, this.locale, this.currency);
        if (this.format == "N2") newValue = formatNumber(value, this.locale, this.digitsInfo);
        if (this.format == "N0") newValue = formatNumber(value, this.locale, this.digitsInfo);
        if (this.format == "NX") newValue = formatNumber(value, this.locale, this.digitsInfo);
        if (this.decimalCharacter) return newValue.replace(".", "x").replace(",", ".").replace("x", ",") + this.suffix;

        return this.prefix + newValue + this.suffix;
    }
}
