import { ModelBase } from "./ModelBase";
import { EntityTypeUserView, UserView } from "projects/app/src/app/services/http/clients/api-proxies";

export class EntityUser extends ModelBase implements EntityTypeUserView {
    userId: number;
    userFirstName: string | undefined;
    userLastName: string | undefined;
    userEmail: string | undefined;
    userExternalId: number;
    userFullName: string | undefined;

    constructor() {
        super();
        this.id = 0;
        this.userId = 0;
        this.userFirstName = undefined;
        this.userLastName = undefined;
        this.userEmail = undefined;
        this.userExternalId = 0;
        this.userFullName = undefined;
    }

    static fromInterface(dto: EntityTypeUserView): EntityUser {
        const companyUser = new EntityUser();
        companyUser.mapFromInterface(dto);
        return companyUser;
    }

    static fromUserView(userView: UserView): EntityUser {
        const companyUser = new EntityUser();
        companyUser.userId = userView.id;
        companyUser.userFirstName = userView.firstName;
        companyUser.userLastName = userView.lastName;
        companyUser.userEmail = userView.email;
        companyUser.userFullName = userView.fullName;
        companyUser.userExternalId = userView.externalId ?? 0;
        return companyUser;
    }
}
