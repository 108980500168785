import { Injectable } from "@angular/core";
import {
    BoxResultType,
    CustomReportServiceView,
    GetDataFilterValuesParameters,
    SharedReportDto,
    ServicesTermsClient,
    GetServiceTermsValuesResultType,
    GetServiceTermsMetricsResultTypeGetServiceTermsTotalizedValuesDetailsAndTotalizedResultDto,
    GetServiceTermsLineChartResultType,
    GetServiceTermsColumnChartResultType
} from "./reporting-api-proxies";
import { CustomReportServiceBase } from "./custom-report/custom-report-base.service";

@Injectable({
    providedIn: "root"
})
export class ServiceTermsService extends CustomReportServiceBase<ServicesTermsClient, CustomReportServiceView> {
    constructor() {
        super(new ServicesTermsClient());
    }

    async getValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceTermsValuesResultType[]> {
        return await this.client.servicesTermsGetServiceTermsValues(parameters);
    }

    async getBoxesValues(parameters: GetDataFilterValuesParameters): Promise<BoxResultType[]> {
        return await this.client.servicesTermsGetServiceTermsBoxes(parameters);
    }

    async getColumnChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceTermsColumnChartResultType[]> {
        return await this.client.servicesTermsGetServiceTermsColumnChartValues(parameters);
    }

    async getMetricsValues(parameters: GetDataFilterValuesParameters): Promise<Record<string, number>> {
        const data = await this.client.servicesTermsGetServiceTermsMetrics(parameters);
        return this.transformMetricsData(data);
    }

    async getLineChartValues(parameters: GetDataFilterValuesParameters): Promise<GetServiceTermsLineChartResultType[]> {
        return await this.client.servicesTermsGetServiceTermsLineChartValues(parameters);
    }

    override async saveCustomReport(body: CustomReportServiceView): Promise<CustomReportServiceView> {
        if (body.isPublic) return await this.client.servicesTermsSaveCatalogReport(body);
        return await this.client.servicesTermsSaveCustomReport(body);
    }

    override async getCustomReport(id: number): Promise<CustomReportServiceView> {
        return await this.client.servicesTermsGetCustomReport(id);
    }

    override async shareReport(body: CustomReportServiceView): Promise<SharedReportDto> {
        return await this.client.servicesTermsCreateSharedReport(body);
    }

    override async getSharedReport(id: string): Promise<CustomReportServiceView> {
        return this.client.servicesTermsGetSharedReport(id);
    }

    transformMetricsData(data: GetServiceTermsMetricsResultTypeGetServiceTermsTotalizedValuesDetailsAndTotalizedResultDto): Record<string, number> {
        const ROWS = [
            "quantity",
            "directCost",
            "contingency",
            "escalation",
            "grossMargin",
            "sellPrice",
            "totalDirectCost",
            "totalDirectCostPercent",
            "avgSellPrice",
            "avgSellPricePercentage"
        ]; // "termNumber",

        if (!data.results) return {};

        const transformedData = data.results?.reduce(
            (acc: Record<string, number>, curr) => {
                Object.entries(curr).forEach(([key, value]) => {
                    if (ROWS.includes(key)) {
                        if (key !== "termNumber") {
                            acc[`term${curr.termNumber}${key}`] = value;
                        }
                    }
                });

                return acc;
            },
            {} as Record<string, number>
        );

        return transformedData;
    }
}
