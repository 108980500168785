import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-descriptive-item",
    templateUrl: "./descriptive-item.component.html",
    styleUrls: ["./descriptive-item.component.scss"]
})
export class DescriptiveItemComponent {
    @Input()
    text: string;

    @Input()
    tooltip?: string;

    pendingUpdateIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.pendingUpdateIcon = this.iconSanitizer.getIcon("pendingUpdate");
        this.text = "";
    }
}
