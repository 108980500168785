import { Component, OnInit } from "@angular/core";
import { BuildingBuildingClassView, BuildingClassView } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingClassService } from "projects/app/src/app/services/http/clients/building-class.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-building-class-editor",
    templateUrl: "./building-class-editor.component.html",
    styleUrls: ["./building-class-editor.component.scss"]
})
export class BuildingClassEditorComponent extends EditorComponentBase<BuildingBuildingClassView[] | number | null> implements OnInit {
    buildingClassItems?: BuildingClassView[];
    selectedBuildingClass?: BuildingClassView;
    selectedBuildingClasses?: BuildingClassView[];

    constructor(private readonly buildingClassService: BuildingClassService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.buildingClassItems = await this.buildingClassService.getAll();

        if (this.buildingClassItems?.length && this.bindingProperty) {
            if (Array.isArray(this.bindingProperty) && this.bindingProperty.length) {
                this.selectedBuildingClasses = this.buildingClassItems.filter(
                    x => (this.bindingProperty as BuildingBuildingClassView[])?.some(obj2 => x["id"] === obj2["buildingClassId"])
                );
            } else {
                this.selectedBuildingClass = this.buildingClassItems.find(x => x.id === this.bindingProperty);
            }
        }
    }

    onSelectedProductTypeChange() {
        if (this.selectedBuildingClasses)
            this.bindingProperty = this.selectedBuildingClasses.map(x => ({ buildingClassId: x.id }) as BuildingBuildingClassView);
    }

    onSelectBuildingClass() {
        this.bindingProperty = this.selectedBuildingClass?.id ?? null;
        this.valueChange.emit();
    }
}
