export enum AddressTypes {
    Country = 1,
    Region,
    PostCode,
    District,
    Place,
    Locality,
    Neighborhood,
    Block,
    Street,
    Address,
    SecondaryAddress
}