<a class="list-element" tabindex="0" (keydown.enter)="navigate(link)" (click)="navigate(link)">
    <div class="textWrapper">
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
    </div>
    <div class="actions">
        <button *ngIf="canRemoveItem" class="resetStyles trashButton" (click)="onRemoveElement($event)">
            <div class="icon" [innerHTML]="trashIcon"></div>
        </button>
        <app-cell-favorite [ngClass]="{'isFavorite': isFavorite}" [favorite]="isFavorite" (favoriteChange)="onFavoriteChange($event)"></app-cell-favorite>
    </div>
</a>
