<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Opportunities available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'isFavorite', headerTemplate: headerFavorite, cellTemplate: cellFavorite, sortable: true, columnSize: 'small' },
                { key: 'code', label: 'ID', sortable: true },
                { key: 'name', label: 'Name', sortable: true, tooltip: true, columnSize: 'large' },
                { key: 'dueDate', label: 'Due Date', sortable: true, cellTemplate: cellDueDate },
                { key: 'companyName', label: 'Customer', sortable: true, tooltip: true },
                { key: 'potentialValue', label: 'Potential Value ($)', sortable: true, cellTemplate: cellPotentialValue },
                { key: 'proposedGrossMarginPercentage', label: 'Potential GM (%)', sortable: true, cellTemplate: cellProposedGM },
                { key: 'divisionName', label: 'Division', sortable: true, tooltip: true },
                { key: 'salesUsers', label: 'Salesperson', sortable: true, cellTemplate: cellSalesPerson },
                { key: 'opportunityStatusName', label: 'Status', sortable: true },
                { key: 'probabilityValue', label: 'Probability', sortable: true, cellTemplate: cellProbability },
                { key: 'isActive', label: 'Condition', sortable: false, cellTemplate: cellIsActive }
            ]
        }"
        [source]="opportunities"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #headerFavorite>
            <app-cell-favorite [isHeader]="true"></app-cell-favorite>
        </ng-template>

        <ng-template #cellFavorite let-data>
            <app-cell-favorite [favorite]="data.object.isFavorite" (favoriteChange)="onFavoriteChanged(data.object)"></app-cell-favorite>
        </ng-template>

        <ng-template #cellDueDate let-data>
            {{ data.object.dueDate | date: "MM/dd/y" }}
        </ng-template>

        <ng-template #cellActualValue let-data>
            {{ data.object.actualValue | currency }}
        </ng-template>

        <ng-template #cellActualGM let-data>
            {{ data.object.actualGrossMarginPercentage | customPercentage }}
        </ng-template>

        <ng-template #cellSalesPerson let-data>
            <app-user-avatar [users]="data.object.opportunityUsers"></app-user-avatar>
        </ng-template>

        <ng-template #cellProbability let-data>
            {{ data.object.probabilityName }}
        </ng-template>

        <ng-template #cellPotentialValue let-data>
            {{ data.object.potentialValue | currency }}
        </ng-template>

        <ng-template #cellProposedGM let-data>
            {{ data.object.proposedGrossMarginPercentage | customPercentage }}
        </ng-template>

        <ng-template #cellIsActive let-data>
            {{ data.object.isActive ? "Active" : "Archived" }}
        </ng-template>
    </lib-grid-view>
</ng-template>
