import { Component, OnInit } from "@angular/core";
import { EntityUser } from "projects/app/src/app/models/EntityUser";
import { EntityTypeUserView, SearchUsersParameters, UserView } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { ObjectExtensionsService } from "projects/ngx-lib/src/lib/services/object-extensions.service";

@Component({
    selector: "app-entity-user-editor",
    templateUrl: "./entity-user-editor.component.html",
    styleUrls: ["./entity-user-editor.component.scss"]
})
export class EntityUserEditorComponent extends EditorComponentBase<EntityTypeUserView[]> implements OnInit {
    userItems?: EntityUser[];
    selectedUsers?: EntityUser[];

    constructor(
        private readonly userService: UserService,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.bindingProperty) {
            const mappedResults = this.bindingProperty.map(x => EntityUser.fromInterface(x));
            this.selectedUsers = mappedResults;
        }
    }

    async onUserTextChange(event: string) {
        const results: UserView[] = await this.userService.search({
            filterText: event,
            isActive: true
        } as SearchUsersParameters);

        this.userItems = results.map(x => EntityUser.fromUserView(x));
    }

    onSelectedUserChange(event: EntityUser[]) {
        this.bindingProperty = this.objectExtensionService.clone(event) as EntityTypeUserView[];
        this.valueChange.emit();
        this.validate();
    }
}
