import { Injectable } from "@angular/core";
import { ISchedulerClient, SchedulerClient, SchedulerView } from "./reporting-api-proxies";

@Injectable({
    providedIn: "root"
})
export class SchedulerService {
    private client: ISchedulerClient;
    constructor() {
        this.client = new SchedulerClient();
    }

    async getById(id: number): Promise<SchedulerView> {
        return await this.client.schedulerGetSchedulerById(id);
    }

    async archive(body: number): Promise<void> {
        return await this.client.schedulerArchiveScheduler(body);
    }

    async changeState(body: number): Promise<number> {
        return await this.client.schedulerChangeStateScheduler(body);
    }

    async save(body: SchedulerView): Promise<SchedulerView> {
        return await this.client.schedulerSave(body);
    }
}
