import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { CompanyView, OpportunityCompanyView, SearchCompaniesForOpportunityParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityCompanyService } from "projects/app/src/app/services/http/clients/opportunity-company.service";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
// import { ModalEditOpportunityCompanyComponent } from "../../../../shared/modals/modal-edit-opportunity-company/modal-edit-opportunity-company.component";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { ModalEditOpportunityCompanyComponent } from "../../../../shared/modals/modal-edit-opportunity-company/modal-edit-opportunity-company.component";
@Component({
    selector: "app-opportunities-single-companies",
    templateUrl: "./opportunities-single-companies.component.html",
    styleUrls: ["./opportunities-single-companies.component.scss"]
})
export class OpportunitiesSingleCompaniesComponent implements OnChanges {
    @Input()
    opportunityId?: number;

    @Output()
    opportunityCompaniesChange: EventEmitter<OpportunityCompanyView[]>;

    @Output()
    tabCounterChange: EventEmitter<void>;

    opportunityCompanies: OpportunityCompanyView[];
    orderConfig?: IGenericGridOrderConfig<OpportunityCompanyView>;
    paginationConfig: IGenericGridPaginationConfig;
    searchConfig: SearchCompaniesForOpportunityParameters;
    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;
    allowSetCompany?: boolean;
    opportunityCompany?: OpportunityCompanyView;
    isActionAllowed: boolean;
    noResults: boolean;
    isLoading: boolean;

    constructor(
        private readonly opportunityCompanyService: OpportunityCompanyService,
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.opportunityCompanies = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            opportunityId: this.opportunityId
        } as SearchCompaniesForOpportunityParameters;
        this.allowSetCompany = true;
        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Opportunity
        };
        this.opportunityCompany = undefined;
        this.isActionAllowed = false;
        this.opportunityCompaniesChange = new EventEmitter<OpportunityCompanyView[]>();
        this.tabCounterChange = new EventEmitter<void>();
        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.opportunityId && changes["opportunityId"]) {
            const availableActions = await this.opportunityService.availableActions(this.opportunityId);
            this.isActionAllowed = availableActions.includes(OpportunityAction.EditCompany);
            this.searchConfig.opportunityId = this.opportunityId;
            this.typeAheadSearchByEntityParameters.entity = {
                opportunityId: this.opportunityId
            };
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();
        await this.awaiter.awaitAction(
            "Loading Opportunity Companies",
            async () => {
                const response = await this.opportunityCompanyService.search(this.searchConfig);
                const { pageInfo, results } = response;
                this.opportunityCompanies = this.opportunityCompanies?.concat(results ?? []);
                this.noResults = this.opportunityCompanies.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;
        this.opportunityCompanies = [];
        await this.refreshGridView();
    }

    onRowClicked(opportunityCompany: OpportunityCompanyView) {
        this.router.navigate(["companies-single", opportunityCompany.companyId, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(opportunityCompany: OpportunityCompanyView) {
        if (opportunityCompany?.companyId && opportunityCompany.companyId > 0) {
            window.open(`/companies-single/${opportunityCompany.companyId}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onSelectedCompany(company: CompanyView): Promise<void> {
        if (!company || !this.opportunityId) return;
        try {
            const companySaved = await this.opportunityService.saveCompany({
                companyId: company.id,
                opportunityId: this.opportunityId
            } as any as OpportunityCompanyView);
            if (companySaved) {
                this.tabCounterChange.emit();
                this.opportunityCompanies.unshift(companySaved);
                this.noResults = false;
                this.opportunityCompaniesChange.emit(this.opportunityCompanies);
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the company");
        }
    }

    async editItem(opportunityCompany: OpportunityCompanyView): Promise<void> {
        const opportunityCompanyUpdated = await this.modalService.open(ModalEditOpportunityCompanyComponent, {
            company: opportunityCompany
        });

        if (opportunityCompanyUpdated) {
            const opportunityContactIndex = this.opportunityCompanies.findIndex(company => company.id === opportunityCompanyUpdated.id);
            this.opportunityCompanies.splice(opportunityContactIndex, 1, opportunityCompanyUpdated);
            this.opportunityCompaniesChange.emit(this.opportunityCompanies);
        }
    }

    async removeItem(opportunityCompany: OpportunityCompanyView): Promise<void> {
        const { id: opportunityCompanyId } = opportunityCompany;
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove company",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this company from the company list?",
            title: "Remove company"
        });
        if (!responseOk) return;
        await this.awaiter.awaitAction(
            "Removing Company",
            async () => {
                if (!opportunityCompanyId) return;
                await this.opportunityCompanyService.delete(opportunityCompanyId);
                const opportunityCompanyIndex = this.opportunityCompanies.findIndex(company => company.id === opportunityCompanyId);
                this.opportunityCompanies.splice(opportunityCompanyIndex, 1);
                this.tabCounterChange.emit();
                this.noResults = this.opportunityCompanies.length === 0;
                this.opportunityCompaniesChange.emit(this.opportunityCompanies);
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            opportunityId: this.opportunityId
        } as SearchCompaniesForOpportunityParameters;
    }
}
