import { Injectable } from "@angular/core";
import { EntityTypeService } from "./http/clients/entity-type.service";
import { EntityTypes } from "../models/enums/EntityTypes";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

export type TabCounterType = { [key: string]: number };

@Injectable({
    providedIn: "root"
})
export class TabCounterService {
    constructor(
        private readonly entityTypeService: EntityTypeService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {}

    async updateCounters(entityTypeId: EntityTypes, entityId: number, tabCounter: TabCounterType): Promise<void> {
        try {
            const counters = await this.entityTypeService.getEntityCounters({
                entityTypeId: entityTypeId,
                entityId: entityId
            });
            if (counters?.length) {
                for (const counter of counters) {
                    if (counter.name) {
                        tabCounter[counter.name] = counter.count;
                    }
                }
            }
        } catch (error) {
            this.snackbarNotificationService.error("Error when trying to get tab counters");
        }
    }
}
