<ng-container *ngIf="selectedItem && isReadOnly && allowToNavigate; else selectItemTemplate">
    <app-internal-navigator [path]="routeToSingle" [label]="selectedItem.fullName"></app-internal-navigator>
</ng-container>
<ng-template #selectItemTemplate>
    <app-type-ahead-contacts
        [isReadOnly]="isReadOnly"
        [setCompany]="true"
        [displaySelected]="displaySelectedContact"
        [searchAllCompanies]="true"
        [selectedContact]="selectedItem"
        (selectedContactChange)="onSelectedContact($event)"
    >
    </app-type-ahead-contacts>
</ng-template>
