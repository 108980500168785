import { IconSanitizerService } from "./../../services/icon-sanitizer.service";
import { Component, Input, OnInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { icons } from "../../assets/icons";

@Component({
    selector: "lib-external-link",
    templateUrl: "./external-link.component.html",
    styleUrls: ["./external-link.component.scss"]
})
export class ExternalLinkComponent implements OnInit {
    readonly icons = icons;

    @Input()
    message?: string;

    @Input()
    url?: string;

    @Input()
    externalLinkId?: string;

    externalLinkIcon?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.message = "";
        this.url = "";
    }

    ngOnInit(): void {
        this.externalLinkIcon = this.iconSanitizer.getIcon("externalLink");
    }
}
