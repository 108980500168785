import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IMenuGroup } from "../generic-menu/generic-menu.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NavigationStart, Router, Event } from "@angular/router";

@Component({
    selector: "lib-generic-side-nav",
    templateUrl: "./generic-side-nav.component.html",
    styleUrls: ["./generic-side-nav.component.scss"]
})
export class GenericSideNavComponent implements OnInit {
    @Input()
    source: IMenuGroup[];

    @Input()
    logoCollapsed?: string;

    @Input()
    logoExpanded?: string;

    @Input()
    currentUrl?: string;

    isExpanded: boolean;

    @Output()
    toggleSidenav: EventEmitter<boolean>;

    svgExpanded?: SafeHtml;
    svgCollapsed?: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private router: Router
    ) {
        this.source = [];
        this.isExpanded = false;
        this.currentUrl = "";
        this.logoExpanded = "";
        this.logoCollapsed = "";
        this.svgExpanded = undefined;
        this.svgCollapsed = undefined;
        this.toggleSidenav = new EventEmitter();
    }

    ngOnInit(): void {
        this.svgExpanded = this.sanitizer.bypassSecurityTrustHtml(this.logoExpanded ?? "");
        this.svgCollapsed = this.sanitizer.bypassSecurityTrustHtml(this.logoCollapsed ?? "");
        this.changeRouteDetected();
    }

    onToggleButton() {
        this.isExpanded = !this.isExpanded;
        this.toggleSidenav.emit(this.isExpanded);
    }

    private changeRouteDetected() {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if (event.url === "/") return;
                this.currentUrl = event.url;
            }
        });
    }
}
