import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";

type ServiceWithGetById = {
    getById: (id: number) => Promise<{ name?: string }>;
};

export const entityTitleResolver: ResolveFn<string> = async (route: ActivatedRouteSnapshot) => {
    const entityId = parseInt(route.paramMap.get("id") ?? "0");
    const property = (route.data["property"] as string) ?? "name";

    const serviceClass = route.data["service"] as new (...args: never[]) => ServiceWithGetById;

    if (!serviceClass) {
        return "Unknown Entity";
    }

    try {
        const service = inject(serviceClass);

        if (typeof service.getById !== "function") {
            throw new Error("Provided service does not implement getById");
        }

        if (entityId) {
            const entity = await service.getById(entityId);
            return entity?.[property] ?? "Unknown Entity";
        }
        return "Unknown Entity";
    } catch (error) {
        return "Error Loading Entity";
    }
};
