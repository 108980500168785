import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "projects/app/src/app/services/auth.service";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityEstimateView, SearchBaseEstimateResultDto, SearchEstimateTemplatesResultDto } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityEstimateService } from "projects/app/src/app/services/http/clients/opportunity-estimate.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IUserOrganization } from "projects/ngx-lib/src/lib/interfaces/user-organization.interface";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";
import { SafeHtml } from "@angular/platform-browser";

interface IModalCreateEstimateParameters {
    opportunityId: number;
}

@Component({
    selector: "app-modal-create-estimate",
    templateUrl: "./modal-create-estimate.component.html",
    styleUrls: ["./modal-create-estimate.component.scss"]
})
export class ModalCreateEstimateComponent extends ModalComponentBase<IModalCreateEstimateParameters, OpportunityEstimateView> implements OnInit {
    protected override parameters?: IModalCreateEstimateParameters;

    estimateType: "blank" | "template" | "linkToExisting";

    searchText: string;

    selectedTemplate?: SearchEstimateTemplatesResultDto;

    source?: SearchEstimateTemplatesResultDto[];

    selectedTemplateUUID?: SearchBaseEstimateResultDto;

    userOrganization?: IUserOrganization;

    isLoading: boolean;

    canCreateBlankEstimate?: boolean;

    disabled: boolean;

    closeIcon: SafeHtml;

    constructor(
        private readonly opportunityEstimateService: OpportunityEstimateService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly authService: AuthService,
        private readonly awaiter: AwaiterService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.searchText = "";
        this.estimateType = "blank";
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
        this.disabled = true;
    }

    ngOnInit(): void {
        this.canCreateBlankEstimate = !this.authService.hasRestrictedAccess();
        if (!this.canCreateBlankEstimate) {
            this.estimateType = "template";
        }
        this.changedDisabled();
    }

    async onTemplateTextChanged(): Promise<void> {
        if (!this.searchText) return;

        this.source = await this.opportunityEstimateService.searchBaseEstimateTemplates({
            filterText: this.searchText
        });
    }

    onTemplateChange(itemSelected: SearchEstimateTemplatesResultDto): void {
        this.selectedTemplate = itemSelected;
        this.changedDisabled();
    }

    onTemplateUUIDsChange(itemSelected: SearchBaseEstimateResultDto): void {
        this.selectedTemplateUUID = itemSelected;
        this.changedDisabled();
    }

    removeSelectedTemplate() {
        this.selectedTemplate = undefined;
    }

    onEstimateTypeChange() {
        this.changedDisabled();
    }

    async onConfirm(): Promise<void> {
        if (this.estimateType === "linkToExisting" && this.selectedTemplateUUID) {
            await this.awaiter.awaitAction(
                "Linking Estimate...",
                async () => {
                    try {
                        const baseEstimateId = this.selectedTemplateUUID ? this.selectedTemplateUUID.id : undefined;

                        if (baseEstimateId && this.parameters?.opportunityId) {
                            const response = await this.opportunityEstimateService.linkBaseEstimate({
                                opportunityId: this.parameters.opportunityId,
                                baseEstimateId: baseEstimateId
                            });

                            if (response) {
                                this.snackbarNotificationService.success("Estimate linked successfully.");
                                this.close(response);
                            }
                        }
                    } catch (error) {
                        const message = this.awaiter.processError(error);
                        this.snackbarNotificationService.error(message);
                        this.close(undefined);
                    }
                },
                loading => (this.isLoading = loading)
            );
        } else if (this.estimateType === "blank" || this.estimateType === "template") {
            await this.crateEstimate();
        }
    }

    async crateEstimate(): Promise<void> {
        await this.awaiter.awaitAction(
            "Creating Opportunity Estimate...",
            async () => {
                if (!this.parameters) return;

                try {
                    const response = await this.opportunityEstimateService.createBaseEstimate({
                        opportunityId: this.parameters?.opportunityId,
                        templateId: this.selectedTemplate?.id
                    });

                    if (response) {
                        this.snackbarNotificationService.success("Opportunity Estimate created successfully.");
                        this.close(response);
                    }
                } catch (error) {
                    const message = this.awaiter.processError(error);
                    this.snackbarNotificationService.error(message);
                    this.close(undefined);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    changedDisabled() {
        console.log(this.estimateType, this.canCreateBlankEstimate);
        this.disabled = !(
            (this.estimateType === "linkToExisting" && this.selectedTemplateUUID && !this.selectedTemplateUUID.opportunityId) ||
            (this.estimateType === "template" && this.selectedTemplate) ||
            this.estimateType === "blank"
        );
    }
}
