<div class="calendarContainer">
    <table class="calendarTable">
        <thead>
            <tr *ngIf="currentView === 'day' || currentView === 'week'">
                <th></th>
                <th *ngFor="let day of currentPeriod" [ngClass]="{'today': isToday(day), 'weekend': isWeekend(day)}">
                    <div class="dayContainer">
                        <span class="dayName">{{ day.toFormat("EEE") }}</span>
                        <span class="dayNumber">{{ day.toFormat("d") }}</span>
                    </div>
                </th>
            </tr>
            <tr *ngIf="currentView === 'month'">
                <th></th>
                <th *ngFor="let day of daysHeader; let i = index" [ngClass]="{'weekend': i === 0 || i === 6}">
                    <div class="dayContainer">
                        <span class="dayName">{{ day }}</span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="currentView === 'day' || currentView === 'week'">
                <tr *ngFor="let hour of hours; let hourIndex = index">
                    <td class="hour" [id]="'hour-' + hourIndex">{{ hour | ampm }}</td>
                    <td *ngFor="let day of currentPeriod" class="dayCell" [ngClass]="{'weekend': isWeekend(day)}">
                        <div class="events" *ngIf="eventsForPeriod">
                            <ng-container
                                *ngIf="eventTemplate; else defaultTemplate"
                                [ngTemplateOutlet]="eventTemplate"
                                [ngTemplateOutletContext]="{events: eventsForPeriod[day.toISODate() || ''][hour]}">
                            </ng-container>
                            <ng-template #defaultTemplate>
                                <div *ngFor="let event of eventsForPeriod[day.toISODate() || ''][hour]">
                                    <div class="eventCard" tabindex="0">
                                        <div *ngIf="event.title" class="title">{{ event.title }}</div>
                                        <div *ngIf="event.description" class="description">{{ event.description }}</div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="currentView === 'month'">
                <tr *ngFor="let week of weeksInMonth">
                    <td class="hour"></td>
                    <td *ngFor="let day of week" class="dayCell" [ngClass]="{'weekend': isWeekend(day)}">
                        <div class="events">
                            <span class="dayNumberCell">
                                <span class="number" [ngClass]="{'today': isToday(day)}">{{ day.day }}</span>
                            </span>
                            <ng-container *ngIf="getLimitedEventsForDay(day) as limitedEvents">
                                <div class="events">
                                    <ng-container
                                        *ngIf="eventTemplate; else defaultTemplate"
                                        [ngTemplateOutlet]="eventTemplate"
                                        [ngTemplateOutletContext]="{events: limitedEvents}">
                                    </ng-container>
                                    <ng-template #defaultTemplate>
                                        <div *ngFor="let event of limitedEvents">
                                            <div class="eventCard" tabindex="0">
                                                <div *ngIf="event.title" class="title">{{ event.title }}</div>
                                                <div *ngIf="event.description" class="description">{{ event.description }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <button class="viewMoreButton" *ngIf="eventsForPeriod && eventCountPerDay[day.toISODate() || ''] > eventLimitPerDay" (click)="onViewMoreClicked(day)">
                                +{{ eventCountPerDay[day.toISODate() || ""] - eventLimitPerDay}}
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>