import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Opportunity } from "projects/app/src/app/models/Opportunity";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    OpportunityEstimateView,
    OpportunityView,
    DomainValidationResult,
    OpportunityContactView,
    GetOpportunityActualValuesResultDto
} from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ModalService, SnackbarNotificationService, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { ModalCreateEstimateComponent } from "../../../shared/modals/modal-create-estimate/modal-create-estimate.component";
import { OpportunityStatuses } from "projects/app/src/app/models/enums/OpportunityStatuses";
import { OpportunitiesSingleOverviewComponent } from "./opportunities-single-overview/opportunities-single-overview.component";
import { OpportunityAction } from "projects/app/src/app/models/enums/OpportunityAction";
import { ModalConfirmComponent } from "projects/app/src/app/components/shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { OpportunityActualValue } from "projects/app/src/app/models/OpportunityActualValue";
import { OpportunityUsersChange } from "./opportunities-single-team/opportunities-single-team.component";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";
@Component({
    selector: "app-opportunities-single",
    templateUrl: "./opportunities-single.component.html",
    styleUrls: ["./opportunities-single.component.scss"]
})
export class OpportunitiesSingleComponent implements OnInit {
    private opportunityId: number;

    opportunity: Opportunity;

    currentTab: string;

    isModelEqual: boolean;

    subtitle?: string;

    opportunityInitialState: OpportunityView;

    opportunityEstimates?: OpportunityEstimateView[];

    baseEstimateExternalUrl?: string;

    opportunityStatusId?: OpportunityStatuses;

    errors?: DomainValidationResult[];

    @ViewChild("overview")
    overview?: OpportunitiesSingleOverviewComponent;

    isCreateEstimate: boolean;

    canEditOpportunity: boolean;

    isActive?: boolean;

    canArchive: boolean;

    canRestore: boolean;

    canCreateEstimate: boolean;

    entityTypeId: EntityTypes;

    actualValue: GetOpportunityActualValuesResultDto;

    availableActions?: number[];

    isLoading: boolean;

    tabsCounters: TabCounterType = {
        Contacts: 0,
        Teams: 0,
        Notes: 0
    };

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly route: ActivatedRoute,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.opportunityId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = "Overview";
        this.isModelEqual = true;
        this.opportunity = new Opportunity();
        this.opportunityInitialState = new Opportunity();
        this.errors = undefined;
        this.isCreateEstimate = false;
        this.canEditOpportunity = false;
        this.isActive = true;
        this.canArchive = false;
        this.canRestore = false;
        this.canCreateEstimate = false;
        this.entityTypeId = EntityTypes.Opportunity;
        this.actualValue = new OpportunityActualValue();
        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        if (!this.opportunityId) return;

        await this.awaiter.awaitAction("Getting Opportunity Info", async () => {
            this.opportunityInitialState = await this.opportunityService.getById(this.opportunityId);
            await this.validateActions(this.opportunityInitialState.id);

            this.actualValue = OpportunityActualValue.fromInterface(await this.opportunityService.actualValues(this.opportunityInitialState.id));

            const opportunityFromInitialState = Opportunity.fromInterface(this.opportunityInitialState);
            this.opportunity = this.objectExtensionService.clone(opportunityFromInitialState) as Opportunity;

            this.isActive = this.opportunityInitialState.isActive;
            this.baseEstimateExternalUrl = this.opportunity.baseEstimateExternalUrl;
            this.opportunityStatusId = this.opportunity.opportunityStatusId;

            await this.updateTabCounters();
        });
    }

    async validateActions(opportunityId: number): Promise<void> {
        this.availableActions = await this.opportunityService.availableActions(opportunityId);
        this.canEditOpportunity = this.availableActions.includes(OpportunityAction.Edit);
        this.canArchive = this.availableActions.includes(OpportunityAction.Archive);
        this.canRestore = this.availableActions.includes(OpportunityAction.Restore);
        this.canCreateEstimate = this.availableActions.includes(OpportunityAction.CreateEstimate);
    }

    async saveOpportunity(): Promise<void> {
        this.isCreateEstimate = false;

        const areAllCardsValid = this.overview?.validate();
        if (areAllCardsValid) {
            await this.awaiter.awaitAction(
                "Saving Opportunity",
                async () => {
                    this.isLoading = true;
                    await this.opportunityService.save(this.opportunity);
                    this.snackbarNotificationService.success("Opportunity edited successfully.");
                    await this.updateTabCounters();
                    this.opportunityInitialState = this.objectExtensionService.clone(this.opportunity) as OpportunityView;
                    this.validateActions(this.opportunityInitialState.id);
                    this.isModelEqual = true;
                    this.overview?.clearValidationErrors();
                },
                loading => (this.isLoading = loading)
            );
        }
    }

    async cancel(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Cancel Editing",
            cancelCaption: "Continue Edition",
            content: "Are you sure you want to exit? Your changes will be lost",
            title: "Unsaved Changes"
        });

        if (!responseOk) return;

        this.opportunity = Opportunity.fromInterface(this.opportunityInitialState);
        this.isModelEqual = true;
    }

    onValueChanged(): void {
        this.isModelEqual = this.objectExtensionService.isEqualTo(this.opportunity, this.opportunityInitialState, ["id"], false);
    }

    async validateCreateEstimate(): Promise<void> {
        if (!this.isCreateEstimate) {
            this.errors = await this.opportunityService.validateForEstimateCreation(this.opportunityId);
            this.isCreateEstimate = true;
        }

        if (this.errors?.length !== 0) {
            this.currentTab = "Overview";
            setTimeout(() => {
                const areAllCardsValid = this.overview?.validate();
                if (!areAllCardsValid) this.overview?.scrollToFirstErrorElement();
            }, 1);
        } else {
            this.createBaseEstimate();
        }
    }

    private async createBaseEstimate(): Promise<void> {
        const responseOk = await this.modalService.open(ModalCreateEstimateComponent, {
            opportunityId: this.opportunityId
        });

        if (responseOk) {
            this.baseEstimateExternalUrl = responseOk.externalUrl;
            this.opportunityStatusId = responseOk.opportunityStatusId;

            if (this.currentTab === "Estimates") {
                this.opportunityEstimates = [responseOk];
            } else {
                this.currentTab = "Estimates";
            }
        }
    }

    onOpportunityContactsChange(opportunityContacts: OpportunityContactView[]): void {
        this.opportunity.opportunityContacts = opportunityContacts;
        this.opportunityInitialState.opportunityContacts = opportunityContacts;
    }

    onOpportunityUsersChange(opportunityUsersChange: OpportunityUsersChange): void {
        this.opportunity.opportunityUsers = opportunityUsersChange.opportunityUsers;
        this.opportunityInitialState.opportunityUsers = opportunityUsersChange.opportunityUsers;
        if (opportunityUsersChange.opportunityActions) {
            this.canEditOpportunity = opportunityUsersChange.opportunityActions.includes(OpportunityAction.Edit);
            this.canCreateEstimate = opportunityUsersChange.opportunityActions.includes(OpportunityAction.CreateEstimate);
        }
    }

    async archiveOpportunity(): Promise<void> {
        if (!this.canArchive) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this opportunity?",
            title: "Archive Opportunity"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Opportunity", async () => {
            await this.opportunityService.deactivate(this.opportunity.id);
            this.isActive = false;
            this.canRestore = true;
            this.canArchive = false;
        });
    }

    async restoreOpportunity(): Promise<void> {
        if (!this.canRestore) return;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this opportunity?",
            title: "Restore Opportunity"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Opportunity", async () => {
            await this.opportunityService.activate(this.opportunity.id);
            this.isActive = true;
            this.canArchive = true;
            this.canRestore = false;
        });
    }

    async onUpdateEntity(): Promise<void> {
        await this.updateTabCounters();
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.opportunityId, this.tabsCounters);
    }
}
