import { Component, OnInit } from "@angular/core";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";
import { AccountView, BuildingProductTypeView } from "projects/app/src/app/services/http/clients/api-proxies";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-account-building-product-types-editor",
    templateUrl: "./account-building-product-types-editor.component.html",
    styleUrls: ["./account-building-product-types-editor.component.scss"]
})
export class AccountBuildingProductTypesEditorComponent extends EditorComponentBase<AccountView | null> implements OnInit {
    selectedProductTypesItems?: BuildingProductTypeView[];
    constructor(private readonly accountService: AccountService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        if (this.additionalInfo.accountId) {
            this.selectedProductTypesItems = await this.accountService.getBuildingProductTypes(this.additionalInfo.accountId);
        }
    }
}
