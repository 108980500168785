import { Component, OnInit } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { OpportunityContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ModalComponentBase } from "projects/ngx-lib/src/lib/classes/modal-component-base";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { SnackbarNotificationService, ObjectExtensionsService, IconSanitizerService } from "projects/ngx-lib/src/public-api";
import { SafeHtml } from "@angular/platform-browser";

interface IModalEditOpportunityContactParameters {
    contact: OpportunityContactView;
}

@Component({
    selector: "app-modal-edit-opportunity-contact",
    templateUrl: "./modal-edit-opportunity-contact.component.html",
    styleUrls: ["./modal-edit-opportunity-contact.component.scss"]
})
export class ModalEditOpportunityContactComponent extends ModalComponentBase<IModalEditOpportunityContactParameters, OpportunityContactView> implements OnInit {
    protected override parameters!: IModalEditOpportunityContactParameters;

    contactFullName!: string;

    contact!: OpportunityContactView;

    factory: AppEditorFactory;

    configItems: IEditorItemConfig[];

    contactInitialState!: OpportunityContactView;

    isLoading: boolean;

    closeIcon: SafeHtml;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.contactFullName = this.parameters?.contact.fullName ?? "";
        this.contact = this.objectExtensionService.clone(this.parameters?.contact) as OpportunityContactView;
        this.contactInitialState = this.objectExtensionService.clone(this.parameters?.contact) as OpportunityContactView;
        this.configItems = [
            {
                label: "Follow Up on",
                componentId: EditorType.date,
                fieldName: "followUpDate",
                bindContext: this.contact,
                additionalInfo: { timeEnabled: true }
            },
            {
                label: "Last Contact",
                componentId: EditorType.date,
                fieldName: "lastContactDate",
                bindContext: this.contact,
                additionalInfo: { timeEnabled: true }
            },
            {
                label: "Roles",
                componentId: EditorType.entity,
                fieldName: "opportunityContactBuildingContactRoles",
                entityId: "opportunityBuildingContactRole",
                bindContext: this.contact,
                additionalInfo: {
                    entityType: EntityTypes.Contact
                }
            }
        ];
    }

    async saveContact(): Promise<void> {
        await this.awaiter.awaitAction(
            "Saving Contact...",
            async () => {
                const opportunityContactSaved = await this.opportunityService.saveContact(this.contact);

                if (opportunityContactSaved) {
                    this.snackbarNotificationService.success("Contact saved successfully.");
                    this.close(opportunityContactSaved);
                }
            },
            loading => (this.isLoading = loading)
        );
    }

    onClose() {
        this.contact = this.contactInitialState;
        this.close(this.contactInitialState);
    }
}
