import { Injectable } from "@angular/core";
import { GridApi } from "ag-grid-community";

@Injectable({
    providedIn: "root"
})
export class GridService {
    private gridApi?: GridApi;

    setGridApi(api: GridApi) {
        this.gridApi = api;
    }

    downloadCSV(filename: string) {
        if (this.gridApi) this.gridApi.exportDataAsCsv({ fileName: filename });
    }
}
