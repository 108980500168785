import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { OpportunityCalendarEvent } from "projects/app/src/app/models/enums/OpportunityCalendarEvent";
import { SearchOpportunitiesCalendarResult } from "projects/app/src/app/services/http/clients/api-proxies";

@Component({
    selector: "app-opportunity-card-compact",
    templateUrl: "./opportunity-card-compact.component.html",
    styleUrls: ["./opportunity-card-compact.component.scss"]
})
export class OpportunityCardCompactComponent {
    protected scrollStrategy: ScrollStrategy;

    isExpanded: boolean;

    @Input()
    data?: SearchOpportunitiesCalendarResult;

    @Input()
    size: "minimal" | "normal";

    dueDatetype: OpportunityCalendarEvent;
    followUpType: OpportunityCalendarEvent;

    constructor(
        private readonly router: Router,
        private overlay: Overlay
    ) {
        this.size = "normal";
        this.isExpanded = false;
        this.dueDatetype = OpportunityCalendarEvent.DueDate;
        this.followUpType = OpportunityCalendarEvent.FollowUpContactDate;
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    onGoToClicked(id?: number): void {
        this.router.navigate([`/opportunities-single/${id}`]);
    }

    onKeyDown(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === "Escape") {
            this.isExpanded = !this.isExpanded;
        }
    }
}
