import { Component } from "@angular/core";
import { TooltipPosition } from "../../directives/tooltip.directive";

@Component({
    selector: "lib-tooltip",
    templateUrl: "./tooltip.component.html",
    styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent {
    tooltip: string;

    position: TooltipPosition;

    left: number;

    top: number;

    visible: boolean;

    constructor() {
        this.tooltip = "";
        this.position = TooltipPosition.ABOVE;
        this.left = 0;
        this.top = 0;
        this.visible = false;
    }
}
