<table *ngIf="this.metricValues">
    <thead>
        <tr>
            <th class="col-head" ></th>
            <th class="col-head" *ngFor="let col of settings.cols"> {{ col.label }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of settings.rows">
            <th class="row-head">{{ row.label }}</th>
            <td *ngFor="let col of settings.cols">
                <ng-container [ngSwitch]="row.format">
                    <span *ngSwitchCase="'currency'">
                        ${{ metricValues[col.name + row.name] | formatNumberWithSuffix }}
                    </span>
                    <span *ngSwitchCase="'customPercent'">
                        {{ metricValues[col.name + row.name] | customPercentage }}
                    </span>
                    <span *ngSwitchCase="'percent'">
                        {{ metricValues[col.name + row.name] | percent: '1.0-2' }}
                    </span>
                    <span *ngSwitchDefault>
                        {{ metricValues[col.name + row.name] | number: '1.0-2'  }}
                    </span>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>