import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ColDef, ColumnState, GridApi, Column } from "ag-grid-enterprise";
import { SnackbarNotificationService } from "projects/ngx-lib/src/lib/services/snackbar-notification.service";

@Injectable({
    providedIn: "root"
})
export class GridService {
    private columnVisibilityState: Record<string, boolean> = {};
    private _columnVisibilityState$ = new BehaviorSubject<Record<string, boolean>>(this.columnVisibilityState);
    public columnVisibilityState$ = this._columnVisibilityState$.asObservable();

    private groupingState: ColDef[] = [];
    private _groupingState$ = new BehaviorSubject<ColDef[]>(this.groupingState);
    public groupingState$ = this._groupingState$.asObservable();

    private gridApi?: GridApi;

    constructor(private readonly snackbarNotificationService: SnackbarNotificationService) {
        this.columnVisibilityState = {};
        this.groupingState = [];
    }

    setGridApi(api: GridApi): void {
        if (!this.gridApi) {
            this.gridApi = api;
        }
    }

    removeGridApi(): void {
        this.gridApi = undefined;
        this.groupingState = [];
        this.columnVisibilityState = {};
        this._columnVisibilityState$.next(this.columnVisibilityState);
        this._groupingState$.next(this.groupingState);
    }

    downloadCSV(filename: string): void {
        if (this.gridApi) {
            this.gridApi.exportDataAsCsv({ fileName: filename });
        }
    }

    getColsNames(cols: ColDef[]): string[] {
        return cols.flatMap(col => (col.field ? [col.field] : []));
    }

    getGroupingAvailableCols(cols: ColDef[]): ColDef[] {
        return cols.filter((col: ColDef) => col.enableRowGroup);
    }

    setGroupingState(newOrder: string[]): void {
        this.gridApi?.setRowGroupColumns(newOrder);
    }

    limitGroupingColumns(max: number): void {
        if (!this.gridApi) return;

        const rowGroupColumns = this.gridApi.getRowGroupColumns();

        if (rowGroupColumns.length > max) {
            const allowedGroupingColumns = rowGroupColumns.slice(0, max).map(col => col.getColId());
            this.gridApi.setRowGroupColumns(allowedGroupingColumns);
            this.snackbarNotificationService.error("Grouping is limited to 3 columns.");
        }
    }

    getGroupingState(): ColDef[] {
        const groupedCols = this.gridApi?.getColumnState().filter(col => col.rowGroupIndex !== null);
        const parsedCols = this.parseColStateToColDef(groupedCols ?? []);
        const reorderedCols = this.orderColsByIndex(parsedCols);
        return reorderedCols;
    }

    private parseColStateToColDef(cols: ColumnState[]): ColDef[] {
        const displayedColumns = this.gridApi?.getColumns() ?? [];
        return displayedColumns.map(col => {
            const colState = cols.find(c => c.colId === col.getId());
            return {
                field: col.getColDef().field,
                headerName: col.getColDef().headerName,
                rowGroupIndex: colState?.rowGroupIndex ?? null
            } as ColDef;
        });
    }

    private orderColsByIndex(cols: ColDef[]): ColDef[] {
        return cols.filter(col => col.rowGroupIndex !== null).sort((a, b) => (a.rowGroupIndex ?? 0) - (b.rowGroupIndex ?? 0));
    }

    setGroupingStateValue(newOrder: ColDef[]): void {
        this.groupingState = [...newOrder];
        this._groupingState$.next(this.groupingState);
    }

    getGroupingStateValue(): ColDef[] {
        return this.groupingState;
    }

    getUnlockedCols(cols: ColDef[]): ColDef[] {
        return cols.filter((col: ColDef) => !col.lockVisible);
    }

    getVisibleCols(): Column[] {
        return this.gridApi ? this.gridApi.getAllDisplayedColumns() : [];
    }

    setColumnsVisible(arr: string[], val: boolean): void {
        if (this.gridApi) {
            this.gridApi.setColumnsVisible(arr, val);
        }
    }

    updateColumnVisibilityState(change: Record<string, boolean>): void {
        this.columnVisibilityState = { ...this.columnVisibilityState, ...change };
        this._columnVisibilityState$.next(this.columnVisibilityState);
    }

    getColumnVisibilityState(): Record<string, boolean> {
        return this.columnVisibilityState;
    }

    getHiddenState(): string[] {
        const currentState = this.columnVisibilityState;
        return Object.keys(currentState).filter(key => !currentState[key]);
    }

    getGridApi(): GridApi | undefined {
        return this.gridApi;
    }

    clearColumnVisibilityState(): void {
        this.columnVisibilityState = {};
        this._columnVisibilityState$.next(this.columnVisibilityState);
    }
}
