import { Component, OnDestroy, OnInit } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { SearchOpportunitiesCalendarParameters, SearchOpportunitiesCalendarResult } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { IFullViewCalendarEvent } from "projects/ngx-lib/src/lib/components/full-view-calendar/interfaces/full-view-calendar.interface";
import { DateTimeService } from "projects/ngx-lib/src/public-api";
import { FilterValueOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";
import { DateRanges, FullViewCalendarViewType } from "projects/ngx-lib/src/lib/components/full-view-calendar/types/full-view-calendar.types";
import { Subscription } from "rxjs";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";

@Component({
    selector: "app-opportunities-calendar",
    templateUrl: "./opportunities-calendar.component.html",
    styleUrls: ["./opportunities-calendar.component.scss"]
})
export class OpportunitiesCalendarComponent implements OnInit, OnDestroy {
    filters?: FilterValueOpportunities;
    searchConfig: SearchOpportunitiesCalendarParameters;
    events?: IFullViewCalendarEvent<SearchOpportunitiesCalendarResult>[];
    currentRange?: DateRanges;
    currentView: FullViewCalendarViewType;

    private filterSubscription!: Subscription;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly dateTimeService: DateTimeService,
        private readonly awaiter: AwaiterService,
        private readonly filterStateService: FilterStateService<FilterValueOpportunities>
    ) {
        this.searchConfig = {} as SearchOpportunitiesCalendarParameters;
        this.currentView = "week";
    }

    async ngOnInit(): Promise<void> {
        this.filterSubscription = this.filterStateService.stateFilters$.subscribe(newFilters => {
            if (!newFilters) return;
            this.filters = { ...newFilters };
            this.refreshCalendarView();
        });
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    async refreshCalendarView(): Promise<void> {
        this.buildSearchConfig();
        await this.awaiter.awaitAction("Getting Calendar Opportunities", async () => {
            const result = await this.opportunityService.searchCalendar({
                ...this.searchConfig,
                ...(this.getDateRanges() as unknown as Date)
            } as SearchOpportunitiesCalendarParameters);

            this.events = result.map((opportunity: SearchOpportunitiesCalendarResult) => ({
                title: opportunity.name,
                description: opportunity.code,
                data: opportunity,
                date: this.dateTimeService.getDateTimeFromString(opportunity.eventDate.toString()),
                startTime: this.dateTimeService.timeOnly(opportunity.eventDate)
            }));
        });
    }

    async onDateRangeSelected(range: DateRanges): Promise<void> {
        this.currentRange = range;
        this.filterStateService.updateMainFilters({ ...this.filters });
    }

    private getDateRanges(): { dueDateFrom: string | null; dueDateTo: string | null } {
        if (this.currentRange?.dateFrom || this.currentRange?.dateTo) {
            return {
                dueDateFrom: this.currentRange?.dateFrom,
                dueDateTo: this.currentRange?.dateTo
            };
        } else {
            return this.getDefaultDateRanges();
        }
    }

    private getDefaultDateRanges(): { dueDateFrom: string | null; dueDateTo: string | null } {
        if (this.currentView === "day")
            return {
                dueDateFrom: this.dateTimeService.today().startOf("day").toISO(),
                dueDateTo: this.dateTimeService.today().endOf("day").toISO()
            };

        if (this.currentView === "week")
            return {
                dueDateFrom: this.dateTimeService.getStartOfWeek(this.dateTimeService.now()).toISO(),
                dueDateTo: this.dateTimeService.getEndOfWeek(this.dateTimeService.now()).toISO()
            };

        return {
            dueDateFrom: this.dateTimeService.getStartOfMonth(this.dateTimeService.now(), true).toISO(),
            dueDateTo: this.dateTimeService.getEndOfMonth(this.dateTimeService.now(), true).toISO()
        };
    }

    private buildSearchConfig(): void {
        this.searchConfig = {
            filterText: this.filters?.searchText ?? undefined,
            opportunityStatusIds: this.filters?.statuses?.length ? this.filters?.statuses.map(s => s.id) : undefined,
            divisionIds: this.filters?.divisions?.length ? this.filters?.divisions.map(d => d.id) : undefined,
            probabilityValueFrom: this.filters?.probabilityFrom,
            probabilityValueTo: this.filters?.probabilityTo,
            onlyFavorites: this.filters?.onlyFavorites,
            companyIds: this.filters?.companies?.length ? this.filters?.companies.map(c => c.id) : undefined,
            salesUserId: this.filters?.salesperson?.externalId,
            potentialValueFrom: this.filters?.potentialValueFrom,
            potentialValueTo: this.filters?.potentialValueTo,
            proposedGrossMarginFrom: this.filters?.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.filters?.proposedGrossMarginTo,
            isActive: this.filters?.activeStatus?.value
        } as SearchOpportunitiesCalendarParameters;
    }
}
