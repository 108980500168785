import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { Messages } from "projects/app/src/app/messages/messages";
import { UserInformationChangedMessage } from "projects/app/src/app/messages/user-information-changed.message";
import { User } from "projects/app/src/app/models/security/user";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { UserPersonalInformationDto } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EditorItemComponent, MessageBusService, ModalComponentBase, SnackbarNotificationService, StorageService } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-modal-user-account-settings",
    templateUrl: "./modal-user-account-settings.component.html",
    styleUrls: ["./modal-user-account-settings.component.scss"]
})
export class ModalUserAccountSettingsComponent extends ModalComponentBase<undefined, undefined> implements OnInit {
    factory: AppEditorFactory;
    configItems: IEditorItemConfig[];
    user!: UserPersonalInformationDto;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    constructor(
        private readonly userService: UserService,
        private readonly storageService: StorageService,
        private readonly awaiter: AwaiterService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly messageBusService: MessageBusService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting user information...", async () => {
            this.user = await this.userService.getUserInformation();

            this.configItems = [
                {
                    label: "Email",
                    componentId: EditorType.textInput,
                    fieldName: "email",
                    bindContext: this.user,
                    validations: [{ type: ValidatorType.email }, { type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 256 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "First name",
                    componentId: EditorType.textInput,
                    fieldName: "firstName",
                    bindContext: this.user,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 50 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Last name",
                    componentId: EditorType.textInput,
                    fieldName: "lastName",
                    bindContext: this.user,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 60 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Title / Position",
                    componentId: EditorType.textInput,
                    fieldName: "title",
                    bindContext: this.user,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                }
            ];
        });
    }

    validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }

    async saveSettings(): Promise<void> {
        this.sanitizeData();

        if (!this.validate()) return;

        await this.awaiter.awaitAction("Saving user information...", async () => {
            await this.userService.changeUserInformation(this.user);

            const userSaved = this.storageService.getItem("user") as User;

            userSaved.email = this.user.email ?? "";
            userSaved.firstName = this.user.firstName ?? "";
            userSaved.lastName = this.user.lastName ?? "";

            this.storageService.setItem("user", userSaved);
            this.messageBusService.send(
                Messages.UserInformationChanged,
                new UserInformationChangedMessage({ firstName: userSaved.firstName, lastName: userSaved.lastName })
            );

            this.snackbarNotificationService.success("User information was successfully updated");
            this.close(undefined);
        });
    }

    private sanitizeData() {
        Object.keys(this.user).forEach(key => {
            const value = (this.user as unknown as { [key: string]: string })[key];
            if (typeof value === "string") {
                (this.user as any)[key] = value.trim();
            }
        });
    }
}
