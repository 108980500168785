import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ChangeOrderService } from "projects/app/src/app/services/http/clients/reporting-app/change-order.service";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { salesMainFilters } from "./shared/main-filters";
import {
    changeOrderStatusEditorConfig,
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    projectDueDateEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    projectStatusEditorConfig
} from "./shared/additional-filters";

export const ChangeOrders: IReportConfiguration<ChangeOrderService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ChangeOrderService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getChangeOrdersBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getChangeOrdersMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "submittedOnHold", label: "Submitted/On-hold" },
                        { name: "rejectedCancel", label: "Rejected/Canceled" },
                        { name: "accepted", label: "Accepted" }
                    ],
                    rows: [
                        { name: "Qty", label: "Quantity" },
                        { name: "GrossMargin", label: "Gross Margin (%)", format: "customPercent" },
                        { name: "SellPrice", label: "Sell Price", format: "currency" }
                    ]
                }
            },
            {
                methodName: "getLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Change Orders by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "changeOrderName",
                            yKey: "sellPrice",
                            yName: "Sell Price"
                        },
                        {
                            type: "line",
                            xKey: "changeOrderName",
                            yKey: "grossMargin",
                            yName: "Gross Margin (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["sellPrice"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["grossMargin"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "sellPrice",
                            yName: "Amount"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview3
    },
    gridTab: {
        widgets: [
            {
                methodName: "getChangeOrdersValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "projectId", headerName: "Project ID", lockVisible: true },
                    { field: "projectName", headerName: "Project Name", lockVisible: true },
                    { field: "projectDueDate", headerName: "Project Due Date", type: "date" },
                    { field: "projectStatus", headerName: "Project Status" },
                    { field: "organization", headerName: "Organization" },
                    { field: "salesPerson", headerName: "Salesperson" },
                    { field: "changeOrderId", headerName: "Change Order ID" },
                    { field: "changeOrderName", headerName: "Change Order Name" },
                    { field: "changeOrderStatus", headerName: "Change Order Status" },
                    { field: "grossMargin", headerName: "Gross Margin (%)", type: "customPercentage" },
                    { field: "sellPrice", headerName: "Sell Price", type: "currency" },
                    { field: "markets", headerName: "Market", type: "array" },
                    { field: "lead", headerName: "Opportunity Source" },
                    { field: "division", headerName: "Division" },
                    { field: "customer", headerName: "Customer" },
                    { field: "account", headerName: "Account" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            changeOrderStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
