/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Input, ViewContainerRef } from "@angular/core";
import { TooltipComponent } from "../components/tooltip/tooltip.component";
import { DomSanitizer } from "@angular/platform-browser";

export enum TooltipPosition {
    ABOVE = "above",
    ABOVELEFT = "above-left",
    BELOW = "below",
    LEFT = "left",
    RIGHT = "right"
}

@Directive({
    selector: "[libTooltip]"
})
export class TooltipDirective<T> {
    @Input()
    tooltip?: string | T[keyof T];

    @Input()
    position?: TooltipPosition;

    @Input()
    showDelay?: number;

    @Input()
    hideDelay?: number;

    private componentRef: ComponentRef<any> | null;

    private showTimeout?: number;

    private hideTimeout?: number;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly sanitizer: DomSanitizer
    ) {
        this.tooltip = "";
        this.position = TooltipPosition.ABOVE;
        this.showDelay = 0;
        this.hideDelay = 300;
        this.componentRef = null;
    }

    @HostListener("mouseenter")
    onMouseEnter(): void {
        if (!this.componentRef) this.initializeTooltip();
    }

    @HostListener("mouseleave")
    onMouseLeave(): void {
        if (this.componentRef) this.setHideTooltipTimeout();
    }

    @HostListener("resize")
    onResize(): void {
        if (this.componentRef) this.setHideTooltipTimeout();
    }

    private initializeTooltip() {
        if (this.tooltip && !this.componentRef) {
            this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);
            const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
            document.body.appendChild(tooltipDOMElement);
            this.setTooltipComponentProperties();
        }
    }

    private setTooltipComponentProperties() {
        if (this.componentRef) {
            this.componentRef.instance.tooltip = this.sanitizer.bypassSecurityTrustHtml(String(this.tooltip ?? ""));
            this.componentRef.instance.position = this.position;
            this.setTooltipPosition();
            this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
        }
    }

    private setTooltipPosition() {
        if (this.componentRef) {
            const { left, right, top, bottom, height } = this.elementRef.nativeElement.getBoundingClientRect();

            switch (this.position) {
                // ABOVE: default positioning. It is centered horizontally
                case TooltipPosition.ABOVE: {
                    this.componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this.componentRef.instance.top = Math.round(top);
                    break;
                }
                case TooltipPosition.ABOVELEFT: {
                    this.componentRef.instance.left = Math.round(left + 8);
                    this.componentRef.instance.top = Math.round(top - 8);
                    break;
                }
                case TooltipPosition.BELOW: {
                    this.componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this.componentRef.instance.top = Math.round(bottom);
                    break;
                }
                case TooltipPosition.RIGHT: {
                    this.componentRef.instance.left = Math.round(right + 8);
                    this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
                    break;
                }
                case TooltipPosition.LEFT: {
                    this.componentRef.instance.left = Math.round(left - this.componentRef.instance.width - 8);
                    this.componentRef.instance.top = Math.round(top + height / 2);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    private showTooltip() {
        if (this.componentRef) {
            this.componentRef.instance.visible = true;
        }
    }

    private setHideTooltipTimeout() {
        this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
    }

    destroy(): void {
        if (this.componentRef) {
            window.clearInterval(this.showTimeout);
            window.clearInterval(this.hideTimeout);
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.destroy();
    }
}
