import { ValueFormatterParams } from "ag-grid-community";

export function currencyFormatter(params: ValueFormatterParams) {
    return "$" + formatNumber(params.value);
}
export function formatNumber(number: number) {
    return Math.floor(number).toLocaleString();
}

export function percentageFormatter(value: number | null) {
    if (value === undefined || value === null || isNaN(value)) return null;
    return (value * 100).toFixed(2) + "%";
}
