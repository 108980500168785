import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { icons } from "../assets/icons";

@Injectable({
    providedIn: "root"
})
export class IconSanitizerService {
    private sanitizedIcons: Record<string, SafeHtml> = {};
    constructor(private readonly sanitizer: DomSanitizer) {}
    /**
Sanitizes all icons and stores them in memory.
   */
    initializeIcons(): void {
        Object.keys(icons).forEach(key => {
            this.sanitizedIcons[key] = this.sanitizer.bypassSecurityTrustHtml(icons[key]);
        });
    }
    /**
Gets a sanitized icon by its key.
@param key - The key of the icon (e.g., 'div25whiteHorizontal')
@returns A SafeHtml icon or undefined if the key doesn't exist
   */
    getIcon(key: keyof typeof icons): SafeHtml {
        return this.sanitizedIcons[key];
    }
}
