<div class="container">
    <lib-generic-type-ahead-multiselect
        [source]="userItems"
        [selectedItems]="selectedUsers"
        (selectedItemsChange)="onSelectedUserChange($event)"
        [config]="{
            defaultText: 'Add Opportunity User',
            itemDisplayKey: 'userFullName',
            itemTemplate: userTemplate
        }"
        [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add ' + label"
        (textChange)="onUserTextChange($event)"
        removeKey="userExternalId"
        [isReadOnly]="isReadOnly"
        [error]="!isValid"
    >
    </lib-generic-type-ahead-multiselect>
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>

<ng-template #userTemplate let-data>
    <div class="userContainer">
        <p class="userName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText: data.inputText"></p>
        <p class="userEmail" [innerHTML]="data.object?.email | highlightText: data.inputText"></p>
    </div>
</ng-template>
