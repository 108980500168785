<lib-section-header [title]="'Accounts'" [iconName]="'accounts'" [actionsTemplate]="actionsTemplate">
    <ng-template #actionsTemplate>
        <div class="actions">
            <button class="primary-btn" (click)="onCreateNewAccountClicked()">+ New Account</button>
        </div>
    </ng-template>
</lib-section-header>

<div class="filters">
    <lib-grid-filters>
        <lib-search-input *libGridFilter [(text)]="filterValue.searchText" (textChange)="onFilterChanged()" [placeholderText]="'Name'" name="search">
        </lib-search-input>

        <lib-generic-dropdown
            *libGridFilter
            [source]="accountTypes"
            [(selectedItem)]="filterValue.accountType"
            (selectedItemChange)="onFilterChanged()"
            [config]="{
                multiple: false,
                defaultText: 'Type',
                itemDisplayKey: 'name'
            }"
        >
        </lib-generic-dropdown>

        <lib-generic-dropdown
            *libGridFilter
            [source]="markets"
            [(selectedItem)]="filterValue.market"
            (selectedItemChange)="onFilterChanged()"
            [config]="{
                multiple: false,
                defaultText: 'Markets',
                itemDisplayKey: 'name'
            }"
        >
        </lib-generic-dropdown>

        <lib-generic-dropdown
            *libGridFilter
            [source]="activeStatuses"
            [(selectedItem)]="filterValue.activeStatus"
            (selectedItemChange)="onFilterChanged()"
            [config]="{
                multiple: false,
                defaultText: 'Select Condition',
                itemDisplayKey: 'name'
            }"
        >
        </lib-generic-dropdown>
    </lib-grid-filters>
</div>

<div class="list">
    <app-accounts-list [filters]="filterValue" (sortChanged)="onSortChangedFromList($event)"></app-accounts-list>
</div>
