import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { GenericCardComponent } from "projects/ngx-lib/src/lib/components/generic-card/generic-card.component";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { EntityTypeCustomFieldService } from "projects/app/src/app/services/http/clients/entity-type-custom-field.service";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";

@Component({
    selector: "app-companies-single-overview",
    templateUrl: "./companies-single-overview.component.html",
    styleUrls: ["./companies-single-overview.component.scss"]
})
export class CompaniesSingleOverviewComponent implements OnChanges {
    @Input()
    company?: CompanyView;

    @Input()
    isReadOnly?: boolean;

    factory: AppEditorFactory;

    mainInfoCardConfig!: IEditorItemConfig[];
    mainContactCardConfig!: IEditorItemConfig[];
    mainAccountCardConfig!: IEditorItemConfig[];
    mainOfficeCardConfig!: IEditorItemConfig[];
    customFieldsConfig!: IEditorItemConfig[];

    @ViewChild("mainInfoCard")
    mainInfoCard?: GenericCardComponent;

    constructor(private readonly entityCustomFieldService: EntityTypeCustomFieldService) {
        this.factory = new AppEditorFactory();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.company && changes["company"]) {
            this.mainInfoCardConfig = [
                {
                    label: "Name",
                    componentId: EditorType.textInput,
                    fieldName: "name",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                    additionalInfo: {
                        shouldTrimSpaces: true
                    }
                },
                {
                    label: "Abbreviation",
                    componentId: EditorType.textInput,
                    fieldName: "abbreviation",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 50 } }]
                },
                {
                    label: "Website",
                    componentId: EditorType.link,
                    fieldName: "webSite",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "Linkedin",
                    componentId: EditorType.link,
                    fieldName: "linkedin",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 100 } }]
                },
                {
                    label: "Type",
                    componentId: EditorType.companyType,
                    fieldName: "companyTypeId",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Markets",
                    componentId: EditorType.entity,
                    fieldName: "companyMarkets",
                    bindContext: this.company,
                    entityId: "companyMarket",
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Salesperson",
                    componentId: EditorType.entityUser,
                    fieldName: "companySalespersons",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly
                },
                {
                    label: "Description",
                    componentId: EditorType.textarea,
                    fieldName: "description",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    validations: [{ type: ValidatorType.string, args: { maxLength: 1000 } }]
                }
            ];

            this.mainContactCardConfig = [
                {
                    componentId: EditorType.companyMainContact,
                    fieldName: "contactId",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly
                }
            ];

            this.mainOfficeCardConfig = [
                {
                    componentId: EditorType.building,
                    fieldName: "mainOfficeId",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        allowToNavigate: true
                    }
                }
            ];

            this.mainAccountCardConfig = [
                {
                    componentId: EditorType.account,
                    fieldName: "accountId",
                    bindContext: this.company,
                    isReadOnly: this.isReadOnly,
                    additionalInfo: {
                        allowToNavigate: true
                    }
                }
            ];

            if (!this.company.customFieldValues?.length)
                this.company.customFieldValues = await this.entityCustomFieldService.getCustomFieldsByEntityType(EntityTypes.Company);
            this.customFieldsConfig =
                this.company.customFieldValues.map(item => {
                    return {
                        label: item.name,
                        componentId: item.editor,
                        bindContext: item,
                        fieldName: "value",
                        isReadOnly: this.isReadOnly
                    } as IEditorItemConfig;
                }) ?? [];
        }
    }
}
