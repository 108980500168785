<div class="container">
    <input
        libInputFormatter="C2"
        [(ngModel)]="bindingProperty"
        (ngModelChange)="dataChanged($event)"
        [ngClass]="{isReadOnly: isReadOnly, error: !isValid }"
        [readOnly]="isReadOnly"
        [placeholder]="isReadOnly && !bindingProperty || !label ? '' : 'Add ' + label"
    />
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>