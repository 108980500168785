<article class="status-content" [ngClass]="{ 'status-active': schedulerStatusId === 1, 'status-paused': schedulerStatusId === 2 }">
    <section class="status-icon">
        <div
            class="icon icon-active"
            [ngClass]="{ 'icon-active': schedulerStatusId === 1, 'icon-paused': schedulerStatusId === 2 }"
            [innerHTML]="scheduleIcon"
        ></div>
    </section>
    <section class="status-description">
        {{ schedulerStatusId === 1 ? "Schedule Active" : schedulerStatusId === 2 ? "Schedule Stand by" : "" }}
    </section>
</article>
