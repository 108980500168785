<ng-container *ngIf="mainActionable">
    <ng-container *ngTemplateOutlet="mainActionable; context: { openClose: openCloseOverlay.bind(this) }"> </ng-container>
</ng-container>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayOpen]="isOverlayOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-custom-backdrop"
    (backdropClick)="isOverlayOpen = false; onOverlayChange()"
    (detach)="isOverlayOpen = false"
>
    <div class="content" [ngStyle]="overlayStyles" [ngClass]="{ show: isOverlayOpen }" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <ng-container *ngIf="headerTemplate">
            <ng-container *ngTemplateOutlet="headerTemplate; context: { close: closeOverlay.bind(this) }"> </ng-container>
        </ng-container>
        <ng-content></ng-content>
        <ng-container *ngIf="actionButtonsRef">
            <ng-container *ngTemplateOutlet="actionButtonsRef; context: { close: closeOverlay.bind(this) }"> </ng-container>
        </ng-container>
    </div>
</ng-template>
