import { Component, OnInit } from "@angular/core";
import {
    ArchiveEntityOptionView,
    CompanyTypeView,
    ContactView,
    MarketView,
    QuickSearchContactsParameters
} from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyTypeService } from "projects/app/src/app/services/http/clients/company-type.service";
import { MarketService } from "projects/app/src/app/services/http/clients/market.service";
import { ITypeAheadService } from "../../../shared/generic-type-ahead/generic-type-ahead.component";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { Router } from "@angular/router";
import { FilterService, IGetById } from "projects/ngx-lib/src/public-api";
import { ArchiveEntityService } from "projects/app/src/app/services/http/clients/archive-entity.service";
import { Condition } from "projects/app/src/app/models/enums/Condition";
export interface IFilterValueCompanies {
    searchText?: string;
    companyType?: CompanyTypeView;
    market?: MarketView;
    contact?: ContactView;
    activeStatus?: ArchiveEntityOptionView;
}

@Component({
    selector: "app-companies-main",
    templateUrl: "./companies-main.component.html",
    styleUrls: ["./companies-main.component.scss"]
})
export class CompaniesMainComponent implements OnInit {
    filterValue: IFilterValueCompanies;

    companyTypes: CompanyTypeView[];

    markets: MarketView[];

    contactServiceFilter: ITypeAheadService<ContactView, QuickSearchContactsParameters>;

    activeStatuses?: ArchiveEntityOptionView[];

    constructor(
        private readonly contactService: ContactService,
        private readonly companyTypeService: CompanyTypeService,
        private readonly marketService: MarketService,
        private readonly router: Router,
        private readonly filterService: FilterService,
        private readonly archiveEntityService: ArchiveEntityService
    ) {
        this.filterValue = {};
        this.companyTypes = [];
        this.markets = [];
        this.contactServiceFilter = contactService;
    }

    async ngOnInit(): Promise<void> {
        this.companyTypes = await this.companyTypeService.getAll();
        this.markets = await this.marketService.getAll();
        this.activeStatuses = await this.archiveEntityService.getArchiveOptions();
        this.filterValue = {
            ...(this.activeStatuses?.length && { activeStatus: this.activeStatuses.find(c => c.id === Condition.Active) })
        };
        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    buildFilters(): void {
        this.filterValue = {
            searchText: this.filterValue.searchText,
            companyType: this.filterValue.companyType,
            market: this.filterValue.market,
            contact: this.filterValue.contact,
            activeStatus: this.filterValue.activeStatus
        };
    }

    async onFilterChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IFilterValueCompanies>(this.filterValue, "searchText");
        await this.filterService.serializeTypeAhead<IFilterValueCompanies, ContactView>("contact", this.filterValue.contact, "id");
        await this.filterService.serializeArray<IFilterValueCompanies, CompanyTypeView>(this.filterValue, "companyType", "id");
        await this.filterService.serializeArray<IFilterValueCompanies, MarketView>(this.filterValue, "market", "id");
        await this.filterService.serializeArray<IFilterValueCompanies, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IFilterValueCompanies>(this.filterValue, "searchText");
        await this.filterService.deserializeTypeAhead<IFilterValueCompanies, ContactView>(this.filterValue, "contact", this.contactServiceFilter as IGetById);
        this.filterService.deserializeArray<IFilterValueCompanies, CompanyTypeView>(this.filterValue, "companyType", "id", this.companyTypes ?? []);
        this.filterService.deserializeArray<IFilterValueCompanies, MarketView>(this.filterValue, "market", "id", this.markets ?? []);
        this.filterService.deserializeArray<IFilterValueCompanies, ArchiveEntityOptionView>(this.filterValue, "activeStatus", "id", this.activeStatuses ?? []);
    }

    onCreateNewCompanyClicked() {
        this.router.navigate([`/companies-edit`]);
    }
}
