<lib-generic-type-ahead-multiselect
    [isReadOnly]="isReadOnly"
    [source]="companyItems"
    [selectedItems]="selectedCompanyItems"
    [config]="{
        defaultText: 'Select',
        itemDisplayKey: 'companyName',
        itemTemplate: companyTemplate
    }"
    removeKey="companyId"
    (textChange)="onCompanyTextChange($event)"
    (selectedItemsChange)="onSelectedCompanyChange($event)"
    (removeItemChange)="onRemoveCompanyChange($event)"
    [isReadOnly]="isReadOnly"
    [action]="addCompanyAction"
    [placeholder]="'Add Company'"
>
</lib-generic-type-ahead-multiselect>
<ng-template #companyTemplate let-data>
    <app-custom-menu-item [inputText]="data.inputText" [title]="data.object?.companyName"> </app-custom-menu-item>
</ng-template>
