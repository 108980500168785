import { Injectable } from "@angular/core";
import {
    CompanyClient,
    CompanyView,
    ICompanyClient,
    QuickSearchCompaniesParameters,
    SearchCompaniesParameters,
    CompanyViewPaginatedResultDto,
    SearchAvailableCompanyBuildingsParameters,
    BuildingView,
    CompanyBuildingViewPaginatedResultDto,
    SearchCompaniesForBuildingParameters
} from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class CompanyService {
    private client: ICompanyClient;
    constructor() {
        this.client = new CompanyClient();
    }

    async save(body: CompanyView): Promise<CompanyView> {
        return await this.client.companySave(body);
    }

    async getById(id: number): Promise<CompanyView> {
        return await this.client.companyGetById(id);
    }

    async searchForBuilding(body: SearchCompaniesForBuildingParameters): Promise<CompanyBuildingViewPaginatedResultDto> {
        return await this.client.companySearchCompaniesForBuilding(body);
    }

    async searchCompanies(body: SearchCompaniesParameters | undefined): Promise<CompanyViewPaginatedResultDto> {
        return await this.client.companySearch(body);
    }

    async search(body: QuickSearchCompaniesParameters | undefined): Promise<CompanyView[]> {
        return await this.client.companyQuickSearch(body);
    }

    async searchAvailableBuildings(body: SearchAvailableCompanyBuildingsParameters): Promise<BuildingView[]> {
        return await this.client.companySearchAvailableBuildings(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.companyActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.companyDeactivate(id);
    }
}
