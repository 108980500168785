import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView, CompanyView } from "projects/app/src/app/services/http/clients/api-proxies";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { ModalService } from "projects/ngx-lib/src/public-api";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { TabCounterService, TabCounterType } from "projects/app/src/app/services/tab-counter.service";

@Component({
    selector: "app-companies-single",
    templateUrl: "./companies-single.component.html",
    styleUrls: ["./companies-single.component.scss"]
})
export class CompaniesSingleComponent implements OnInit {
    private companyId: number;

    company?: CompanyView;

    contact?: ContactView;

    currentTab: string;

    listViewSelectedItem?: CompanyView;

    isActive?: boolean;

    entityTypeId: EntityTypes;

    tabsCounters: TabCounterType = {
        Buildings: 0,
        Contacts: 0,
        Opportunities: 0,
        Notes: 0
    };

    constructor(
        private readonly companyService: CompanyService,
        private readonly contactService: ContactService,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly tabCounterService: TabCounterService
    ) {
        this.companyId = parseInt(this.route.snapshot.paramMap.get("id") ?? "0");
        this.currentTab = "Overview";
        this.isActive = true;
        this.entityTypeId = EntityTypes.Company;
    }

    async ngOnInit(): Promise<void> {
        await this.awaiter.awaitAction("Getting Company Info", async () => {
            this.company = await this.companyService.getById(this.companyId);
            this.isActive = this.company.isActive;
        });

        if (this.company?.contactId) {
            this.contact = await this.contactService.getById(this.company?.contactId);
        }

        await this.updateTabCounters();
    }

    onEditCompany() {
        this.router.navigate([`/companies-edit/${this.companyId}`], { skipLocationChange: true });
    }

    async archiveCompany(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Archive",
            cancelCaption: "Cancel",
            content: "Are you sure you want to archive this company?",
            title: "Archive Company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Archiving Company", async () => {
            if (!this.company?.id) return;

            await this.companyService.deactivate(this.company.id);
            this.isActive = false;
        });
    }

    async restoreCompany(): Promise<void> {
        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Restore",
            cancelCaption: "Cancel",
            content: "Are you sure you want to restore this company?",
            title: "Restore Company"
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Restoring Company", async () => {
            if (!this.company?.id) return;
            await this.companyService.activate(this.company.id);
            this.isActive = true;
        });
    }

    async onUpdateEntity(): Promise<void> {
        await this.updateTabCounters();
    }

    private async updateTabCounters(): Promise<void> {
        await this.tabCounterService.updateCounters(this.entityTypeId, this.companyId, this.tabsCounters);
    }
}
