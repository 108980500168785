/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
    CompanyView,
    DivisionView,
    OpportunityStatusView,
    QuickSearchCompaniesParameters,
    SearchDivisionsParameters,
    SearchUsersParameters,
    UserView
} from "../../../../services/http/clients/api-proxies";
import { ITypeAheadService } from "../../generic-type-ahead/generic-type-ahead.component";
import { CompanyService } from "../../../../services/http/clients/company.service";
import { UserService } from "../../../../services/http/clients/user.service";
import { UserRoles } from "../../../../models/enums/UserRoles";
import { IAdditionalFiltersOpportunities, IAdditionalFiltersApplied } from "../opportunities-filters.component";
import { FilterService, IGetById, ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { OpportunityStatusService } from "../../../../services/http/clients/opportunity-status.service";
import { DivisionService } from "../../../../services/http/clients/division.service";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";

@Component({
    selector: "app-opportunities-additional-filters",
    templateUrl: "./opportunities-additional-filters.component.html",
    styleUrls: ["./opportunities-additional-filters.component.scss"]
})
export class OpportunitiesAdditionalFiltersComponent implements OnInit, OnChanges {
    protected scrollStrategy: ScrollStrategy;

    @Input()
    additionalFilters: IAdditionalFiltersOpportunities;

    @Output()
    additionalFiltersChange: EventEmitter<IAdditionalFiltersOpportunities>;

    @Input()
    removeFilter?: IAdditionalFiltersApplied;

    selectedFilters: IAdditionalFiltersOpportunities;
    opened: boolean;
    placeholder: string;
    divisions?: DivisionView[];
    statuses?: OpportunityStatusView[];
    companiesServiceFilter: ITypeAheadService<CompanyView, QuickSearchCompaniesParameters>;
    userServiceFilter: ITypeAheadService<UserView, SearchUsersParameters>;
    userRoleIds: UserRoles[];
    disableClearBtn: boolean;
    filtersApplied: boolean;

    constructor(
        private readonly companyService: CompanyService,
        private readonly userService: UserService,
        private readonly opportunityStatusService: OpportunityStatusService,
        private readonly divisionService: DivisionService,
        private readonly filterService: FilterService,
        private readonly overlay: Overlay,
        private readonly objectExtensionService: ObjectExtensionsService
    ) {
        this.selectedFilters = {};
        this.additionalFilters = {};
        this.additionalFiltersChange = new EventEmitter<IAdditionalFiltersOpportunities>();
        this.opened = false;
        this.placeholder = "Additional filters";
        this.companiesServiceFilter = companyService;
        this.userServiceFilter = userService;
        this.userRoleIds = [UserRoles.SalesPerson];
        this.statuses = [];
        this.divisions = [];
        this.disableClearBtn = false;
        this.filtersApplied = false;
        this.scrollStrategy = this.overlay.scrollStrategies.close();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["additionalFilters"]?.currentValue) {
            this.selectedFilters = this.objectExtensionService.clone(this.additionalFilters);
            this.filtersApplied = this.someFilterApplied();
            this.disableClearBtn = !this.someFilterApplied();
        }

        if (changes["removeFilter"] && !changes["removeFilter"].firstChange) {
            this.onRemoveFilter(changes["removeFilter"].currentValue);
        }
    }

    async ngOnInit(): Promise<void> {
        this.statuses = await this.opportunityStatusService.getAll();
        this.divisions = await this.divisionService.search({ isActive: true } as SearchDivisionsParameters);

        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();
        if (this.someFilterApplied()) this.emitAdditionalFilters();
    }

    toggleDropdown(): void {
        this.opened = !this.opened;
    }

    onKeyPress(e: KeyboardEvent) {
        if (e.key === "Enter" || e.key === "Escape") {
            this.toggleDropdown();
        }
    }

    closeDropdown() {
        this.opened = false;
    }

    onFilterChanged() {
        this.disableClearBtn = !this.someFilterApplied();
    }

    async onRemoveFilter(filter?: IAdditionalFiltersApplied): Promise<void> {
        if (filter && Object.keys(filter).length) {
            if (this.selectedFilters && filter.key in this.selectedFilters) {
                const appliedFilter = (this.selectedFilters as any)[filter.key];
                if (Array.isArray(appliedFilter) && appliedFilter.length) {
                    const index = appliedFilter.findIndex((f: any) => f.name === filter.value);

                    if (index > -1) {
                        appliedFilter[index] = undefined;
                        (this.selectedFilters as any)[filter.key] = appliedFilter.filter((f: any) => f);
                    }
                } else {
                    (this.selectedFilters as any)[filter.key] = undefined;
                }
            } else {
                const rangeFiltersKeys = Object.keys(this.selectedFilters ?? []).filter(
                    item => item.toLowerCase().includes("from") || item.toLowerCase().includes("to")
                );
                if (rangeFiltersKeys.length) {
                    for (const item of rangeFiltersKeys) {
                        if (item.includes(filter.key)) {
                            (this.selectedFilters as any)[item] = undefined;
                        }
                    }
                }
            }
            this.emitAdditionalFilters();
        }

        await this.updateFilterUrlParams();
    }

    async onClear(): Promise<void> {
        for (const key in this.selectedFilters) {
            if (Object.hasOwn(this.selectedFilters, key)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.selectedFilters as any)[key] = undefined;
            }
        }
        this.filtersApplied = this.someFilterApplied();
        await this.updateFilterUrlParams();
        this.selectedFilters = {};
        this.emitAdditionalFilters();
        this.closeDropdown();
    }

    async onApply(): Promise<void> {
        if (Object.keys(this.selectedFilters).length) {
            await this.updateFilterUrlParams();
            this.emitAdditionalFilters();
            this.filtersApplied = this.someFilterApplied();
        }
        this.closeDropdown();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serializeTypeAhead<IAdditionalFiltersOpportunities, CompanyView>("companies", this.additionalFilters.companies, "id", true);
        await this.filterService.serializeArray<IAdditionalFiltersOpportunities, DivisionView>(this.additionalFilters, "divisions", "id", true);
        await this.filterService.serializeTypeAhead<IAdditionalFiltersOpportunities, UserView>("salesperson", this.additionalFilters.salesperson, "externalId");
        await this.filterService.serializeArray<IAdditionalFiltersOpportunities, OpportunityStatusView>(this.additionalFilters, "statuses", "id", true);
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "probabilityFrom");
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "probabilityTo");
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "potentialValueFrom");
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "potentialValueTo");
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "proposedGrossMarginFrom");
        await this.filterService.serialize<IAdditionalFiltersOpportunities>(this.additionalFilters, "proposedGrossMarginTo");
    }

    private async deserializeUrlParams() {
        await this.filterService.deserializeTypeAhead<IAdditionalFiltersOpportunities, CompanyView>(
            this.additionalFilters,
            "companies",
            this.companyService as IGetById,
            true
        );
        this.filterService.deserializeArray<IAdditionalFiltersOpportunities, DivisionView>(
            this.additionalFilters,
            "divisions",
            "id",
            this.divisions ?? [],
            true
        );
        await this.filterService.deserializeTypeAhead<IAdditionalFiltersOpportunities, UserView>(
            this.additionalFilters,
            "salesperson",
            this.userService as IGetById
        );
        this.filterService.deserializeArray<IAdditionalFiltersOpportunities, OpportunityStatusView>(
            this.additionalFilters,
            "statuses",
            "id",
            this.statuses ?? [],
            true
        );
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "probabilityFrom");
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "probabilityTo");
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "potentialValueFrom");
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "potentialValueTo");
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "proposedGrossMarginFrom");
        this.filterService.deserializeNumber<IAdditionalFiltersOpportunities>(this.additionalFilters, "proposedGrossMarginTo");
    }

    private someFilterApplied(): boolean {
        return Object.values(this.selectedFilters).some(f => (Array.isArray(f) ? f.length : f));
    }

    private emitAdditionalFilters() {
        this.additionalFilters = this.objectExtensionService.clone(this.selectedFilters);
        this.additionalFiltersChange.emit(this.additionalFilters);
    }
}
