<div class="container">
    <div
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="header"
        (click)="openClose()"
        (keydown)="onHeaderKeydown($event)"
        [ngClass]="{
            opened: opened,
            disabled: disabled,
            error: error,
            readOnly: isReadOnly,
            placeholder: (dateRangeEnabled && !dateFrom && !dateTo) || (!dateRangeEnabled && !selectedDate),
            empty: !selectedDate
        }"
        role="button"
        tabindex="0"
        [title]="defaultTitle"
    >
        {{ isReadOnly && !selectedDate ? "" : title ?? defaultTitle }}
    </div>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="opened"
        [cdkConnectedOverlayWidth]="flexibleSize && trigger.elementRef.nativeElement.offsetWidth"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-custom-backdrop'"
        [cdkConnectedOverlayPush]="true"
        (backdropClick)="opened = false"
        (overlayOutsideClick)="opened = false"
        (detach)="opened = false"
    >
        <div class="panel" [ngClass]="{ flexibleSize: flexibleSize }">
            <lib-datepicker
                #datepicker
                [defaultTitle]="defaultTitle"
                [dateRangeEnabled]="dateRangeEnabled"
                [rangeDirection]="rangeDirection"
                [displaySelected]="displaySelected"
                [customDefaultRanges]="customDefaultRanges"
                [timeEnabled]="timeEnabled"
                [calendarTypeDisplay]="calendarTypeDisplay"
                (titleChange)="onTitleChanged($event)"
                [(selectedDate)]="selectedDateTime"
                [(dateFrom)]="dateTimeFrom"
                [(dateTo)]="dateTimeTo"
                [minDate]="minDateTime"
                (saveDate)="onChanged()"
                (resetDate)="onChanged()"
            >
            </lib-datepicker>
        </div>
    </ng-template>
</div>
