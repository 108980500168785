import { Injectable } from "@angular/core";
import { IProductTypeClient, ProductTypeClient, ProductTypeView, ProductTypeViewPaginatedResultDto, SearchProductTypeParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ProductTypeService {
    private client: IProductTypeClient;
    constructor() {
        this.client = new ProductTypeClient();
    }

    async getAll(): Promise<ProductTypeView[]> {
        return await this.client.productTypeGetAll();
    }

    async searchProductTypes(body: SearchProductTypeParameters): Promise<ProductTypeViewPaginatedResultDto> {
        return await this.client.productTypeSearchProductTypes(body);
    }
}
