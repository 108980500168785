<div class="datepicker-filter" [id]="datepickerId | idGenerator: 'ngx-datepicker-'">
    <div class="datepicker-left-panel" *ngIf="dateRangeEnabled">
        <div class="default-range-buttons">
            <div *ngFor="let range of defaultRanges">
                <button
                    *ngIf="!range.disabled"
                    tabindex="0"
                    class="range-selection-button resetStyles"
                    [ngClass]="{ selected: range.key === dateRange }"
                    (click)="onSelectRange(range.key)"
                >
                    {{ range.text }}
                </button>
            </div>
        </div>
        <div class="datepicker-footer">
            <button class="datepicker-clear" (click)="onClear()" tabindex="0">Clear</button>
            <button class="datepicker-apply" (click)="onApply()" tabindex="0">Apply</button>
        </div>
    </div>
    <div class="datepicker-right-panel">
        <p *ngIf="!dateRangeEnabled && displaySelected" class="selected-date">
            {{ inputSelectedDateValue }} <span *ngIf="timeValue"> - {{ timeValue }}</span>
        </p>
        <div class="from-to-input" *ngIf="dateRangeEnabled">
            <input type="text" placeholder="From" [(ngModel)]="inputFromValue" disabled />
            <span>-</span>
            <input type="text" placeholder="To" [(ngModel)]="inputToValue" disabled />
        </div>
        <div class="calendar datepicker-calendar">
            <lib-calendar
                #calendar
                [dateRangeEnabled]="dateRangeEnabled"
                [minDate]="minDate"
                [dateFrom]="dateFrom"
                (dateFromChange)="onDateFromCalendarChange($event)"
                [dateTo]="dateTo"
                (dateToChange)="onDateToCalendarChange($event)"
                [selectedDate]="selectedDate"
                (selectedDateChange)="onSelectedDateChange($event)"
                [(showCalendarSelection)]="showCalendarsSelection"
                [calendarTypeDisplay]="calendarTypeDisplay"
                [isFullView]="isFullView"
            >
            </lib-calendar>
        </div>
        <div class="datepicker-footer" *ngIf="!dateRangeEnabled">
            <input class="timeInput" *ngIf="!dateRangeEnabled && timeEnabled" type="time" [(ngModel)]="timeValue" (ngModelChange)="onTimeChange($event)" />
            <div *ngIf="calendarTypeDisplay === monthCalendarType" [ngStyle]="{ width: timeEnabled ? 'auto' : '100%' }">
                <button class="secondary-btn small" (click)="onClear()" tabindex="0">Clear</button>
                <button class="primary-btn small" (click)="onApply()" tabindex="0">Apply</button>
            </div>
        </div>
    </div>
</div>
