import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
    GridActionsComponent,
    GridViewComponent,
    TabViewComponent,
    GridTabViewComponent,
    CalendarComponent,
    DatepickerComponent,
    DatepickerDropdownComponent,
    SelectedSummaryComponent,
    SearchInputComponent,
    FilterSearchInputComponent,
    FilterGenericDropdownComponent,
    GenericMenuComponent,
    GenericSideNavComponent,
    FilterGenericTypeAheadDropdownComponent,
    GenericTypeAheadMultiselectComponent,
    DropdownPanelComponent,
    SeparatorLineComponent,
    SectionHeaderComponent,
    ListViewComponent,
    BadgeComponent,
    SnackbarNotificationComponent,
    SnackbarNotificationsContainerComponent,
    SlideToggleComponent,
    FilterSlideToggleComponent,
    HelperInfoComponent,
    GenericCardComponent,
    EditorItemComponent,
    GenericTypeAheadDropdownComponent,
    SectionSubHeaderComponent,
    NoDataComponent,
    ExternalLinkComponent,
    StatusIndicatorComponent,
    DashboardCardComponent,
    TrendIndicatorComponent,
    DropdownRangeValuesComponent,
    FilterDropdownRangeValuesComponent,
    CustomInputWithIconComponent,
    DynamicGridComponent,
    FullViewCalendarComponent,
    OptionsMenuComponent,
    GenericDropdownMultiselectComponent,
    CalendarViewComponent,
    TooltipComponent,
    CounterBadgeComponent
} from "../components";
import { GenericDropdownComponent } from "../components/generic-dropdown/generic-dropdown.component";
import { GenericGridComponent } from "../components/generic-grid/generic-grid.component";
import { TabItemComponent } from "../components/tab-view/tab-item/tab-item.component";
import { RouterModule } from "@angular/router";
import {
    GridFilterDirective,
    DetailPanelDirective,
    IntersectionListenerDirective,
    FocusTrapDirective,
    DragAndDropDirective,
    TooltipDirective,
    InputFormatterDirective
} from "../directives";
import { CommaSeparatedPipe, HighlightTextPipe, AmpmPipe, SortPipe, FormatNumberWithSuffixPipe } from "../pipes";
import { GridFiltersComponent } from "../components/grid-filters/grid-filters.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PortalModule } from "@angular/cdk/portal";
import { OverlayModule } from "@angular/cdk/overlay";
import { A11yModule } from "@angular/cdk/a11y";

@NgModule({
    declarations: [
        GenericGridComponent,
        GenericDropdownComponent,
        GenericDropdownMultiselectComponent,
        FilterGenericTypeAheadDropdownComponent,
        GenericTypeAheadDropdownComponent,
        TabViewComponent,
        TabItemComponent,
        GenericMenuComponent,
        GenericSideNavComponent,
        GridFilterDirective,
        GridFiltersComponent,
        GridActionsComponent,
        GridViewComponent,
        GridTabViewComponent,
        CalendarComponent,
        DatepickerComponent,
        DatepickerDropdownComponent,
        SelectedSummaryComponent,
        SearchInputComponent,
        FilterSearchInputComponent,
        FilterGenericDropdownComponent,
        DetailPanelDirective,
        DropdownPanelComponent,
        SectionHeaderComponent,
        SeparatorLineComponent,
        IntersectionListenerDirective,
        ListViewComponent,
        FocusTrapDirective,
        BadgeComponent,
        SnackbarNotificationComponent,
        SnackbarNotificationsContainerComponent,
        SlideToggleComponent,
        FilterSlideToggleComponent,
        GenericTypeAheadMultiselectComponent,
        HelperInfoComponent,
        GenericCardComponent,
        EditorItemComponent,
        SectionSubHeaderComponent,
        NoDataComponent,
        ExternalLinkComponent,
        StatusIndicatorComponent,
        DashboardCardComponent,
        TrendIndicatorComponent,
        HighlightTextPipe,
        DropdownRangeValuesComponent,
        FilterDropdownRangeValuesComponent,
        CustomInputWithIconComponent,
        CommaSeparatedPipe,
        DynamicGridComponent,
        FullViewCalendarComponent,
        CalendarViewComponent,
        AmpmPipe,
        SortPipe,
        DragAndDropDirective,
        OptionsMenuComponent,
        TooltipDirective,
        TooltipComponent,
        InputFormatterDirective,
        FormatNumberWithSuffixPipe,
        CounterBadgeComponent
    ],
    imports: [CommonModule, BrowserModule, RouterModule, FormsModule, ReactiveFormsModule, OverlayModule, PortalModule, A11yModule],
    exports: [
        GenericGridComponent,
        GenericDropdownComponent,
        GenericDropdownMultiselectComponent,
        FilterGenericTypeAheadDropdownComponent,
        GenericTypeAheadDropdownComponent,
        TabViewComponent,
        TabItemComponent,
        GenericMenuComponent,
        GenericSideNavComponent,
        GridFilterDirective,
        GridFiltersComponent,
        GridActionsComponent,
        GridViewComponent,
        GridTabViewComponent,
        CalendarComponent,
        DatepickerComponent,
        DatepickerDropdownComponent,
        SelectedSummaryComponent,
        SearchInputComponent,
        FilterSearchInputComponent,
        FilterGenericDropdownComponent,
        DetailPanelDirective,
        DropdownPanelComponent,
        SectionHeaderComponent,
        SearchInputComponent,
        SeparatorLineComponent,
        IntersectionListenerDirective,
        ListViewComponent,
        FocusTrapDirective,
        BadgeComponent,
        SnackbarNotificationComponent,
        SnackbarNotificationsContainerComponent,
        SlideToggleComponent,
        FilterSlideToggleComponent,
        GenericTypeAheadMultiselectComponent,
        HelperInfoComponent,
        GenericCardComponent,
        EditorItemComponent,
        SectionSubHeaderComponent,
        NoDataComponent,
        ExternalLinkComponent,
        StatusIndicatorComponent,
        DashboardCardComponent,
        TrendIndicatorComponent,
        HighlightTextPipe,
        DropdownRangeValuesComponent,
        FilterDropdownRangeValuesComponent,
        CustomInputWithIconComponent,
        CommaSeparatedPipe,
        DynamicGridComponent,
        FullViewCalendarComponent,
        CalendarViewComponent,
        AmpmPipe,
        SortPipe,
        DragAndDropDirective,
        TooltipDirective,
        TooltipComponent,
        InputFormatterDirective,
        FormatNumberWithSuffixPipe,
        CounterBadgeComponent
    ],
    providers: [FormatNumberWithSuffixPipe]
})
export class NgxLibModule {}
