<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Contacts available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'firstName', label: 'First Name', sortable: true, tooltip: true },
                { key: 'lastName', label: 'Last Name', sortable: true, tooltip: true },
                { key: 'phone', label: 'Phone', sortable: false, tooltip: true, cellTemplate: formatPhoneArray },
                { key: 'email', label: 'Email', sortable: false, tooltip: true, cellTemplate: formatEmailArray },
                { key: 'companyName', label: 'Company', sortable: true, tooltip: true },
                { key: 'isActive', label: 'Condition', sortable: false, cellTemplate: cellIsActive }
            ]
        }"
        [source]="contacts"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #formatEmailArray let-data>
            {{ data.object.email | commaSeparated }}
        </ng-template>

        <ng-template #formatPhoneArray let-data>
            {{ data.object.phone | commaSeparated }}
        </ng-template>

        <ng-template #cellIsActive let-data>
            {{ data.object.isActive ? "Active" : "Archived" }}
        </ng-template>
    </lib-grid-view>
</ng-template>
