import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    marketsEditorConfig,
    opportunityDueDateEditorConfig,
    opportunitySourceEditorConfig,
    projectEndDateEditorConfig,
    projectStartDateEditorConfig,
    relatedOpportunitiesConditionEditorConfig,
    salesPersonsEditorConfig
} from "./shared/additional-filters";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ReportType } from "../../types/report-type.enum";
import { ReportingAccountService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-account.service";
import { truncateValue } from "projects/app/src/app/utils/format-functions";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";

export const Accounts: IReportConfiguration<ReportingAccountService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ReportingAccountService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getAccountBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getAccountMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Avg" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [{ name: "AwardedAmount", label: "Awarded Amount", format: "currency" }]
                }
            },
            {
                methodName: "getAccountLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Accounts by Awarded Amount"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "accountName",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        },
                        {
                            type: "line",
                            xKey: "accountName",
                            yKey: "awardedCount",
                            yName: "Awarded Count"
                        },
                        {
                            type: "line",
                            xKey: "accountName",
                            yKey: "opportunityQty",
                            yName: "Opportunities Count"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Quantity"
                            },
                            keys: ["awardedCount", "opportunityQty"]
                        }
                    ]
                }
            },
            {
                methodName: "getAccountColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "awardedAmount",
                            yName: "Awarded Amount"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["awardedAmount"]
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.ExtendedTwoPlusOne
    },
    gridTab: {
        widgets: [
            {
                methodName: "getAccountValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "accountName", headerName: "Account Name", lockVisible: true, enableRowGroup: true },
                    { field: "accountType", headerName: "Account Type", enableRowGroup: true },
                    { field: "accountMarket", headerName: "Account Market" },
                    { field: "opportunityQty", headerName: "Opportunities (qty)", aggFunc: "sum" },
                    { field: "salesPersons", headerName: "Salespersons", enableRowGroup: true },
                    { field: "awardedCount", headerName: "Awarded Count", aggFunc: "sum" },
                    { field: "awardedAmount", headerName: "Awarded Amount", type: "currency", aggFunc: "sum" },
                    { field: "organization", headerName: "Organization", enableRowGroup: true },
                    { field: "condition", headerName: "Condition", enableRowGroup: true },
                    { field: "accountLastUpdatedNote", headerName: "Last Updated Note", tooltipField: "accountLastUpdatedNote" },
                    { field: "modificationDate", headerName: "Modification Date", type: "date" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            opportunityDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            accountEditorConfig,
            relatedOpportunitiesConditionEditorConfig,
            conditionEditorConfig,
            salesPersonsEditorConfig
        ]
    }
};
