<div class="mapContainer">
    <div *ngIf="selectedMarkerData" class="infoPanelContainer">
        <header>
            <lib-counter-badge [label]="'Opportunities'" [count]="opportunitiesCounter || 0"></lib-counter-badge>
            <button (click)="closePanel()" [innerHTML]="closeIcon" class="resetStyles closeButton" aria-label="Close panel"></button>
        </header>
        <ng-container *ngFor="let item of selectedMarkerData" class="itemContent">
            <app-opportunity-card-expanded
                [data]="item"
                [showEventInfo]="false"
                [allowCloseCard]="false"
                (goToClicked)="onGoToClicked(item?.id)"
            ></app-opportunity-card-expanded>
        </ng-container>
    </div>

    <div id="map"></div>
</div>

<ng-container #popupContainer></ng-container>
