<ng-container *ngIf="!isReadOnly; else linkTemplate">
    <input
        type="text"
        [(ngModel)]="bindingProperty"
        (ngModelChange)="dataChanged()"
        [placeholder]="(isReadOnly && !bindingProperty) || !label ? '' : 'Add ' + label"
        [class.error]="!isValid"
    />
    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</ng-container>

<ng-template #linkTemplate>
    <a *ngIf="bindingProperty" [href]="bindingProperty | ensureHttps" target="_blank" rel="noopener noreferrer" appEnsureHttps>
        <button class="resetStyles">
            <ng-container [ngSwitch]="fieldName">
                <ng-container *ngSwitchCase="'linkedin'">
                    <div class="icon" [innerHTML]="profileIcon"></div>
                    <span>Visit profile</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="icon" [innerHTML]="websiteIcon"></div>
                    <span>Visit website</span>
                </ng-container>
            </ng-container>
        </button>
    </a>
</ng-template>
