import { Component, EventEmitter, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BaseFilterComponent } from "../base-filter/base-filter.component";
import { GenericDropdownComponent } from "../../generic-dropdown/generic-dropdown.component";
import { Overlay } from "@angular/cdk/overlay";

@Component({
    selector: "lib-filter-generic-dropdown",
    templateUrl: "../../generic-dropdown/generic-dropdown.component.html",
    styleUrls: ["../../generic-dropdown/generic-dropdown.component.scss"]
})
export class FilterGenericDropdownComponent<T> extends GenericDropdownComponent<T> implements BaseFilterComponent, OnChanges {
    @Output()
    filterChanged: EventEmitter<void> = new EventEmitter<void>();

    constructor(overlay: Overlay) {
        super(overlay);
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    override emitItemChanged(item?: T) {
        this.selectedItemChange.emit(item);
        this.filterChanged.emit();
    }
}
