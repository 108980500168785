import { Component, Input } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";

@Component({
    selector: "app-report-scheduler-status-indicator",
    templateUrl: "./report-scheduler-status-indicator.component.html",
    styleUrls: ["./report-scheduler-status-indicator.component.scss"]
})
export class ReportSchedulerStatusIndicatorComponent {
    @Input()
    schedulerStatusId?: number;

    scheduleIcon: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.scheduleIcon = this.iconSanitizer.getIcon("schedule");
    }
}
