<lib-generic-type-ahead-multiselect
    [source]="items"
    [(selectedItems)]="selectedItems"
    (selectedItemsChange)="onSelectedItemsChange()"
    [config]="{
        defaultText: 'Select ' + label,
        multiple: true,
        itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name'
    }"
    [placeholder]="placeholder"
    (textChange)="onEntityTextChange($event)"
>
</lib-generic-type-ahead-multiselect>
