import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import {
    CustomReportView,
    ReportTemplateView,
    SearchReportTemplateParameters
} from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";
import { IListElement } from "../../../shared/titled-list-group/list-element/list-element.component";
import {
    CustomReportSearchService,
    IReportsSearchService
} from "projects/app/src/app/services/http/clients/reporting-app/custom-report/custom-report-search.service";
import { ModalConfirmComponent } from "../../../shared/modals/modal-confirm/modal-confirm.component";
import { ModalService } from "projects/ngx-lib/src/public-api";

interface IFilterValueReportsList {
    searchText?: string;
    onlyFavorites?: boolean;
}

interface IReportsList {
    title?: string;
    items: IListElement[];
}

@Component({
    selector: "app-reports-list",
    templateUrl: "./reports-list.component.html",
    styleUrls: ["./reports-list.component.scss"]
})
export class ReportsListComponent implements OnInit {
    @Input()
    service!: IReportsSearchService<CustomReportView | ReportTemplateView>;

    @Input()
    name?: string;

    @Output()
    tabCounterChange: EventEmitter<number>;

    filterValue: IFilterValueReportsList;

    groupedList: IReportsList[];

    searchConfig!: SearchReportTemplateParameters;

    reportList: (CustomReportView | ReportTemplateView)[];

    constructor(
        private readonly awaiter: AwaiterService,
        private readonly modalService: ModalService,
        private readonly customReportService: CustomReportSearchService
    ) {
        this.tabCounterChange = new EventEmitter<number>();
        this.reportList = [];
        this.groupedList = [];

        this.filterValue = {
            onlyFavorites: false,
            searchText: ""
        };
    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }

    async loadData(): Promise<void> {
        this.buildSearchConfig();
        await this.awaiter.awaitAction("Loading Reports", async () => {
            if (this.service) {
                this.reportList = await this.service.searchReports(this.searchConfig);
                this.groupItems(this.reportList);
                this.tabCounterChange.emit(this.reportList.length);
            }
        });
    }

    groupItems(data: (CustomReportView | ReportTemplateView)[]): void {
        const groups: { [key: string]: IReportsList } = {};

        data.forEach(item => {
            const groupId = item.reportTemplateGroupId ?? 0;
            const groupName = item.reportTemplateGroupName ?? "No Group Assigned";

            if (!groups[groupId]) {
                groups[groupId] = {
                    title: groupName,
                    items: []
                };
            }

            groups[groupId].items.push({
                id: item.id,
                title: item.name,
                subtitle: (item as CustomReportView).reportTemplateName,
                link: this.getLink(item),
                canRemoveItem: this.canDeleteItem(item as CustomReportView),
                isFavorite: item.isFavorite ?? false
            });
        });

        this.groupedList = Object.values(groups);
    }

    getLink(item: ReportTemplateView | CustomReportView) {
        return this.name !== "Templates" ? `${item.reportTemplateUrl}/${item.id}` : item.reportTemplateUrl;
    }

    async onFiltersChanged(): Promise<void> {
        await this.loadData();
    }

    async onToggleFavorite(item: IListElement) {
        await this.awaiter.awaitAction("Updating Favorite", async () => {
            const response = await this.service.toggleFavorite(item.id);
            item.isFavorite = response;
        });
    }

    async onRemoveItem(itemId: number): Promise<void> {
        await this.loadData();
        const report = this.reportList.find(el => el.id === itemId);
        if (!report || !this.canDeleteItem(report as CustomReportView)) throw new Error("You do not have permission to delete this report");

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Delete",
            cancelCaption: "Cancel",
            content: "This report will be deleted including all the data. This action cannot be undone. Are you sure you want to delete it?",
            title: "Delete Report",
            isReject: true
        });

        if (!responseOk) return;

        await this.awaiter.awaitAction("Deleting Report", async () => {
            await this.customReportService.deactivate(itemId);

            const deletedItemIndex = this.reportList.findIndex(r => r.id === itemId);

            if (deletedItemIndex > -1) this.reportList.splice(deletedItemIndex, 1);

            this.groupItems(this.reportList);
        });
    }

    private buildSearchConfig(): void {
        this.searchConfig = {
            filterText: this.filterValue.searchText,
            onlyFavorites: this.filterValue.onlyFavorites
        } as SearchReportTemplateParameters;
    }

    private canDeleteItem(item: CustomReportView) {
        return !!item.reportTemplateId && !item.isPublic;
    }
}
