import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ArchiveEntityOptionView, CompanyView, DivisionView, OpportunityStatusView, UserView } from "../../../services/http/clients/api-proxies";
import { FilterService } from "projects/ngx-lib/src/lib/services/filter.service";
import { ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { Condition } from "../../../models/enums/Condition";
import { ArchiveEntityService } from "../../../services/http/clients/archive-entity.service";

export type FilterValueOpportunities = IMainFiltersOpportunities & IAdditionalFiltersOpportunities;

export interface IAdditionalFiltersOpportunities {
    companies?: CompanyView[];
    divisions?: DivisionView[];
    salesperson?: UserView;
    statuses?: OpportunityStatusView[];
    probabilityFrom?: number;
    probabilityTo?: number;
    potentialValueFrom?: number;
    potentialValueTo?: number;
    proposedGrossMarginFrom?: number;
    proposedGrossMarginTo?: number;
}

export interface IAdditionalFiltersApplied {
    key: string;
    name: string;
    value: string;
    order: number;
}

export interface IMainFiltersOpportunities {
    searchText?: string;
    onlyFavorites?: boolean;
    dateFrom?: Date;
    dateTo?: Date;
    activeStatus?: ArchiveEntityOptionView;
}

@Component({
    selector: "app-opportunities-filters",
    templateUrl: "./opportunities-filters.component.html",
    styleUrls: ["./opportunities-filters.component.scss"]
})
export class OpportunitiesFiltersComponent implements OnInit, OnChanges {
    @Input()
    filters?: FilterValueOpportunities;

    @Output()
    filtersChange: EventEmitter<FilterValueOpportunities>;

    @Input()
    mainFiltersToClear?: string[];

    mainFilters: IMainFiltersOpportunities;
    additionalFilters: IAdditionalFiltersOpportunities;
    disabledClearFiltersBtn: boolean;
    removeAdditionalFilter?: IAdditionalFiltersApplied;
    activeStatuses?: ArchiveEntityOptionView[];

    constructor(
        private readonly filterService: FilterService,
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly archiveEntityService: ArchiveEntityService
    ) {
        this.filtersChange = new EventEmitter<FilterValueOpportunities>();
        this.mainFilters = {};
        this.additionalFilters = {};
        this.disabledClearFiltersBtn = false;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["filters"]?.currentValue) {
            this.additionalFilters = this.objectExtensionService.pickProperties(changes["filters"]?.currentValue, Object.keys(this.additionalFilters));
        }

        if (this.mainFiltersToClear && changes["mainFiltersToClear"]) {
            this.clearFilters(this.mainFilters, this.mainFiltersToClear);
        }
    }

    async ngOnInit(): Promise<void> {
        this.activeStatuses = await this.archiveEntityService.getArchiveOptions();

        this.mainFilters = {
            ...(this.activeStatuses?.length && { activeStatus: this.activeStatuses.find(c => c.id === Condition.Active) })
        };

        await this.deserializeUrlParams();
        await this.updateFilterUrlParams();

        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    async onFiltersChanged(): Promise<void> {
        await this.updateFilterUrlParams();
        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    async onClearAllFilters(): Promise<void> {
        this.clearFilters(this.mainFilters);
        this.clearFilters(this.additionalFilters);

        // undefined is a possible value to clear all filters but I use an empty object because otherwise
        // when applying filters the first time and deleting them all,
        // if I keep undefined value the ngOnChanges of OpportunitiesAdditionalFiltersComponent will not detect it
        this.removeAdditionalFilter = {} as IAdditionalFiltersApplied;
    }

    onAdditionalFiltersChange() {
        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    onRemoveAdditionalFilter(filter: IAdditionalFiltersApplied) {
        this.removeAdditionalFilter = filter;
    }

    private buildFilters(): void {
        this.filters = {
            searchText: this.mainFilters.searchText ? this.mainFilters.searchText : undefined,
            onlyFavorites: this.mainFilters.onlyFavorites,
            dateFrom: this.mainFilters.dateFrom,
            dateTo: this.mainFilters.dateTo,
            activeStatus: this.mainFilters.activeStatus,
            companies: this.additionalFilters.companies,
            divisions: this.additionalFilters.divisions,
            salesperson: this.additionalFilters.salesperson,
            statuses: this.additionalFilters.statuses,
            probabilityFrom: this.additionalFilters.probabilityFrom,
            probabilityTo: this.additionalFilters.probabilityTo,
            potentialValueFrom: this.additionalFilters.potentialValueFrom,
            potentialValueTo: this.additionalFilters.potentialValueTo,
            proposedGrossMarginFrom: this.additionalFilters.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.additionalFilters.proposedGrossMarginTo
        };
        this.disabledClearFilters();
    }

    private async updateFilterUrlParams(): Promise<void> {
        await this.filterService.serialize<IMainFiltersOpportunities>(this.mainFilters, "searchText");
        await this.filterService.serialize<IMainFiltersOpportunities>(this.mainFilters, "onlyFavorites");
        await this.filterService.serializeDate<IMainFiltersOpportunities>(this.mainFilters, "dateFrom");
        await this.filterService.serializeDate<IMainFiltersOpportunities>(this.mainFilters, "dateTo");
        await this.filterService.serializeArray<IMainFiltersOpportunities, ArchiveEntityOptionView>(this.mainFilters, "activeStatus", "id");
    }

    private async deserializeUrlParams() {
        this.filterService.deserialize<IMainFiltersOpportunities>(this.mainFilters, "searchText");
        this.filterService.deserializeBoolean<IMainFiltersOpportunities>(this.mainFilters, "onlyFavorites");
        this.filterService.deserializeDate<IMainFiltersOpportunities>(this.mainFilters, "dateFrom");
        this.filterService.deserializeDate<IMainFiltersOpportunities>(this.mainFilters, "dateTo");
        this.filterService.deserializeArray<IMainFiltersOpportunities, ArchiveEntityOptionView>(
            this.mainFilters,
            "activeStatus",
            "id",
            this.activeStatuses ?? []
        );
    }

    private disabledClearFilters(): void {
        this.disabledClearFiltersBtn = !this.someFilterApplied();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async clearFilters(filtersObject: any, keys: string[] = []) {
        if (keys.length === 0) {
            // Clear all filters
            for (const key in filtersObject) {
                if (Object.hasOwn(filtersObject, key)) {
                    filtersObject[key] = undefined;
                }
            }
        } else {
            // Clear specific filters
            for (const key of keys) {
                if (Object.hasOwn(filtersObject, key)) {
                    filtersObject[key] = undefined;
                }
            }
        }

        await this.updateFilterUrlParams();
        this.buildFilters();
        this.filtersChange.emit(this.filters);
    }

    private someFilterApplied(): boolean {
        if (!this.filters) return false;
        return Object.values(this.filters).some(f => (Array.isArray(f) ? f.length : f));
    }
}
