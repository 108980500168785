import { GridLayout, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import { ServiceModulesService } from "projects/app/src/app/services/http/clients/reporting-app/service-modules.service";
import { WidgetType } from "../../types/report-widget-type.enum";
import {
    projectDueDateEditorConfig,
    projectStartDateEditorConfig,
    projectEndDateEditorConfig,
    projectStatusEditorConfig,
    marketsEditorConfig,
    opportunitySourceEditorConfig
} from "./shared/additional-filters";
import { salesMainFilters } from "./shared/main-filters";

export const ServiceModules: IReportConfiguration<ServiceModulesService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ServiceModulesService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "subcontractor", label: "Subcontractor" },
                        { name: "expense", label: "Expenses" },
                        { name: "estimateCustom", label: "Custom" },
                        { name: "laborCost", label: "Labor" },
                        { name: "materialCost", label: "Material" }
                    ],
                    rows: [
                        { name: "GM", label: "Gross Margin (%)", format: "customPercent" },
                        { name: "SellPrice", label: "Sell Price ", format: "currency" },
                        { name: "Percentage", label: "Percentage", format: "percent" }
                    ]
                }
            },
            {
                methodName: "getLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Services by Sell Price"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "sellPrice",
                            yName: "Total Sell Price "
                        },
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "grossMarginPercent",
                            yName: "Total Gross Margin (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["sellPrice"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["grossMarginPercent"],
                            label: {
                                formatter: function (params: any) {
                                    return params.value * 100 + "%";
                                }
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "subcontractorSellPrice",
                            yName: "Total Subcontractor Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "expenseSellPrice",
                            yName: "Total Expenses Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "customSellPrice",
                            yName: "Total Custom Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "laborSellPrice",
                            yName: "Total Labor Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "materialSellPrice",
                            yName: "Total Material Sell Price"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom"
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["subcontractorSellPrice", "expenseSellPrice", "customSellPrice", "laborSellPrice", "materialSellPrice"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.overview4
    },
    gridTab: {
        widgets: [
            {
                methodName: "getValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "serviceName", headerName: "Service Name", lockVisible: true },
                    { field: "quantity", headerName: "Total in Awarded Final Estimates (qty)", lockVisible: true },
                    { field: "grossMargin", headerName: "Total Gross Margin (%)", type: "customPercentage" },
                    { field: "sellPrice", headerName: "Total Sell Price", type: "currency" },
                    { field: "avgSellPrice", headerName: "Average Sell Price", type: "currency" },

                    { field: "subcontractorGM", headerName: "Total Subcontractor GM (%)", type: "customPercentage" },
                    { field: "subcontractorSellPrice", headerName: "Total Subcontractor Sell Price", type: "currency" },
                    { field: "subcontractorPercentage", headerName: "Subcontractor (%)", type: "percentage" },

                    { field: "expenseGM", headerName: "Total Expenses GM (%)", type: "customPercentage" },
                    { field: "expenseSellPrice", headerName: "Total Expenses Sell Price", type: "currency" },
                    { field: "expensePercentage", headerName: "Expenses (%)", type: "percentage" },

                    { field: "estimateCustomGM", headerName: "Total Custom GM (%)", type: "customPercentage" },
                    { field: "estimateCustomSellPrice", headerName: "Total Custom Sell Price", type: "currency" },
                    { field: "customPercentage", headerName: "Custom (%)", type: "percentage" },

                    { field: "laborCostGM", headerName: "Total Labor GM (%)", type: "customPercentage" },
                    { field: "laborCostSellPrice", headerName: "Total Labor Sell Price", type: "currency" },
                    { field: "laborCostPercentage", headerName: "Labor (%)", type: "percentage" },

                    { field: "materialCostGM", headerName: "Total Material GM (%)", type: "customPercentage" },
                    { field: "materialCostSellPrice", headerName: "Total Material Sell Price", type: "currency" },
                    { field: "materialCostPercentage", headerName: "Material (%)", type: "percentage" }
                ]
            }
        ],
        layout: GridLayout.grid1
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
