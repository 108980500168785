<lib-generic-dropdown
    [source]="markets"
    [config]="{
        multiple: false,
        defaultText: 'Select Market',
        itemDisplayKey: 'name'
    }"
    [(selectedItem)]="selectedMarket"
    (selectedItemChange)="onSelectMarket()"
>
</lib-generic-dropdown>
