<article class="container">
    <section class="filtersContainer">
        <app-opportunities-main-filters [currentTab]="currentTab" [activeStatuses]="activeStatuses"></app-opportunities-main-filters>

        <app-opportunities-additional-filters [statuses]="statuses" [divisions]="divisions" class="filter"></app-opportunities-additional-filters>

        <div class="clearFilters">
            <button class="tertiary-btn" [disabled]="disabledClearFiltersBtn" (click)="onClearAllFilters()">Clear filters</button>
        </div>
    </section>

    <section class="filtersAppliedContainer">
        <app-opportunities-additional-filters-applied></app-opportunities-additional-filters-applied>
    </section>
</article>
