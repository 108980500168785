import { ValueFormatterParams } from "ag-grid-enterprise";

export function currencyFormatter(params: ValueFormatterParams) {
    return "$" + formatNumber(params.value);
}
export function formatNumber(number: number) {
    return Math.floor(number).toLocaleString();
}

export function percentageFormatter(value: number | null) {
    if (value === undefined || value === null || isNaN(value)) return null;
    return (value * 100).toFixed(2) + "%";
}

export function truncateValue(value: string, maxLength = 20): string {
    return value.length > maxLength ? value.substring(0, maxLength) + "..." : value;
}
