import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { SearchContactsParameters, ContactView } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import { Router } from "@angular/router";
import { IFilterValueContacts } from "../contacts-main/contacts-main.component";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";

@Component({
    selector: "app-contacts-list",
    templateUrl: "./contacts-list.component.html",
    styleUrls: ["./contacts-list.component.scss"]
})
export class ContactsListComponent implements OnChanges {
    contacts: ContactView[];

    @Input()
    filters?: IFilterValueContacts;

    orderConfig?: IGenericGridOrderConfig<ContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly contactService: ContactService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService
    ) {
        this.contacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchContactsParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.contacts = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContacts(this.searchConfig);
                const { pageInfo, results } = response;
                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;
        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.companyId = this.filters?.company?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.contacts = [];

        await this.refreshGridView();
    }

    onRowClicked(contact: ContactView) {
        this.router.navigate([`/contacts-single/${contact.id}`]);
    }

    onMouseWheelClicked(contact: ContactView) {
        if (contact?.id && contact.id > 0) {
            window.open(`/contacts-single/${contact.id}`, "_blank");
        }
    }
}
