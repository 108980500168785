import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IGroupLevel } from "../group-by-rows-filter.component";
import { ColDef } from "ag-grid-enterprise";
import { SafeHtml } from "@angular/platform-browser";
import { IconSanitizerService } from "projects/ngx-lib/src/public-api";

@Component({
    selector: "app-column-selector",
    templateUrl: "./column-selector.component.html",
    styleUrls: ["./column-selector.component.scss"]
})
export class ColumnSelectorComponent {
    @Input()
    cols: ColDef[] = [];
    @Input()
    group!: IGroupLevel;
    @Input()
    selectedColumn?: ColDef;
    @Input()
    appliedGroups?: ColDef[];

    @Output()
    selectedColumnChange = new EventEmitter<ColDef>();
    @Output()
    closeOverlayEvent = new EventEmitter();

    arrowIcon?: SafeHtml;
    checkMarkIcon?: SafeHtml;

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.arrowIcon = this.iconSanitizer.getIcon("nextArrow");
        this.checkMarkIcon = this.iconSanitizer.getIcon("checkMark");
    }

    selectColumn(col: ColDef) {
        this.selectedColumn = col;
    }

    applySelection() {
        if (this.selectedColumn) {
            this.selectedColumnChange.emit(this.selectedColumn);
        }
    }

    closeOverlay() {
        this.closeOverlayEvent.emit();
    }

    isSelectedColumn(col: ColDef): boolean {
        if (this.appliedGroups && this.appliedGroups[this.group.level - 1]) {
            return this.appliedGroups[this.group.level - 1].field === col.field;
        }
        return false;
    }
}
