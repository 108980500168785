<div>
    <lib-generic-side-nav
        (toggleSidenav)="onSidenavToggle($event)"
        *ngIf="isLoggedIn"
        [currentUrl]="currentUrl"
        [source]="menu"
        [logoExpanded]="logoExpanded"
        [logoCollapsed]="logoCollapsed"
    ></lib-generic-side-nav>
    <section class="content" [ngClass]="{ loggedIn: isLoggedIn, shifted: isSidenavOpen }">
        <app-header [currentUrl]="currentUrl" [isLoggedIn]="isLoggedIn" [user]="user" class="header"></app-header>
        <app-loading-spinner [ngClass]="{ fadeIn: fade, fadeOut: !fade }" [placeholder]="loadingCaption" *ngIf="isLoading"></app-loading-spinner>
        <router-outlet></router-outlet>
    </section>
    <lib-snackbar-notifications-container></lib-snackbar-notifications-container>
</div>
