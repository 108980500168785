<div class="wrapper">
    <div class="avatar">
        <ng-container *ngIf="organization && organization.organizationImageUrl; else placeholder">
            <img [ngSrc]="organization.organizationImageUrl" alt="Organization logo" width="35" height="35" />
        </ng-container>

        <ng-template #placeholder>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 18 18">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.87">
                    <g fill="#85b1ce">
                        <g>
                            <path d="M9,0 L18,18 L12.693,18 L9,10.6137931 L5.307,18 L0,18 L9,0 Z" />
                        </g>
                    </g>
                </g>
            </svg>
        </ng-template>
    </div>

    <div class="information" libTooltip [tooltip]="organization?.organizationName" [position]="orgNameTooltipPosition">
        <span class="name">{{ organization?.organizationName }}</span>
        <div class="role">{{ organization?.userRoleDescription }}</div>
    </div>
</div>
