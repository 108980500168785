import { Injectable } from "@angular/core";
import { IProductTypeClient, ProductTypeClient, ProductTypeView, SearchProductTypesParameters } from "./api-proxies";

@Injectable({
    providedIn: "root"
})
export class ProductTypeService {
    private client: IProductTypeClient;
    constructor() {
        this.client = new ProductTypeClient();
    }

    async searchProductTypes(body: SearchProductTypesParameters): Promise<ProductTypeView[]> {
        return await this.client.productTypeSearchProductTypes(body);
    }

    async save(body: ProductTypeView[]): Promise<ProductTypeView[]> {
        return await this.client.productTypeSave(body);
    }

    async activate(id: number): Promise<void> {
        return await this.client.productTypeActivate(id);
    }

    async deactivate(id: number): Promise<void> {
        return await this.client.productTypeDeactivate(id);
    }
}
