import { Component, Input } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { AlertType } from "../../../interfaces/alert-type.interface";

interface ITitlePrefix {
    text: string;
    textColor?: AlertType;
}

@Component({
    selector: "app-custom-menu-item",
    templateUrl: "./custom-menu-item.component.html",
    styleUrls: ["./custom-menu-item.component.scss"]
})
export class CustomMenuItemComponent {
    @Input()
    icon?: SafeHtml;

    @Input()
    inputText: string;

    @Input()
    title: string;

    @Input()
    subtitle: string;

    @Input()
    titlePrefix?: ITitlePrefix;

    @Input()
    disabled?: boolean;

    constructor() {
        this.inputText = "";
        this.icon = undefined;
        this.title = "";
        this.subtitle = "";
    }
}
