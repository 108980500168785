<div class="filtersContainer">
    <div class="dropdown">
        <button
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            class="resetStyles toggleDropdownButton"
            (click)="toggleDropdown()"
            (keydown)="onKeyPress($event)"
            [ngClass]="{
                opened: opened,
                downArrow: !opened,
                upArrow: opened,
                placeholder: !filtersApplied
            }"
        >
            {{ placeholder }}
        </button>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-custom-backdrop"
    (backdropClick)="toggleDropdown()"
    (detach)="opened = false"
>
    <div class="itemsContainer" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="itemsContainerHeader">
            <div class="title">Additional filters</div>
            <div class="buttonsContainer">
                <button class="secondary-btn" (click)="onClear()" tabindex="0" [disabled]="disableClearBtn">Clear</button>
                <button class="primary-btn" (click)="onApply()" tabindex="0">Apply</button>
            </div>
        </div>

        <div class="filtersWrapper">
            <div class="filterItem">
                <div class="label">
                    <span>Customer</span>
                </div>
                <div class="filter">
                    <app-generic-type-ahead
                        placeholder="Customer"
                        [service]="companiesServiceFilter"
                        [config]="{
                            multiple: true,
                            defaultText: 'Customer',
                            itemDisplayKey: 'name'
                        }"
                        [filter]="{ pageSize: 20, isActive: true }"
                        [removeKey]="'id'"
                        [(selectedItems)]="selectedFilters.companies"
                        (filterChanged)="onFilterChanged()"
                    ></app-generic-type-ahead>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Potential Value ($)</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Potential Value ($)'"
                        [iconType]="'dollar'"
                        [(valueFrom)]="selectedFilters.potentialValueFrom"
                        [(valueTo)]="selectedFilters.potentialValueTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    ></lib-dropdown-range-values>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Potential GM (%)</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Potential GM (%)'"
                        [iconType]="'percentage'"
                        [(valueFrom)]="selectedFilters.proposedGrossMarginFrom"
                        [(valueTo)]="selectedFilters.proposedGrossMarginTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    ></lib-dropdown-range-values>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Division</span>
                </div>
                <div class="filter">
                    <lib-generic-dropdown-multiselect
                        [source]="divisions"
                        [config]="{
                            multiple: true,
                            defaultText: 'Division',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItems)]="selectedFilters.divisions"
                        (selectedItemsChange)="onFilterChanged()"
                    >
                    </lib-generic-dropdown-multiselect>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Salesperson</span>
                </div>
                <div class="filter">
                    <app-generic-type-ahead
                        placeholder="Salesperson"
                        [service]="userServiceFilter"
                        [config]="{
                            defaultText: 'Salesperson',
                            itemDisplayKey: 'fullName',
                            itemTemplate: userTemplate
                        }"
                        [filter]="{ roleIds: this.userRoleIds }"
                        [(selectedItem)]="selectedFilters.salesperson"
                        (filterChanged)="onFilterChanged()"
                    ></app-generic-type-ahead>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Status</span>
                </div>
                <div class="filter">
                    <lib-generic-dropdown-multiselect
                        [source]="statuses"
                        [config]="{
                            multiple: true,
                            defaultText: 'Status',
                            itemDisplayKey: 'name'
                        }"
                        [(selectedItems)]="selectedFilters.statuses"
                        (selectedItemsChange)="onFilterChanged()"
                    >
                    </lib-generic-dropdown-multiselect>
                </div>
            </div>

            <div class="filterItem">
                <div class="label">
                    <span>Probability</span>
                </div>
                <div class="filter">
                    <lib-dropdown-range-values
                        [placeholder]="'Set Probability'"
                        [minValue]="0"
                        [maxValue]="100"
                        [iconType]="'percentage'"
                        [(valueFrom)]="selectedFilters.probabilityFrom"
                        [(valueTo)]="selectedFilters.probabilityTo"
                        (valueFromChange)="onFilterChanged()"
                        (valueToChange)="onFilterChanged()"
                    >
                    </lib-dropdown-range-values>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #userTemplate let-data>
    <div class="userContainer">
        <p class="userCondition" *ngIf="!data.object?.isActive">Inactive</p>
        <p class="userName" [innerHTML]="data.object?.firstName + ' ' + data.object?.lastName | highlightText: data.inputText"></p>
        <p class="userEmail" [innerHTML]="data.object?.email | highlightText: data.inputText"></p>
    </div>
</ng-template>
