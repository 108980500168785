<div class="container">
    <ng-container *ngIf="entityComponentType === 'type-ahead-multiselect'; else defaultComponent">
        <lib-generic-type-ahead-multiselect
            [source]="source"
            [selectedItems]="selectedItems"
            (selectedItemsChange)="onSelectedItemChange()"
            [config]="{
                defaultText: 'Select ' + label,
                itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name'
            }"
            [placeholder]="(isReadOnly && !bindingProperty) || !label ? 'Select an Item' : 'Add ' + label"
            (textChange)="onEntityTextChange($event)"
            [isReadOnly]="isReadOnly"
            [error]="!isValid"
            [allowToSaveNewItem]="additionalInfo?.allowToSaveNewItem"
        >
        </lib-generic-type-ahead-multiselect>
    </ng-container>

    <ng-template #defaultComponent>
        <lib-generic-dropdown-multiselect
            name="entity"
            id="entity"
            [source]="source"
            [(selectedItems)]="selectedItems"
            (selectedItemsChange)="onSelectedItemChange()"
            [config]="{
                multiple: true,
                defaultText: 'Select ' + label,
                itemDisplayKey: additionalInfo?.itemDisplayKey ?? 'name'
            }"
            [isReadOnly]="isReadOnly"
            [error]="!isValid"
        >
        </lib-generic-dropdown-multiselect>
    </ng-template>

    <app-validation-error-message [show]="!isValid" [errors]="validationErrors"></app-validation-error-message>
</div>
