import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "customPercentage"
})
export class CustomPercentagePipe implements PipeTransform {
    transform(value?: number | string | null): string | null {
        if (value === undefined || value === null) return null;
        const numericValue = typeof value === "string" ? parseFloat(value) : value;

        if (numericValue && isNaN(numericValue)) return null;

        const formattedNumber = (numericValue * 100).toFixed(2);
        return `@${formattedNumber}%`;
    }
}
