import { Injectable } from "@angular/core";
import { IReportTemplateClient, ReportTemplateView, ReportTemplateClient, SearchReportTemplateParameters } from "./reporting-api-proxies";
import { IReportsSearchService } from "./custom-report/custom-report-search.service";

@Injectable({
    providedIn: "root"
})
export class ReportTemplateService implements IReportsSearchService<ReportTemplateView> {
    private client: IReportTemplateClient;
    constructor() {
        this.client = new ReportTemplateClient();
    }

    async searchReports(body: SearchReportTemplateParameters): Promise<ReportTemplateView[]> {
        const reportNames = [
            "Sales Leads",
            "Estimates Bid",
            "Estimates Awarded",
            "Opportunities",
            "Buildings",
            "Companies",
            "Change Orders",
            "Awarded Final Estimates",
            "Service Modules",
            "User Projects",
            "Accounts",
            "Service Terms",
            "Service Category Costs",
            "Service Subcategory Costs",
            "Service Costs"
        ];
        const crmReports = await this.client.reportTemplateSearchReportTemplates(body);
        return crmReports.filter(r => reportNames.includes(r.name ?? ""));
    }

    async getByRoute(filterText: string): Promise<ReportTemplateView> {
        return await this.client.reportTemplateGetByRoute(filterText);
    }

    async toggleFavorite(id: number): Promise<boolean> {
        return await this.client.reportTemplateToggleFavorite(id);
    }
}
