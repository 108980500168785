import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ITypeAheadDropdownEntityParameters } from "projects/app/src/app/interfaces/type-ahead-dropdown-entity-parameters.interface";
import { EntityTypes } from "projects/app/src/app/models/enums/EntityTypes";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { BuildingContactView, BuildingView, SearchBuildingsForContactParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { BuildingContactService } from "projects/app/src/app/services/http/clients/building-contact.service";
import { BuildingService } from "projects/app/src/app/services/http/clients/building.service";
import { IGenericGridOrderConfig, IGenericGridPaginationConfig } from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalConfirmComponent } from "../../../../shared/modals/modal-confirm/modal-confirm.component";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { ModalEditBuildingContactComponent } from "../../../../shared/modals/modal-edit-building-contact/modal-edit-building-contact.component";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";

@Component({
    selector: "app-contacts-single-buildings",
    templateUrl: "./contacts-single-buildings.component.html",
    styleUrls: ["./contacts-single-buildings.component.scss"]
})
export class ContactsSingleBuildingsComponent implements OnChanges {
    @Input()
    contactId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    buildings: BuildingContactView[];

    orderConfig?: IGenericGridOrderConfig<BuildingContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchBuildingsForContactParameters;

    typeAheadSearchByEntityParameters: ITypeAheadDropdownEntityParameters;

    noResults: boolean;

    isLoading: boolean;

    constructor(
        private readonly buildingService: BuildingService,
        private readonly buildingContactService: BuildingContactService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly modalService: ModalService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router
    ) {
        this.buildings = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            contactId: this.contactId
        } as SearchBuildingsForContactParameters;

        this.noResults = true;
        this.isLoading = true;

        this.typeAheadSearchByEntityParameters = {
            entityKeyName: EntityTypes.Contact
        };

        this.tabCounterChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.contactId && changes["contactId"]) {
            this.searchConfig.contactId = this.contactId;
            await this.refreshGridView();
        }
    }

    async refreshGridView(): Promise<void> {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Buildings",
            async () => {
                const response = await this.buildingService.searchBuildingsForContact(this.searchConfig);
                const { pageInfo, results } = response;
                this.buildings = this.buildings?.concat(results ?? []);

                this.noResults = this.buildings.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig(): void {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            contactId: this.contactId
        } as SearchBuildingsForContactParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.buildings = [];
        await this.refreshGridView();
    }

    onRowClicked(buildingContact: BuildingContactView): void {
        this.router.navigate(["buildings-single", buildingContact.buildingId, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(buildingContact: BuildingContactView): void {
        if (buildingContact?.buildingId && buildingContact.buildingId > 0) {
            window.open(`/buildings-single/${buildingContact.buildingId}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onSelectedBuilding(building: BuildingView): Promise<void> {
        if (!building || !this.contactId) return;
        try {
            const buildingSaved = await this.buildingContactService.save({
                contactId: this.contactId,
                buildingId: building.id
            } as BuildingContactView);

            if (buildingSaved) {
                this.tabCounterChange.emit();
                this.buildings.unshift(buildingSaved);
                this.noResults = false;
            }
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to save the building");
        }
    }

    async editItem(buildingContact: BuildingContactView): Promise<void> {
        const buildingContactUpdated = await this.modalService.open(ModalEditBuildingContactComponent, {
            contact: buildingContact
        });

        if (buildingContactUpdated) {
            const contactIndex = this.buildings.findIndex(building => building.id === buildingContactUpdated.id);
            this.buildings.splice(contactIndex, 1, buildingContactUpdated);
        }
    }

    async removeItem(buildingContact: BuildingContactView): Promise<void> {
        const { buildingId } = buildingContact;

        const responseOk = await this.modalService.open(ModalConfirmComponent, {
            acceptCaption: "Remove building",
            cancelCaption: "Cancel",
            content: "Are you sure you want to remove this building from the building list?",
            title: "Remove building"
        });

        if (!responseOk) return;

        try {
            if (!buildingId || !this.contactId) return;

            await this.buildingContactService.remove({
                contactId: this.contactId,
                buildingId
            } as BuildingContactView);

            const buildingIndex = this.buildings.findIndex(currentBuilding => currentBuilding.buildingId === buildingId);
            this.buildings.splice(buildingIndex, 1);

            this.tabCounterChange.emit();
            this.noResults = this.buildings.length === 0;
        } catch (error) {
            this.snackbarNotificationService.error("An error occurred while trying to delete the building");
        }
    }
}
