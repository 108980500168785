<div class="wrapper">
    <div class="addCompany">
        <app-type-ahead-companies [searchParameters]="typeAheadSearchByEntityParameters" (selectedCompanyChange)="onSelectedCompany($event)">
        </app-type-ahead-companies>
    </div>
</div>

<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Companies available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Name', sortable: true, tooltip: true },
                { key: 'fullAddress', label: 'Main Office Address', sortable: false, tooltip: true },
                { key: 'contactFirstName', label: 'Main Contact', sortable: true, tooltip: true, cellTemplate: contactFullName },
                { key: 'companyTypeName', label: 'Type', sortable: true },
                { key: 'markets', label: 'Markets', sortable: true, tooltip: true },
                { key: 'isActive', label: 'Condition', sortable: false, cellTemplate: cellIsActive },
                { key: 'id', sortable: false, cellTemplate: kebabMenu, columnSize: 'small', customClasses: ['action-grid'] }
            ]
        }"
        [routerLinkConfig]="routerLinkConfig"
        [source]="companies"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
        <ng-template #contactFullName let-data>
            <div>{{ data.object.contactFirstName }} {{ data.object.contactLastName }}</div>
        </ng-template>

        <ng-template #cellIsActive let-data>
            {{ data.object.isActive ? "Active" : "Archived" }}
        </ng-template>

        <ng-template #kebabMenu let-data>
            <app-kebab-menu>
                <button role="menuitem" class="resetStyles" (click)="removeItem(data.object)">Remove</button>
            </app-kebab-menu>
        </ng-template>
    </lib-grid-view>
</ng-template>
