import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { ContactView, SearchContactsForAccountParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { ContactService } from "projects/app/src/app/services/http/clients/contact.service";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { ModalService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { CompanyService } from "projects/app/src/app/services/http/clients/company.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";

@Component({
    selector: "app-accounts-single-contacts",
    templateUrl: "./accounts-single-contacts.component.html",
    styleUrls: ["./accounts-single-contacts.component.scss"]
})
export class AccountsSingleContactsComponent implements OnChanges {
    @Input()
    accountId?: number;

    @Output()
    tabCounterChange: EventEmitter<void>;

    @Output()
    accountChange: EventEmitter<void>;

    contacts: ContactView[];

    orderConfig?: IGenericGridOrderConfig<ContactView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchContactsForAccountParameters;

    contact?: ContactView;

    noResults: boolean;

    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<ContactView> = {
        base: "/contacts-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly contactService: ContactService,
        private readonly companyService: CompanyService,
        private readonly accountService: AccountService,
        private readonly awaiter: AwaiterService,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly snackbarNotificationService: SnackbarNotificationService
    ) {
        this.contacts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            accountId: this.accountId
        } as SearchContactsForAccountParameters;

        this.noResults = true;
        this.isLoading = true;

        this.tabCounterChange = new EventEmitter<void>();
        this.accountChange = new EventEmitter<void>();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.accountId && changes["accountId"]) {
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Contacts",
            async () => {
                const response = await this.contactService.searchContactsForAccount(this.searchConfig);

                const { pageInfo, results } = response;

                this.contacts = this.contacts?.concat(results ?? []);

                this.noResults = this.contacts.length === 0;

                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            sortDirection: this.orderConfig?.order,
            sortBy: this.orderConfig?.key,
            accountId: this.accountId
        } as SearchContactsForAccountParameters;
    }

    async onOrderChanged(): Promise<void> {
        this.searchConfig.sortDirection = this.orderConfig?.order;
        this.searchConfig.sortBy = this.orderConfig?.key;
        this.paginationConfig.currentPage = 1;

        this.contacts = [];
        await this.refreshGridView();
    }

    onRowClicked(contact: ContactView) {
        this.router.navigate(["/contacts-single", contact.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(contact: ContactView) {
        if (contact?.id && contact.id > 0) {
            window.open(`/contacts-single/${contact.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }
}
