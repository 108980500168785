<div
    class="container"
    [ngClass]="trendClass"
    *ngIf="previousValue !== null && currentValue !== null"
    [id]="trendIndicatorId | idGenerator: 'ngx-trendIndicator-'"
>
    {{ displayValue }}
    <span *ngIf="trendClass !== 'neutral'">
        <div *ngIf="trendClass === 'positive'" class="icon" [innerHTML]="trendIndicatorPositiveIcon"></div>
        <div *ngIf="trendClass === 'negative'" class="icon" [innerHTML]="trendIndicatorNegativeIcon"></div>
    </span>
</div>
