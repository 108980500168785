import { Component, OnDestroy, OnInit } from "@angular/core";
import { ReportingCompanySearchService } from "projects/app/src/app/services/http/clients/reporting-app/reporting-company-search.service";
import { ReportsService } from "../../../../../services/reports.service";
import { IBaseDto } from "projects/app/src/app/services/http/clients/reporting-app/base-dto.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-reporting-company-editor",
    templateUrl: "./reporting-company-editor.component.html",
    styleUrls: ["./reporting-company-editor.component.scss"]
})
export class ReportingCompanyEditorComponent extends EditorComponentBase<number[] | null> implements OnInit, OnDestroy {
    items: IBaseDto[];
    selectedItems?: IBaseDto[];
    subscription?: Subscription;

    constructor(
        private readonly reportingCompanySearchService: ReportingCompanySearchService,
        private readonly reportsService: ReportsService
    ) {
        super();
        this.items = [];
    }

    async ngOnInit(): Promise<void> {
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (ready && this.bindingProperty !== null && this.bindingProperty?.length) {
                this.selectedItems = await this.reportingCompanySearchService.getByIds(this.bindingProperty);
            } else if (ready && (this.bindingProperty === null || this.bindingProperty === undefined)) {
                this.selectedItems = [];
            }
        });
    }

    async onSelectedItemsChange(): Promise<void> {
        if (this.selectedItems) {
            this.bindingProperty = this.selectedItems.length ? this.selectedItems.map(item => item.id) : null;
            this.valueChange.emit();
        }
    }

    async onEntityTextChange(text: string) {
        this.items = await this.reportingCompanySearchService.search(text);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
