import { Component, OnInit } from "@angular/core";

import { NoteUser } from "projects/app/src/app/models/NoteUser";
import { NoteUserView, SearchUsersParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { UserService } from "projects/app/src/app/services/http/clients/user.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";

@Component({
    selector: "app-note-user-editor",
    templateUrl: "./note-user-editor.component.html",
    styleUrls: ["./note-user-editor.component.scss"]
})
export class NoteUserEditorComponent extends EditorComponentBase<NoteUserView[] | undefined> implements OnInit {
    userItems?: NoteUser[];

    selectedUsers?: NoteUser[];

    constructor(private readonly userService: UserService) {
        super();
    }

    ngOnInit(): void {
        if (this.bindingProperty) {
            const mappedResults = this.bindingProperty.map(x => NoteUser.fromInterface(x));
            this.selectedUsers = mappedResults?.filter(user => user.userExternalId);
        }
    }

    async onUserTextChange(event: string) {
        const results = await this.userService?.search({
            filterText: event,
            excludeLoggedUser: true,
            isActive: true
        } as SearchUsersParameters);

        this.userItems = results.map(x => NoteUser.fromUserView(x));
    }

    onSelectedUserChange(event: NoteUser[]) {
        this.bindingProperty = this.selectedUsers = event;
    }

    onRemoveUserChange() {
        if (this.bindingProperty) {
            this.bindingProperty = this.selectedUsers;
        }
    }
}
