import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ColDef, ColumnVisibleEvent, FirstDataRenderedEvent, GridOptions, GridReadyEvent } from "ag-grid-community";
import { customThemeQuartz } from "projects/app/src/app/ag-grid-custom-theme";
import { SelectedColsType } from "projects/app/src/app/components/shared/grid-filters/column-visibility-filter/column-visibility-filter.component";
import { CustomPercentagePipe } from "projects/app/src/app/pipes/custom-percentage.pipe";
import { GridService } from "projects/app/src/app/services/http/clients/reporting-app/grid.service";
import { percentageFormatter } from "projects/app/src/app/utils/format-functions";

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html",
    styleUrls: ["./grid.component.scss"],
    providers: [DatePipe]
})
export class GridComponent {
    @Input()
    finalEstimates: [];

    @Input()
    colDefs: ColDef[] = [];

    dropDownTitle = "Select columns";

    groupByDropDownTitle = "Group by...";

    public isDropdownVisible = false;

    suppressMoveWhenColumnDragging: boolean;

    public agTheme = customThemeQuartz;

    @Output()
    visibleColsChange: EventEmitter<SelectedColsType> = new EventEmitter<SelectedColsType>();

    gridOptions: GridOptions;

    constructor(
        private readonly gridService: GridService,
        private readonly currencyPipe: CurrencyPipe,
        private readonly customPercentagePipe: CustomPercentagePipe,
        private readonly datePipe: DatePipe
    ) {
        this.finalEstimates = [];
        this.suppressMoveWhenColumnDragging = false;
        this.gridOptions = {
            columnTypes: {
                percentage: {
                    valueFormatter: (params: any) => this.formatPercentage(params.value),
                    useValueFormatterForExport: false
                },
                customPercentage: {
                    valueFormatter: (params: any) => this.formatCustomPercentage(params.value),
                    useValueFormatterForExport: false
                },
                date: {
                    valueFormatter: (params: any) => this.formatDate(params.value)
                },
                array: {
                    valueFormatter: (params: any) => this.formatArray(params.value),
                    useValueFormatterForExport: false
                },
                currency: {
                    valueFormatter: (params: any) => this.formatCurrency(params.value),
                    useValueFormatterForExport: false
                }
            }
        };
    }
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 100
    };
    public autoGroupColumnDef: ColDef = {
        minWidth: 200
    };

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api.sizeColumnsToFit();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridService.setGridApi(params.api);
    }

    onColumnVisibleChange(e: ColumnVisibleEvent) {
        if (e.column && e.visible != undefined) {
            this.visibleColsChange.emit({ [e.column.getColId()]: e.visible });
        }
    }

    formatDate(date: string) {
        return this.datePipe.transform(date, "MM-dd-y") ?? "";
    }

    private formatArray(arr: string[]) {
        if (!arr.length) {
            return "";
        }
        return arr.join(", ");
    }

    private formatCurrency(value: number): string {
        return this.currencyPipe.transform(value) ?? "";
    }

    private formatCustomPercentage(value: number): string {
        return this.customPercentagePipe.transform(value) ?? "";
    }

    private formatPercentage(value: number): string {
        return percentageFormatter(value) ?? "";
    }
}
