import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-location-details",
    templateUrl: "./location-details.component.html",
    styleUrls: ["./location-details.component.scss"]
})
export class LocationDetailsComponent {
    @Input()
    line2?: string;

    @Input()
    isReadOnly?: boolean;

    @Output()
    line2Change: EventEmitter<string>;

    @Input()
    location?: any;

    constructor() {
        this.line2Change = new EventEmitter<string>();
    }

    onInputChange(event: string) {
        this.line2Change.emit(event);
    }
}
