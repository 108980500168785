import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";

export interface IListElement {
    id: number;
    title?: string;
    subtitle?: string;
    link?: string;
    isScheduleItem?: boolean;
    schedulerStatusId?: number;
    tooltipSchedule?: string;
    canRemoveItem: boolean;
    isFavorite: boolean;
    scheduleTooltip?: string;
}
@Component({
    selector: "app-list-element",
    templateUrl: "./list-element.component.html",
    styleUrls: ["./list-element.component.scss"]
})
export class ListElementComponent {
    @Input()
    itemId?: number;

    @Input()
    title?: string;

    @Input()
    subtitle?: string;

    @Input()
    link?: string;

    @Input()
    canRemoveItem: boolean;

    @Input()
    isFavorite: boolean;

    @Input()
    scheduleTooltip?: string;

    @Input()
    isScheduleItem?: boolean;

    @Input()
    schedulerStatusId?: number;

    @Output()
    isFavoriteChange: EventEmitter<boolean>;

    @Output()
    removeElementRequest: EventEmitter<number>;

    trashIcon: SafeHtml;
    scheduleActiveIcon: SafeHtml;
    schedulePauseIcon: SafeHtml;

    constructor(
        private readonly router: Router,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        this.link = "";
        this.isFavorite = false;
        this.canRemoveItem = false;
        this.isScheduleItem = true;
        this.isFavoriteChange = new EventEmitter<boolean>();
        this.removeElementRequest = new EventEmitter<number>();
        this.trashIcon = this.iconSanitizer.getIcon("trash");
        this.scheduleActiveIcon = this.iconSanitizer.getIcon("schedule-active");
        this.schedulePauseIcon = this.iconSanitizer.getIcon("schedule-pause");
    }

    navigate(url?: string) {
        this.router.navigate([url]);
    }

    onFavoriteChange(event: boolean) {
        this.isFavoriteChange.emit(event);
    }

    onRemoveElement(event: Event) {
        this.removeElementRequest.emit(this.itemId);
        this.preventNavigation(event);
    }

    private preventNavigation(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }
}
