import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";

export const crmMainFilters = [
    {
        componentId: EditorType.reportingOrganization,
        fieldName: "organizationIds"
    },
    {
        componentId: EditorType.reportingUser,
        fieldName: "salesPersonIds",
        additionalInfo: {
            placeholder: "Search Users"
        }
    },
    {
        componentId: EditorType.reportingOpportunitySearch,
        fieldName: "opportunityIds"
    }
];

export const salesMainFilters = [
    {
        componentId: EditorType.reportingOrganization,
        fieldName: "organizationIds"
    },
    {
        componentId: EditorType.reportingUser,
        fieldName: "salesPersonIds",
        additionalInfo: {
            placeholder: "Search Users"
        }
    },
    {
        componentId: EditorType.reportingProjectSearch,
        fieldName: "projectIds"
    }
];
