import { IconSanitizerService } from "./../../services/icon-sanitizer.service";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { icons } from "projects/ngx-lib/src/lib/assets/icons";
import { SafeHtml } from "@angular/platform-browser";

export interface IGenericGridColumnConfig<T> {
    key: keyof T;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headerTemplate?: TemplateRef<any> | null;
    sortable: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cellTemplate?: TemplateRef<any> | null;
    maxLines?: number;
    tooltip?: boolean;
    columnSize?: "small" | "medium" | "large";
    customClasses?: string[];
    hidden?: boolean;
}

export interface IGenericGridConfig {
    allowSelection?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    noRowsTemplate?: TemplateRef<any>;
}

export interface IGenericGridOrderConfig<T> {
    key: keyof T;
    order: Order;
}

export interface IGenericGridPaginationConfig {
    currentPage: number;
    pagesCount: number;
    totalCount: number;
    pageSize?: number;
    pagesToDisplay?: number;
    autoLoad?: boolean;
}

export enum Order {
    asc = "A",
    desc = "D"
}

@Component({
    selector: "lib-generic-grid",
    templateUrl: "./generic-grid.component.html",
    styleUrls: ["./generic-grid.component.scss"]
})
export class GenericGridComponent<T> implements OnChanges, OnInit {
    private readonly _pagesToDisplay = 5;

    readonly icons = icons;

    _selectedItems?: T[] = [];

    @Input()
    source?: T[];

    @Input()
    columnsConfig?: IGenericGridColumnConfig<T>[];

    @Input()
    config?: IGenericGridConfig;

    @Input()
    selectedItems?: T[] = [];

    @Output()
    selectedItemsChange = new EventEmitter<T[]>();

    @Input()
    orderConfig?: IGenericGridOrderConfig<T>;

    @Output()
    orderConfigChange = new EventEmitter<IGenericGridOrderConfig<T> | undefined>();

    @Input()
    paginationConfig?: IGenericGridPaginationConfig;

    @Output()
    paginationConfigChange: EventEmitter<IGenericGridPaginationConfig> = new EventEmitter<IGenericGridPaginationConfig>();

    @Output()
    rowClicked: EventEmitter<T> = new EventEmitter<T>();

    @Output()
    mouseWheelClick: EventEmitter<T> = new EventEmitter<T>();

    @Input()
    isReadOnly?: boolean;

    isAllSelected?: boolean = false;

    @Input()
    hasExternalLink?: boolean;

    @Input()
    defaultMaxLines?: number;

    @Input()
    genericGridId?: string;

    externalLinkIcon?: SafeHtml;

    columnClasses: { [index: number]: string[] } = [];

    previousIcon: SafeHtml;

    nextIcon: SafeHtml;

    sortIcon: SafeHtml;

    get pagesToDisplay(): number {
        return this.paginationConfig?.pagesToDisplay ?? this._pagesToDisplay;
    }

    ngOnInit(): void {
        if (this.orderConfig) this.orderItems(this.orderConfig.key);
        this.columnsConfig?.forEach((column, i) => {
            if (column.hidden) {
                this.columnClasses[i] = ["hidden"];
            }
        });
        if (this.hasExternalLink) {
            this.columnsConfig?.push({
                key: "externalLink" as keyof T,
                sortable: false,
                columnSize: "small"
            });
        }

        this.columnsConfig?.forEach((column, i) => {
            this.columnClasses[i] = this.getColumnClasses(column);
        });
    }

    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.sortIcon = this.iconSanitizer.getIcon("sortIcon");
        this.externalLinkIcon = this.iconSanitizer.getIcon("externalLink");
        this.previousIcon = this.iconSanitizer.getIcon("previousArrow");
        this.nextIcon = this.iconSanitizer.getIcon("nextArrow");
        this.hasExternalLink = false;
        this.defaultMaxLines = 2;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["selectedItems"] && changes["selectedItems"].currentValue) {
            this._selectedItems = this.selectedItems ? [...this.selectedItems] : undefined;
            this.setAllSelected();
        }
    }

    onRowClicked(item: T, event?: Event) {
        if (!(event?.target as Element)?.classList.contains("noNavigate")) this.rowClicked?.emit(item);
    }

    onMouseDown(event: MouseEvent, item: T): void {
        // * During mouse events caused by the depression or release of a mouse button, button MUST be used to indicate which pointer device button changed state.
        // * Value 1 MUST indicate the auxiliary button (in general, the middle button, often combined with a mouse wheel).
        // * To see other values visit: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#value

        if (event.button === 1) {
            event.preventDefault();
            this.mouseWheelClick.emit(item);
        }
    }

    getColumnClasses(column: IGenericGridColumnConfig<T>): string[] {
        let classes: string[] = [];

        if (column.hidden) {
            classes.push("hidden");
        }

        if (column.sortable) classes.push("clickable");
        else classes.push("noClickable");

        if (this.isOrderAsc(column)) classes.push("order-asc");
        if (this.isOrderDesc(column)) classes.push("order-desc");

        if (column.columnSize === "small") classes.push("smallSize");
        else if (column.columnSize === "large") classes.push("largeSize");
        else {
            classes.push("mediumSize");
        }

        if (column.customClasses) classes = [...classes, ...column.customClasses];

        return classes;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTemplate(columnConfig: IGenericGridColumnConfig<T>): TemplateRef<any> | null {
        if (!columnConfig.cellTemplate) return null;

        return columnConfig.cellTemplate;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getNoRowTemplate(): TemplateRef<any> {
        return this.config!.noRowsTemplate!;
    }

    headerClicked(column: IGenericGridColumnConfig<T>) {
        if (!column.sortable) return;
        this.orderItems(column.key);
    }

    isOrderAsc(column: IGenericGridColumnConfig<T>): boolean {
        return column.key === this.orderConfig?.key && this.orderConfig.order === Order.asc;
    }

    isOrderDesc(column: IGenericGridColumnConfig<T>): boolean {
        return column.key === this.orderConfig?.key && this.orderConfig.order === Order.desc;
    }

    isSelected(item: T) {
        return this._selectedItems?.includes(item);
    }

    selectAll() {
        if (!this.isAllSelected) this._selectedItems = this.source ? [...this.source] : [];
        else this._selectedItems = [];
        this.selectedItemsChange.emit(this._selectedItems);
    }

    selectItem(item: T) {
        if (this.isSelected(item)) {
            this._selectedItems = this._selectedItems?.filter(x => x !== item);
        } else {
            this._selectedItems?.push(item);
        }
        this.selectedItemsChange.emit(this._selectedItems);
    }

    getPaginationItems(): number[] {
        if (!this.source || !this.paginationConfig) return [1];

        const firstPage = this.paginationConfig.currentPage - this.pagesToDisplay <= 2 ? 1 : this.paginationConfig.currentPage - this.pagesToDisplay;
        const lastPage =
            this.paginationConfig.currentPage + this.pagesToDisplay + 1 >= this.paginationConfig.pagesCount
                ? this.paginationConfig.pagesCount
                : this.paginationConfig.currentPage + this.pagesToDisplay;

        const pages = [];
        for (let i = firstPage; i <= lastPage; i++) {
            pages.push(i);
        }

        return pages;
    }

    changePage(page: number) {
        if (!this.paginationConfig) return;
        this.paginationConfigChange.emit({ ...this.paginationConfig, currentPage: page });
    }

    canGoPrevious() {
        return this.paginationConfig?.currentPage !== 1;
    }

    canGoNext() {
        if (!this.paginationConfig?.currentPage || !this.paginationConfig?.pagesCount) return false;
        return !!(this.source?.length && this.paginationConfig?.currentPage < this.paginationConfig?.pagesCount);
    }

    goFirst() {
        this.changePage(1);
    }

    goPrevious() {
        if (!this.canGoPrevious()) return;
        this.changePage(this.paginationConfig!.currentPage - 1);
    }

    goNext() {
        if (!this.canGoNext()) return;
        this.changePage(this.paginationConfig!.currentPage + 1);
    }

    goLast() {
        this.changePage(this.paginationConfig!.pagesCount);
    }

    showDotsBefore(): boolean {
        if (!this.paginationConfig) return false;
        return this.paginationConfig.currentPage - this.pagesToDisplay > 2;
    }

    showDotsAfter(): boolean {
        if (!this.paginationConfig) return false;
        return this.paginationConfig.currentPage + this.pagesToDisplay + 1 < this.paginationConfig.pagesCount;
    }

    private orderItems(key: keyof T) {
        if (key === this.orderConfig?.key && this.orderConfig?.order === Order.desc) {
            this.orderConfigChange.emit(undefined);
            return;
        }

        let order = Order.asc;
        if (key === this.orderConfig?.key) {
            order = this.orderConfig?.order === Order.asc ? Order.desc : Order.asc;
        }

        this.orderConfigChange.emit({ key, order });
    }

    private setAllSelected() {
        this.isAllSelected = this.selectedItems?.length === this.source?.length;
    }

    onClick(isReadOnly: boolean | undefined): boolean {
        if (isReadOnly) return false;

        return true;
    }
}
