<div class="modal-wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="modal-content">
        <div class="modal-header">
            <h2>Edit Address Line 2</h2>
            <div class="bar"></div>

            <button class="close-wrapper" (click)="close(undefined)" aria-label="Close modal">
                <div class="icon" [innerHTML]="closeIcon"></div>
            </button>
        </div>

        <div class="modal-body">
            <app-location-details [location]="location" [line2]="location.line2" (line2Change)="updateLine2($event)"></app-location-details>
        </div>

        <div class="modal-footer">
            <button class="modal-button cancel" (click)="close(undefined)" [disabled]="isLoading">Cancel</button>
            <app-loading-button [isLoading]="isLoading" (clickEvent)="saveAddress()" text="Save"></app-loading-button>
        </div>
    </div>
</div>
