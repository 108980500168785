<ng-container *ngIf="noResults && !isLoading; else showContent">
    <lib-no-data [iconName]="'emptyData'" [title]="'No Buildings available.'"> </lib-no-data>
</ng-container>

<ng-template #showContent>
    <lib-grid-view
        [gridConfig]="{
            columnsConfig: [
                { key: 'name', label: 'Name', sortable: true, tooltip: true },
                { key: 'addressLines', label: 'Address', sortable: true, tooltip: true },
                { key: 'cityName', label: 'City', sortable: true },
                { key: 'stateName', label: 'State', sortable: true },
                { key: 'countryName', label: 'Country', sortable: true }
            ]
        }"
        [source]="buildings"
        [(orderConfig)]="orderConfig"
        (orderConfigChange)="onOrderChanged()"
        [(paginationConfig)]="paginationConfig"
        (paginationConfigChange)="refreshGridView()"
        (rowClicked)="onRowClicked($event)"
        (mouseWheelClick)="onMouseWheelClicked($event)"
    >
    </lib-grid-view>
</ng-template>
