<ng-container *ngIf="selectedCompany && isReadOnly && allowToNavigate; else selectItemTemplate">
    <app-internal-navigator [path]="routeToSingle" [label]="selectedCompany.name"></app-internal-navigator>
</ng-container>
<ng-template #selectItemTemplate>
    <app-type-ahead-companies
        [isReadOnly]="isReadOnly"
        [displaySelected]="displaySelectedCompany"
        [selectedCompany]="selectedCompany"
        [editorInfoAllowAction]="editorInfoAllowAction"
        (selectedCompanyChange)="onSelectedCompany($event)"
        [isActive]="isActive"
    >
    </app-type-ahead-companies>
</ng-template>
