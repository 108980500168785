import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { IReportConfiguration } from "../../types/report-config.interface";
import {
    projectDueDateEditorConfig,
    projectStartDateEditorConfig,
    projectEndDateEditorConfig,
    projectStatusEditorConfig,
    marketsEditorConfig,
    opportunitySourceEditorConfig
} from "./shared/additional-filters";
import { salesMainFilters } from "./shared/main-filters";
import { ServiceCostsService } from "projects/app/src/app/services/http/clients/reporting-app/service-costs.service";
import { WidgetType } from "../../types/report-widget-type.enum";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";
import { percentageFormatter, truncateValue } from "projects/app/src/app/utils/format-functions";

export const ServiceCosts: IReportConfiguration<ServiceCostsService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: ServiceCostsService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        {
                            name: "avg",
                            label: "Avg"
                        },
                        {
                            name: "min",
                            label: "Min"
                        },
                        {
                            name: "med",
                            label: "Med"
                        },
                        {
                            name: "max",
                            label: "Max"
                        }
                    ],
                    rows: [
                        {
                            name: "DCPercent",
                            label: "Direct Cost (%)",
                            format: "percent"
                        },
                        {
                            name: "DirectCost",
                            label: "Direct Cost ($)",
                            format: "currency"
                        },
                        {
                            name: "TDCPercent",
                            label: "Total Direct Cost (%)",
                            format: "percent"
                        },
                        {
                            name: "TDC",
                            label: "Total Direct Cost ($)",
                            format: "currency"
                        }
                    ]
                }
            },
            {
                methodName: "getLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Services by Avg DC$"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "avgDCPercent",
                            yName: "Avg Direct Cost (%)"
                        },
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "avgDirectCost",
                            yName: "Avg Direct Cost ($)"
                        },
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "avgTDCPercent",
                            yName: "Avg Total Direct Cost (%)"
                        },
                        {
                            type: "line",
                            xKey: "serviceName",
                            yKey: "avgTDC",
                            yName: "Avg Total Direct Cost ($)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["avgDirectCost", "avgTDC"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["avgDCPercent", "avgTDCPercent"],
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "sellPrice",
                            yName: "Total Sell Price"
                        },
                        {
                            type: "bar",
                            xKey: "salesPersonName",
                            yKey: "totalDirectCost",
                            yName: "Total Direct Cost ($)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["sellPrice", "totalDirectCost"],
                            title: {
                                text: "Amount"
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.FourRowStack
    },
    gridTab: {
        widgets: [
            {
                methodName: "getValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    {
                        field: "serviceName",
                        headerName: "Service Name",
                        enableRowGroup: true,
                        lockVisible: true
                    },
                    {
                        field: "quantity",
                        headerName: "Total in Awarded Final Estimates (qty) ",
                        enableRowGroup: true
                    },
                    {
                        field: "totalSellPrice",
                        headerName: "Total Sell Price",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "totalGMPercent",
                        headerName: "Total Gross Margin (%)",
                        type: "customPercentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "avgSellPrice",
                        headerName: "Avg Sell Price",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "avgDCPercent",
                        headerName: "Avg Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "minDCPercent",
                        headerName: "Min Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "medDCPercent",
                        headerName: "Med Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "maxDCPercent",
                        headerName: "Max Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "avgDirectCost",
                        headerName: "Avg Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "minDirectCost",
                        headerName: "Min Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "medDirectCost",
                        headerName: "Med Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "maxDirectCost",
                        headerName: "Max Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "avgTDCPercent",
                        headerName: "Avg Total Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "minTDCPercent",
                        headerName: "Min Total Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "medTDCPercent",
                        headerName: "Med Total Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "maxTDCPercent",
                        headerName: "Max Total Direct Cost (%)",
                        type: "percentage",
                        aggFunc: "avg"
                    },
                    {
                        field: "avgTDC",
                        headerName: "Avg Total Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "minTDC",
                        headerName: "Min Total Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "medTDC",
                        headerName: "Med Total Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    },
                    {
                        field: "maxTDC",
                        headerName: "Max Total Direct Cost ($)",
                        type: "currency",
                        aggFunc: "sum"
                    }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...salesMainFilters],
        additional: [
            projectDueDateEditorConfig,
            projectStartDateEditorConfig,
            projectEndDateEditorConfig,
            projectStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig
        ]
    }
};
