import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AppEditorFactory } from "projects/app/src/app/factories/app-editor-factory/app-editor-factory";
import { EditorType } from "projects/app/src/app/factories/app-editor-factory/enum/editor-type";
import { ValidatorType } from "projects/ngx-lib/src/lib/enums/validator-type";
import { IEditorItemConfig } from "projects/ngx-lib/src/lib/interfaces/editor-item-config.interface";
import { MessageBoxType, ModalComponentBase, ObjectExtensionsService, SnackbarNotificationService } from "projects/ngx-lib/src/public-api";
import { EditorItemComponent } from "projects/ngx-lib/src/lib/components/editor-item/editor-item.component";
import { OrganizationDto } from "projects/app/src/app/services/http/clients/reporting-app/reporting-api-proxies";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { SafeHtml } from "@angular/platform-browser";

interface IModalSaveCustomReportParameters {
    editMode?: boolean;
    id?: number;
    canAddToCatalog?: boolean;
    canUpdateCatalog?: boolean;
    reportService: any;
    filterParameters: any;
    reportData: any;
}
@Component({
    selector: "app-modal-report-save",
    templateUrl: "./modal-report-save.component.html",
    styleUrls: ["./modal-report-save.component.scss"]
})
export class ModalReportSaveComponent extends ModalComponentBase<IModalSaveCustomReportParameters, any> implements OnInit {
    modalTitle: string;
    factory: AppEditorFactory;
    configItems: IEditorItemConfig[];
    bindContext: {
        name: string;
        reportTemplateName: string;
        reportTemplateId: number;
        isFavorite: boolean;
        isPublic: boolean;
        organizationIds: OrganizationDto[];
    };
    initialState?: any;
    service?: any;
    hasCatalogPermissions?: boolean;
    showOrganizationsSelector?: boolean;
    message: string;
    messageType: MessageBoxType;
    showPrivateReportMessage: boolean;
    isPublicLastValue: boolean;

    @ViewChildren("editorItem")
    editors?: QueryList<EditorItemComponent>;

    closeIcon: SafeHtml;

    constructor(
        private readonly objectExtensionService: ObjectExtensionsService,
        private readonly snackbarNotificationService: SnackbarNotificationService,
        private readonly awaiter: AwaiterService,
        private readonly iconSanitizer: IconSanitizerService
    ) {
        super();
        this.factory = new AppEditorFactory();
        this.configItems = [];
        this.bindContext = {
            name: "",
            reportTemplateName: "",
            reportTemplateId: 0,
            isFavorite: false,
            isPublic: false,
            organizationIds: []
        };
        this.hasCatalogPermissions = false;
        this.showOrganizationsSelector = false;
        this.message = "";
        this.messageType = null;
        this.modalTitle = "Save as custom report";
        this.showPrivateReportMessage = false;
        this.isPublicLastValue = false;
        this.closeIcon = this.iconSanitizer.getIcon("closeModal");
    }

    async ngOnInit(): Promise<void> {
        if (!this.parameters?.reportService) throw new Error("Unable to save custom report");
        this.service = this.parameters.reportService;

        if (this.parameters?.editMode) {
            this.modalTitle = "Update Report";
            this.bindContext.name = this.parameters?.reportData.name;
            this.bindContext.isFavorite = this.parameters?.reportData.isFavorite;
            this.bindContext.isPublic = this.parameters?.reportData.isPublic;
        }

        this.bindContext.organizationIds = this.parameters?.reportData.organizations;
        this.bindContext.reportTemplateName = this.parameters?.reportData.reportTemplateName ?? this.parameters?.reportData.name;
        this.bindContext.reportTemplateId = this.parameters?.reportData.reportTemplateId
            ? this.parameters.reportData.reportTemplateId
            : this.parameters?.reportData.id;

        this.initialState = this.objectExtensionService.clone(this.bindContext);
        this.isPublicLastValue = this.initialState.isPublic;

        this.initConfigItems();
    }

    async saveSettings(): Promise<any> {
        if (!this.validate()) return;

        await this.awaiter.awaitAction("Save Report...", async () => {
            const response = await this.service.saveCustomReport({
                id: this.parameters?.id,
                name: this.bindContext.name,
                reportTemplateId: this.bindContext.reportTemplateId,
                filterParameters: this.parameters?.filterParameters,
                isFavorite: this.bindContext.isFavorite,
                isPublic: this.bindContext.isPublic,
                organizations: this.bindContext.organizationIds?.length > 0 && this.bindContext.isPublic ? this.bindContext.organizationIds : null
            });
            this.snackbarNotificationService.success(`The report has been successfully ${this.parameters?.editMode ? "updated" : "created"}`);
            this.close(response);
        });
    }

    onEditorsChange() {
        this.onCatalogEditorChange();
    }

    private onCatalogEditorChange() {
        if (!this.hasCatalogPermissions) return;

        const catalogConfig = this.configItems.filter((c: { fieldName: string }) => c.fieldName === "isPublic");
        if (!catalogConfig.length) return;

        const changes = this.isPublicLastValue != this.bindContext.isPublic;
        if (changes) {
            this.showPrivateReportMessage = this.initialState.isPublic && !this.bindContext.isPublic;
            if (this.showPrivateReportMessage) {
                const privateReportMessage = {
                    message: "This report will be removed from all organization catalogs and will be available exclusively as a custom report for the creator.",
                    type: "danger"
                };

                this.message = privateReportMessage.message;
                this.messageType = privateReportMessage.type as MessageBoxType;
            }
            this.isPublicLastValue = this.bindContext.isPublic;
            this.toggleOrganizationEditor();
        }
    }

    private toggleOrganizationEditor() {
        const organizationEditorIndex = this.configItems.findIndex((editor: any) => editor.fieldName === "organizationIds");

        if (this.bindContext.isPublic && organizationEditorIndex === -1) {
            this.configItems.push({
                componentId: EditorType.reportingManagerOrganization,
                fieldName: "organizationIds",
                label: "Manager Organizations",
                bindContext: this.bindContext
            });
        } else if (!this.bindContext.isPublic && organizationEditorIndex > -1) {
            this.configItems.splice(organizationEditorIndex, 1);
            this.bindContext.organizationIds = [];
        }
    }

    private initConfigItems() {
        this.configItems = [
            {
                label: "Name",
                componentId: EditorType.textInput,
                bindContext: this.bindContext,
                fieldName: "name",
                validations: [{ type: ValidatorType.required }, { type: ValidatorType.string, args: { maxLength: 100 } }],
                additionalInfo: {
                    shouldTrimSpaces: true
                }
            },
            {
                label: "Report Template",
                componentId: EditorType.textInput,
                bindContext: this.bindContext,
                fieldName: "reportTemplateName",
                isReadOnly: true
            },
            {
                label: "Type",
                componentId: EditorType.textInput,
                bindContext: this.parameters?.reportData,
                fieldName: "reportTemplateGroupName",
                isReadOnly: true
            }
        ];

        if (!this.parameters?.editMode) {
            this.configItems.push({
                label: "Add to favorite",
                bindContext: this.bindContext,
                componentId: EditorType.checkbox,
                fieldName: "isFavorite",
                additionalInfo: {
                    wrapperCenter: true
                }
            });
        }

        this.hasCatalogPermissions = this.parameters?.canAddToCatalog || this.parameters?.canUpdateCatalog;
        if (this.hasCatalogPermissions) {
            this.configItems.push({
                label: "Add to Catalog",
                bindContext: this.bindContext,
                componentId: EditorType.checkbox,
                fieldName: "isPublic",
                additionalInfo: {
                    wrapperCenter: true
                }
            });
        }

        if (this.hasCatalogPermissions && this.bindContext.isPublic) {
            this.configItems.push({
                componentId: EditorType.reportingManagerOrganization,
                fieldName: "organizationIds",
                label: "Manager Organizations",
                bindContext: this.bindContext
            });
        }
    }
    private validate(): boolean {
        return this.editors?.map(editorItem => editorItem.validate()).every(editorItem => editorItem) ?? false;
    }
}
