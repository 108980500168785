import { Component, OnDestroy, OnInit } from "@angular/core";
import { DivisionService } from "projects/app/src/app/services/http/clients/reporting-app/division.service";
import { ReportsService } from "projects/app/src/app/services/reports.service";
import { IBaseDto } from "projects/app/src/app/services/http/clients/reporting-app/base-dto.service";
import { EditorComponentBase } from "projects/ngx-lib/src/lib/classes/editor-component-base";
import { Subscription } from "rxjs";

@Component({
    selector: "app-reporting-division-editor",
    templateUrl: "./reporting-division-editor.component.html",
    styleUrls: ["./reporting-division-editor.component.scss"]
})
export class ReportingDivisionEditorComponent extends EditorComponentBase<number[] | null> implements OnInit, OnDestroy {
    items: IBaseDto[];
    selectedItems?: IBaseDto[];
    subscription?: Subscription;

    constructor(
        private readonly reportingDivisionService: DivisionService,
        private readonly reportsService: ReportsService
    ) {
        super();
        this.items = [];
    }

    async ngOnInit(): Promise<void> {
        this.items = await this.reportingDivisionService.getAll();
        this.subscription = this.reportsService.selectedProperty$.subscribe(async ready => {
            if (ready && this.items?.length && this.bindingProperty !== null && this.bindingProperty?.length && Array.isArray(this.bindingProperty)) {
                this.selectedItems = this.items.filter(obj => this.bindingProperty?.find(id => id === obj["id"]) !== undefined);
            } else if (ready && (this.bindingProperty === null || this.bindingProperty === undefined)) {
                this.selectedItems = [];
            }
        });
    }

    onSelectedItemsChange() {
        if (this.selectedItems?.length) {
            this.bindingProperty = this.selectedItems.map(obj => obj.id);
        } else {
            this.bindingProperty = null;
        }
        this.valueChange.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
