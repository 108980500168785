import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { SafeHtml } from "@angular/platform-browser";

export interface IMenuGroup {
    id: string;
    name?: string;
    isVisible: boolean;
    menuItems: IMenuItem[];
    isFooter?: boolean;
}

interface IMenuItem {
    id: string;
    name: string;
    icon: SafeHtml;
    link?: string;
    isVisible?: boolean;
}

export interface IGenericMenuConfig {
    defaultText: string;
}

@Component({
    selector: "lib-generic-menu",
    templateUrl: "./generic-menu.component.html",
    styleUrls: ["./generic-menu.component.scss"]
})
export class GenericMenuComponent implements OnChanges {
    currentActiveMenuItem?: IMenuItem;

    @Input()
    config?: IGenericMenuConfig;

    @Input()
    source: IMenuGroup[];

    @Input()
    isExpanded: boolean;

    @Input()
    currentUrl?: string;

    private flatMenuItems: IMenuItem[];

    constructor(private readonly router: Router) {
        this.source = [];
        this.flatMenuItems = [];
        this.isExpanded = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["source"] && changes["source"].currentValue) {
            this.flattenMenuItems();
        }

        if (changes["currentUrl"] && this.currentUrl && changes["currentUrl"].currentValue !== changes["currentUrl"].previousValue) {
            if (!this.source.length) return;
            this.updateActiveMenuItem();
        }
    }

    selectMenuItem(menuItem: IMenuItem): void {
        if (!this.isActiveMenuItem(menuItem)) this.currentActiveMenuItem = menuItem;
        this.navigate();
    }

    isActiveMenuItem(menuItem: IMenuItem): boolean {
        return this.currentActiveMenuItem === menuItem;
    }

    onMouseDown(event: MouseEvent, menuItem: IMenuItem): void {
        // * During mouse events caused by the depression or release of a mouse button, button MUST be used to indicate which pointer device button changed state.
        // * Value 1 MUST indicate the auxiliary button (in general, the middle button, often combined with a mouse wheel).
        // * To see other values visit: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#value

        if (event.button === 1) {
            event.preventDefault();
            window.open(menuItem.link, "_blank");
        }
    }

    private navigate(): void {
        if (this.currentActiveMenuItem?.link === undefined) return;
        this.router.navigate(["/", this.currentActiveMenuItem?.link]);
    }

    private flattenMenuItems(): void {
        this.flatMenuItems = this.source.flatMap(group => group.menuItems);
    }

    private updateActiveMenuItem(): void {
        const menuActive = this.flatMenuItems.find(menu => this.currentUrl?.includes(menu.link ?? ""));
        this.currentActiveMenuItem = menuActive ?? this.source[0].menuItems[0];
    }
}
