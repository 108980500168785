import { ObjectExtensionsService } from "projects/ngx-lib/src/public-api";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig,
    Order
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { OpportunityView, SearchOpportunitiesParameters, SearchOpportunitiesResult } from "projects/app/src/app/services/http/clients/api-proxies";
import { OpportunityService } from "projects/app/src/app/services/http/clients/opportunity.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { FilterStateService } from "projects/app/src/app/services/filter-state.service";
import { FilterValueOpportunities } from "projects/app/src/app/interfaces/opportunity-filters";

@Component({
    selector: "app-opportunities-list",
    templateUrl: "./opportunities-list.component.html",
    styleUrls: ["./opportunities-list.component.scss"]
})
export class OpportunitiesListComponent implements OnInit, OnDestroy {
    filters?: FilterValueOpportunities;

    opportunities: SearchOpportunitiesResult[];
    orderConfig?: IGenericGridOrderConfig<SearchOpportunitiesResult>;
    paginationConfig!: IGenericGridPaginationConfig;
    searchConfig!: SearchOpportunitiesParameters;
    noResults: boolean;
    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<SearchOpportunitiesResult> = {
        base: "/opportunities-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    private filterSubscription?: Subscription;

    constructor(
        private readonly opportunityService: OpportunityService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute,
        private readonly filterStateService: FilterStateService<FilterValueOpportunities>,

        private readonly objectExtensionsService: ObjectExtensionsService
    ) {
        this.opportunities = [];
        this.noResults = true;
        this.isLoading = true;
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage,
            isActive: true
        } as SearchOpportunitiesParameters;
    }

    async ngOnInit() {
        const params = this.route.snapshot.queryParams;
        const sortBy = params["sortBy"] as string | undefined;
        const sortDirection = params["sortDirection"] as Order | undefined;
        if (sortBy && sortDirection) {
            const newOrderConfig: IGenericGridOrderConfig<SearchOpportunitiesResult> = {
                key: this.getKeyFromLabel(sortBy),
                displayName: sortBy,
                order: sortDirection
            };
            await this.onOrderChanged(newOrderConfig);
        }

        this.filterSubscription = this.filterStateService.stateFilters$.subscribe(async newFilters => {
            if (newFilters && Object.keys(newFilters).length === 0) {
                newFilters = undefined;
            }
            if (this.filters && Object.keys(this.filters).length === 0) {
                this.filters = undefined;
            }

            if (!this.filters && !newFilters) return;

            if (this.objectExtensionsService.isEqualTo(this.filters, newFilters)) return;

            this.filters = { ...newFilters };
            this.paginationConfig.currentPage = 1;
            this.opportunities = [];
            await this.refreshGridView();
        });
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    async refreshGridView() {
        await this.awaiter.awaitAction(
            "Loading Opportunities",
            async () => {
                this.buildSearchConfig();
                const response = await this.opportunityService.search(this.searchConfig);
                const { pageInfo, results } = response;
                this.opportunities = this.opportunities?.concat(results ?? []);
                this.noResults = this.opportunities.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    async onOrderChanged(newOrderConfig: IGenericGridOrderConfig<SearchOpportunitiesResult> | undefined): Promise<void> {
        this.orderConfig = newOrderConfig;

        this.searchConfig = {
            ...this.searchConfig,
            sortBy: newOrderConfig?.key,
            sortDirection: newOrderConfig?.order
        };

        if (this.filters) {
            const newFilter = {
                ...this.filters,
                sortBy: newOrderConfig?.key === "isFavorite" ? newOrderConfig?.key : newOrderConfig?.displayName,
                sortDirection: newOrderConfig?.order
            } as FilterValueOpportunities;

            this.filterStateService.updateMainFilters({ ...newFilter });
        }
    }

    onRowClicked(opportunity: SearchOpportunitiesResult) {
        this.router.navigate(["/opportunities-single", opportunity.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(opportunity: SearchOpportunitiesResult) {
        if (opportunity?.id && opportunity.id > 0) {
            window.open(`/opportunities-single/${opportunity.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    async onFavoriteChanged(item: SearchOpportunitiesResult) {
        await this.awaiter.awaitAction("Setting Favorite", async () => {
            try {
                if (!item.isFavorite) {
                    await this.opportunityService.setAsFavorite(item.id);
                    item.isFavorite = true;
                } else {
                    await this.opportunityService.removeFromFavorite(item.id);
                    item.isFavorite = false;
                }
            } catch {
                throw new Error("Error changing favorite mode");
            }
        });
    }

    private buildSearchConfig() {
        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage ?? 1,
            sortBy: this.orderConfig?.key ?? undefined,
            sortDirection: this.orderConfig?.order ?? undefined,
            filterText: this.filters?.searchText ?? undefined,
            opportunityStatusIds: this.filters?.statuses?.length ? this.filters?.statuses.map(s => s.id) : undefined,
            divisionIds: this.filters?.divisions?.length ? this.filters?.divisions.map(d => d.id) : undefined,
            probabilityValueFrom: this.filters?.probabilityFrom,
            probabilityValueTo: this.filters?.probabilityTo,
            onlyFavorites: this.filters?.onlyFavorites,
            companyIds: this.filters?.companies?.length ? this.filters?.companies.map(c => c.id) : undefined,
            salesUserId: this.filters?.salesperson?.externalId,
            dueDateFrom: this.filters?.dateFrom,
            dueDateTo: this.filters?.dateTo,
            potentialValueFrom: this.filters?.potentialValueFrom,
            potentialValueTo: this.filters?.potentialValueTo,
            proposedGrossMarginFrom: this.filters?.proposedGrossMarginFrom,
            proposedGrossMarginTo: this.filters?.proposedGrossMarginTo,
            isActive: this.filters?.activeStatus?.value
        } as SearchOpportunitiesParameters;
    }

    private getKeyFromLabel(label: string): keyof OpportunityView | "isFavorite" {
        const mapping: Record<string, keyof OpportunityView | "isFavorite"> = {
            ID: "code",
            Name: "name",
            "Due Date": "dueDate",
            Customer: "companyName",
            "Potential Value ($)": "potentialValue",
            "Potential GM (%)": "proposedGrossMarginPercentage",
            Division: "divisionName",
            Salesperson: "salesUsers",
            Status: "opportunityStatusName",
            Probability: "probabilityValue",
            Condition: "isActive",
            isFavorite: "isFavorite"
        };

        return mapping[label] ?? "name";
    }
}
