import { IconSanitizerService } from "projects/ngx-lib/src/lib/services/icon-sanitizer.service";
import { Component, Input } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-auth-wrapper",
    templateUrl: "./auth-wrapper.component.html",
    styleUrls: ["./auth-wrapper.component.scss"]
})
export class AuthWrapperComponent {
    @Input()
    title?: string;

    authIcon: SafeHtml;
    constructor(private readonly iconSanitizer: IconSanitizerService) {
        this.authIcon = this.iconSanitizer.getIcon("authWrapper");
    }
}
