import { GridDisplayScheme, OverviewLayout } from "projects/app/src/app/components/pages/reports/types/report-layout-type.enum";
import { ReportType } from "../../types/report-type.enum";
import { BidEstimateService } from "projects/app/src/app/services/http/clients/reporting-app/bid-estimates.service";
import { WidgetType } from "projects/app/src/app/components/pages/reports/types/report-widget-type.enum";
import { crmMainFilters } from "./shared/main-filters";
import {
    accountEditorConfig,
    bidAmountEditorConfig,
    bidDateEditorConfig,
    conditionEditorConfig,
    customerEditorConfig,
    divisionEditorConfig,
    estimateTypeEditorConfig,
    marketsEditorConfig,
    opportunitySourceEditorConfig,
    opportunityStatusEditorConfig,
    probabilityEditorConfig
} from "./shared/additional-filters";
import { IReportConfiguration } from "../../types/report-config.interface";
import { truncateValue, percentageFormatter } from "projects/app/src/app/utils/format-functions";
import { AgChartLegendLabelFormatterParams } from "ag-grid-enterprise";

export const EstimatesBid: IReportConfiguration<BidEstimateService> = {
    base: {
        type: ReportType.ReportSection,
        serviceType: BidEstimateService
    },
    overviewTab: {
        widgets: [
            {
                methodName: "getEstimateBoxesValues",
                type: WidgetType.Box,
                layoutTemplateArea: "boxes"
            },
            {
                methodName: "getEstimateMetricsValues",
                type: WidgetType.Metrics,
                layoutTemplateArea: "metrics",
                settings: {
                    cols: [
                        { name: "avg", label: "Average" },
                        { name: "min", label: "Min" },
                        { name: "max", label: "Max" },
                        { name: "med", label: "Med" }
                    ],
                    rows: [
                        { name: "BidAmount", label: "Bid Amount", format: "currency" },
                        { name: "GrossMarginPercentage", label: "Gross Margin (%)", format: "customPercent" }
                    ]
                }
            },
            {
                methodName: "getEstimateLineChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart",
                settings: {
                    title: {
                        text: "Top 10 Estimates by Bid Amount"
                    },
                    series: [
                        {
                            type: "line",
                            xKey: "estimateName",
                            yKey: "totalBidAmount",
                            yName: "Bid Amount"
                        },
                        {
                            type: "line",
                            xKey: "estimateName",
                            yKey: "totalGrossMarginPercentage",
                            yName: "Gross Margin (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            title: {
                                text: "Amount"
                            },
                            keys: ["totalBidAmount"]
                        },
                        {
                            type: "number",
                            position: "right",
                            title: {
                                text: "Percentage"
                            },
                            keys: ["totalGrossMarginPercentage"],
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            }
                        }
                    ]
                }
            },
            {
                methodName: "getEstimateColumnChartValues",
                type: WidgetType.Chart,
                layoutTemplateArea: "chart2",
                settings: {
                    title: {
                        text: "Salesperson Performance"
                    },
                    series: [
                        {
                            type: "bar",
                            xKey: "salesPerson",
                            yKey: "totalBidAmount",
                            yName: "Bid Amount"
                        },
                        {
                            type: "line",
                            xKey: "salesPerson",
                            yKey: "totalGrossMarginPercentage",
                            yName: "Gross Margin (%)"
                        }
                    ],
                    axes: [
                        {
                            type: "category",
                            position: "bottom",
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => truncateValue(params.value, 30)
                            }
                        },
                        {
                            type: "number",
                            position: "left",
                            keys: ["totalBidAmount"],
                            title: {
                                text: "Amount"
                            }
                        },
                        {
                            type: "number",
                            position: "right",
                            keys: ["totalGrossMarginPercentage"],
                            title: {
                                text: "Percentage"
                            },
                            label: {
                                formatter: (params: AgChartLegendLabelFormatterParams) => percentageFormatter(Number(params.value))
                            }
                        }
                    ]
                }
            }
        ],
        layout: OverviewLayout.ExtendedTwoPlusOne
    },
    gridTab: {
        widgets: [
            {
                methodName: "getEstimateValues",
                type: WidgetType.Grid,
                layoutTemplateArea: "grid",
                settings: [
                    { field: "baseEstimateId", headerName: "Estimate ID", lockVisible: true },
                    { field: "estimateName", headerName: "Estimate Name", enableRowGroup: true },
                    { field: "estimateType", headerName: "Estimate Type", enableRowGroup: true },
                    { field: "bidDate", headerName: "Bid Date", type: "date" },
                    { field: "opportunityCode", headerName: "Opportunity ID" },
                    { field: "opportunityName", headerName: "Opportunity Name", enableRowGroup: true },
                    { field: "customer", headerName: "Customer", enableRowGroup: true },
                    { field: "account", headerName: "Account", enableRowGroup: true },
                    { field: "bidAmount", headerName: "Bid Amount", type: "currency", aggFunc: "sum" },
                    { field: "grossMarginPercentage", headerName: "Gross Margin (%)", type: "customPercentage", aggFunc: "avg" },
                    { field: "division", headerName: "Division", enableRowGroup: true },
                    { field: "salesPerson", headerName: "Salesperson", enableRowGroup: true },
                    { field: "status", headerName: "Opportunity Status", enableRowGroup: true },
                    { field: "market", headerName: "Market" },
                    { field: "leadSource", headerName: "Opportunity Source", enableRowGroup: true },
                    { field: "organization", headerName: "Organization", enableRowGroup: true },
                    { field: "probability", headerName: "Probability", aggFunc: "avg" },
                    { field: "condition", headerName: "Condition", enableRowGroup: true },
                    { field: "opportunityLastUpdatedNote", headerName: "Last Updated Note", tooltipField: "opportunityLastUpdatedNote" }
                ]
            }
        ],
        layout: GridDisplayScheme.Standard
    },
    filters: {
        main: [...crmMainFilters],
        additional: [
            bidDateEditorConfig,
            bidAmountEditorConfig,
            estimateTypeEditorConfig,
            opportunityStatusEditorConfig,
            marketsEditorConfig,
            opportunitySourceEditorConfig,
            divisionEditorConfig,
            customerEditorConfig,
            accountEditorConfig,
            probabilityEditorConfig,
            conditionEditorConfig
        ]
    }
};
