<div class="container">
    <lib-section-header
        title="{{ opportunity.name ?? '' }}"
        subTitle="{{ opportunity.code }}"
        [actionsTemplate]="actionsTemplate"
        [mainAdditionalTemplate]="statusTemplate"
        [iconName]="'goBack'"
    >
        <ng-template #statusTemplate>
            <app-opportunity-status-indicator *ngIf="opportunityStatusId" [status]="opportunityStatusId" />
            <lib-status-indicator *ngIf="!isActive" [iconName]="'archived'" [colorType]="'error'" [text]="'Archived'"></lib-status-indicator>
        </ng-template>

        <ng-template #actionsTemplate>
            <div class="actions">
                <ng-container *ngIf="currentTab === 'Overview' && !isModelEqual">
                    <lib-helper-info message="Unsaved changes"></lib-helper-info>
                    <div class="editButtons">
                        <button class="secondary-btn" (click)="cancel()">Cancel</button>
                        <button class="primary-btn" [disabled]="isLoading" (click)="saveOpportunity()">Save</button>
                    </div>
                </ng-container>
                <button *ngIf="!baseEstimateExternalUrl && isModelEqual && canCreateEstimate" class="primary-btn" (click)="validateCreateEstimate()">
                    Create Estimate
                </button>
                <lib-external-link
                    *ngIf="baseEstimateExternalUrl && isModelEqual"
                    [url]="baseEstimateExternalUrl"
                    message="DIV25 Sales App"
                ></lib-external-link>
                <app-kebab-menu *ngIf="isModelEqual && (canArchive || canRestore)">
                    <button role="menuitem" *ngIf="canArchive && this.isActive" class="resetStyles" (click)="archiveOpportunity()">Archive</button>
                    <button role="menuitem" *ngIf="canRestore && !this.isActive" class="resetStyles" (click)="restoreOpportunity()">Restore</button>
                </app-kebab-menu>
            </div>
        </ng-template>
    </lib-section-header>

    <div class="tabGroup">
        <lib-tab-view [(currentTab)]="currentTab" (currentTabChange)="tabChange($event)" alignment="start" separation="wide">
            <lib-tab-item tabName="Overview" [bodyTemplate]="overviewTab">
                <ng-template #overviewTab>
                    <div class="tab">
                        <app-opportunities-single-overview
                            cdkScrollable
                            #overview
                            *ngIf="opportunity.id !== 0"
                            [opportunity]="opportunity"
                            [isReadOnly]="!canEditOpportunity"
                            (valueChange)="onValueChanged()"
                            [isCreateEstimate]="isCreateEstimate"
                            [actualValue]="actualValue"
                        ></app-opportunities-single-overview>
                    </div>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Estimates" [bodyTemplate]="estimatesTab">
                <ng-template #estimatesTab>
                    <app-opportunities-single-estimates
                        [opportunityId]="opportunity.id"
                        [opportunityEstimates]="opportunityEstimates"
                        (validate)="validateCreateEstimate()"
                    >
                    </app-opportunities-single-estimates>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Contacts" [bodyTemplate]="contactsTab" [headerTemplate]="headerTemplate">
                <ng-template #contactsTab>
                    <app-opportunities-single-contacts
                        [opportunityId]="opportunity.id"
                        (opportunityContactsChange)="onOpportunityContactsChange($event)"
                        (tabCounterChange)="onTabCountersChange()"
                    ></app-opportunities-single-contacts>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Team" [bodyTemplate]="teamTab" [headerTemplate]="headerTemplate">
                <ng-template #teamTab>
                    <app-opportunities-single-team
                        [opportunityId]="opportunity.id"
                        (opportunityUsersChange)="onOpportunityUsersChange($event)"
                        (tabCounterChange)="onTabCountersChange()"
                    ></app-opportunities-single-team>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Activity Log" [bodyTemplate]="activityLogTab">
                <ng-template #activityLogTab>
                    <app-opportunities-single-activity-log [opportunityId]="opportunity.id"></app-opportunities-single-activity-log>
                </ng-template>
            </lib-tab-item>
            <lib-tab-item tabName="Notes" [bodyTemplate]="notesTab" [headerTemplate]="headerTemplate">
                <ng-template #notesTab>
                    <div class="tab">
                        <app-notes [entityTypeId]="entityTypeId" [entityId]="opportunity.id" (tabCounterChange)="onTabCountersChange()"></app-notes>
                    </div>
                </ng-template>
            </lib-tab-item>

            <ng-template #headerTemplate let-tab="tab">
                <lib-counter-badge [label]="tab.tabName" [count]="tabsCounters[tab.tabName] || 0"></lib-counter-badge>
            </ng-template>
        </lib-tab-view>
    </div>
</div>
