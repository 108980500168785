import { OpportunityCompanyView, OpportunityCompanyRoleView, CompanyView } from "../services/http/clients/api-proxies";
import { ModelBase } from "./ModelBase";
export class OpportunityCompany extends ModelBase implements OpportunityCompanyView {
    opportunityName: string | undefined;
    companyId: number;
    companyName: string | undefined;
    companyRoleDescriptions: string | undefined;
    fullAddress: string | undefined;
    companyTypeName: string | undefined;
    markets: string | undefined;
    opportunityCompanyRoles: OpportunityCompanyRoleView[] | undefined;
    constructor() {
        super();
        this.opportunityName = "";
        this.companyId = 0;
        this.companyName = "";
        this.companyRoleDescriptions = "";
        this.fullAddress = "";
        this.companyTypeName = "";
        this.markets = "";
        this.opportunityCompanyRoles = [];
    }
    static fromInterface(dto: OpportunityCompanyView): OpportunityCompany {
        const opportunityCompany = new OpportunityCompany();
        opportunityCompany.mapFromInterface(dto);
        return opportunityCompany;
    }

    static fromCompanyView(companyView: CompanyView): OpportunityCompany {
        const opportunityCompany = new OpportunityCompany();
        opportunityCompany.companyId = companyView.id;
        opportunityCompany.companyName = companyView.name;
        opportunityCompany.fullAddress = companyView.fullAddress;
        opportunityCompany.companyTypeName = companyView.companyTypeName;
        opportunityCompany.markets = companyView.markets;
        opportunityCompany.opportunityCompanyRoles = [];
        return opportunityCompany;
    }
}
