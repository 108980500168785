import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from "@angular/core";
import {
    IGenericGridOrderConfig,
    IGenericGridPaginationConfig,
    IRouterLinkConfig,
    Order
} from "projects/ngx-lib/src/lib/components/generic-grid/generic-grid.component";
import { AccountView, SearchAccountParameters } from "projects/app/src/app/services/http/clients/api-proxies";
import { IFilterValueAccounts } from "../accounts-main/accounts-main.component";
import { Router, ActivatedRoute } from "@angular/router";
import { AwaiterService } from "projects/app/src/app/services/awaiter.service";
import { TabNames } from "projects/app/src/app/models/enums/TabNames";
import { AccountService } from "projects/app/src/app/services/http/clients/account.service";

@Component({
    selector: "app-accounts-list",
    templateUrl: "./accounts-list.component.html",
    styleUrls: ["./accounts-list.component.scss"]
})
export class AccountsListComponent implements OnChanges, OnInit {
    accounts: AccountView[];

    @Input() filters?: IFilterValueAccounts;

    @Output() sortChanged = new EventEmitter<IGenericGridOrderConfig<AccountView>>();

    orderConfig?: IGenericGridOrderConfig<AccountView>;

    paginationConfig: IGenericGridPaginationConfig;

    searchConfig: SearchAccountParameters;

    noResults: boolean;

    isLoading: boolean;

    routerLinkConfig: IRouterLinkConfig<AccountView> = {
        base: "/accounts-single",
        idKey: "id",
        extra: [TabNames[TabNames.Overview]]
    };

    constructor(
        private readonly accountService: AccountService,
        private readonly router: Router,
        private readonly awaiter: AwaiterService,
        private readonly route: ActivatedRoute
    ) {
        this.accounts = [];
        this.paginationConfig = {
            pagesCount: 0,
            totalCount: 0,
            currentPage: 1,
            autoLoad: true
        };

        this.searchConfig = {
            pageNumber: this.paginationConfig.currentPage
        } as SearchAccountParameters;

        this.noResults = true;
        this.isLoading = true;
    }

    async ngOnInit(): Promise<void> {
        const params = this.route.snapshot.queryParams;
        const sortBy = params["sortBy"] as string | undefined;
        const sortDirection = params["sortDirection"] as Order | undefined;

        if (sortBy && sortDirection) {
            const newOrderConfig: IGenericGridOrderConfig<AccountView> = {
                key: this.getKeyFromLabel(sortBy),
                displayName: sortBy,
                order: sortDirection
            };

            await this.onOrderChanged(newOrderConfig);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!changes["filters"].firstChange) {
            this.paginationConfig.currentPage = 1;
            this.accounts = [];
            await this.refreshGridView();
        }
    }

    async refreshGridView() {
        this.buildSearchConfig();

        await this.awaiter.awaitAction(
            "Loading Accounts",
            async () => {
                const response = await this.accountService.searchAccount(this.searchConfig);
                const { pageInfo, results } = response;
                this.accounts = this.accounts?.concat(results ?? []);
                this.noResults = this.accounts.length === 0;
                this.paginationConfig.pagesCount = pageInfo?.totalPages ?? 0;
                this.paginationConfig.currentPage = pageInfo?.pageNumber ?? 1;
                this.paginationConfig.totalCount = pageInfo?.itemsCount ?? 0;
            },
            loading => (this.isLoading = loading)
        );
    }

    private buildSearchConfig() {
        this.searchConfig.pageNumber = this.paginationConfig.currentPage ?? 1;
        this.searchConfig.filterText = this.filters?.searchText;
        this.searchConfig.accountTypeId = this.filters?.accountType?.id;
        this.searchConfig.marketId = this.filters?.market?.id;
        this.searchConfig.isActive = this.filters?.activeStatus?.value;
    }

    async onOrderChanged(newOrderConfig: IGenericGridOrderConfig<AccountView> | undefined): Promise<void> {
        this.orderConfig = newOrderConfig;

        this.searchConfig.sortBy = newOrderConfig?.displayName;
        this.searchConfig.sortDirection = newOrderConfig?.order;

        if (this.filters) {
            this.filters.sortBy = newOrderConfig?.displayName;
            this.filters.sortDirection = newOrderConfig?.order;
        }

        this.paginationConfig.currentPage = 1;
        this.accounts = [];

        await this.refreshGridView();

        this.sortChanged.emit(this.orderConfig);
    }

    onRowClicked(account: AccountView) {
        this.router.navigate(["/accounts-single", account.id, TabNames[TabNames.Overview]]);
    }

    onMouseWheelClicked(account: AccountView) {
        if (account?.id && account.id > 0) {
            window.open(`/accounts-single/${account.id}/${TabNames[TabNames.Overview]}`, "_blank");
        }
    }

    private getKeyFromLabel(label: string): keyof AccountView {
        const mapping: Record<string, keyof AccountView> = {
            Name: "name",
            Type: "accountTypeName",
            Markets: "markets",
            Condition: "isActive"
        };
        return mapping[label] ?? "name";
    }
}
