import { Component } from "@angular/core";
import { ReportWidgetComponent } from "../report-widget.component";

@Component({
    selector: "app-metrics-widget",
    templateUrl: "./metrics-widget.component.html"
})
export class MetricsWidgetComponent<TService, TParameters> extends ReportWidgetComponent<TService, TParameters> {
    metricsData: any;

    override setResultData(result: unknown): void {
        this.metricsData = result;
    }
}
